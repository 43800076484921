import { invokeApi } from '../utils/invokeApi';
import {LOCAL_STORAGE_KEYS} from "../utils/constants";
const NodeRSA = require('node-rsa');
const crypto = require('crypto');

const getRSAPubKey = async () => {
    let requestObj = {
        path   : '/my_dq/auth/passencrypt/pubkey',
        method : 'GET'
    };
    const resp = await invokeApi(requestObj);
    if (resp.data.publicKey) return resp.data.publicKey;
    return false
}

export const loginUser = async (email, _password, remember_me) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/my_dq/auth/login',
        method : 'POST',
        headers : {
            'xc-dq-locale': locale
        }
    };

    let encapsulation = false;
    let password = _password;
    const publicKeyString = await getRSAPubKey();
    if (publicKeyString) {
        try {
            const publicKey = new NodeRSA(publicKeyString);
            const nonce = crypto.randomBytes(16).toString('hex');
            const timestamp = new Date().toISOString();
            const crypto_str = `${nonce}-${timestamp}`
            const password_obj = JSON.stringify({ _password, crypto_str });

            password = publicKey.encrypt(password_obj, 'base64');
            encapsulation = true;
        } catch (e) {
            // will continue sending the plane password text
        }
    }

    const postData = {
        email,
        password,
        encapsulation,
        remember_me
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const getGoogleSignInLink = () => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/my_dq/auth/oauth2/google',
        method : 'GET',
        headers : {
            'xc-dq-locale': locale,
        }
    };
    return invokeApi(requestObj);
}

export const persistGuestGetGoogleSignInLink = () => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/auth/persist_guest/oauth2/google',
        method : 'GET',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale,
        }
    };
    return invokeApi(requestObj);
}

export const loginUserViaSSOToken = (sso_token, platform) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/my_dq/auth/login_via_sso_token',
        method : 'POST',
        headers : {
            'xc-dq-locale': locale
        }
    };
    requestObj['postData'] = {
        sso_token,
        platform
    };

    return invokeApi(requestObj);
}

export const loginUserViaToken = (access_token, single_use) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/my_dq/auth/login_via_token',
        method : 'POST',
        headers : {
            'xc-dq-locale': locale
        }
    };
    requestObj['postData'] = {
        access_token,
        single_use
    };

    return invokeApi(requestObj);
}

export const loginUserViaConnectToken = async (connect_token, signature) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/my_dq/auth/login_via_connect_token',
        method : 'POST',
        headers : {
            'xc-dq-locale': locale
        }
    };

    const publicKeyString = await getRSAPubKey();
    const publicKey = new NodeRSA(publicKeyString);
    const nonce = crypto.randomBytes(16).toString('hex');
    const timestamp = new Date().toISOString();
    const crypto_str = `${nonce}-${timestamp}`
    const connect_obj = JSON.stringify({ connect_token, signature, crypto_str });

    const dq_data = publicKey.encrypt(connect_obj, 'base64');

    requestObj['postData'] = {
        dq_data
    };

    return invokeApi(requestObj);
}

export const setPasswordViaToken = async (access_token, _password) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/auth/set_password/via_token',
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        }
    };

    let encapsulation = false;
    let password = _password;
    const publicKeyString = await getRSAPubKey();
    if (publicKeyString) {
        try {
            const publicKey = new NodeRSA(publicKeyString);
            const nonce = crypto.randomBytes(16).toString('hex');
            const timestamp = new Date().toISOString();
            const crypto_str = `${nonce}-${timestamp}`
            const password_obj = JSON.stringify({ _password, crypto_str });

            password = publicKey.encrypt(password_obj, 'base64');
            encapsulation = true;
        } catch (e) {
            // will continue sending the plane password text
        }
    }
    requestObj['postData'] = {
        access_token,
        password,
        encapsulation
    };

    return invokeApi(requestObj);
}

export const initPassReset = (email) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/my_dq/auth/init_reset_password',
        method : 'POST',
        headers : {
            'xc-dq-locale': locale
        }
    };
    const postData = {
        email,
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const passReset = async (reset_token, _password) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/my_dq/auth/reset_password',
        method : 'POST',
        headers : {
            'xc-dq-locale': locale
        }
    };

    let encapsulation = false;
    let password = _password;
    const publicKeyString = await getRSAPubKey();
    if (publicKeyString) {
        try {
            const publicKey = new NodeRSA(publicKeyString);
            const nonce = crypto.randomBytes(16).toString('hex');
            const timestamp = new Date().toISOString();
            const crypto_str = `${nonce}-${timestamp}`
            const password_obj = JSON.stringify({ _password, crypto_str });

            password = publicKey.encrypt(password_obj, 'base64');
            encapsulation = true;
        } catch (e) {
            // will continue sending the plane password text
        }
    }
    const postData = {
        reset_token,
        password,
        encapsulation
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const registerUser = async (username, email, _password, first_name, last_name, access_code, remember_me, showAccessCode, access_info) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/my_dq/auth/register',
        method : 'POST',
        headers : {
            'xc-dq-locale': locale
        }
    };
    let access_code_required = false;
    if (showAccessCode) {
        access_code_required = true;
    }

    let encapsulation = false;
    let password = _password;
    const publicKeyString = await getRSAPubKey();
    if (publicKeyString) {
        try {
            const publicKey = new NodeRSA(publicKeyString);
            const nonce = crypto.randomBytes(16).toString('hex');
            const timestamp = new Date().toISOString();
            const crypto_str = `${nonce}-${timestamp}`
            const password_obj = JSON.stringify({ _password, crypto_str });

            password = publicKey.encrypt(password_obj, 'base64');
            encapsulation = true;
        } catch (e) {
            // will continue sending the plane password text
        }
    }
    const postData = {
        username,
        email,
        password,
        encapsulation,
        first_name,
        last_name,
        access_code,
        remember_me,
        access_code_required,
        access_info
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const persistGuestRegisterUser = async (username, email, _password, first_name, last_name, access_code, remember_me, showAccessCode) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/auth/persist_guest/register',
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        }
    };
    let access_code_required = false;
    if (showAccessCode) {
        access_code_required = true;
    }

    let encapsulation = false;
    let password = _password;
    const publicKeyString = await getRSAPubKey();
    if (publicKeyString) {
        try {
            const publicKey = new NodeRSA(publicKeyString);
            const nonce = crypto.randomBytes(16).toString('hex');
            const timestamp = new Date().toISOString();
            const crypto_str = `${nonce}-${timestamp}`
            const password_obj = JSON.stringify({ _password, crypto_str });

            password = publicKey.encrypt(password_obj, 'base64');
            encapsulation = true;
        } catch (e) {
            // will continue sending the plane password text
        }
    }
    const postData = {
        username,
        email,
        password,
        encapsulation,
        first_name,
        last_name,
        access_code,
        remember_me,
        access_code_required
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const persistGuestLoginUser = async (email, _password, remember_me) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/auth/persist_guest/login',
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        }
    };

    let encapsulation = false;
    let password = _password;
    const publicKeyString = await getRSAPubKey();
    if (publicKeyString) {
        try {
            const publicKey = new NodeRSA(publicKeyString);
            const nonce = crypto.randomBytes(16).toString('hex');
            const timestamp = new Date().toISOString();
            const crypto_str = `${nonce}-${timestamp}`
            const password_obj = JSON.stringify({ _password, crypto_str });

            password = publicKey.encrypt(password_obj, 'base64');
            encapsulation = true;
        } catch (e) {
            // will continue sending the plane password text
        }
    }
    const postData = {
        email,
        password,
        encapsulation,
        remember_me
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}


export const logoutUser = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    if (token) {
        let requestObj = {
            path   : '/my_dq/auth/logout',
            method : 'POST',
            headers : {
                Authorization : `Bearer ${token}`
            }
        };
        requestObj['postData'] = {
            all_devices: true
        };

        return invokeApi(requestObj);
    }
    return {
        logged_out: true,
        local_token_missing: true
    };
};