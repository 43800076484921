import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../utils/invokeApi";

export const getStripeConfigService = () => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/payment/stripe/config',
        method : 'GET',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        }
    };

    return invokeApi(requestObj);
};


export const getProductsService = () => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/payment/products/get',
        method : 'GET',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        },
    };
    return invokeApi(requestObj);
};

export const buyPackageStripeService = (profile_id, pack_code) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/payment/stripe/package',
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        },
        postData : {
            profile_id,
            pack_code
        }
    };
    return invokeApi(requestObj);
};

export const buyModuleStripeService = (profile_id, module_code) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/payment/stripe/module',
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        },
        postData : {
            profile_id,
            module_code
        }
    };
    return invokeApi(requestObj);
};

export const buyBadgeStripeService = (profile_id, badge_code) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/payment/stripe/badge',
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        },
        postData : {
            profile_id,
            badge_code
        }
    };
    return invokeApi(requestObj);
};

export const updateStripeIntentService = (intent_id) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/payment/stripe/intent/update',
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        },
        postData : {
            intent_id,
        }
    };
    return invokeApi(requestObj);
};

export const applyVoucherService = (voucher_code, payment_id) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/payment/voucher/apply',
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        },
        postData : {
            voucher_code,
            payment_id
        }
    };
    return invokeApi(requestObj);
};