import React, {useState} from 'react';
import Button, {btnSize} from "../../../components/UI/Button/Button.ui";
import {useAuth} from "../../../context/auth";
import {useGlobalState} from "../../../context/global_state";
import {registerUser, setPasswordViaToken} from "../../../services/auth.service";

import { withRouter } from "react-router";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {loadNewGuestUser} from "../auth_common.lib";
import headerImg from '../../../assets/icons/dq-icons/dq-logo.png';
import dqIcon from "../../../assets/icons/dq-icons/dq-logo.png";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import {useLocation} from "react-router-dom";

const CompleteRegisteration = (props) => {

    const { history } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);

    const [formPassword, setFormPassword] = useState('');
    const [formConfirmPassword, setFormConfirmPassword] = useState('');

    const { setToastShow, setToastMessage, profile, setProfile } = useGlobalState();

    const registerAction = async () => {

        // check that no input field is null or blank
        if ([formPassword, formConfirmPassword].includes('')) {
            setToastShow(true);
            setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__plz_fill_req_fields'))
            return
        }

        // check if users have checked the agreement box

        // check if both the password matches
        if (formPassword !== formConfirmPassword) {
            setToastShow(true);
            setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'uo__pass_mismatch_plz_retype'))
            return
        }

        setLoading(true);
        try {
            const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN_FOR_SET_PASSWORD);
            if (token && token.trim().length > 1) {
                const result = await setPasswordViaToken(token, formPassword);
                console.log('result.data complete-reg--> ', result.data);
                if (result.data && result.data.success) {
                    localStorage.setItem(LOCAL_STORAGE_KEYS.FORCE_TO_SET_PASSWORD, false);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN_FOR_SET_PASSWORD, '');
                }
            }
            const switch_profile = Boolean(localStorage.getItem(LOCAL_STORAGE_KEYS.PROMPT_SWITCH_PROFILE));
            if(switch_profile) {
                history.push('/switch_profile');
            } else {
                const dqw_access = localStorage.getItem(LOCAL_STORAGE_KEYS.ORG_HAVE_DQ_ACCESS);
                if (dqw_access && dqw_access === 'true') {
                    history.push('/select_platform');
                } else {
                    history.push('/home');
                }
            }
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
        }
        setLoading(false);
        // // proceed to register the user, invoke the registration API
        // try {
        //     setLoading(true);
        //     let profile
        //     const remember_me = true;
        //     const result = await registerUser(formEmail, formPassword, formFirstName, formLastName, remember_me);
        //     if (result.data && result.data.user) {
        //         await setAuthToken(result.data.auth_token);
        //         await setAuthUser(result.data.user);
        //
        //         profile = result.data.profile
        //         await setProfile(profile);
        //         imp:: if re-use this code-block, do set the proper settings in state too, using setSettings from context-api
        //
        //         if (!profile) {
        //             return history.push('/home')
        //         }
        //
        //         if (profile.is_demographic_updated) {
        //             history.push('/home')
        //         } else {
        //             history.push('/demographic')
        //         }
        //     }
        //     setLoading(false)
        // } catch (e) {
        //     setToastShow(true);
        //     setToastMessage(e.message);
        //     setLoading(false);
        // }

    }


    const onChangePassword = (e) => {
        setFormPassword(e.target.value);
    }

    const onChangeConfirmPassword = (e) => {
        setFormConfirmPassword(e.target.value);
    }
    // FORM VALUE EVENTS END's

    return (
        <div className={'register-container'}>

            <LoaderWithBackDrop
                loading={loading}
            />

            <div className="dq-logo-image"><img src={dqIcon} alt='DQ'/></div>


            <div className={'dq-audience-login'}>
                <p>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__plz_set_ur_new_pass')}</p>
            </div>

            <form onSubmit={e => e.preventDefault()}>

                <div className='dq-register-row'>
                    <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__password')}</label>
                    <input className={'dq-register-txt'}
                        type='password'
                        name={'password'}
                        id={'password'}
                        value={formPassword}
                        onChange={onChangePassword}
                    />
                </div>

                <div className='dq-register-row'>
                    <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__confirm_password')}</label>
                    <input className={'dq-register-txt'}
                        type='password'
                        name={'confirm_password'}
                        id={'confirm_password'}
                        value={formConfirmPassword}
                        onChange={onChangeConfirmPassword}
                    />
                </div>
            </form>

            <div className={'dq-options-submit'}>
                <button onClick={registerAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__submit')}</button>
            </div>

            {/*<div className={'dq-audience-login'}>*/}
            {/*    <p>Already have an account?</p>*/}
            {/*    <a onClick={loginLink}>Login here</a>*/}
            {/*</div>*/}

            {/*<br />*/}
            {/*<br />*/}
            {/*<br />*/}
            {/*<a*/}
            {/*    className={'login-link'}*/}
            {/*    onClick={guestLogin}*/}
            {/*>Continue as Guest</a>*/}

        </div>
    );

}

export default withRouter(CompleteRegisteration);
