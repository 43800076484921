import React, {useState} from 'react';
import {useGlobalState} from "../../../context/global_state";

import { withRouter } from "react-router";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import dqIcon from "../../../assets/icons/dq-icons/dq-logo.png";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import {useLocation} from "react-router-dom";

const SwitchProfile = (props) => {

    const { history } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);

    const { setToastShow, setToastMessage, profile, setProfile } = useGlobalState();

    const available_profiles = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AVAILABLE_PROFILES));

    const onSelectProfile = async (org_code) => {
        const selected_profile = available_profiles.find(profile => profile.org_code === org_code);
        if(selected_profile) {
            localStorage.setItem(LOCAL_STORAGE_KEYS.PROMPT_SWITCH_PROFILE, "false");
            localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE, org_code);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.HOME_APP_START_API_RESPONSE);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.HOME_APP_START_CACHE_TIME);
            setProfile(selected_profile);
            if(selected_profile.is_demographic_updated) {
                history.push('/select_platform')
            }
            else {
                history.push('/demographic')
            }
        }
        else {
            setToastShow(true);
            setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'dq_profile__not_exists'));
        }
    }

    return (
        <div className={'register-container'}>

            <LoaderWithBackDrop
                loading={loading}
            />

            <div className="dq-logo-image"><img src={dqIcon} alt='DQ'/></div>

            <div className={'dq-audience-login'}>
                <p>
                    {GET_APP_LOCALIZE_TEXT(pageLc, 'dq_profile__multiple')}
                </p>
            </div>

            {
                available_profiles.map((profile) =>
                    <div className={'dq-options-submit'}>
                        <button onClick={() => onSelectProfile(profile.org_code)}>
                            {profile.org_code}
                        </button>
                    </div>
            )}
        </div>
    );

}

export default withRouter(SwitchProfile);
