import React, {useEffect, useState} from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {useLocation} from "react-router-dom";

export default function CheckoutForm(props) {

    const {paymentIntent, setLoading, onApplyVoucher, discount} = props;

    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [voucher, setVoucher] = useState("");

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    useEffect(() => { //on first render
        setLoading(false);
    }, [])

    const handleSubmit = async () => {

        setLoading(true);

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setMessage(null);
        setIsProcessing(true);

        // if referrer is empty, use products page as default
        const referrer = location.state.referrer || '/products'
        const returnURL = `${window.location.href}?redirect_to=${referrer}`;

        const {discounted_amount} = discount || {};
        if(discounted_amount === 0) { //mitigate flow in case of discount
            const extraParams = `&payment_intent=${paymentIntent}&redirect_status=succeeded`
            window.location.replace(returnURL+extraParams);

        } else { //real flow
            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: returnURL
                },
            });

            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                console.error(error);
                setMessage("An unexpected error occurred.");
            }
        }

        setIsProcessing(false);
        setLoading(false);
    };

    return (
        <>
            <PaymentElement id="payment-element" />
            <div className="stripe-label">Voucher Code</div>
            <div className="stripe-text-field-container">
                <input className="stripe-text-field" value={voucher} onChange={(e) => setVoucher(e.target.value)}  type="text" placeholder={'Optional'}/>
                <button id="apply-voucher" onClick={() => onApplyVoucher(voucher)}>
                    Apply Code
                </button>
            </div>
            <br/>
            <button className={'iaction-btn'} id="submit" disabled={isProcessing || !stripe || !elements} onClick={() => handleSubmit()}>
                {isProcessing ? "Processing..." : "Pay now"}
            </button>
            {/* Show any error or success messages */}
            {message && <div style={{color: "red"}}>{message}</div>}
            <br/>
        </>
    );
}