import { DQ_THEMES } from "../utils/constants";
import NUSWheel from "../assets/images/NUS_Wheel2.png"
import RaazImg from "../assets/images/Raaz-Img3.png"
import NUSLogo from "../assets/images/NUS_Logo.png"
import dqIcon from '../assets/icons/dq-icons/dq-logo.png'
import cancelIcon from '../assets/icons/cancel.png'
import Spinner from '../assets/images/dq-spinner.png'
export const defaultVariant = {
    name: DQ_THEMES.DEFAULT,
    image: '',
    icon: dqIcon,
    logo: dqIcon,
    headerImg: dqIcon,
    displayVariant: {
        display: 'block',
    },
    scoreSpinner: Spinner,
    fonts: ["Source Sans Pro", "Helvetica Neue", "Lemon/Milk","Varela", "BaiJamj"],
    fontWeight: {
        light: '500',
        bold: '900',
        semibold:'600',
    }
}
export const NUS_Variant = {
    name: DQ_THEMES.NUS_THEME,
    image:NUSWheel,
    logo: NUSLogo,
    icon: NUSWheel,
    headerImg: cancelIcon,
    displayVariant:{
        display:'none',
        backgroundColor: '#00acb1',
    },
    scoreSpinner:NUSWheel,
    fonts: ["Source Sans Pro", "Helvetica Neue", "Lemon/Milk","Varela", "BaiJamj"],
    fontWeight: {
        light: '500',
        bold: '900',
        semibold:'600',
    }
};

const variants=[
    defaultVariant,
    NUS_Variant,
]
export default variants;