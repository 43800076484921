import variants, {defaultVariant, NUS_Variant} from './variants';
import {DQ_THEMES} from "../utils/constants";

const createTheme = (themeName) => {
    let themeConfig;

    switch (themeName) {
        case DQ_THEMES.NUS_THEME:
            themeConfig = NUS_Variant;
            break;
        case DQ_THEMES.DEFAULT:
            themeConfig = defaultVariant;
            break;
        default:
            console.warn(new Error(`The theme ${themeName} is not valid`));
            themeConfig = defaultVariant;
    }

    const theme = {
        ...themeConfig,
        palette: {
            ...themeConfig.palette,
        },
    };

    console.log("Constructed Theme:", theme);
    return theme;
};

export default createTheme;
