import React, {useEffect, useState} from 'react';
import {useGlobalState} from "../../context/global_state";

import { withRouter } from "react-router";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import dqIcon from "../../assets/icons/dq-icons/dq-logo.png";
import {useLocation} from "react-router-dom";
import {getProductsService, updateStripeIntentService} from "../../services/payment.service";
import {PRODUCT_TYPE} from "../../utils/constants";
import {getProfileService} from "../../services/home.service";

const Products = (props) => {

    const { history } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);

    const { setToastShow, setToastMessage, profile, setProfile } = useGlobalState();

    const [packages, setPackages] = useState([]);

    const {autoSelect, productType, referrer} = location.state || {};

    async function getProducts() {
        setLoading(true);
        try {
            const result = await getProductsService();
            if(result.data) {
                const {packages} = result.data;
                setPackages(packages);
                if(autoSelect && productType === PRODUCT_TYPE.PACKAGE) {
                    const pack = packages.find((pack) => pack.code === autoSelect)
                    if(pack) onSelectPackage(pack, referrer);
                }
            }
        }
        catch (e) {
            console.error(e);
            setToastShow(true);
            setToastMessage('Unable to find products');
        }
        setLoading(false);
        return Promise.resolve(true);
    }

    const reloadProfile = async () => {
        setLoading(true);
        let updated_profile = profile;
        try {
            const res = await getProfileService();
            updated_profile = {
                ...profile,
                ...res.data.profile
            }
            setProfile(updated_profile);
        } catch (e) {
            console.error(e)
            setToastMessage('Unable to reload profile');
            setToastShow();
        }
        setLoading(false);
    }

    function onSelectPackage(pack, referrer) {
        const defaultReferrer = '/products';
        const params = {
            pathname: `/checkout`,
            state: {
                product: {...pack, type: PRODUCT_TYPE.PACKAGE},
                referrer: referrer || defaultReferrer
            }
        }
        if(referrer) history.replace(params); //replace so that it goes to actual referrer on back button
        else history.push(params);
    }

    useEffect(() => {
        if(profile && profile.org_code) {
            getProducts().then(_ => console.log('products fetched successfully'))
        } else {
            reloadProfile().then(_ => console.log('profile reloaded successfully'));
        }
    }, [profile])

    return (
        <div className={'register-container'}>

            <LoaderWithBackDrop
                loading={loading}
            />

            <div className="dq-logo-image"><img src={dqIcon} alt='DQ'/></div>

            <div className={'dq-audience-login'}>
                <p>
                    Select a Package
                </p>
                {
                    packages.map((pack) =>
                        <div className={'dq-options-submit'}>
                            <button disabled={pack.is_owned} onClick={() => onSelectPackage(pack)}>
                                {pack.name} - {pack.cost} {pack.currency.toUpperCase()}
                                {
                                    pack.is_owned &&
                                        <div>
                                            (Owned)
                                        </div>
                                }
                            </button>
                        </div>
                    )
                }
                <br/>
                <button className={'iaction-btn'} onClick={() => history.push('/home')}>Go Home</button>
            </div>
        </div>
    );

}

export default withRouter(Products);
