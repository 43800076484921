import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../utils/invokeApi";

export const globalNationalAveragesScoresService = (org_code, progL5, country, scoring) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : `/my_dq/stats/averages/scores`,
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        org_code, 
        progL5,
        country,
        scoring: scoring || "standards"
    };

    return invokeApi(requestObj);
}