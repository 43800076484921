import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { getActiveThemeColor } from "../../../utils/utils";
import { useGlobalState } from "../../../context/global_state";
import NUS_Wheel2 from "../../../assets/images/NUS_Wheel2.png";

const LSQSliderPoster = (props) => {
    const { choices, onChoiceClick } = props;
    const [selectedIndex, setSelectedIndex] = useState(3);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const { profile } = useGlobalState();
    const slidingBarRef = React.createRef();

    useEffect(() => {
        setSelectedIndex(3);
        setPosition({ x: (3 * 14 + 5) / 100 * slidingBarRef.current.offsetWidth, y: 0 });
    }, [choices]);

    const onSliderDrag = (event, info) => {
        const sliderPosition = info.x / slidingBarRef.current.offsetWidth * 100;
        const newIndex = calculateIndex(sliderPosition);
        setSelectedIndex(newIndex);
    };

    const onSliderStop = (event, info) => {
        const sliderPosition = info.x / slidingBarRef.current.offsetWidth * 100;
        const newIndex = calculateIndex(sliderPosition);
        onChoiceClick(newIndex);
        setSelectedIndex(newIndex);
        setPosition({ x: info.x, y: 0 });
    };

    const calculateIndex = (sliderPosition) => {
        let newIndex;
        if (sliderPosition <= 8.7) {
            newIndex = 0;
        } else if (sliderPosition > 8.7 && sliderPosition <= 24.7) {
            newIndex = 1;
        } else if (sliderPosition > 24.7 && sliderPosition <= 39.5) {
            newIndex = 2;
        } else if (sliderPosition > 39.5 && sliderPosition <= 53.5) {
            newIndex = 3;
        } else if (sliderPosition > 53.5 && sliderPosition <= 67) {
            newIndex = 4;
        } else if (sliderPosition > 67 && sliderPosition <= 80) {
            newIndex = 5;
        } else if (sliderPosition > 80 && sliderPosition <= 90) {
        newIndex = 6;
    } else {
            newIndex = 4;
        }
        return newIndex;
    };

    const sliderAnswerColor = (index) => {
        return selectedIndex === index ? { color: getActiveThemeColor(profile) } : {};
    };

    const sliderAnswerClick = (index) => {
        onChoiceClick(index);
        setSelectedIndex(index);
        setPosition({ x: (index * 14 + 5) / 100 * slidingBarRef.current.offsetWidth, y: 0 });
    };

    return (
        <div className={'qt-slider-container'}>
            <div className={'qt-slider-section'} ref={slidingBarRef} style={{ border: '2px solid rgb(0, 172, 177)', backgroundColor: '#eee' }}>
                <Draggable axis="x" position={position} bounds="parent" onDrag={onSliderDrag} onStop={onSliderStop}>
                    <img className={'qt-slider-img'} src={NUS_Wheel2} />
                </Draggable>
            </div>
            <div className={'qt-slider-answers'}>
                {
                    choices.map( (choice, index) => (<span
                            key={index}
                        style={sliderAnswerColor(index)}
                        className={'qt-slider-answer-span'} onClick={() => sliderAnswerClick(index)}
                    >{index+1}</span>))
                }
            </div>
        </div>
    );
};

export default LSQSliderPoster;
