import React, {useEffect, useState} from 'react';
import { withRouter } from "react-router";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import dqIcon from '../../assets/icons/dq-icons/dq-logo.png';
import StripePayment from "./components/StripePayment.comp";
import {applyVoucherService} from "../../services/payment.service";
import {useGlobalState} from "../../context/global_state";

const Checkout = (props) => {

    const { history } = props;

    const location = history.location;

    const {product} = location.state || {};
    const {name, cost, currency} = product || {};

    const [loading, setLoading] = useState(true);
    const [paymentID, setPaymentID] = useState(null); //can be re-used if payment method is changed
    const [discountDetails, setDiscountDetails] = useState(null);
    const {discounted_amount, amount_off, percent_off} = discountDetails || {};

    const {setToastMessage, setToastShow, setProfile} = useGlobalState();

    useEffect(() => {
        if(!product) {
            const params = location.search;
            if(!params) {
                history.push('/products')
            }
        }
    }, [])

    function onCreatePayment(id) {
        setPaymentID(id);
    }

    async function onApplyVoucher(voucher) {
        setLoading(true);
        try {
            const result = await applyVoucherService(voucher, paymentID);
            if(result.data) {
                setDiscountDetails(result.data);
                setToastMessage("Voucher applied successfully")
                setToastShow(true);
            }
        }
        catch (e) {
            console.error(e);
            setToastMessage(e.message);
            setToastShow(true);
        }
        setLoading(false);
    }

    return (
        <div className={'register-container'}>

            <LoaderWithBackDrop
                loading={loading}
            />

            <div className="dq-logo-image"><img src={dqIcon} alt='DQ'/></div>
            {
                <div className={'dq-audience-login'}>
                    {
                        product ?
                            <p>
                                You're buying: <b>{name}</b> for &nbsp;
                                {
                                    !discountDetails ?
                                        `${cost} ${currency.toUpperCase()}`
                                        :
                                        <>
                                            <s>{cost}</s>&nbsp;
                                            {discounted_amount}&nbsp;
                                            {currency.toUpperCase()}&nbsp;
                                            {"(Discounted)"}
                                            {/*{amount_off ? `(${amount_off} ${currency.toUpperCase()} off)` : null}*/}
                                            {/*{amount_off && percent_off ? " & " : null}*/}
                                            {/*{percent_off ? `(${percent_off}% off)` : null}*/}
                                        </>
                                }
                            </p>
                        : null
                    }
                    <br/>
                    <StripePayment
                        product={product}
                        paymentID={paymentID}
                        onCreatePayment={onCreatePayment}
                        onApplyVoucher={onApplyVoucher}
                        setLoading={setLoading}
                        discount={discountDetails}
                    />
                </div>
            }
            </div>
    );

}

export default withRouter(Checkout);
