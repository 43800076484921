import {GET_APP_LOCALIZE_TEXT, GET_WEBSITE_LOCALIZE_TEXT} from "./dq_lc_service/lc_service";
export const DEFAULT_ORG_CODE = 'DQ';

export const LOCAL_STORAGE_KEYS = {
    AUTH_TOKEN: 'my_dq_auth_token',
    AUTH_USER: 'my_dq_auth_user',
    HOME_APP_START_API_RESPONSE: 'my_dq_home_list_areas_meta',
    HOME_APP_START_CACHE_TIME: 'my_dq_home_list_cache_time',
    HOME_LIST_ITEMS_DATA: 'home_list_items_data',
    ORG_CODE_OF_DB_PROFILE: 'db_profile_org_code',
    ACTIVE_ORG_CODE: 'active_org_code',
    ACTIVE_APP_KEY: 'active_app_key',
    FIRST_TIME_BOOT: 'first_boot',
    UNDER_MAINTENANCE_ACCESS_KEY: 'under_maintenance_access_key',
    FORCE_TO_SET_PASSWORD: 'force_to_set_password',
    TOKEN_FOR_SET_PASSWORD: 'token_for_set_password',
    COLLAB_STRING: 'collab_string',
    COOKIE_POPUP: 'cookie_consent',
    CURRENT_USER_AGE: 'current_user_age',
    LAST_ACTIVE_QUIZ_ID: 'last_active_quiz_id',
    ACTIVE_LOCALE: 'active_locale',
    AVAILABLE_PROFILES: 'available_profiles',
    PROMPT_SWITCH_PROFILE: 'prompt_switch_profile',
    ACTIVE_ASSESS_MODE: 'active_assess_mode',
    ACTIVE_DQ_SETTINGS: 'active_dq_settings',
    ORG_HAVE_DQ_ACCESS: 'org_have_dq_access',
    CONN_DEFAULT_MODULE_CODE: 'conn_default_module_code',
    CONN_REDIRECT_URL: 'conn_redirect_url',
    POST_DEMOGRAPHIC_REDIRECT_LINK: 'dq_post_demographic_redirect_link',
    SKIP_INTRO_POSTER_OF_QUIZ: 'dq_skip_intro_poster_of_quiz'
}

export const QUIZ_TIME_MODES = {
    START_MODE: 'start',
    RESUME_MODE: 'resume',
}

export const DQ_ASSESS_APP_MODE = {
    BADGES: 1,
    MODULES: 2
}

export const MISC_CONSTANTS = {
    DEFAULT_THEME_COLOR: '#f8b143'
}

export const LOCALES_ENABLED = [
    'en',
    'ko',
    'ja',
    'th',
    'es',
    'vi'
]

export const LOCALES_DISABLED_LIVE = []

export const DEFAULT_LOCALE = 'en';

export const LOCALE_TO_LANGUAGE_MAP = {
    en: 'English',
    ko: '한국어',
    ja: '日本語',
    th: 'ภาษาไทย',
    es: 'Español',
    vi: 'Tiếng Việt'
}

export const USER_PROFILE_ACCESS_LEVEL = {
    NO_ACCESS: 0,
    FULL_ACCESS: 1,
    PARTIAL_ACCESS: 2
}

export const PRODUCT_TYPE = {
    PACKAGE: 0,
    MODULE: 1,
    BADGE: 2
}

export const NUS_REDIRECT_URL = "https://ctic.nus.edu.sg/living-well-digitally"

export const FREE_TESTS = {
    SimpleDQTest: {
        badge: (locale) => {
            return {
                area_code: "DQDC",
                badge_code: "FreeDCT",
                desc: GET_APP_LOCALIZE_TEXT(locale, 'dq_home__dc_score_helps_what_message'),
                duration: `10 ${GET_APP_LOCALIZE_TEXT(locale, 'dq_home__min_duration')}`,
                icon_url: "https://storage.googleapis.com/mydq-local-bucket/admin/media/1/480463aa-aeb5-4cdb-803c-b241e821ff76/qd-citizenship.png",
                id: "61db2b763ed3df19d8f64ebe",
                is_active: true,
                name: GET_APP_LOCALIZE_TEXT(locale, 'dq_home__free_assess'),
                org_code: "DQ",
                badge_background: "#1a194c",
                primary_color: "#fff",
                theme_color: "#32baf1"
            }
        },
        area: {
            area_code: "DQDC",
            badge_background: "#1a194c",
            icon_url: "https://cdn1.iconfinder.com/data/icons/image-manipulations/100/13-512.png",
            id: "61db2b7b3ed3df19d8f64ebf",
            is_active: true,
            lock_background: "#575769",
            lock_text_color: "#1a194c",
            org_code: "rand",
            primary_color: "#fff",
            primary_color_locked: "#777",
            secondary_color: "#3cc6f3",
            secondary_color_locked: "#3cc6f3",
            theme_color: "#32baf1"
        }
    }
}
export const DQ_THEMES = {
    DEFAULT: "DEFAULT",
    NUS_THEME: "NUS_THEME",
};

export const DQ_ENABLED_THEMES = [DQ_THEMES.DEFAULT, DQ_THEMES.NUS_THEME];

export const DIGITAL_CITIZEN_TYPES = (locale) => {
    return {
        'C1': {
            title: GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__carefree_citizen'),
            desc_list: [
                GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__feel_comfort_online_text'),
                GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__invlove_with_risks_text')
            ]
        },
        'C2': {
            title: GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__ordinary_citizen'),
            desc_list: [
                GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__avg_tech_user_message_text'),
                GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__tend_to_follow_algo')
            ]
        },
        'C3': {
            title: GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__always_on_citizen'),
            desc_list: [
                GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__like_comm_with_others'),
                GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__maynot_consider_risks')
            ]
        },
        'C4': {
            title: GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__catious_citizen'),
            desc_list: [
                GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__aware_of_risks_not_fully_explore'),
                GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__likely_having_risks_in_dw')
            ]
        },
        'C5': {
            title: GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__digilog_citizen'),
            desc_list: [
                GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__balanced_digital_citizen'),
                GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__use_tech_mindfully')
            ]
        }
    }
}

export const DIGITAL_WELLBEING_DOMAINS = (locale) => {
    return {
        'DigitalSocialRelations': {
            title: "Digital Social Relations",
            desc: "The ability to use technology to build and maintain relationships in family and social communities",
        },
        'DigitalHealth': {
            title: "Digital Health",
            desc: "The ability to be aware of one's physical and psychological wellbeing and maintain a healthy relationship when using technology",
        },
        'DigitalConsumption': {
            title: "Digital Consumption",
            desc: "The ability to access, make informed choices and understand rights and responsibilities in digital consumption",
        },
        'DigitalEmployment':{
            title: "Digital Employment",
            desc: "The ability to identify and use opportunities to acquire digital competencies to improve work life and contribute to the economy (includes self employed work, freelance workers, gig workers)",
        },
        'DigitalCivicParticipation': {
            title: "Digital Civic Participation",
            desc: "The ability to lead and participate in cause oriented groups and initiatives towards meaningful changes in digital and physical environments",
        },
    }
}

export const DIGITAL_WELLBEING_PROFILES = (locale) => {
    return {
        'DigitalNovice': {
            title: "Digital Novice",
            desc: "Just starting your digital journey! You may be new to the tech scene, but fear not–there's a whole digital world waiting for you to explore. Focus on establishing a foundation for your digital wellbeing.",
        },
        'TechExplorer': {
            title: "Tech Explorer",
            desc: "Congratulations! You've embarked on the path of tech exploration. As a Tech Explorer, you're familiar with the basics and ready to discover more about the digital realm, while keeping an eye on your digital wellness.",
        },
        'DigitalAdventurer': {
            title: "Digital Adventurer",
            desc: "You're on an exciting digital adventure! As a Digital Adventurer, you've progressed beyond the basics and are delving into more advanced aspects of the digital landscape, ensuring a balance for your digital wellbeing.",
        },
        'ScreenSorcerer':{
            title: "Screen Sorcerer",
            desc: "You're a Screen Sorcerer, mastering the digital arts! With proficiency in various tech skills, you wield your digital tools with finesse and navigate the digital world effortlessly, always mindful of maintaining your digital wellness.",
        },
        'WellbeingWhiz': {
            title: "Wellbeing Whiz",
            desc: "At the pinnacle of digital mastery, you're a true expert in the realm of technology. Your skills are unmatched, and you navigate the digital landscape with unparalleled expertise, all while prioritizing and optimizing your digital wellness.",
        },
    }
}

export const DQW_DCT_SCORES_DIST_FUNC = (locale) => {
    return [
        {"code": "DQ", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__global'), "0-70": 0.29, "71-80": 8.66, "81-90": 22.99, "91-100": 19.77, "101-110": 18.85, "111-120": 18.18, "121-130": 10.54, ">130": 0.72, "AVGScore": "N/A"},
        {"code": "AU", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__australia'), "0-70": 0.2, "71-80": 2.7, "81-90": 9.04, "91-100": 11.74, "101-110": 18.92, "111-120": 30.57, "121-130": 24.42, ">130": 2.41, "AVGScore": 109.734734283234},
        {"code": "KH", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__cambodia'), "0-70": 0, "71-80": 1.8, "81-90": 1.8, "91-100": 10.81, "101-110": 13.51, "111-120": 39.64, "121-130": 29.73, ">130": 2.7, "AVGScore": 113.44812883374},
        {"code": "CN", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__china'), "0-70": 0.41, "71-80": 6.64, "81-90": 18.26, "91-100": 12.45, "101-110": 19.09, "111-120": 26.14, "121-130": 16.18, ">130": 0.83, "AVGScore": 103.901372973272},
        {"code": "CO", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__colombia'), "0-70": 0, "71-80": 5.18, "81-90": 16.12, "91-100": 18.2, "101-110": 23.37, "111-120": 23.96, "121-130": 12.13, ">130": 1.04, "AVGScore": 103.428561319351},
        {"code": "DO", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__dominican_republic'), "0-70": 0.38, "71-80": 5, "81-90": 14.81, "91-100": 18.27, "101-110": 23.65, "111-120": 25, "121-130": 12.5, ">130": 0.38, "AVGScore": 103.519843667666},
        {"code": "EC", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__ecuador'), "0-70": 0.12, "71-80": 2.45, "81-90": 6.38, "91-100": 15.71, "101-110": 26.26, "111-120": 32.39, "121-130": 16.32, ">130": 0.37, "AVGScore": 108.036041573797},
        {"code": "SV", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__el_salvador'), "0-70": 0, "71-80": 0, "81-90": 8.06, "91-100": 12.9, "101-110": 19.35, "111-120": 35.48, "121-130": 22.58, ">130": 1.61, "AVGScore": 110.636557764434},
        {"code": "HK", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__hong_kong'), "0-70": 0.25, "71-80": 5.89, "81-90": 19.23, "91-100": 16.38, "101-110": 22.27, "111-120": 23.88, "121-130": 11.29, ">130": 0.81, "AVGScore": 102.612388441052},
        {"code": "IN", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__india'), "0-70": 0, "71-80": 0, "81-90": 4.1, "91-100": 4.1, "101-110": 16.92, "111-120": 30.26, "121-130": 40, ">130": 4.62, "AVGScore": 116.177094631662},
        {"code": "ID", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__indonesia'), "0-70": 0, "71-80": 1.94, "81-90": 5.16, "91-100": 12.9, "101-110": 26.13, "111-120": 31.61, "121-130": 21.29, ">130": 0.97, "AVGScore": 109.945984161084},
        {"code": "IE", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__ireland'), "0-70": 0, "71-80": 7.59, "81-90": 16.46, "91-100": 26.58, "101-110": 13.92, "111-120": 17.72, "121-130": 16.46, ">130": 1.27, "AVGScore": 102.207365650019},
        {"code": "JP", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__japan'), "0-70": 0, "71-80": 2.3, "81-90": 10.75, "91-100": 18.75, "101-110": 30.26, "111-120": 23.46, "121-130": 13.93, ">130": 0.55, "AVGScore": 105.468604455365},
        {"code": "KR", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__korea_republic_of'), "0-70": 0, "71-80": 2.61, "81-90": 11.3, "91-100": 15.22, "101-110": 27.39, "111-120": 28.7, "121-130": 14.35, ">130": 0.43, "AVGScore": 106.199950449992},
        {"code": "MY", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__malaysia'), "0-70": 0, "71-80": 3.16, "81-90": 11.05, "91-100": 11.05, "101-110": 21.05, "111-120": 32.11, "121-130": 20.53, ">130": 1.05, "AVGScore": 108.188123517612},
        {"code": "MX", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__mexico'), "0-70": 0.28, "71-80": 4.79, "81-90": 16.04, "91-100": 19.72, "101-110": 20.18, "111-120": 23.41, "121-130": 14.75, ">130": 0.83, "AVGScore": 103.824656767618},
        {"code": "NZ", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__new_zealand'), "0-70": 0.12, "71-80": 8.11, "81-90": 17.57, "91-100": 15.85, "101-110": 18.55, "111-120": 22.6, "121-130": 15.85, ">130": 1.35, "AVGScore": 103.210632952594},
        {"code": "NG", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__nigeria'), "0-70": 0, "71-80": 0.59, "81-90": 4.73, "91-100": 11.83, "101-110": 18.93, "111-120": 33.73, "121-130": 26.63, ">130": 3.55, "AVGScore": 112.586026426093},
        {"code": "PE", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__peru'), "0-70": 0, "71-80": 2.52, "81-90": 10.98, "91-100": 19.91, "101-110": 29.06, "111-120": 24.26, "121-130": 11.67, ">130": 1.6, "AVGScore": 105.239695280477},
        {"code": "PH", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__philippines'), "0-70": 0, "71-80": 2.94, "81-90": 7.93, "91-100": 12.33, "101-110": 18.36, "111-120": 26.73, "121-130": 29.37, ">130": 2.35, "AVGScore": 110.351966708469},
        {"code": "QA", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__qatar'), "0-70": 0, "71-80": 1.34, "81-90": 8.05, "91-100": 18.79, "101-110": 24.16, "111-120": 32.21, "121-130": 13.42, ">130": 2.01, "AVGScore": 107.538027302336},
        {"code": "SG", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__singapore'), "0-70": 0.08, "71-80": 3.61, "81-90": 9.8, "91-100": 14.17, "101-110": 19.87, "111-120": 27.42, "121-130": 23.52, ">130": 1.53, "AVGScore": 108.405394216407},
        {"code": "ZA", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__south_africa'), "0-70": 0, "71-80": 14.81, "81-90": 20.37, "91-100": 14.81, "101-110": 20.37, "111-120": 16.67, "121-130": 12.96, ">130": 0, "AVGScore": 99.2654681331044},
        {"code": "ES", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__spain'), "0-70": 0.22, "71-80": 7.14, "81-90": 21.65, "91-100": 22.77, "101-110": 21.88, "111-120": 17.97, "121-130": 7.59, ">130": 0.78, "AVGScore": 99.7318268079294},
        {"code": "SE", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__sweden'), "0-70": 0, "71-80": 3.31, "81-90": 11.26, "91-100": 7.28, "101-110": 17.22, "111-120": 29.14, "121-130": 31.13, ">130": 0.66, "AVGScore": 110.59436420489},
        {"code": "TH", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__thailand'), "0-70": 0.4, "71-80": 12.41, "81-90": 31.91, "91-100": 23.44, "101-110": 16.65, "111-120": 11.2, "121-130": 3.85, ">130": 0.14, "AVGScore": 94.3235304876939},
        {"code": "TR", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__turkey'), "0-70": 0.23, "71-80": 3.51, "81-90": 13.35, "91-100": 15.46, "101-110": 17.56, "111-120": 26.7, "121-130": 21.08, ">130": 2.11, "AVGScore": 107.168467610582},
        {"code": "GB", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__united_kingdom'), "0-70": 0, "71-80": 1.85, "81-90": 9.26, "91-100": 9.26, "101-110": 22.22, "111-120": 37.04, "121-130": 18.52, ">130": 1.85, "AVGScore": 109.498093739043},
        {"code": "US", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__united_states'), "0-70": 0.18, "71-80": 4.07, "81-90": 11.28, "91-100": 11.09, "101-110": 20.89, "111-120": 31.24, "121-130": 19.96, ">130": 1.29, "AVGScore": 107.924119889318},
        {"code": "VN", "demo_country": GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__viet_nam'), "0-70": 0.84, "71-80": 13.25, "81-90": 25.24, "91-100": 21.76, "101-110": 17.43, "111-120": 12.13, "121-130": 8.23, ">130": 1.12, "AVGScore": 96.6975968051753}
    ]
};

export const NAV_DIRECTION = {
    FORWARD: 1,
    BACKWARD: 2
}