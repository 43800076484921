import React, {useEffect, useState} from 'react';
import Button, {btnSize} from "../../../components/UI/Button/Button.ui";
import {
    loginUser,
    loginUserViaSSOToken,
    loginUserViaToken,
    getGoogleSignInLink,
    logoutUser, loginUserViaConnectToken
} from "../../../services/auth.service";
import {useAuth} from "../../../context/auth";
import {useGlobalState} from "../../../context/global_state";
import { withRouter } from "react-router";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {loadNewGuestUser} from "../auth_common.lib";

import dqIcon from '../../../assets/icons/dq-icons/dq-logo.png';
import {DQ_ENABLED_THEMES, DQ_THEMES, LOCAL_STORAGE_KEYS, LOCALES_ENABLED} from "../../../utils/constants";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT, GET_WEBSITE_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import Helmet from "react-helmet";
import {hasDQPAccess, hasDQWAccess} from "../../../utils/utils";

const Login = (props) => {

    const { history, location } = props;

    const page_location = useLocation();
    const pageLc = page_location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);

    const [formEmail, setFormEmail] = useState('');
    const [formPassword, setFormPassword] = useState('');

    const { setAuthToken, setAuthUser, authToken, authUser } = useAuth();
    const { setToastShow, setToastMessage, setProfile, settings, setSettings} = useGlobalState();

    useEffect( () => {
        const params = new URLSearchParams(location.search);
        const url_token = params.get('access_token');
        const otp_token = params.get('otp_token');
        const error = params.get('error');

        const connect_token = params.get('connect_token');
        const signature = params.get('signature');

        if(error) {
            setToastShow(true);
            setToastMessage(error);
        } else if (url_token) {
            // try to login via provided token in url
            loginViaToken(url_token, true).then(r=>console.log('successfully logged in to application using token'))
        } else if(otp_token) {
            loginViaToken(otp_token, false).then(r=>console.log('successfully logged in to application using token'))
        } else if (authToken && authUser) {
            history.push('/home')
        } else if (connect_token && signature) {
            // make the call to api:loginViaConnectToken
            loginViaConnectToken(connect_token, signature).then(r=>console.log('successfully logged in to application using connect-token'));
        }
    }, [] );

    const loginViaConnectToken = async (connect_token, signature) => {
        try {
            setLoading(true);

            // logout old user first
            await LogoutActionWithoutRedirect();

            let profile;
            const result = await loginUserViaConnectToken(connect_token, signature);

            if (result.data && result.data.user && result.data.org_code) {
                // set the data of login for new user
                await setAuthToken(result.data.auth_token);
                await setAuthUser(result.data.user);
                // set default profile
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE, result.data.org_code);
                profile = result.data.profile
                await setProfile(profile);
                if (result.data.mydq_app_theme && DQ_ENABLED_THEMES.includes(result.data.mydq_app_theme)) {
                    await setSettings({
                        ...settings,
                        theme: result.data.mydq_app_theme
                    });
                }

                // set module_code & redirect_url in local-storage.
                localStorage.setItem(LOCAL_STORAGE_KEYS.CONN_DEFAULT_MODULE_CODE, result.data.module_code);
                localStorage.setItem(LOCAL_STORAGE_KEYS.CONN_REDIRECT_URL, result.data.redirect_url);

                history.push('/home');
            }

            setLoading(false);
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
        }
    }

    const loginViaToken = async (token, redirect_to_set_password) => {
        try {
            setLoading(true);

            // logout old user first
            if (authToken && authUser) {
                await LogoutActionWithoutRedirect();
            }

            let profile;
            const result = await loginUserViaToken(token, !redirect_to_set_password);

            if (result.data && result.data.user && result.data.org_code) {
                // set the data of login for new user
                await setAuthToken(result.data.auth_token);
                await setAuthUser(result.data.user);
                // set default profile
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE, result.data.org_code);
                localStorage.setItem(LOCAL_STORAGE_KEYS.ORG_HAVE_DQ_ACCESS, result.data.dqw_access);
                profile = result.data.profile
                await setProfile(profile);
                if (result.data.mydq_app_theme && DQ_ENABLED_THEMES.includes(result.data.mydq_app_theme)) {
                    await setSettings({
                        ...settings,
                        theme: result.data.mydq_app_theme
                    });
                }

                // set available profiles
                const {available_profiles} = result.data;
                localStorage.setItem(LOCAL_STORAGE_KEYS.AVAILABLE_PROFILES, JSON.stringify(available_profiles));
                // check if there are multiple profiles
                const multiple_profiles = available_profiles.length > 1;
                if(multiple_profiles) {
                    localStorage.setItem(LOCAL_STORAGE_KEYS.PROMPT_SWITCH_PROFILE, true.toString());
                }
                if(redirect_to_set_password) {
                    localStorage.setItem(LOCAL_STORAGE_KEYS.FORCE_TO_SET_PASSWORD, true);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN_FOR_SET_PASSWORD, token);
                    history.push('/complete_registration')
                }
                else {
                    let path;
                    if(multiple_profiles) path = "/switch_profile";
                    else if(profile.is_demographic_updated) {
                        if (!result.data.dqw_access) {
                            path = "/home"
                        } else {
                            path = "/select_platform"
                        }
                    }
                    else path = "/demographic"
                    history.push(path);
                }
            }
            setLoading(false);
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
        }
    }

    const LogoutActionWithoutRedirect = async (make) => {

        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            setProfile(null);
            setSettings({
                ...settings,
                theme: DQ_THEMES.DEFAULT
            });
            localStorage.clear();

            const locale = location.pathname.split('/')[1];
            if (LOCALES_ENABLED.includes(locale)) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, locale);
            }
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
        }
    }

    const loginAction = async () => {

        try {
            setLoading(true);
            let profile;
            const remember_me = true;
            const result = await loginUser(formEmail, formPassword, remember_me);

            // if (result.data && result.data.redirect_to_dq_portal && result.data.access_token) {
            //     window.location.assign(`${process.env.REACT_APP_DQ_PRO_LOGIN_URL}?token=${result.data.access_token}`);
            // } else {
            //     setToastShow(true);
            //     setToastMessage('You does not have permission to access DQ PRO!');
            // }
            if (result.data && result.data.redirect_to_dq_portal && result.data.access_token) {
                setToastShow(true);
                setToastMessage('You does not have permission to access DQ Assessments!');
            } else if (result.data && result.data.user && result.data.org_code) {
                await setAuthToken(result.data.auth_token);
                await setAuthUser(result.data.user);

                //default profile
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE, result.data.org_code);
                profile = result.data.profile
                await setProfile(profile);
                if (result.data.mydq_app_theme && DQ_ENABLED_THEMES.includes(result.data.mydq_app_theme)) {
                    await setSettings({
                        ...settings,
                        theme: result.data.mydq_app_theme
                    });
                }

                //multiple profiles
                const {available_profiles} = result.data;
                localStorage.setItem(LOCAL_STORAGE_KEYS.AVAILABLE_PROFILES, JSON.stringify(available_profiles));
                const multiple_profiles = available_profiles.length > 1;

                if (!profile) {
                    history.push('/home')
                }
                else {
                    if(multiple_profiles) {
                        localStorage.setItem(LOCAL_STORAGE_KEYS.PROMPT_SWITCH_PROFILE, true.toString());
                        history.push('/switch_profile')
                    }
                    else {
                        if(profile.is_demographic_updated) {
                            if (hasDQPAccess(profile) && !hasDQWAccess(profile) && !result.data.dqw_access) {
                                history.push('/home')
                            } else {
                                history.push('/select_platform')
                            }
                        }
                        else {
                            history.push('/demographic')
                        }
                    }
                }
            }
            setLoading(false);
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
        }

    }

    const loginWithGoogleAction = async () => {
        console.log('initiating google login');
        setLoading(true);
        try {
            const result = await getGoogleSignInLink();
            const {redirect_link} = result.data;
            window.open(redirect_link, "_self");
        }
        catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
        }
    }

    const registerLink = () => {
        console.log('register Link Clicked');
        history.push('/register', true);
    }

    const recoverLink = () => {
        console.log('recover Link Clicked');
        history.push('/recover', true);
    }

    const registerAsCompanyLink = () => {
        console.log('register-company Link Clicked');
        window.location.assign(process.env.REACT_APP_REGISTER_AS_COMPANY_LINK)
    }

    const registerAsSchoolLink = () => {
        console.log('register-company Link Clicked');
        window.location.assign(process.env.REACT_APP_REGISTER_AS_SCHOOL_LINK)
    }

    const termsOfUseLink = () => {
        history.push('/terms_of_use', true);
    }

    const privacyPolicyLink = () => {
        history.push('/privacy_policy', true);
    }
    const homeLink = () => {
        history.push('/');
    }

    const onChangeEmail = (e) => {
        setFormEmail(e.target.value);
    }

    const onChangePassword = (e) => {
        setFormPassword(e.target.value);
    }

    return (
        <div className={'login-container'}>
            <Helmet title="DQ Assessment" />
            <LoaderWithBackDrop
                loading={loading}
            />
            
            <div className="dq-logo-image"><img onClick={homeLink} src={dqIcon} alt='DQ'/></div>
            
            <div className="dq-login-content">
                <form onSubmit={e => e.preventDefault()}>
                    <div>
                        <input
                            className={'dq-login-txt'}
                            type='text'
                            name={'email'}
                            id={'email'}
                            value={formEmail}
                            placeholder={GET_APP_LOCALIZE_TEXT(pageLc, 'uo__username')}
                            onChange={onChangeEmail}
                        />
                    </div>
                    <div>
                        <input
                            className={'dq-login-txt'}
                            type='password'
                            name={'password'}
                            id={'password'}
                            value={formPassword}
                            placeholder={GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__password')}
                            onChange={onChangePassword}
                        />
                    </div>
                </form>
            </div>
            
            <div className={'dq-options-submit'}>
                <button onClick={loginAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__login')}</button>
            </div>

            <div className={'forgot-password'}>
                <a onClick={recoverLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__forgot_password')}</a>
            </div>

            {
                process.env.REACT_APP_ENABLE_LOGIN_WITH_GOOGLE === '1' && <div className={'google-login'}>
                    <button onClick={loginWithGoogleAction}>
                        {GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__login_with_google')}
                    </button>
                </div>
            }


            <div className={'dq-audience-login'}>
                <p>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__not_have_an_account')}</p>
                <a onClick={registerLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__register_as_individual')}</a>
                {/* <div style={{marginTop: '8px'}}>
                    <a onClick={registerAsCompanyLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__register_as_company')}</a>
                </div>
                <div style={{marginTop: '8px'}}>
                    <a onClick={registerAsSchoolLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__register_as_school')}</a>
                </div> */}
            </div>

            <div className={'dq-audience-login-blue'}>
            <a className={'dq-footer-link'} href={`${process.env.REACT_APP_DQ_PRO_BASE_URL}/${pageLc}/login`} target='_blank'>
                    {GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__login_to_dashboard')}
                </a>
            </div>
            
            <div className={'dq-guest-footer'}>
                <a className={'dq-footer-link'} href={'/privacy_policy'} target={'_blank'}>
                    {GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__privacy_policy')}
                </a>
                <a className={'dq-footer-link'} href={'/terms_of_use'} target={'_blank'}>
                    {GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__terms_of_use')}
                </a>
                <div className={'dq-copyright'}>&copy; {new Date().getFullYear()} {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__dq_lab_pte_ltd_all_rights_reserved')}</div>
            </div>

            {/*<br />*/}
            {/*<br />*/}
            {/*<br />*/}
            {/*<a*/}
            {/*    className={'login-link'}*/}
            {/*    onClick={guestLogin}*/}
            {/*>Continue as Guest</a>*/}
        </div>
    );

}

export default withRouter(Login);
