//import dqIndexLogo from "../../../assets/images/dqindex/dqindex-logo.png";
import dqLogo from "../../../assets/images/Logo4.png";
import DQLogo from "../../../assets/images/Logo3.png";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import LangDropdown from "./LangDropdown.comp";
//import LoginModal from "./LoginModal.comp";
import { GET_APP_LOCALIZE_TEXT, GET_WEBSITE_LOCALIZE_TEXT } from "../../../utils/dq_lc_service/lc_service";

const navigations = [
    // {
    //     title: "assessment__dq_index",
    //     href: '/'
    // },
    // {
    //     title: "home__dq_assessment",
    //     href: '/assessment'
    // },
]


const Header = () => {

    const location = useLocation();
    const split_location = location.pathname.split('/');
    const current_href = "/" + split_location[2];

    const [collapsed, setCollapsed] = useState(true);
    const pageLc = location.pathname.split('/')[1];

    return (
        <header className='index-header'>
            <div className='iheader-contnet'>
                <div className='index-logo'>
                    <img src={DQLogo} alt='DQIndex' />
                    <div className={`index-collapse-icon ${collapsed ? 'closed' : 'open'}` } onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? (
                            /* Show menu icon when collapsed (three spans) */
                            <>
                            <span></span>
                            <span></span>
                            <span></span>
                            </>
                        ) : (
                            /* Show a cross (X) icon when open */
                            <i className="fa fa-times" style={{
                                fontSize: '34px',
                                marginTop: '8px',
                                color: '#ed6628'}} ></i>
                        )}
                    </div>
                </div>
                <div className={`index-menu ${collapsed ? 'collapsed' : ''}`}>
                    {/* <LangDropdown className={'imenu-language'}/> */}
                    {
                        navigations.map((navigation) => {
                            return (
                                <div className='imenu-item'>
                                    <a className={current_href === navigation.href ? 'imenu-item-active' : ''} href={navigation.href}>
                                        {GET_WEBSITE_LOCALIZE_TEXT(pageLc, navigation.title)}
                                    </a>
                                </div>
                            );
                        })
                    }
                    {<>
                        <div style={{ padding: '0 25px', }}>
                            <LangDropdown className={'imenu-language'} />
                        </div>
                        <div className='imenu-item' >
                            <a className='imenu-link' href="/login" >{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__login')}</a> 
                            {/*<LoginModal />*/}
                        </div>

                        <div className='imenu-item' >
                            <a className='imenu-link' href="/register">{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__signup')}</a>
                            {/*<SignUpModal />*/}
                        </div>

                    </>
                    }
                </div>
            </div>
        </header>
    );
}

export default Header;