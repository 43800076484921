import React, {useState, useEffect} from 'react';
import Button, {btnSize} from "../../../components/UI/Button/Button.ui";
import ShareOnMedia from "../../../components/UI/ShareOnMedia/ShareOnMedia.ui";
import ScoreChart from "../../../components/UI/Charts/ScoreChart.ui";

import {DQ_THEMES, QUIZ_TIME_MODES} from "../../../utils/constants";
import {getPercentileStr, getBadgeResultStr, getBadgeResultColorClass} from "../../../utils/utils";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import styled from "styled-components";

const ListItemDetail = (props) => {

    const { history, badge, selected, profile, global_average, national_average } = props;

    const is_standard_score = badge.default_scoring && badge.default_scoring === 'raw' ? false : true;
    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [quizBtnText, setQuizBtnText] = useState('Take Test');
    const [badgeScore, setBadgeScore] = useState('0');
    const [badgePercentile, setBadgePercentile] = useState('');
    const [badgeAllScores, setBadgeAllScores] = useState([]);

    useEffect( ()=>{
        const curr_badge_state = profile.badges[badge.badge_code];
        if (curr_badge_state) {
            if (curr_badge_state.in_progress) {
                setQuizBtnText(GET_APP_LOCALIZE_TEXT(pageLc, 'post_quiz_resume_test'))
            } else if (curr_badge_state.finished) {
                setQuizBtnText(GET_APP_LOCALIZE_TEXT(pageLc, 'dq_home__retake_test'));
                setBadgeScore(parseFloat(curr_badge_state.obtained_score).toFixed(2) + ''); // cast to string
                setBadgePercentile(getPercentileStr(curr_badge_state.obtained_score, curr_badge_state.percentile, pageLc));
                if (typeof curr_badge_state.scores === 'object' && curr_badge_state.scores.length > 0) {
                    setBadgeAllScores(curr_badge_state.scores);
                }
            }
        }
    }, [profile] )

    let componentClasses = `${'home-item-detail-container'} ${'home-item-detail-close'}`
    if (selected === badge.id) {
        componentClasses = `${'home-item-detail-container'} ${'home-item-detail-open'}`
    }

    const takeQuizAction = () => {
        const curr_badge_state = profile.badges[badge.badge_code];
        if (curr_badge_state) {
            if (curr_badge_state.in_progress) {
                history.push(`/take/quiz/${QUIZ_TIME_MODES.RESUME_MODE}/${curr_badge_state.resume_ref}`);
            } else {
                history.push(`/take/quiz/${QUIZ_TIME_MODES.START_MODE}/${badge.badge_code}`);
            }
        } else {
            history.push(`/take/quiz/${QUIZ_TIME_MODES.START_MODE}/${badge.badge_code}`);
        }
    }
    const BodyContainer = styled.div`
        font-family: ${(props) => {
            switch (props.theme.name) {
                case DQ_THEMES.DEFAULT:
                    return `"${props.theme.fonts[0]}", "${props.theme.fonts[1]}"` ;
                case DQ_THEMES.NUS_THEME:
                    return `"${props.theme.fonts[4]}"`;
                default:
                    return '';
            }
        }};
        font-weight: ${(props) => {
            switch (props.theme.name) {
                case DQ_THEMES.DEFAULT:
                    return 700;
                case DQ_THEMES.NUS_THEME:
                    return 700;
                default:
                    return 600;
            }
        }};
    `
    return(
        <div className={componentClasses} style={{borderColor: badge.theme_color}}>
            <div className={'home-item-detail-header-section'}>
                <div className={'home-item-detail-header-score'}>
                    {
                        badgeScore && badgePercentile ?
                            <>
                                <span className={getBadgeResultColorClass(badgeScore, is_standard_score)}>{badgeScore.split(".")[0]}</span>
                                <span className={`${getBadgeResultColorClass(badgeScore, is_standard_score)} header-score-pt`}>.{badgeScore.split(".")[1]}</span>
                                <div className={'home-item-detail-header-score-desc'}>{getBadgeResultStr(badgeScore, true, is_standard_score, pageLc)}</div>
                                <div className={'score-group'}>{badgePercentile}</div>
                            </>
                        : null
                    }
                </div>
                <BodyContainer className={'home-item-detail-header-title'}>
                    <div>{badge.desc}</div>
                </BodyContainer>
            </div>
            <div className={'home-item-detail-charts-section'}>
                <ScoreChart
                    score={badgeScore}
                    all_scores={badgeAllScores}
                    default_scoring={badge.default_scoring}
                    scoreChange={-0.02}
                    showStats={false}
                    global_average={global_average}
                    national_average={national_average}
                />
            </div>
            <div className={'home-item-detail-btn-section'}>
                <ShareOnMedia
                    title='Share Test'
                    themeColor={badge.theme_color}
                />
                <div className={'home-item-detail-t-btn'}>
                    <Button
                        size={btnSize.sm}
                        title={quizBtnText}
                        action={takeQuizAction}
                        themeColor={badge.theme_color}
                    />
                </div>

            </div>
        </div>
    )
}

export default ListItemDetail;