import React, { useEffect, useState, useRef } from 'react';

import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';

import { GET_WEBSITE_LOCALIZE_TEXT } from "../../../utils/dq_lc_service/lc_service";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement
);

ChartJS.defaults.font.size = 13;

const all_keys = [
  "0-70",
  "71-80",
  "81-90",
  "91-100",
  "101-110",
  "111-120",
  "121-130",
  ">130"
]

const DC_ScoreChart = (props) => {

  const { all_scores, locale } = props;

  const options = {
    responsive: true,
    maintainAspectRatio:true,
    plugins: {
      
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              const percentage = context.dataset.data[context.dataIndex];
              const value = Math.trunc(percentage);
              label=value + "%";
            }
            return label;
          }
        },
        enabled: true,
        usePointStyle: true,
        titleAlign: 'center',
        backgroundColor: '#ffff',
        padding: 6,
        bodyColor: 'black',
        bodyFont: {
          size: 17,
          weight:'bold',
        },
        bodyFontColor: 'red',
      },

      legend: {
        display: true,
        position: 'left',
        align: 'center',
        labels: {
          // fontSize: window.innerWidth < 350 ? 12 : 16,
          boxWidth: 60,
          padding: 20,
          textAlign: 'left',
          margin: 65,
        },
      },
      title: {
        display: true,
        margin: 0,
        padding: 0,
        text: GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__distribution_of_dcs'),
        font: {
          size: 21,
        },
        color: '#001F3D',
      },
    },
  };

  const chartData = {
    labels: all_keys,
    datasets: [
      {
        label: '',
        data: all_keys.map((key) => all_scores[key] || 0),
        backgroundColor: [
          '#052F2F',
          '#0F5958',
          '#077a7a',
          '#08A0A0',
          '#09C0C0',
          '#04E0DF',
          '#0AFEFF',
          '#AFFFFF',
        ],
        borderWidth: 0,
      },
    ]
  };


  // for (let key of all_keys) {
  //     if (all_scores[key]) {
  //         chartData.datasets[0].data.push(all_scores[key])
  //     } else {
  //         chartData.datasets[0].data.push(0)
  //     }
  // }

  return (
    <div className='dc-chart-content' >
      <Pie data={chartData} options={options} />
    </div >
  );

};

export default DC_ScoreChart;
