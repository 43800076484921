import React, {useCallback, useEffect, useRef, useState} from 'react';
import Helmet from "react-helmet";
import BorderButton , {btnSize} from "../../components/UI/BorderButton/BorderButton.ui";

import raz_head_img from '../../assets/raz/raz-head.png';
import left_arrow from '../../assets/images/left-arrow.png';
import right_arrow from '../../assets/images/right-arrow.png';

import {useLocation, useParams} from "react-router-dom";

import { withRouter } from "react-router";

import {
    StepType,
} from './questions';
import GuidePoster from "./components/GuidePoster.comp";
import QuestionPoster from "./components/QuestionPoster.comp";
import {
    fetchAssessmentsService,
    getAssessHistoryService,
    updateAssessHistoryService
} from "../../services/home.service";
import {useGlobalState} from "../../context/global_state";
import {LOCAL_STORAGE_KEYS, NAV_DIRECTION, QUIZ_TIME_MODES} from "../../utils/constants";
import {getActiveThemeColor, getMapFromObjectListByKey} from "../../utils/utils";
import RazProgressSVG from "../../components/UI/SVG_IMGs/RazProgressSVG";
import {useAuth} from "../../context/auth";
import Footer from "../../components/Footer/plugable.footer";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";


const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
        return new Promise((resolve, reject) => { // Wrap the debounced call in a Promise
            const later = () => {
                try {
                    resolve(func(...args)); // Resolve with the result of func
                } catch (error) {
                    reject(error); // Reject if there's an error
                }
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        });
    };
};



const QuizTime = (props) => {

    const { mode, identifier } = useParams();
    const { history } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const { authUser } = useAuth();
    const [loading, setLoading] = useState(false);

    const [currentStepCopy, setCurrentStepCopy] = useState(1);
    const [currentStep, setCurrentStep] = useState(1);
    const [currentQuestion, setCurrentQuestion] = useState(undefined);
    const [answers, setAnswers] = useState({});
    const [Quiz, setQuiz] = useState(undefined);
    const [total_steps, setTotalSteps] = useState(0);

    const [badgeCode, setBadgeCode] = useState('');

    const [progress, setProgress] = useState(0);

    const { setToastShow, setToastMessage, profile, setProfile } = useGlobalState();

    const stepHideCount = useRef(0);
    const stepSkipCount = useRef(0);
    const showAliasKeys = useRef(undefined);
    const hideAliasKeys = useRef(undefined);
    const postSelectionActionsTrace = useRef({});
    const navDirection = useRef(NAV_DIRECTION.FORWARD)

    const qa_mode = useRef(false);

    useEffect( () => {
        loadQuestions().then( d => console.log('loaded questions'));

        setTimeout( () => {
            console.log('main1 => profile --> ', profile)
        }, 1000)

        const params = new URLSearchParams(location.search);
        const qa_mode_param = params.get('qa-mode');
        if (qa_mode_param && qa_mode_param === '1') {
            if (['LOCAL', 'DEVELOP'].includes(process.env.REACT_APP_NAME)) {
                qa_mode.current = true;
            }
        }
    }, []);

    useEffect( () => {
        if (badgeCode !== '') {
            const list_items_map = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.HOME_LIST_ITEMS_DATA));
            if (list_items_map && list_items_map[badgeCode]) {
                const theme_color = list_items_map[badgeCode].theme_color;
                if (theme_color) {
                    setProfile({
                        ...profile,
                        active_theme_color: theme_color
                    })
                }
            }
        }
    }, [badgeCode] )

    useEffect( () => {

        if (Quiz) {
            const curr_progress = ((currentStep-1-stepSkipCount.current) / (total_steps-stepHideCount.current)) * 100;
            setProgress(curr_progress);

            if (currentQuestion) {
                let updatesToPost = {
                    qid: Quiz._id,
                    answers,
                    curr_progress,
                    currentStep,
                    q_no: currentQuestion.question_number,
                    step_hide_count: stepHideCount.current,
                    hide_keys: hideAliasKeys.current,
                    show_keys: showAliasKeys.current,
                    post_sel_action_trace: postSelectionActionsTrace.current,
                    skip_count: stepSkipCount.current
                }

                if (currentStep > total_steps) {
                    debouncedUpdateAssessHistory(updatesToPost).then(res => {
                        setProfile({
                            ...profile,
                            ...res.data.profile
                        });

                        if (authUser.is_guest_user) {
                            history.push('/post_quiz_landing');
                            localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_ACTIVE_QUIZ_ID, Quiz._id);
                        } else {
                            history.push(`/result/quiz/${Quiz._id}`)
                        }
                    });
                } else {
                    debouncedUpdateAssessHistory(updatesToPost).then(res => {
                        console.log('Debounced call completed.');
                        if (currentStep === total_steps) {
                            setProfile({
                                ...profile,
                                ...res.data.profile,
                                last_finished_badge: identifier
                            });
                        } else {
                            setProfile({
                                ...profile,
                                ...res.data.profile
                            });
                        }
                    });
                }
            }

        }

    }, [currentStepCopy])


    useEffect( () => {
        if(Quiz && currentStep && answers){
            selectCurrentQuestion();
        }
    }, [currentStep])


    const updateAnswers = (_answers) => {
        setAnswers({
            ..._answers
        });
    }

    const loadQuestions = async () => {
        try {
            let res;
            switch (mode) {
                case QUIZ_TIME_MODES.START_MODE:
                    res = await fetchAssessmentsService(identifier);
                    setBadgeCode(identifier)
                    break;
                case QUIZ_TIME_MODES.RESUME_MODE:
                    res = await getAssessHistoryService(identifier);
                    setBadgeCode(res.data.badge_code)
                    break;
            }

            if (res.data.total_questions === 0) {
                // show_error and take user back to home page
                setToastMessage('Not Available at the moment, Try back later!');
                setToastShow(true);
                history.push('/home')
            }

            setQuiz(res.data);
            setAnswers(res.data.answers || {});
            setTotalSteps(res.data.total_steps);
            setCurrentStep(res.data.current_step);
            setProgress(res.data.progress_percentage);

            if (res.data.step_hide_count) stepHideCount.current = res.data.step_hide_count;
            if (res.data.show_alias_keys) {
                showAliasKeys.current = res.data.show_alias_keys;
            } else {
                showAliasKeys.current = [];
            }
            if (res.data.hide_alias_keys) {
                hideAliasKeys.current = res.data.hide_alias_keys;
            } else {
                hideAliasKeys.current = [];
            }
            if (res.data.post_selection_actions_trace) postSelectionActionsTrace.current = res.data.post_selection_actions_trace;
            if (res.data.step_skip_count) stepSkipCount.current = res.data.step_skip_count;

            // edge case handled :: if backend responded with error on last score calculation api hit.
            if (res.data.current_step > res.data.total_steps) {
                if (authUser.is_guest_user) {
                    history.push('/post_quiz_landing');
                    localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_ACTIVE_QUIZ_ID, res.data._id);
                } else {
                    history.push(`/result/quiz/${res.data._id}`)
                }
            }
            setProgress(progress);
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.CONN_DEFAULT_MODULE_CODE);
            history.push('/home?reload_profile=1')
        }
    }

    const selectCurrentQuestion = () => {
        // // un-comment the following code block, to show demographic screen before quiz result.
        // if (profile && profile.org_code !== "" && !profile.is_demographic_updated) {
        //     localStorage.setItem(LOCAL_STORAGE_KEYS.POST_DEMOGRAPHIC_REDIRECT_LINK, window.location.pathname);
        //     localStorage.setItem(LOCAL_STORAGE_KEYS.SKIP_INTRO_POSTER_OF_QUIZ, 'true');
        //     return history.push('/demographic');
        // }
        if(Quiz && Quiz.steps[currentStep]) {
            if (Quiz.steps[currentStep].type === StepType.QUESTION) {
                setCurrentQuestion(Quiz.steps[currentStep].question);
            }
            else if (Quiz.steps[currentStep].type === StepType.PAIRED_QUESTION) {
                if(answers[Quiz.steps[currentStep].question[0].question_number-1].selectedIndex == 1) {
                    setCurrentQuestion(Quiz.steps[currentStep].question[1]);
                } else {
                    setCurrentQuestion(Quiz.steps[currentStep].question[0]);
                }
            }
        }
    }

    const debouncedUpdateAssessHistory = useCallback(debounce(async (updates) => {
        try {
            const res = await updateAssessHistoryService(updates.qid, updates.answers, updates.curr_progress
                , updates.currentStep, updates.q_no, updates.step_hide_count, updates.hide_keys
                , updates.show_keys, updates.post_sel_action_trace, updates.skip_count);
            return res;
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
        }
    }, 200), []);

    const stepOver = () => {
        setCurrentStep(currentStep + 1);
        setCurrentStepCopy(currentStep + 1);
        navDirection.current = NAV_DIRECTION.FORWARD;
    }

    const stepBack = () => {
        setCurrentStep(currentStep - 1);
        setCurrentStepCopy(currentStep - 1);
        navDirection.current = NAV_DIRECTION.BACKWARD;
    }

    const btnAction = () => {
        stepOver();
    }

    const questionNextAction = (skip_step=false) => {
        if (Quiz && Quiz.steps[currentStep]) {
            let goNext = false;
            let logout = false;

            if (currentQuestion && currentQuestion.question_number in answers) {
                if (answers[currentQuestion.question_number].selectedIndex > -1) goNext = true;
                if (answers[currentQuestion.question_number].selIndexes.length > 0) goNext = true;
                if (skip_step) goNext = true;

                let _step_hide_count = stepHideCount.current;
                let _hide_alias_keys = hideAliasKeys.current;
                let _show_alias_keys = showAliasKeys.current;
                let lc_hide_count = 0;
                let lc_hide_keys = [];
                let lc_show_keys = [];
                let _post_selection_actions_trace = postSelectionActionsTrace.current;
                const curr_applied_action = _post_selection_actions_trace[currentQuestion.code];
                if (curr_applied_action && !skip_step) {
                    _step_hide_count = _step_hide_count - curr_applied_action.hide_count;
                    _hide_alias_keys = _hide_alias_keys.filter(item => !curr_applied_action.hide_keys.includes(item));
                    _show_alias_keys = _show_alias_keys.filter(item => !curr_applied_action.show_keys.includes(item));
                }
                currentQuestion.ui_rules.forEach((rule) => {
                    switch(rule.type) {
                        case "NEXT_BTN_ACTION":
                            if (skip_step) break;
                            if(rule.effect === "LOGOUT" && (answers[currentQuestion.question_number].selectedIndex === rule.index ||
                                answers[currentQuestion.question_number].selIndexes.includes(rule.index))) {
                                logout = true;
                            }
                            break;
                        case "POST_SELECTION_ACTION":
                            if(rule.effect === "SHOW_DEFAULT") {
                                // do the stuff
                                // this must be the first question of assessment.
                                // to handle the special case of NUS
                                if (answers[currentQuestion.question_number].selectedIndex === rule.index_key ||
                                    answers[currentQuestion.question_number].selIndexes.includes(rule.index_key)) {
                                    // add the alias to show keys
                                    _show_alias_keys = rule.alias_key;
                                    lc_show_keys = rule.alias_key;
                                    _step_hide_count = _show_alias_keys.length * -1;
                                } // no else, as not needed to reduce count if showing by-default needs to be tuned.
                            }
                            if(rule.effect === "SHOW") {
                                // do the stuff
                                if (skip_step) {
                                    // decrease steps
                                    _step_hide_count = _step_hide_count + rule.alias_key.length;
                                    lc_hide_count = lc_hide_count + rule.alias_key.length;
                                } else {
                                    if (answers[currentQuestion.question_number].selectedIndex === rule.index_key ||
                                        answers[currentQuestion.question_number].selIndexes.includes(rule.index_key)) {
                                        // add the alias to show keys
                                        _show_alias_keys = [..._show_alias_keys, ...rule.alias_key];
                                        lc_show_keys = [...lc_show_keys, ...rule.alias_key];
                                    } else {
                                        // decrease steps
                                        _step_hide_count = _step_hide_count + rule.alias_key.length;
                                        lc_hide_count = lc_hide_count + rule.alias_key.length;
                                    }
                                }
                            }
                            if(rule.effect === "HIDE") {
                                // do the stuff
                                if (answers[currentQuestion.question_number].selectedIndex === rule.index_key ||
                                    answers[currentQuestion.question_number].selIndexes.includes(rule.index_key)) {
                                    // add the alias to show keys
                                    _hide_alias_keys = [..._hide_alias_keys, ...rule.alias_key];
                                    lc_hide_keys = [...lc_hide_keys, ...rule.alias_key];
                                    // decrease steps too
                                    _step_hide_count = _step_hide_count + rule.alias_key.length;
                                    lc_hide_count = lc_hide_count + rule.alias_key.length;
                                }
                            }
                            break;
                    }
                });
                stepHideCount.current = _step_hide_count;
                hideAliasKeys.current = _hide_alias_keys;
                showAliasKeys.current = _show_alias_keys;
                // set the data for current question-code, in post_selection_actions_trace object. for re-visit logic.
                if (!skip_step) {
                    _post_selection_actions_trace = {
                        ..._post_selection_actions_trace,
                        [currentQuestion.code]: {
                            hide_count: lc_hide_count,
                            hide_keys: lc_hide_keys,
                            show_keys: lc_show_keys
                        }
                    }
                } else {
                    _post_selection_actions_trace = {
                        ..._post_selection_actions_trace,
                        [currentQuestion.code]: {
                            hide_count: 0,
                            hide_keys: [],
                            show_keys: []
                        }
                    }
                }
                postSelectionActionsTrace.current = _post_selection_actions_trace;
            }

            if (logout) {
                window.location.replace('/logout');
                return;
            }

            if (goNext) {
                stepOver();
            }
        }
    }

    const questionPrevAction = () => {
        // if (Quiz.steps[currentStep-1].type !== StepType.GUIDE) {
        //     setCurrentQuestion(currentQuestion - 1);
        // }
        stepBack();
    }

    const nextBtnColorGet = () => {
        if (Quiz && Quiz.steps[currentStep]) {
            if (currentQuestion && currentQuestion.question_number in answers) {
                if (answers[currentQuestion.question_number].selectedIndex < 0 && answers[currentQuestion.question_number].selIndexes.length < 1) {
                    return '#c1beb9';
                } else {
                    return getActiveThemeColor(profile);
                }
            }
        }
        return '#c1beb9';
    }

    return (
        <div className={'quiz-time-main-container'}>
            <Helmet htmlAttributes={{lang: pageLc}} />
            <div className={'quiz-time-progress-container'} >
                <div
                    className={'quiz-time-progress-bar'}
                    style={{
                        backgroundColor: getActiveThemeColor(profile),
                        width: `${progress}%`
                    }}
                />

                <RazProgressSVG
                    color={getActiveThemeColor(profile)}
                    style={{
                        left: `${progress-6}%`
                    }}
                />

                {/*<img*/}
                {/*    src={raz_head_img}*/}
                {/*    className={'quiz-time-progress-img'}*/}
                {/*    style={{*/}
                {/*        left: `${progress-10}%`*/}
                {/*    }}*/}
                {/*/>*/}

                {
                    Quiz && Quiz.steps[currentStep] && currentQuestion && [StepType.QUESTION, StepType.PAIRED_QUESTION].indexOf(Quiz.steps[currentStep].type) > -1 ?
                        <p>
                            <span style={{color: getActiveThemeColor(profile)}}>{currentQuestion.question_number - stepSkipCount.current}</span>
                            <span className={'qt-question-num-total'}>/{Quiz.total_questions - stepHideCount.current}</span>
                        </p> : null
                }
            </div>

            {
                Quiz && Quiz.steps[currentStep] && Quiz.steps[currentStep].type === StepType.GUIDE ?
                    <GuidePoster locale={pageLc} nextAction={btnAction} {...Quiz.steps[currentStep].guide} /> : null
            }

            {
                Quiz && Quiz.steps[currentStep] && currentQuestion && [StepType.QUESTION, StepType.PAIRED_QUESTION].indexOf(Quiz.steps[currentStep].type) > -1 ?
                    <QuestionPoster
                        answers={answers}
                        updateAnswers={updateAnswers}
                        question={currentQuestion}
                        questionNextAction={questionNextAction}
                        questionPrevAction={questionPrevAction}
                        show_alias_keys={showAliasKeys.current}
                        hide_alias_keys={hideAliasKeys.current}
                        nav_direction={navDirection.current}
                        step_skip_count_ref={stepSkipCount}
                        enable_qa_mode={qa_mode.current}
                    /> : null
            }


            {
                Quiz && Quiz.steps[currentStep] && Quiz.steps[currentStep].type === StepType.GUIDE ?
                    null :
                    <>
                        <div
                            style={{backgroundColor: nextBtnColorGet()}}
                            className={'qt-next-btn'} onClick={() => questionNextAction()}>
                            <img className={'qt-next-btn-img'} src={right_arrow} />
                        </div>
                        <div
                            style={{backgroundColor: getActiveThemeColor(profile)}}
                            className={'qt-prev-btn'} onClick={questionPrevAction}>
                            <img className={'qt-prev-btn-img'} src={left_arrow} />
                        </div>
                    </>
            }
            <Footer />
            <LoaderWithBackDrop loading={loading} />
        </div>
    )
}

export default withRouter(QuizTime);
