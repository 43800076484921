import React, { useState, useEffect } from 'react';
import { GET_APP_LOCALIZE_TEXT, GET_WEBSITE_LOCALIZE_TEXT } from "../../../utils/dq_lc_service/lc_service";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {
    LOCAL_STORAGE_KEYS,
    LOCALE_TO_LANGUAGE_MAP,
    LOCALES_DISABLED_LIVE,
    LOCALES_ENABLED
} from "../../../utils/constants";
import { useHistory, useLocation } from "react-router-dom";
import { isEnvDev } from "../../../utils/utils";

const LangDropdown = (props) => {

    const history = useHistory();
    const location = useLocation();

    const { className } = props;

    const pageLc = location.pathname.split('/')[1];

    const disabledLocales = isEnvDev() ? [] : LOCALES_DISABLED_LIVE;

    const getLanguageFromLc = (lc_code) => {
        if (LOCALE_TO_LANGUAGE_MAP[lc_code]) {
            return LOCALE_TO_LANGUAGE_MAP[lc_code]
        }
        return lc_code
    }

    const changeLocale = (loc_code) => {
        const path = location.pathname.split('/')[2];

        if (LOCALES_ENABLED.includes(loc_code)) {
            localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, loc_code);
        }

        history.push(`/${loc_code}/${path}`);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    return (
        <div className={'dq-lang-select'}>
            <Dropdown
                className={className}
                value={pageLc}
                onChange={(e) => changeLocale(e.value)}
                options={LOCALES_ENABLED.filter((lc) => !disabledLocales.includes(lc)).map((lc) => ({
                    value: lc,
                    // label: getLanguageFromLc(lc)
                    label: (
                        <div className="language-option">
                            <span className="language-label">{getLanguageFromLc(lc)}</span>
                        </div>
                    ),
                }))}
                optionRenderer={(option) => option.label}
            />
        </div>

    )
}

export default LangDropdown;