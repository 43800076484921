import {DEFAULT_LOCALE, USER_PROFILE_ACCESS_LEVEL} from "./constants";
import {GET_APP_LOCALIZE_TEXT} from "./dq_lc_service/lc_service";

export const getMapFromObjectListByKey = (objectList, mapKey) => {
    let dict = {};
    for (let item of objectList) {
        dict[item[mapKey]] = item;
    }
    return dict;
}

export const getArrayFromMap = (map) => {
    return Object.keys(map).map((k) => map[k])
}

export const getActiveThemeColor = (profile) => {
    if (profile && profile.active_theme_color && profile.active_theme_color.length > 3) {
        return profile.active_theme_color;
    } else {
        return "#32baf1" // - light blue, '#f8b143' - orange
    }
}

export const getPercentileStr = (score, percentile, locale) => {
    if (percentile > 50) {
        return `${GET_APP_LOCALIZE_TEXT(locale, 'dq_home__bottom_percent')} ${parseFloat(101-percentile).toFixed(0)}%`;
    } else {
        return `${GET_APP_LOCALIZE_TEXT(locale, 'dq_home__top_percent')} ${parseFloat(1+percentile).toFixed(0)}%`
    }
}

export const getBadgeResultStr = (score, short = false, is_standard_score, locale = DEFAULT_LOCALE) => {
    let results = [
        GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__require_attention'),
        GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__bellow_avg'),
        GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__good_job'),
        GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__excellent'),
    ];
    if (short && is_standard_score) results = [
        GET_APP_LOCALIZE_TEXT(locale, 'dq_home__bellow_avg'),
        GET_APP_LOCALIZE_TEXT(locale, 'dq_home__bellow_avg'),
        GET_APP_LOCALIZE_TEXT(locale, 'dq_home__avg'),
        GET_APP_LOCALIZE_TEXT(locale, 'dq_home__excellent'),
    ];
    if(!is_standard_score) results = [
        GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__low'),
        GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__low'),
        GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__medium'),
        GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__high'),
    ];

    if(is_standard_score) {
        if (score < 85)  return results[0];
        else if (score >= 85 && score < 100) return results[1];
        else if (score >= 100 && score < 115) return results[2];
    } else {
        if (score < 30)  return results[0];
        else if (score >= 30 && score < 70) return results[2];
    }
    return results[3];
}

export const getBadgeResultColorClass = (score, is_standard_score) => {
    if(is_standard_score) {
        if (score < 85) return 'bavg-color';
        else if (score >= 85 && score < 100) return 'gr-color';
        else if (score >= 100 && score < 115) return 'avg-color';
    } else {
        if (score < 30) return 'bavg-color';
        else if (score >= 30 && score < 70) return 'avg-color';
    }
    return 'lb-color';
}

export const isBadgeEarned = (score) => {
    return score >= 85 ? true : false;
}

export const hasDQWAccess = (profile) => {
    return profile.access_info && profile.access_info.dq_world_access_level !== USER_PROFILE_ACCESS_LEVEL.NO_ACCESS;
}

export const hasDQPAccess = (profile) => {
    return profile.is_user_approved && profile.access_info && profile.access_info.dq_pro_access_level !== USER_PROFILE_ACCESS_LEVEL.NO_ACCESS;
}

export const isEnvDev = () => {
    return process.env.REACT_APP_NAME === "LOCAL" || process.env.REACT_APP_NAME === "DEVELOP";
}