import React, {useEffect} from 'react';
import Helmet from "react-helmet";
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from "react-router";
import DQIndexFooter from './components/Footer.comp';
import dqIndexBanner from "../../assets/images/dqindex/world-map-hr.png";
import dqIndexDCT from "../../assets/images/dqindex/sid2022-img.png";
import dqIndexLaunch from "../../assets/images/dqindex/dqindex-launch-img.png";
import dqIndexConf2020 from "../../assets/images/dqindex/dq-conf-2022-img.png";

import SDG3 from "../../assets/images/dqindex/icons/SDGIcons_3.png";
import SDG4 from "../../assets/images/dqindex/icons/SDGIcons_4.png";
import SDG5 from "../../assets/images/dqindex/icons/SDGIcons_5.png";
import SDG8 from "../../assets/images/dqindex/icons/SDGIcons_8.png";
import SDG9 from "../../assets/images/dqindex/icons/SDGIcons_9.png";
import SDG10 from "../../assets/images/dqindex/icons/SDGIcons_10.png";
import SDG16 from "../../assets/images/dqindex/icons/SDGIcons_16.png";
import SDG17 from "../../assets/images/dqindex/icons/SDGIcons_17.png";
import {useLocation} from "react-router-dom";
import {GET_WEBSITE_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";
import Header from "./components/Header.comp";

const DQIndexHome = (props) => {


    const { history } = props;
    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    useEffect(() => {
        //history.push(`/${pageLc}/login`);
    },[])

    return (
        <div className="index-page-container">
            <Helmet htmlAttributes={{lang: pageLc}} />
            <Header/>

            <section className='index-banner'>
                <img className='ibanner-img' src={dqIndexBanner} alt='DQ' />
                <div className='ibanner-content'>
                    <div className='ibanner-heading'>
                        <h1 className='ihead-title'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__global_project_to_measure')}</h1>
                        <h3 className='ihead-subtitle'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__realtime_tracker')}</h3>
                    </div>
                    <div className='press-release-btn'><a href="https://www.dqinstitute.org/news-post/digital-citizenship-test-cyber-risk-and-digital-skills-assessment-launch/" target={'_blank'}>
                        {ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__read_press_release_br'))}
                    </a></div>
                    <div className='ibanner-sm-txt'>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__disclaimer_1_br'))}</div>
                </div>
            </section>

            <section className='index-section-container'>
                <div className='index-section-content'>
                    <h1 className='isection-title'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__measure_digi_skill_lvl_of_country')}</h1>
                    <div className='isection-content'>
                        <p>
                            {ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__dq_is_starting_new_collab_project'))}
                        </p>
                        <p>
                            {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__covid19_pushed_world_message')}
                        </p>
                        <p>
                            {ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__ieee_digiskill_beyond_tech_competency'))}
                        </p>
                        <p>
                            {ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__based_on_un_sustainable_dev_goals'))}
                        </p>
                        <div className="skills-tracker">
                            <div className="digi-skills-row">
                                <div className="digi-skill dl-cos">
                                    <div className='digi-skill-content'>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__digi_skills_child_safety'))}</div>
                                    <div className='digi-skill-hover hover-left'>
                                        {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__protect_all_children')}
                                        <div><img src={SDG3} alt='SDG3'></img><img src={SDG4} alt='SDG4'></img><img src={SDG16} alt='SDG16'></img></div>
                                    </div>
                                </div>
                                <div className="digi-skill dl-learn">
                                    <div className='digi-skill-content'>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__digi_skills_learning'))}</div>
                                    <div className='digi-skill-hover'>
                                        {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__ensure_quality_statement_1')}
                                        <div><img src={SDG4} alt='SDG4'></img></div>
                                    </div>
                                </div>
                            </div>
                            <div className="digi-skills-row">
                                <div className="digi-skill dl-or">
                                    <div className='digi-skill-content'>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__digital_skills_for_online_participation'))}</div>
                                    <div className='digi-skill-hover hover-left'>
                                        {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__ensure_universal_access_by_all_people')}
                                        <div><img src={SDG9} alt='SDG9'></img><img src={SDG10} alt='SDG10'></img><img src={SDG17} alt='SDG17'></img></div>
                                    </div>
                                </div>
                                <div className="digi-skill dl-dit"><div className='digi-skill-content'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__global_tracker_for_digi_skills')}</div></div>
                                <div className="digi-skill dl-wi">
                                    <div className='digi-skill-content'>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__digiskills_innovation'))}</div>
                                    <div className='digi-skill-hover'>
                                        {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__ensure_digital_skilling')}
                                        <div><img src={SDG8} alt='SDG8'></img><img src={SDG9} alt='SDG9'></img><img src={SDG17} alt='SDG17'></img></div>
                                    </div>
                                </div>
                            </div>
                            <div className="digi-skills-row">
                                <div className="digi-skill dl-wb">
                                    <div className='digi-skill-content'>
                                        {ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__digital_skills_for_well_being'))}
                                    </div>
                                    <div className='digi-skill-hover hover-left'>
                                        {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__promote_wellbeing_in_digital_lives')}
                                        <div><img src={SDG3} alt='SDG3'></img><img src={SDG10} alt='SDG10'></img><img src={SDG16} alt='SDG16'></img></div>
                                    </div>
                                </div>
                                <div className="digi-skill dl-fe">
                                    <div className='digi-skill-content'>
                                        {ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__digital_skills_for_female_empowerment'))}
                                    </div>
                                    <div className='digi-skill-hover'>
                                        {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__achieve_digital_gender_equity')}
                                        <div><img src={SDG5} alt='SDG5'></img></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className='index-section-container'>
                <div className='index-section-content'>
                    <h1 className='isection-title'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__take_action')}</h1>
                    <div className='isection-content'>
                        <p>
                            {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__we_are_inviting_those_who_share_the_common_goal')}
                        </p>
                    </div>
                </div>
            </section>
            <section className='index-section-container isc-full-length'>
                <div className='index-section-content'>
                    <div className='index-actions'>
                        <div className='iactions-content'>
                            <div className='iaction-col iaction-wizard iaction-dct'>
                                <div className='iaction-wizard-content'>
                                    <h3>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__safer_internet_day_february_8_2022'))}</h3>
                                    <h4>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__digital_citizenship_test_dct')}</h4>
                                    <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__dct_was_made_available_to_the_public')}</p>
                                    <img src={dqIndexDCT} alt='DCT'></img>
                                    <div className='iaction-btn'><a href="https://storage.googleapis.com/dqindex.org/assets/common/Digital%20Citizenship%20Test%20(DCT)%20Social%20Media%20Guide.pdf" target='_blank'>
                                        {ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__download_the_test_kit'))}
                                    </a></div>
                                </div>
                            </div>
                            <div className='iaction-col iaction-wizard iaction-co'>
                                <div className='iaction-wizard-content'>
                                    <h3>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__tbd')}</h3>
                                    <h4>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__dq_index_launch'))}</h4>
                                    <p>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__the_dq_index_platform_will_be_launched'))}</p>
                                    <img src={dqIndexLaunch} alt='DQ Index Launch'></img>
                                    <div className='iaction-btn'><a href="https://www.dqinstitute.org/collaborative-rd/" target='_blank'>
                                        {ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__join_the_collaborative'))}
                                    </a></div>
                                </div>
                            </div>
                            <div className='iaction-col iaction-wizard iaction-conf'>
                                <div className='iaction-wizard-content'>
                                    <h3>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__dq_day_october_10_2022'))}</h3>
                                    <h4>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__2022_dq_conference')}</h4>
                                    <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__dq_conference_provides_learning_experiences')}</p>
                                    <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__if_you_interested_in_becoming_a_sponsor')}</p>
                                    <img src={dqIndexConf2020} alt='DQ Conference'></img>
                                    <div className='iaction-btn'><a href="https://docs.google.com/forms/d/e/1FAIpQLSdxAQDZq6hDyQ82OSDLK9kIc543EeGwW2y4wNo-_MGTVxNp1Q/viewform" target='_blank'>
                                        {ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__register_for_the_conference'))}
                                    </a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DQIndexFooter />
        </div>
    );
}

export default withRouter(DQIndexHome);
