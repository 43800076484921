import React, {useEffect, useState} from 'react';
import Button, {btnSize} from "../../../components/UI/Button/Button.ui";
import {useAuth} from "../../../context/auth";
import {useGlobalState} from "../../../context/global_state";
import {
    persistGuestGetGoogleSignInLink,
    persistGuestLoginUser,
    persistGuestRegisterUser,
    registerUser
} from "../../../services/auth.service";

import { withRouter } from "react-router";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {DQ_ENABLED_THEMES, LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import dqIcon from '../../../assets/icons/dq-icons/dq-logo.png';
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT, GET_WEBSITE_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import ReactHtmlParser from "react-html-parser";


const PersistGuest = (props) => {

    const { history } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [loginMode, setLoginMode] = useState(false);

    const [formUsername, setFormUsername] = useState('');
    const [formEmail, setFormEmail] = useState('');
    const [formPassword, setFormPassword] = useState('');

    const [formFirstName, setFormFirstName] = useState('');
    const [formLastName, setFormLastName] = useState('');
    const [formConfirmPassword, setFormConfirmPassword] = useState('');
    const [formAccessCode, setFormAccessCode] = useState('');

    const [showAccessCode, setShowAccessCode] = useState(false);

    const [termsAccepted, setTermsAccepted] = useState(false);

    const { setAuthToken, setAuthUser } = useAuth();
    const { setToastShow, setToastMessage, setProfile, profile, settings, setSettings } = useGlobalState();

    useEffect( () => {

        console.log('profile --> ', profile);

    }, [] )

    const registerAction = async () => {

        // check that no input field is null or blank
        if (showAccessCode) {
            if ([formFirstName, formLastName, formUsername, formEmail, formPassword, formConfirmPassword, formAccessCode].includes('')) {
                setToastShow(true);
                setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__plz_fill_req_fields'))
                return
            }
        } else {
            if ([formFirstName, formLastName, formUsername, formEmail, formPassword, formConfirmPassword].includes('')) {
                setToastShow(true);
                setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__plz_fill_req_fields'))
                return
            }
        }


        // check if users have checked the agreement box

        // check if both the password matches
        if (formPassword !== formConfirmPassword) {
            setToastShow(true);
            setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'uo__pass_mismatch_plz_retype'))
            return
        }

        if (!termsAccepted) {
            setToastShow(true);
            setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'uo__plz_accept_terms_of_use'));
            return
        }
        // check that no input field is null or blank


        // proceed to register the user, invoke the registration API
        try {
            setLoading(true);
            let profile
            const remember_me = true;
            const result = await persistGuestRegisterUser(formUsername, formEmail, formPassword, formFirstName, formLastName
                , formAccessCode, remember_me, showAccessCode);
            console.log('result.data -> ', result.data);
            if (result.data && result.data.user && result.data.org_code) {
                await setAuthToken(result.data.auth_token);
                await setAuthUser(result.data.user);
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE, result.data.org_code);

                profile = result.data.profile
                await setProfile(profile);
                if (result.data.mydq_app_theme && DQ_ENABLED_THEMES.includes(result.data.mydq_app_theme)) {
                    await setSettings({
                        ...settings,
                        theme: result.data.mydq_app_theme
                    });
                }

                if (!profile) {
                    return history.push('/home')
                }

                if (profile.is_demographic_updated) {
                    history.push('/home')
                } else {
                    history.push('/demographic')
                }

            }
            setLoading(false);
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
        }

    }

    const loginAction = async () => {

        try {
            setLoading(true);
            let profile;
            const remember_me = true;
            const result = await persistGuestLoginUser(formEmail, formPassword, remember_me);
            console.log('result.data -> ', result.data);
            if (result.data && result.data.user && result.data.org_code) {
                await setAuthToken(result.data.auth_token);
                await setAuthUser(result.data.user);

                //default profile
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE, result.data.org_code);
                profile = result.data.profile
                await setProfile(profile);
                if (result.data.mydq_app_theme && DQ_ENABLED_THEMES.includes(result.data.mydq_app_theme)) {
                    await setSettings({
                        ...settings,
                        theme: result.data.mydq_app_theme
                    });
                }

                //multiple profiles
                const {available_profiles} = result.data;
                localStorage.setItem(LOCAL_STORAGE_KEYS.AVAILABLE_PROFILES, JSON.stringify(available_profiles));
                const multiple_profiles = available_profiles.length > 1;

                if (!profile) {
                    return history.push('/home')
                }
                else {
                    if(multiple_profiles) {
                        localStorage.setItem(LOCAL_STORAGE_KEYS.PROMPT_SWITCH_PROFILE, true.toString());
                        history.push('/switch_profile')
                    }
                    else {
                        if (!result.data.dqw_access) {
                            history.push('/home')
                        } else {
                            history.push('/select_platform')
                        }
                    }
                }
            }
            setLoading(false);
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
        }

    }

    const loginWithGoogleAction = async () => {
        console.log('initiating google login');
        setLoading(true);
        try {
            const result = await persistGuestGetGoogleSignInLink();
            const {redirect_link} = result.data;
            window.open(redirect_link, "_self");
        }
        catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
        }
    }

    const registerLink = () => {
        setLoginMode(false);
    }

    const loginLink = () => {
        setLoginMode(true);
    }

    const termsOfUseLink = () => {
        history.push('/terms_of_use', true);
    }

    const privacyPolicyLink = () => {
        history.push('/privacy_policy', true);
    }

    const onChangeUsername = (e) => {
        setFormUsername(e.target.value);
    }

    const onChangeEmail = (e) => {
        setFormEmail(e.target.value);
    }

    const onChangePassword = (e) => {
        setFormPassword(e.target.value);
    }

    const onChangeFirstName = (e) => {
        setFormFirstName(e.target.value)
    }

    const onChangeLastName = (e) => {
        setFormLastName(e.target.value)
    }

    const onChangeConfirmPassword = (e) => {
        setFormConfirmPassword(e.target.value);
    }

    const onChangeAccessCode = (e) => {
        setFormAccessCode(e.target.value);
    }

    const onChangeTermsAccepted = (selected) => {
        console.log('onChangeTermsAccepted -> calling me -> ', selected)
        setTermsAccepted(selected)
    }

    return (
        <>
            {
                loginMode ?

                    <div className={'login-container'}>

                        <LoaderWithBackDrop
                            loading={loading}
                        />

                        <div className="dq-logo-image"><img src={dqIcon} alt='DQ'/></div>
                        <div className="dq-login-content">
                            <form onSubmit={e => e.preventDefault()}>
                                <div>
                                    <input
                                        className={'dq-login-txt'}
                                        type='text'
                                        name={'email'}
                                        id={'email'}
                                        value={formEmail}
                                        placeholder={GET_APP_LOCALIZE_TEXT(pageLc, 'uo__username')}
                                        onChange={onChangeEmail}
                                    />
                                </div>
                                <div>
                                    <input
                                        className={'dq-login-txt'}
                                        type='password'
                                        name={'password'}
                                        id={'password'}
                                        value={formPassword}
                                        placeholder={GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__password')}
                                        onChange={onChangePassword}
                                    />
                                </div>
                            </form>
                        </div>
                        
                        <div className={'dq-options-submit'}>
                            <button onClick={loginAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__login')}</button>
                        </div>

                        {
                            process.env.REACT_APP_ENABLE_LOGIN_WITH_GOOGLE === '1' && <div className={'google-login'}>
                                <button onClick={loginWithGoogleAction}>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__login_with_google')}
                                </button>
                            </div>
                        }

                        <div className={'dq-audience-login'}>
                            <p>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__not_have_an_account')}</p>
                            <a onClick={registerLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__register_here')}</a>
                        </div>
                        
                        <div className={'dq-guest-footer'}>
                            <a className={'dq-footer-link'} onClick={privacyPolicyLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__privacy_policy')}</a>
                            <a className={'dq-footer-link'} onClick={termsOfUseLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__terms_of_use')}</a>
                            <div className={'dq-copyright'}>&copy; {new Date().getFullYear()} {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__dq_lab_pte_ltd_all_rights_reserved')}</div>
                        </div>

                    </div>


                    :


                    <div className={'register-container'}>

                        <LoaderWithBackDrop
                            loading={loading}
                        />

                        <form onSubmit={e => e.preventDefault()}>
                            <div className='dq-register-row'>
                                <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__fname')}</label>
                                <input className={'dq-register-txt'}
                                    type='text'
                                    name={'first_name'}
                                    id={'first_name'}
                                    value={formFirstName}
                                    onChange={onChangeFirstName}
                                />
                            </div>
                            <div className='dq-register-row'>
                                <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__lname')}</label>
                                <input className={'dq-register-txt'}
                                    type='text'
                                    name={'last_name'}
                                    id={'last_name'}
                                    value={formLastName}
                                    onChange={onChangeLastName}
                                />
                            </div>

                            <div className='dq-register-row'>
                                <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__username')}</label>
                                <input className={'dq-register-txt'}
                                       type='text'
                                       name={'username'}
                                       id={'username'}
                                       value={formUsername}
                                       onChange={onChangeUsername}
                                />
                            </div>

                            <div className='dq-register-row'>
                                <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__email_address')}</label>
                                <input className={'dq-register-txt'}
                                    type='text'
                                    name={'email'}
                                    id={'email'}
                                    value={formEmail}
                                    onChange={onChangeEmail}
                                />
                            </div>

                            <div className='dq-register-row'>
                                <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__password')}</label>
                                <input className={'dq-register-txt'}
                                    type='password'
                                    name={'password'}
                                    id={'password'}
                                    value={formPassword}
                                    onChange={onChangePassword}
                                />
                            </div>

                            <div className='dq-register-row'>
                                <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__confirm_password')}</label>
                                <input className={'dq-register-txt'}
                                    type='password'
                                    name={'confirm_password'}
                                    id={'confirm_password'}
                                    value={formConfirmPassword}
                                    onChange={onChangeConfirmPassword}
                                />
                            </div>

                            {
                                !showAccessCode && <div className={'dq-audience-login-blue'}>
                                    <a onClick={ () => setShowAccessCode(true) }>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__have_access_code')}
                                    </a>
                                </div>
                            }

                            {
                                showAccessCode && <div className='dq-register-row'>
                                    <label
                                        onClick={ () => setShowAccessCode(false) }
                                    >
                                        {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__access_code_span'))}
                                    </label>
                                    <input className={'dq-register-txt'}
                                           type='text'
                                           name={'access_code'}
                                           id={'access_code'}
                                           value={formAccessCode}
                                           onChange={onChangeAccessCode}
                                    />
                                </div>
                            }


                        </form>

                        <div className={'dq-register-terms'}>
                            <div className={'dq-term-col1'}>
                                <input
                                    type={'radio'}
                                    name={'terms_accepted'}
                                    id={'terms_accepted'}
                                    value={termsAccepted}
                                    onChange={()=>onChangeTermsAccepted(true)}
                                />
                            </div>
                            <div>
                                {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__accept_terms_of_use'))}
                            </div>
                        </div>

                        <div className={'dq-options-submit'}>
                            <button onClick={registerAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__register')}</button>
                        </div>

                        {
                            process.env.REACT_APP_ENABLE_LOGIN_WITH_GOOGLE === '1' && <div className={'google-login'}>
                                <button onClick={loginWithGoogleAction}>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__register_with_google')}
                                </button>
                            </div>
                        }

                        <div className={'dq-audience-login'}>
                            <p>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__already_have_account')}</p>
                            <a onClick={loginLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__login_here')}</a>
                        </div>

                    </div>
            }
        </>
);

}

export default withRouter(PersistGuest);
