
const lc_en = {
    home__dq_index: `DQ Index`,
    home__dq_assessment: `DQ Assessment`,
    home__take_the_test: `Take the Test`,
    home__get_started:`Get Started`,
    home__global_project_to_measure: `Global Project to Measure the Digital Skills Level`,
    home__realtime_tracker: `Real-time Tracker for Digital Skills`,
    home__disclaimer_1: `Disclaimer: The above is for illustrative purposes only and is not indicative of a country’s level of digital skills or anything related.`,
    home__read_press_release: `Read the Press Release`,
    home__measure_digi_skills: `Measure the Digital Skills Level of your Country`,
    home__dqindex_intro_title: `The <a href="www.dqinstitute.org">DQ Institute</a> is starting a new global collaborative project to develop the DQ Index, a global tracker for digital skills.`,
    home__disc_about_cookies: `We use cookies to deliver our online services and to make interactions with our online services easy and meaningful. By continuing to use our site, you agree to our cookie policy. Details of the cookies we use and instructions on how to disable them are set out in our `,
    home__cookies_piolicy_link: ` <a href="/cookies" target="_blank">Cookies Policy</a>`,
    home__project_measure_digi_skill_level: `Global Project to Measure the Digital Skills Level`,
    home__realtime_tracker_for_digi_skills: `Real-time Tracker for Digital Skills`,
    home__read_press_release_br: `Read the <br>Press Release`,
    home__disclaimer_1_br: `Disclaimer: The above is for illustrative purposes only and is not <br>indicative of a country’s level of digital skills or anything related.`,
    home__measure_digi_skill_lvl_of_country: `Measure the Digital Skills Level of your Country`,
    home__dq_is_starting_new_collab_project: `The <a href="https://www.dqinstitute.org/" target="_blank">DQ Institute</a> is starting a new global collaborative project to develop the DQ Index, a global tracker for digital skills.`,
    home__covid19_pushed_world_message: `COVID-19 has pushed the world into fast digitization, where digital skills are now a must-have for every individual.`,
    home__ieee_digiskill_beyond_tech_competency: `“<strong>Digital skills</strong>” go beyond technical competency such as coding and computer operations. In accordance with the <a href="https://standards.ieee.org/ieee/3527.1/7589/" target="_blank">IEEE digital intelligence (DQ) Global Standards (IEEE 3527.1™)</a>, “digital skills” are defined as a comprehensive set of competencies needed to thrive in the digital age.`,
    home__based_on_un_sustainable_dev_goals: `Based on its <a href="https://www.dqinstitute.org/collaborative-rd/" target="_blank">collaborative R&amp;D platform</a> that aggregates different frameworks, researches, and programs, the DQ Index develops a set of standardized metrics that tracks how countries are progressing in terms of the following six digital skills that are aligned with the UN Sustainable Development Goals.`,
    home__global_tracker_for_digi_skills: `Global Tracker for Digital Skills`,
    home__digi_skills_child_safety: `Digital Skills for <span>Child Online Safety</span>`,
    home__protect_all_children: `Protect all children from cyber-risks and harms and ensure digital citizenship skills for all children`,
    home__digi_skills_learning: `Digital Skills for <span>Learning</span>`,
    home__ensure_quality_statement_1: `Ensure quality and comprehensive digital skill education and promote life-long opportunities for all`,
    home__digiskills_innovation: `Digital Skills for <span>Work &amp; Innovation</span>`,
    home__ensure_digital_skilling: `Ensure digital skilling/upskilling of all workforces, promote decent, and productive digital workplace, at all levels, and foster science, technology, and innovation`,
    home__digital_skills_for_female_empowerment: `Digital Skills for <span>Female Empowerment</span>`,
    home__achieve_digital_gender_equity: `Achieve digital gender equity and empower all women and girls through enabling technology`,
    home__digital_skills_for_well_being: `Digital Skills for <span>Well-being</span>`,
    home__promote_wellbeing_in_digital_lives: `Promote wellbeing in digital lives, reduce inequality, and promote safe, trustful, and peaceful digitized societies for all, at all levels`,
    home__digital_skills_for_online_participation: `Digital Skills for <span>Online Participation</span>`,
    home__ensure_universal_access_by_all_people: `Ensure universal and inclusive access by all people, in particular the people in vulnerable situations including seniors and people with special needs, to the Internet and to participation in the digital economy`,
    home__take_action: `Take Action`,
    home__we_are_inviting_those_who_share_the_common_goal: `We are inviting those who share the common goal of closing the digital skills gap to join the DQ Index project. We monitor and track global progress in achieving the goal by measuring our collective impact through the DQ Index.`,
    home__safer_internet_day_february_8_2022: `Safer Internet Day<br> (February 8, 2022)`,
    home__digital_citizenship_test_dct: `Digital Citizenship Test (DCT)`,
    home__dct_was_made_available_to_the_public: `DCT was made available to the public so that all organizations and individuals can use it to understand how ready they are to use technology safely.`,
    home__download_the_test_kit: `Download the <br>Test Kit`,
    home__tbd: `TBD`,
    home__dq_index_launch: `DQ Index Launch <br>&amp; Co-Creation`,
    home__the_dq_index_platform_will_be_launched: `The DQ Index platform will be launched on a later date in 2022 while we invite partners to join the collaborative R&amp;D project.`,
    home__join_the_collaborative: `Join the <br>Collaborative R&amp;D`,
    home__dq_day_october_10_2022: `DQ Day <br> (October 10, 2022)`,
    home__2022_dq_conference: `2022 DQ Conference`,
    home__dq_conference_provides_learning_experiences: `DQ Conference provides learning experiences to the global community about digital skills. The conference will include discussions on the DQ Index, case studies and more.`,
    home__if_you_interested_in_becoming_a_sponsor: `If you’re interested in becoming a sponsor,  speaker, or participant, please register below.`,
    home__register_for_the_conference: `Register for <br>the Conference`,
    home__privacy_policy: `Privacy Policy`,
    home__dq_lab_pte_ltd_all_rights_reserved: `DQ LAB Pte Ltd. All Rights Reserved.`,
    home__language: `Language`,
    home__term_of_use: `Term of Use`,
    home__email: `Email`,
    home__general_enquiries: `General Enquiries`,
    home__social_media: `Social Media`,
    assessment__whats_your_dq:`What's Your DQ?`,
    assessment__find_out_what_type_of_digital_citizen_you_are: `Find out <br>what type of digital citizen you are`,
    assessment__take_the_free_test: `Take The Free Test`,
    assessment__5_types_of_digital_citizen: `5 Types of Digital Citizen`,
    assessment__based_on_statement_misc_1: `Based on <a href="https://www.dqinstitute.org/" target="_blank">DQ Institute's</a> global databank, we have identified 5 different types of digital citizens showing different patterns of skills, technology usage, and cyber-risk exposure.`,
    assessment__carefree: `CAREFREE`,
    assessment__feels_more_comfortable_online_than_offline: `Feels more comfortable online than offline`,
    assessment__potentially_involved_with_various_cyber_risks: `Potentially involved with various cyber-risks`,
    assessment__ordinary: `ORDINARY`,
    assessment__average_technology_user_who_not_fully_aware: `Average technology user who may not be fully aware of the risks nor opportunities`,
    assessment__tend_to_passively_follow_the_algorithm: `Tend to passively follow the algorithm`,
    assessment__always_on: `ALWAYS ON`,
    assessment__likely_communicate_with_others_a_lot_online: `Likely communicate with others a lot online`,
    assessment__may_not_consider_much_about_risks: `May not consider much about potential cyber-risks`,
    assessment__cautious: `CAUTIOUS`,
    assessment__being_aware_of_potential_cyber_risks: `Being aware of potential cyber-risks and not fully exploring the digital world`,
    assessment__likely_having_low_risks_in_the_digi_world: `Likely having low risks in the digital world`,
    assessment__digilog: `DIGILOG`,
    assessment__well_rounded_digital_citizen: `Well-rounded digital citizen who balances online and offline lives`,
    assessment__uses_technology_mindfully: `Uses technology mindfully`,
    assessment__low: `Low`,
    assessment__medium: `Medium`,
    assessment__high: `High`,
    assessment__skills: `Skills`,
    assessment__usage: `Usage`,
    assessment__risks: `Risks`,
    assessment__be_yourself_and_answer_honestly: `Be yourself and answer honestly to find out your digital citizen type and level.`,
    assessment__take_the_test: `Take the Test`,
    assessment__digital_citizenship_test_and_dq_index: `Digital Citizenship Test and DQ Index`,
    assessment__the_level_of_digital_citizenship_is: `The level of <span class="isection-toolip">digital citizenship<div class="tooltip-content">Digital citizenship is a skill set required to use technology safely. As the first step of the IEEE digital intelligence (DQ) Global Standards (IEEE 3527.1™), digital citizenship is considered as a prerequisite for other digital skills, such as coding, cyber security, and other job-readiness technical skills.</div></span> in a country is a strong sign whether their DQ Index score is high or not.`,
    assessment__based_on_years_of_research_desc: `Based on years of research, the DQ Institute developed a set of questions that effectively evaluates the digital citizenship level of individuals, which is called the Digital Citizenship Test (DCT).`,
    assessment__connected_with_the_dq_index_desc: `Connected with the DQ Index, the results from the DCT are immediately updated to the index in order to showcase where each country stands. This allows the countries to effectively utilize resources and to inclusively improve their digital skill levels, creating a positive impact.`,
    assessment__digital_citizenship_is_a_skill_desc: `Digital citizenship is a skill set required to use technology safely. As the first step of the IEEE digital intelligence (DQ) Global Standards (IEEE 3527.1™), digital citizenship is considered as a prerequisite for other digital skills, such as coding, cyber security, and other job-readiness technical skills.`,
    assessment__digital_citizenship_scores: `Digital Citizenship Scores`,
    assessment__global: `Global`,
    assessment__australia: `Australia`,
    assessment__cambodia: `Cambodia`,
    assessment__china: `China`,
    assessment__colombia: `Colombia`,
    assessment__dominican_republic: `Dominican Republic`,
    assessment__ecuador: `Ecuador`,
    assessment__el_salvador: `El Salvador`,
    assessment__hong_kong: `Hong Kong`,
    assessment__india: `India`,
    assessment__indonesia: `Indonesia`,
    assessment__ireland: `Ireland`,
    assessment__japan: `Japan`,
    assessment__korea_republic_of: `Korea, Republic Of`,
    assessment__malaysia: `Malaysia`,
    assessment__mexico: `Mexico`,
    assessment__new_zealand: `New Zealand`,
    assessment__nigeria: `Nigeria`,
    assessment__peru: `Peru`,
    assessment__philippines: `Philippines`,
    assessment__qatar: `Qatar`,
    assessment__singapore: `Singapore`,
    assessment__south_africa: `South Africa`,
    assessment__spain: `Spain`,
    assessment__sweden: `Sweden`,
    assessment__thailand: `Thailand`,
    assessment__turkey: `Turkey`,
    assessment__united_kingdom: `United Kingdom`,
    assessment__united_states: `United States`,
    assessment__viet_nam: `Viet Nam`,
    assessment__n_a_text: `N/A`,
    assessment__national_dq: `National DQ`,
    assessment__global_dq: `Global DQ`,
    assessment__percent_of_participants: `% of Participants`,
    assessment__digital_citizenship_score: `Digital Citizenship Score`,
    assessment__distribution_of_dcs: `Distribution of DCS (Digital Citizenship Score)`,
    assessment__about_dq_assessments: `About DQ Assessments`,
    assessment__forall: `forALL`,
    assessment__available_for_all_age_groups_and_types: `Available for all age groups and types.`,
    assessment__any_system: `Any System`,
    assessment__whether_you_looking_to_integrate: `Whether you’re looking to integrate into your existing system or want an out of the box solution, we can make it happen.`,
    assessment__dq_index: `DQ Index`,
    assessment__scores_are_immediately_updated_into: `Scores are immediately updated into the <a href="https://dqindex.org/">DQ Index</a> for national and global comparisons.`,
    assessment__dq_assessment_was_developed_as: `DQ Assessment was developed as a web/mobile-based platform providing assessment & analytics tools to support digital skills education/training programs to measure digital skills for individuals and organizations based on global benchmarks.`,
    assessment__dq_assessment_will_enable:`It will enable:`,
    assessment__1_common_assessments_that_measure: `Common assessments that measure the levels of multiple competencies of digital intelligence of individuals and organizations against global standards with global benchmarks`,
    assessment__2_in_depth_analysis_to_the_end_users: `In-depth analysis to the end users (e.g., individuals and organizations) to identify their strengths and weaknesses through global benchmark`,
    assessment__3_customized_digital_skill_development: `Customized digital skill development for individuals and organizations to help design customized lifelong training`,
    assessment__dq_assessment_tools_are_developed_based_on: `DQ Assessment tools are developed based on collection of the various assessment instruments from various credible sources and existing research projects of content owner partners.`,
    assessment__toolkits: `Toolkits`,
    assessment__whether_it_be_your_school_company_or: `Whether it be your school, company or country, we have a variety of assessments that helps in measuring your digital citizenship skills based on global benchmark.`,
    assessment__if_you_looking_to_create_a_customized: `If you’re looking to create a customized assessment tool (white-labeled) or in-depth analytic services, or need more information, please <a href="mailto:contact@dqforall.com">contact us</a>.`,
    assessment__primary_schools: `PRIMARY<br>SCHOOLS`,
    assessment__schools_can_get_their_students_ages_8_12: `Schools can get their students ages 8-12 to take a more comprehensive digital citizenship test to find out their digital citizenship levels.`,
    assessment__measures_dq_1_8: `Measures DQ 1-8`,
    assessment__analytics_dashboard_included_for_teachers: `Analytics Dashboard included for teachers`,
    assessment__digital_parenting: `Digital Parenting`,
    assessment__cyber_risk_assessment: `Cyber-Risk Assessment`,
    assessment__learn_more: `Learn More`,
    assessment__secondary_schools: `SECONDARY<br>SCHOOLS`,
    assessment__schools_can_get_their_teenage_students_ages_13_18: `Schools can get their teenage students ages 13-18 to take a more comprehensive digital citizenship test to find out their digital citizenship levels.`,
    assessment__companies_corporates_span: `COMPANIES`,
    assessment__companies_and_organizations_can_assess: `Companies and organizations can assess their employees’ digital citzenship skills and identify areas of needed growth.`,
    assessment__dashboard_included_for_hr: `Analytics Dashboard included for HR staff`,
    assessment__national_packages: `NATIONAL<br/>PACKAGES`,
    assessment__countries_and_govt_localize_assess: `Countries and governments can measure their country’s DQ with customized and localized assessments.`,
    assessment__customize_assessment: `Customized assessments`,
    assessment__localized_for_your_country: `Localized/translated for your country`,
    assessment__consult_strategies_plans: `Consult, strategize, and co-develop of roll-out plans`,
    assessment__balance_use_of_tech: `Balanced Use of Technology`,
    assessment__behavioural_risk_management: `Behavioural Cyber-Risk Management`,
    assessment__digital_empathy: `Digital Empathy`,
    assessment__dc_identity: `Digital Citizen Identity`,
    assessment__ps_cyber_sec_management: `Personal Cyber Security Management`,
    assessment__media_and_info_literacy: `Media and Information Literacy`,
    assessment__digi_footprint_management: `Digital Footprint Management`,
    assessment__privacy_management: `Privacy Management`,
    assessment__read_press_release: `Read the <br>Press Release`,
}


const lc_ko = {
    home__dq_index: `DQ 인덱스`,
    home__dq_assessment: `DQ 평가`,
    home__take_the_test: `무료 테스트하기`,
    home__get_started:`시작하다`,
    home__global_project_to_measure: `디지털 역량 수준을 측정하는 글로벌 프로젝트`,
    home__realtime_tracker: `디지털 역량의 실시간 트래커`,
    home__disclaimer_1: `주의: 위는 단지 이해를 돕기 위한 것이며 국가의 디지털 역량 수준 혹은 그에 대한 관련 사항을 나타내지 않습니다.`,
    home__read_press_release: `보도자료 읽기`,
    home__measure_digi_skills: `국가의 디지털 역량 수준 측정하기`,
    home__dqindex_intro_title: `<a href="www.dqinstitute.org">DQ 연구소</a>는 전 세계 나라의 디지털 역량 수준을 글로벌 표준에 의거하여 객관적으로 추적하고, 평가하기 위해 새로운 글로벌 협업 프로젝트인 DQ 인덱스를 시작합니다.`,
    home__disc_about_cookies: `당사는 쿠키를 사용하여 온라인 서비스를 제공하고 온라인 서비스와의 상호작용을 쉽고 의미 있게 만듭니다. 당사 사이트를 계속 사용함으로써 귀하는 당사의 쿠키 정책에 동의합니다. 당사가 사용하는 쿠키의 세부 사항 및 쿠키를 사용하지 않도록 설정하는 방법에 대한 지침은 당사에 명시되어 있습니다. `,
    home__cookies_piolicy_link: ` <a href="/cookies" target="_blank">쿠키 정책</a>`,
    home__project_measure_digi_skill_level: `디지털 역량 평가를 위한 글로벌 프로젝트`,
    home__realtime_tracker_for_digi_skills: `디지털 역량 측정을 위한 실시간 트래커`,
    home__read_press_release_br: `보도자료<br>읽기`,
    home__disclaimer_1_br: `주의: 위는 단지 이해를 돕기 위한 것이며 국가의 디지털 역량 수준 혹은 그에 대한 관련 사항을 나타내지 않습니다.`,
    home__measure_digi_skill_lvl_of_country: `국가의 디지털 역량 수준 측정하기`,
    home__dq_is_starting_new_collab_project: `<a href="https://www.dqinstitute.org/" target="_blank">DQ 연구소</a>는 전 세계 나라의 디지털 역량 수준을 글로벌 표준에 의거하여 객관적으로 추적하고, 평가하기 위해 새로운 글로벌 협업 프로젝트인 DQ 인덱스를 시작합니다.`,
    home__covid19_pushed_world_message: `COVID-19로 인해 빠르게 진행된 디지털 대전환으로 인해 이제 디지털 역량은 모든 개인에게 반드시 필요한 역량이 되었습니다.`,
    home__ieee_digiskill_beyond_tech_competency: `<strong>디지털 역량</strong>은 단순히 코딩이나 컴퓨터나 디지털 기기를 쓰는 기술적 역량이 아닙니다. <a href="https://standards.ieee.org/ieee/3527.1/7589/" target="_blank">IEEE 디지털 지능(DQ) 글로벌 표준(IEEE 3527.1™)에 따라</a>, "디지털 역량"은 디지털 시대에 성공하는 데 필요한 포괄적 역량으로 정의됩니다.`,
    home__based_on_un_sustainable_dev_goals: `DQ 인덱스는 전 세계의 다양한 프레임워크, 연구 및 프로그램을 집계하는 <a href="https://www.dqinstitute.org/collaborative-rd/" target="_blank">협업 플랫폼</a>을 기반으로 유엔의 '지속 가능한 개발 목표'에 따라 6가지 디지털 역량의 관점에서 국가가 어떻게 디지털 역량을 향상시키고 있는지를 추적하는 표준화된 지표입니다.`,
    home__global_tracker_for_digi_skills: `디지털 역량 측정을 위한 글로벌 트래커`,
    home__digi_skills_child_safety: `<span>아동 온라인 안전</span>을 위한 디지털 역량`,
    home__protect_all_children: `모든 어린이를 사이버 위험과 유해요소로부터 보호하고 모든 어린를 위한 디지털 시민의식 역량을 보장합니다.`,
    home__digi_skills_learning: `<span>학습</span>을 위한 디지털 역량`,
    home__ensure_quality_statement_1: `양질의 포괄적인 디지털 역량 교육을 보장하고 모든 사람들에게 평생 교육 기회를 촉진합니다.`,
    home__digiskills_innovation: `<span>일과 혁신</span>을 위한 디지털 역량`,
    home__ensure_digital_skilling: `모든 인력의 디지털 역량의 증진을 보장하고, 모든 수준에서 훌륭하고 생산적인 디지털 업무 공간을 홍보하며, 과학, 기술 및 혁신을 촉진합니다.`,
    home__digital_skills_for_female_empowerment: `<span>여성 역량 강화</span>를 위한 디지털 역량`,
    home__achieve_digital_gender_equity: `디지털 성 평등을 달성하고 모든 여성들에게 기술을 사용할 수 있도록 합니다.`,
    home__digital_skills_for_well_being: `<span>웰빙</span>을 위한 디지털 역량`,
    home__promote_wellbeing_in_digital_lives: `디지털 생활에서 웰빙을 촉진하고, 불평등을 줄이며, 안전하고 신뢰할 수 있으며 평화로운 디지털화된 사회를 모든 수준에서 촉진합니다.`,
    home__digital_skills_for_online_participation: `<span>온라인 참여</span>를 위한 디지털 역량`,
    home__ensure_universal_access_by_all_people: `모든 사람, 특히 특별한 도움이 필요한 노인과 사람들을 포함한 취약한 상황에 있는 사람들, 인터넷 및 디지털 경제에 참여할 수 있도록 보편적이고 포괄적인 접근을 보장합니다.`,
    home__take_action: `참여하기`,
    home__we_are_inviting_those_who_share_the_common_goal: `디지털 역량 격차를 해소하겠다는 공통의 목표를 공유하는 여러분을 DQ 인덱스 프로젝트에 초대합니다. DQ 인덱스를 통해 디지털 역량 강화를 위한 모든 노력들과 그 임팩트를 공동으로 측정하고, 목표 달성에 위한 글로벌 진행 상황을 모니터링하고 추적합니다.`,
    home__safer_internet_day_february_8_2022: `안전한 인터넷의 날<br>(2022년 2월 8일)`,
    home__digital_citizenship_test_dct: `디지털 시민의식 테스트(DCT)`,
    home__dct_was_made_available_to_the_public: `DCT는 개인이 기술을 안전하고 책임감있게 사용할 준비가 되었는지에 대한 역량을 측정합니다. 안전한 인터넷의 날을 기념하여 모두가 이용할 수 있도록 제공됩니다.`,
    home__download_the_test_kit: `테스트 키트 <br>다운로드`,
    home__tbd: `미정`,
    home__dq_index_launch: `DQ 인덱스 출시 <br>& 공동 개발`,
    home__the_dq_index_platform_will_be_launched: `DQ 인덱스 플랫폼은 2022년 후반에 출시될 예정입니다. 공동 R&D 프로젝트에 참여하실 파트너들을 초대합니다.`,
    home__join_the_collaborative: `공동 R&D 프로젝트에 <br> 참여하기`,
    home__dq_day_october_10_2022: `DQ의 날 <br> (2022년 10월 10일)`,
    home__2022_dq_conference: `2022년 DQ 컨퍼런스`,
    home__dq_conference_provides_learning_experiences: `DQ 컨퍼런스는 디지털 역량에 관련된 교육, 정책, 학술 연구, 그리고 다양한 경험사례를 나누는 장입니다.`,
    home__if_you_interested_in_becoming_a_sponsor: `스폰서, 연사 또는 참가자가 되는 데 관심이 있으시면 아래의 링크를 통해 등록하세요.`,
    home__register_for_the_conference: `컨퍼런스에<br>등록하기`,
    home__privacy_policy: `개인정보 정책`,
    home__dq_lab_pte_ltd_all_rights_reserved: `DQ LAB Pte Ltd. All Rights Reserved.`,
    home__language: `언어`,
    home__term_of_use: `이용약관`,
    home__email: `이메일`,
    home__general_enquiries: `일반 문의`,
    home__social_media: `소셜 미디어`,
    assessment__whats_your_dq:`당신의 DQ는 무엇입니까?`,
    assessment__find_out_what_type_of_digital_citizen_you_are: `<br>디지털 시민의 유형을 알아보세요.`,
    assessment__take_the_free_test: `무료 테스트하기`,
    assessment__5_types_of_digital_citizen: `5가지 유형의 디지털 시민`,
    assessment__based_on_statement_misc_1: `<a href="https://www.dqinstitute.org/" target="_blank">DQ 연구소</a>의 글로벌 데이터 뱅크를 기반으로 디지털 역량 수준, 기술 사용 및 사이버 위험도의 다양한 패턴을 보여주는 디지털 시민의 5가지 유형을 확인했습니다.`,
    assessment__carefree: `골수 디지털 네이게이터`,
    assessment__feels_more_comfortable_online_than_offline: `당신은 오프라인보다 온라인에서 생활하는 것이 더 편함.`,
    assessment__potentially_involved_with_various_cyber_risks: `하지만 다양한 디지털 위험에 이미 노출되어 있을 수 있음.`,
    assessment__ordinary: `평범한 디지털 유저`,
    assessment__average_technology_user_who_not_fully_aware: `디지털 세상의 위험도, 그렇다고 기회도 완전히 잘 알지는 못함.`,
    assessment__tend_to_passively_follow_the_algorithm: `디지털에서 추천하는 알고리즘을 수동적으로 따르는 경향이 있음.`,
    assessment__always_on: `상시접속형 디지털 관종`,
    assessment__likely_communicate_with_others_a_lot_online: `온라인으로 사람들과 소통하는 것에 많은 시간을 투자함.`,
    assessment__may_not_consider_much_about_risks: `디지털 위험 따위는 크게 안중에 없음.`,
    assessment__cautious: `조심성 많은 디지털 경계형`,
    assessment__being_aware_of_potential_cyber_risks: `디지털 위험을 잘 인지하고 있고, 디지털 이용에 대한 경계심을 가지고 있어 쉽게 위험에 빠지지는 않음.`,
    assessment__likely_having_low_risks_in_the_digi_world: `그렇지만 디지털을 제대로 이용하지도 않음.`,
    assessment__digilog: `균형 잡힌 디지로그형`,
    assessment__well_rounded_digital_citizen: `온라인과 오프라인의 삶을 균형 잡을 줄 아는 모범적인 디지털 시민.`,
    assessment__uses_technology_mindfully: `기술을 제대로 이해하고, 신중하게 사용할 줄 알음.`,
    assessment__low: `낮음`,
    assessment__medium: `중간`,
    assessment__high: `높음`,
    assessment__skills: `역량`,
    assessment__usage: `사용량`,
    assessment__risks: `위험도`,
    assessment__be_yourself_and_answer_honestly: `솔직하게 답변하고 여러분의 디지털 시민 유형과 DQ 점수를 알아보세요.`,
    assessment__take_the_test: `무료 테스트하기`,
    assessment__digital_citizenship_test_and_dq_index: `디지털 시민의식 테스트 및 DQ 인덱스`,
    assessment__the_level_of_digital_citizenship_is: `한 국가의 <span class="isection-toolip">디지털 시민의식<div class="tooltip-content">디지털 시민의식은 기술을 안전하고 책임감 있게 사용하는 데 필요한 역량입니다. IEEE 디지털 지능(DQ) 글로벌 표준(IEEE 3527.1™)의 첫 번째 단계로 디지털 시민의식은 코딩, 사이버 보안 및 기타 직무 준비 기술 기술과 같은 다른 디지털 역량을 전제 조건으로 간주합니다.</div></span> 수준은 그 나라의 그 나라의 전체 디지털 역량 수준을 가늠할 수 있는 중요한 지표입니다.`,
    assessment__based_on_years_of_research_desc: `DQ 연구소는 다년간의 연구를 바탕으로 개인의 디지털 시민의식 수준을 평가하는 질문지 ‘디지털 시민의식 테스트(DCT)’를 개발했습니다.`,
    assessment__connected_with_the_dq_index_desc: `DCT의 결과는 DQ 인덱스와 실시간 연계되어 각 국가의 디지털 시민의식 수준을 보여주는 지표에 반영됩니다. DQ인덱스는 각국이 필요한 재원을 효과적으로 활용하여 전국민의 디지털 역량 향상을 이끌고, 디지털 대전환을 성공적으로 이룰 수 있도록 돕습니다.`,
    assessment__digital_citizenship_is_a_skill_desc: `IEEE 디지털 지능(DQ) 글로벌 표준(IEEE 3527.1™)의 첫 번째 단계로 디지털 시민의식은 코딩, AI 역량 및 기타 IT 직무 관련 역량과 같은 다른 디지털 역량을 전제 조건으로 간주합니다`,
    assessment__digital_citizenship_scores: `디지털 시민의식 점수`,
    assessment__global: `글로벌`,
    assessment__australia: `호주`,
    assessment__cambodia: `캄보디아`,
    assessment__china: `중국`,
    assessment__colombia: `콜롬비아`,
    assessment__dominican_republic: `도미니카 공화국`,
    assessment__ecuador: `에콰도르`,
    assessment__el_salvador: `엘살바도르`,
    assessment__hong_kong: `홍콩`,
    assessment__india: `인도`,
    assessment__indonesia: `인도네시아`,
    assessment__ireland: `아일랜드`,
    assessment__japan: `일본`,
    assessment__korea_republic_of: `대한민국`,
    assessment__malaysia: `말레이시아`,
    assessment__mexico: `멕시코`,
    assessment__new_zealand: `뉴질랜드`,
    assessment__nigeria: `나이지리아`,
    assessment__peru: `페루`,
    assessment__philippines: `필리핀`,
    assessment__qatar: `카타르`,
    assessment__singapore: `싱가포르`,
    assessment__south_africa: `남아프리카 공화국`,
    assessment__spain: `스페인`,
    assessment__sweden: `스웨덴`,
    assessment__thailand: `태국`,
    assessment__turkey: `터키`,
    assessment__united_kingdom: `영국`,
    assessment__united_states: `미국`,
    assessment__viet_nam: `베트남`,
    assessment__n_a_text: `N/A`,
    assessment__national_dq: `국가 DQ 인덱스`,
    assessment__global_dq: `글로벌 DQ 인덱스`,
    assessment__percent_of_participants: `참여자 비율`,
    assessment__digital_citizenship_score: `디지털 시민의식 점수`,
    assessment__distribution_of_dcs: `디지털 시민의식 점수(DCS) 분포`,
    assessment__about_dq_assessments: `DQ 평가란?`,
    assessment__forall: `누구든지`,
    assessment__available_for_all_age_groups_and_types: `모든 연령대 및 유형이 사용 가능하다.`,
    assessment__any_system: `어떤 조직에서든지`,
    assessment__whether_you_looking_to_integrate: `기존 시스템에 반영하는 경우든 새로운 시스템을 원하는 경우든 언제나 활용할 수 있다.`,
    assessment__dq_index: `DQ 인덱스`,
    assessment__scores_are_immediately_updated_into: `점수는 즉시 국내 및 글로벌 비교를 위해 <a href="https://dqindex.org/">DQ 인덱스</a>에 반영된다.`,
    assessment__dq_assessment_was_developed_as: `DQ 평가는 웹/모바일 기반의 플랫폼으로 개발되었으며, 디지털 역량 교육 프로그램이 글로벌 벤치마크를 기반으로 개인 및 조직의 디지털 역량을 측정하도록 돕는 평가/분석 도구를 제공합니다.`,
    assessment__dq_assessment_will_enable:` DQ 평가는 다음을 가능케 합니다.`,
    assessment__1_common_assessments_that_measure: `개인 및 조직의 디지털 역량 수준을 글로벌 표준과 비교하여 측정할 수 있습니다.`,
    assessment__2_in_depth_analysis_to_the_end_users: `개인 및 조직의 강점과 약점을 파악하기 위하여 글로벌 벤치마크를 바탕으로 한 상세한 분석을 제공합니다.`,
    assessment__3_customized_digital_skill_development: `생애주기별 교육을 디자인할 수 있도록 개인 및 조직을 위한 맞춤 디지털 역량 개발을 제공합니다.`,
    assessment__dq_assessment_tools_are_developed_based_on: `DQ 평가는 신뢰할 수 있는 다양한 연구결과와 파트너들의 연구 프로젝트를 바탕으로 개발되었습니다.`,
    assessment__toolkits: `다양한 툴킷`,
    assessment__whether_it_be_your_school_company_or: `글로벌 벤치마크에 근거해 디지털 시민의식을 평가하는 다양한 툴킷을 학교, 기업 및 국가에 제공합니다.`,
    assessment__if_you_looking_to_create_a_customized: `만약 맞춤형 평가 수단이나 상세 분석 서비스를 찾으신다면, 또는 기타 정보가 필요하시다면, 당사로 문의해주세요.`,
    assessment__primary_schools: `초등학교`,
    assessment__schools_can_get_their_students_ages_8_12: `만 8~12세 학생들에게 포괄적인 디지털 시민의식 테스트를 제공하여, 디지털 시민의식 수준을 확인할 수 있다.`,
    assessment__measures_dq_1_8: `DQ의 8가지 세부항목 측정`,
    assessment__analytics_dashboard_included_for_teachers: `교사용 분석 대시보드 포함`,
    assessment__digital_parenting: `디지털 양육법 교육`,
    assessment__cyber_risk_assessment: `디지털 위험도 평가`,
    assessment__learn_more: `더 알아보기`,
    assessment__secondary_schools: `중·고등학교`,
    assessment__schools_can_get_their_teenage_students_ages_13_18: `만 13~18세 학생들의 디지털 시민의식을 측정하여, 디지털 시민의식을 더 포괄적으로 이해하게 한다.`,
    assessment__companies_corporates_span: `기업`,
    assessment__companies_and_organizations_can_assess: `기업과 조직은 직원의 디지털 역량과 시민의식을 평가하고 성장이 필요한 부분을 확인할 수 있다.`,
    assessment__dashboard_included_for_hr: `인사 담당자를 위한 분석 대시보드 포함`,
    assessment__national_packages: `국가<br/>패키지`,
    assessment__countries_and_govt_localize_assess: `국가 및 정부는 현지화된 맞춤형 평가를 통해 자국의 DQ를 측정할 수 있다.`,
    assessment__customize_assessment: `맞춤형 평가`,
    assessment__localized_for_your_country: `각국에 맞게 현지화 및 번역 완료`,
    assessment__consult_strategies_plans: `DQ 활용 계획의 컨설팅, 전략화 및 공동 개발`,
    assessment__balance_use_of_tech: `균형 잡힌 기술 사용`,
    assessment__behavioural_risk_management: `행동 디지털 위험 관리`,
    assessment__digital_empathy: `디지털 공감`,
    assessment__dc_identity: `디지털 시민 정체성`,
    assessment__ps_cyber_sec_management: `개인 디지털 보안 관리`,
    assessment__media_and_info_literacy: `미디어 및 정보 리터러시`,
    assessment__digi_footprint_management: `디지털 발자국 관리`,
    assessment__privacy_management: `사생활 관리`,
    assessment__read_press_release: '더 알아보기',
}

const lc_ja = {
    home__dq_index: `DQインデックス`,
    home__dq_assessment: `DQアセスメント`,
    home__take_the_test: `テストを受ける`,
    home__get_started:`始めましょう`,
    home__global_project_to_measure: `世界各国のデジタルスキルを測定するプロジェクト`,
    home__realtime_tracker: `デジタルスキルの水準をリアルタイムに追跡`,
    home__disclaimer_1: `*上記は例示であり、その国のデジタル技術のレベル等を示すものではありません。`,
    home__read_press_release: `プレスリリースを読む`,
    home__measure_digi_skills: `デジタルスキルを測定する`,
    home__dqindex_intro_title: `<a href=\"www.dqinstitute.org\">DQ Institute</a>は、DQインデックスを開発するため、新たな世界共同プロジェクトを開始します。`,
    home__disc_about_cookies: `当社は、当社のオンラインサービスの提供と利用を容易かつ有意義なものにするために、Cookieを使用しています。当社サイトの利用を継続することにより、お客様は当社のCookieに関する規約に同意したものとみなされます。当社が使用するCookieの詳細と、それらを無効にする方法については、以下をご覧ください。 `,
    home__cookies_piolicy_link: ` <a href=\"/cookies\" target=\"_blank\">Cookieの使用に関する方針</a>。`,
    home__project_measure_digi_skill_level: `デジタルスキルの水準測定のための世界共同プロジェクト`,
    home__realtime_tracker_for_digi_skills: `世界各国のデジタルスキルの水準をリアルタイムで追跡`,
    home__read_press_release_br: `プレスリリース<br>を読む`,
    home__disclaimer_1_br: `*上記は例示であり、その国のデジタル技術のレベル等を示すものではありません。`,
    home__measure_digi_skill_lvl_of_country: `デジタルスキルを測定する`,
    home__dq_is_starting_new_collab_project: `<a href=\"https://www.dqinstitute.org/\" target=\"_blank\">DQ Institute</a>は、デジタルスキルのグローバルトラッカーであるDQインデックスを開発するため、新たな世界共同プロジェクトを開始します。`,
    home__covid19_pushed_world_message: `新型コロナウイルス感染症は、世界を急激にデジタル化へと押し進め、デジタルスキルは今や必要不可欠なものになりました。`,
    home__ieee_digiskill_beyond_tech_competency: `\"<strong>デジタルスキル</strong>\"は、コーディングやコンピュータ操作などの技術的な能力を超えたものです。<a href=\"https://standards.ieee.org/ieee/3527.1/7589/\" target=\"_blank\">IEEE digital intelligence (DQ) Global Standards (IEEE 3527.1™)</a> に従い、「デジタルスキル」はデジタル時代をよりよく生きるために必要な包括的なコンピテンシーとして定義されています。`,
    home__based_on_un_sustainable_dev_goals: `DQインデックスは、さまざまなフレームワーク、研究、プログラムを集約した<a href=\"https://www.dqinstitute.org/collaborative-rd/\" target=\"_blank\">共同研究と開発プラットフォーム</a>に基づき、国際連合の持続可能な開発目標に沿った以下の6つのデジタルスキルの観点から各国がどのように進歩しているかを追跡する一連の標準的指標を策定しています。`,
    home__global_tracker_for_digi_skills: `世界各国のデジタルスキルの水準をリアルタイムで追跡`,
    home__digi_skills_child_safety: `<span>オンライン上での子どもの安全</span>のためのデジタルスキル`,
    home__protect_all_children: `すべての子どもをサイバーリスクやその被害から守り、すべての子どもにデジタル・シティズンシップスキルの取得を保障します。`,
    home__digi_skills_learning: `<span>学び</span>のためのデジタルスキル`,
    home__ensure_quality_statement_1: `質の高い包括的なデジタルスキル教育を保障し、すべての人の生涯の機会を拡大します。`,
    home__digiskills_innovation: `<span>ワーク&amp;イノベーション</span>のためのデジタルスキル`,
    home__ensure_digital_skilling: `すべての労働者のデジタルスキルの向上を保証し、あらゆるレベルで適正かつ生産的なデジタルワークプレイスを促進し、科学、技術、イノベーションを促進します。`,
    home__digital_skills_for_female_empowerment: `<span>女性のエンパワーメント</span>のためのデジタルスキル`,
    home__achieve_digital_gender_equity: `デジタルにおけるジェンダー平等を達成し、テクノロジーを通じてすべての女性のエンパワーメントを実現します。`,
    home__digital_skills_for_well_being: `<span>ウェルビーイング</span>のためのデジタルスキル`,
    home__promote_wellbeing_in_digital_lives: `デジタルライフにおけるウェルビーイングを促進し、不平等を是正し、安全で信頼できる平和なデジタル社会をあらゆるレベルで推進します。`,
    home__digital_skills_for_online_participation: `<span>オンライン参加</span>のためのデジタルスキル`,
    home__ensure_universal_access_by_all_people: `すべての人々、特に高齢者や特別なニーズを持つ人々を含む社会的に弱い立場の人々が、インターネットとデジタル経済への参加に普遍的かつ包括的にアクセスできるようにします。`,
    home__take_action: `アクションを起こす`,
    home__we_are_inviting_those_who_share_the_common_goal: `デジタルスキルの格差解消という共通の目標を持つパートナーを、DQインデックス開発プロジェクトにお招きしています。私たちは、DQインデックスを通じて集団的影響を測定することで、目標達成に向けた世界の進捗を監視・追跡しています。`,
    home__safer_internet_day_february_8_2022: `セーファーインターネットデイ<br>（2022年2月8日）`,
    home__digital_citizenship_test_dct: `デジタル・シティズンシップ・テスト(DCT)`,
    home__dct_was_made_available_to_the_public: `DCTは、すべての組織や個人が、テクノロジーを安全に利用するための準備がどの程度整っているかを把握するために利用できるよう、一般に公開されました。`,
    home__download_the_test_kit: `テストをダウンロードする`,
    home__tbd: `公開日未定`,
    home__dq_index_launch: `DQインデックスの公開と共創`,
    home__the_dq_index_platform_will_be_launched: `DQインデックスのプラットフォームは、共同研究・開発プロジェクトに参加するパートナーしながら、2022年に公開する予定です。`,
    home__join_the_collaborative: `共同研究・開発プロジェクトに参加する`,
    home__dq_day_october_10_2022: `DQデー<br>（2022年10月10日）`,
    home__2022_dq_conference: `2022年DQカンファレンス`,
    home__dq_conference_provides_learning_experiences: `DQカンファレンスは、デジタルスキルに関する学習体験をグローバルコミュニティに提供します。カンファレンスでは、DQインデックスに関するディスカッションやケーススタディなどが予定されています。`,
    home__if_you_interested_in_becoming_a_sponsor: `スポンサー、スピーカー、参加者にご興味のある方は、以下よりご登録ください。`,
    home__register_for_the_conference: `カンファレンスへの参加登録`,
    home__privacy_policy: `プライバシーポリシー`,
    home__dq_lab_pte_ltd_all_rights_reserved: `DQ LAB Pte Ltd.All Rights Reserved.`,
    home__language: `Language`,
    home__term_of_use: `利用規約`,
    home__email: `メール`,
    home__general_enquiries: `一般的なお問い合わせ`,
    home__social_media: `ソーシャルメディア`,
    assessment__whats_your_dq:`あなたのDQは何ですか？`,
    assessment__find_out_what_type_of_digital_citizen_you_are: `あなたがどのようなデジタル市民か診断してみましょう`,
    assessment__take_the_free_test: `無料のデジタル・シティズンシップ診断テストを受ける`,
    assessment__5_types_of_digital_citizen: `デジタル市民の5つのタイプ`,
    assessment__based_on_statement_misc_1: `<a href=\"https://www.dqinstitute.org/\" target=\"_blank\">DQ Instituteの</a>グローバルデータバンクに基づき、スキル、テクノロジーの利用方法、サイバーリスクに遭う可能性のパターンが異なる5種類のデジタル市民のタイプを定義しました。`,
    assessment__carefree: `不注意な市民`,
    assessment__feels_more_comfortable_online_than_offline: `オンラインの世界の方が現実世界より心地よく感じています。`,
    assessment__potentially_involved_with_various_cyber_risks: `さまざまなサイバーリスクにまきこまれる可能性があります。`,
    assessment__ordinary: `ふつうの市民`,
    assessment__average_technology_user_who_not_fully_aware: `オンラインの世界がもつリスクやチャンスを十分に理解していない普通の市民です。`,
    assessment__tend_to_passively_follow_the_algorithm: `アルゴリズムがしめす情報をそのまま受け取る傾向があります。`,
    assessment__always_on: `アクティブな市民`,
    assessment__likely_communicate_with_others_a_lot_online: `他の人とオンライン上でひんぱんにコミュニケーションをとっている可能性が高いです。`,
    assessment__may_not_consider_much_about_risks: `オンライン上で行動する時、せん在的なサイバーリスクについてあまり考えていない可能性があります。`,
    assessment__cautious: `慎重な市民`,
    assessment__being_aware_of_potential_cyber_risks: `せん在的なサイバーリスクを認識し、デジタル世界をあまり探索していない状態です。`,
    assessment__likely_having_low_risks_in_the_digi_world: `サイバーリスクにあう可能性は低いでしょう。`,
    assessment__digilog: `デジログ市民`,
    assessment__well_rounded_digital_citizen: `デジタル世界と現実世界の生活のバランスがとれているデジタル市民です。`,
    assessment__uses_technology_mindfully: `テクノロジーを注意深く使用することができています。`,
    assessment__low: `低`,
    assessment__medium: `中`,
    assessment__high: `高`,
    assessment__skills: `スキル`,
    assessment__usage: `使用方法`,
    assessment__risks: `リスク`,
    assessment__be_yourself_and_answer_honestly: `あなたのデジタル市民のタイプやレベルを知るために、自分らしく、正直に答えてください。`,
    assessment__take_the_test: `テストを受ける`,
    assessment__digital_citizenship_test_and_dq_index: `デジタル・シティズンシップテストとDQインデックス`,
    assessment__the_level_of_digital_citizenship_is: `<span class=\"isection-toolip\">デジタル・シティズンシップのレベル<div class=\"tooltip-content\">デジタル・シティズンシップとは、テクノロジーを安全に使用するために必要な資質能力のことです。IEEE デジタルインテリジェンス (DQ) グローバル スタンダード (IEEE 3527.1™) として認定されたDQの最初のステップであるデジタル・シティズンシップは、プログラミングやサイバー セキュリティ、その他の仕事に役立つ技術スキルなどを習得する前に必要な前提条件と考えられています。</div></span> 国としての DQスコアの程度は、とても役に立つ指標となります。`,
    assessment__based_on_years_of_research_desc: `DQ Instituteは長年の研究をもとに、個人のデジタル・シティズンシップのレベルを効果的に評価するテストを開発し、「デジタル・シティズンシップ・テスト（DCT）」と名付けました。`,
    assessment__connected_with_the_dq_index_desc: `DQインデックスと連動し、DCTの結果は即座にインデックスに更新され、各国のランキングが示されます。これにより、各国はデジタルリソースを有効に活用することで、デジタル技術のレベルを包括的に向上させることができるようになり、ポジティブなインパクトを生み出すことができるようになるです。`,
    assessment__digital_citizenship_is_a_skill_desc: `デジタル・シティズンシップとは、テクノロジーを安全に使用するために必要な資質能力のことです。IEEE デジタルインテリジェンス (DQ) グローバル スタンダード (IEEE 3527.1™) として認定されたDQの最初のステップであるデジタル・シティズンシップは、プログラミングやサイバー セキュリティ、その他の仕事に役立つ技術スキルなどを習得する前に必要な前提条件と考えられています。`,
    assessment__digital_citizenship_scores: `デジタル・シティズンシップスコア`,
    assessment__global: `世界`,
    assessment__australia: `オーストラリア`,
    assessment__cambodia: `カンボジア`,
    assessment__china: `中国`,
    assessment__colombia: `コロンビア`,
    assessment__dominican_republic: `ドミニカ共和国`,
    assessment__ecuador: `エクアドル`,
    assessment__el_salvador: `エルサルバドル`,
    assessment__hong_kong: `香港`,
    assessment__india: `インド`,
    assessment__indonesia: `インドネシア`,
    assessment__ireland: `アイルランド`,
    assessment__japan: `日本`,
    assessment__korea_republic_of: `韓国`,
    assessment__malaysia: `マレーシア`,
    assessment__mexico: `メキシコ`,
    assessment__new_zealand: `ニュージーランド`,
    assessment__nigeria: `ナイジェリア`,
    assessment__peru: `ペルー`,
    assessment__philippines: `フィリピン`,
    assessment__qatar: `カタール`,
    assessment__singapore: `シンガポール`,
    assessment__south_africa: `南アフリカ`,
    assessment__spain: `スペイン`,
    assessment__sweden: `スウェーデン`,
    assessment__thailand: `タイ`,
    assessment__turkey: `トルコ`,
    assessment__united_kingdom: `イギリス`,
    assessment__united_states: `米国`,
    assessment__viet_nam: `ベトナム`,
    assessment__n_a_text: `なし`,
    assessment__national_dq: `日本のDQ`,
    assessment__global_dq: `世界のDQ`,
    assessment__percent_of_participants: `参加者の割合`,
    assessment__digital_citizenship_score: `デジタル・シティズンシップスコア`,
    assessment__distribution_of_dcs: `デジタル・シティズンシップスコアの分布図`,
    assessment__about_dq_assessments: `DQアセスメントについて`,
    assessment__forall: `全ての人に`,
    assessment__available_for_all_age_groups_and_types: `すべての年齢層やタイプの方にご利用いただけます。`,
    assessment__any_system: `任意のシステム`,
    assessment__whether_you_looking_to_integrate: `既存のシステムとの統合をお考えの場合も、既存の枠にとらわれないソリューションをお望みの場合も、私たちが実現します。`,
    assessment__dq_index: `DQインデックス`,
    assessment__scores_are_immediately_updated_into: `スコアはすぐに<a href=\"https://www.dqindex.org\">DQインデックス</a>に更新され、国内および世界との比較が可能になります。`,
    assessment__dq_assessment_was_developed_as: `DQアセスメントは、デジタルスキル教育/トレーニングプログラムをサポートするために、アセスメントと分析ツールを提供するウェブ/モバイルベースのプラットフォームとして開発され、グローバルベンチマークに基づいて個人と組織のデジタルスキルを測定します。`,
    assessment__dq_assessment_will_enable:`これにより、以下のことが可能になります。`,
    assessment__1_common_assessments_that_measure: `個人と組織のデジタルインテリジェンスに関する複数のコンピテンシーのレベルを、世界共通のベンチマークに照らして測定する`,
    assessment__2_in_depth_analysis_to_the_end_users: `エンドユーザー（個人・組織など）への徹底的な分析、世界共通のベンチマークによって強み・弱みを明確化する`,
    assessment__3_customized_digital_skill_development: `個人および組織向けにカスタマイズされたデジタルスキルの開発により、カスタマイズされた生涯教育の設計を支援する`,
    assessment__dq_assessment_tools_are_developed_based_on: `DQアセスメントツールは、様々な信頼できるソースやコンテンツオーナーであるパートナーの既存の研究プロジェクトからの様々なアセスメント手段の収集に基づいて開発されています。`,
    assessment__toolkits: `ツールキット`,
    assessment__whether_it_be_your_school_company_or: `学校、会社、国を問わず、世界共通のベンチマークに基づくデジタル・シティズンシップスキルの測定に役立つ様々なアセスメントを用意しています。`,
    assessment__if_you_looking_to_create_a_customized: `カスタマイズされた評価ツール（ホワイトラベル）や詳細な分析サービスの作成、または詳細な情報が必要な場合は、<a href=\"mailto:contact@dqforall.com\">お問い合わせ</a>ください。`,
    assessment__primary_schools: `小学校`,
    assessment__schools_can_get_their_students_ages_8_12: `主に高学年の児童が、デジタル・シティズンシップテストを受け、レベルを確認することができます。`,
    assessment__measures_dq_1_8: `DQ1～8`,
    assessment__analytics_dashboard_included_for_teachers: `学校管理アカウント（ダッシュボード）`,
    assessment__digital_parenting: `デジタル子育て`,
    assessment__cyber_risk_assessment: `サイバーリスクアセスメント`,
    assessment__learn_more: `詳細を見る`,
    assessment__secondary_schools: `中学校`,
    assessment__schools_can_get_their_teenage_students_ages_13_18: `主に13歳から15歳の生徒が、デジタル・シティズンシップテストを受け、レベルを確認することができます。`,
    assessment__companies_corporates_span: `企業<span class=\"kits-col-spacer\">企業</span>`,
    assessment__companies_and_organizations_can_assess: `企業や組織は、従業員のデジタル・シティズンシップスキルを評価し、育成が必要な分野を特定することができます。`,
    assessment__dashboard_included_for_hr: `人事担当者向けのAnalytics Dashboardを含む`,
    assessment__national_packages: `国の機関<br/>パッケージ`,
    assessment__countries_and_govt_localize_assess: `国の機関や政府は、カスタマイズされたテストで自国のDQを測定することができます。`,
    assessment__customize_assessment: `カスタマイズされたテスト`,
    assessment__localized_for_your_country: `日本語翻訳済み`,
    assessment__consult_strategies_plans: `ロールアウトプランのコンサルティング、戦略立案、共同開発`,
    assessment__balance_use_of_tech: `バランスの取れたインターネットの使用`,
    assessment__behavioural_risk_management: `サイバーリスクの防止`,
    assessment__digital_empathy: `デジタルにおける共感`,
    assessment__dc_identity: `デジタル市民としての人格構築`,
    assessment__ps_cyber_sec_management: `個人情報の保護`,
    assessment__media_and_info_literacy: `メディアと情報リテラシー`,
    assessment__digi_footprint_management: `デジタルフットプリントの管理`,
    assessment__privacy_management: `プライバシーの管理`,
    assessment__read_press_release: `プレスリリースを読む`
}

const lc_th = {
    home__dq_index: `ดัชนี DQ`,
    home__dq_assessment: `การประเมิน DQ`,
    home__take_the_test: `ทำแบบทดสอบ`,
    home__get_started:`เริ่ม`,
    home__global_project_to_measure: `โครงการระดับโลกเพื่อวัดระดับทักษะดิจิทัล`,
    home__realtime_tracker: `ตัวติดตามตามเวลาจริงสำหรับทักษะดิจิทัล`,
    home__disclaimer_1: `ข้อจำกัดความรับผิดชอบ: ข้อมูลข้างต้นมีวัตถุประสงค์เพื่อเป็นตัวอย่างเท่านั้น และไม่ได้บ่งบอกถึงทักษะดิจิทัลของประเทศหรือสิ่งอื่นใดที่เกี่ยวข้อง`,
    home__read_press_release: `อ่านข่าวประชาสัมพันธ์`,
    home__measure_digi_skills: `วัดระดับทักษะดิจิทัลในประเทศของคุณ`,
    home__dqindex_intro_title: `<a href=\"www.dqinstitute.org\">DQ Institute</a> กำลังเริ่มโครงการความร่วมมือระดับโลกใหม่เพื่อพัฒนาดัชนี DQ ซึ่งเป็นเครื่องมือติดตามระดับโลกสำหรับทักษะดิจิทัล`,
    home__disc_about_cookies: `เราใช้คุกกี้เพื่อให้บริการออนไลน์ของเราและเพื่อให้การโต้ตอบกับบริการออนไลน์ของเราง่ายและมีความหมาย การใช้เว็บไซต์ของเราต่อไปแสดงว่าคุณยอมรับนโยบายคุกกี้ของเรา รายละเอียดของคุกกี้ที่เราใช้และคำแนะนำเกี่ยวกับวิธีปิดใช้งานมีระบุไว้ในของเรา `,
    home__cookies_piolicy_link: ` <a href=\"/cookies\" target=\"_blank\">นโยบายคุกกี้</a>`,
    home__project_measure_digi_skill_level: `โครงการระดับโลกเพื่อวัดระดับทักษะดิจิทัล`,
    home__realtime_tracker_for_digi_skills: `ตัวติดตามตามเวลาจริงสำหรับทักษะดิจิทัล`,
    home__read_press_release_br: `อ่าน <br>ข่าวประชาสัมพันธ์`,
    home__disclaimer_1_br: `ข้อจำกัดความรับผิดชอบ: ข้อความข้างต้นใช้เพื่อเป็นตัวอย่างเท่านั้น และไม่ได้<br>บ่งบอกถึงทักษะดิจิทัลของประเทศหรือสิ่งอื่นใดที่เกี่ยวข้อง`,
    home__measure_digi_skill_lvl_of_country: `วัดระดับทักษะดิจิทัลในประเทศของคุณ`,
    home__dq_is_starting_new_collab_project: `<a href=\"https://www.dqinstitute.org/\" target=\"_blank\">DQ Institute</a> กำลังเริ่มโครงการความร่วมมือระดับโลกใหม่เพื่อพัฒนาดัชนี DQ ซึ่งเป็นตัวติดตามทั่วโลกสำหรับทักษะดิจิทัล`,
    home__covid19_pushed_world_message: `โควิด-19 ได้ผลักดันโลกไปสู่การแปลงเป็นดิจิทัลอย่างรวดเร็ว ซึ่งตอนนี้ทักษะดิจิทัลเป็นสิ่งที่ทุกคนต้องมี`,
    home__ieee_digiskill_beyond_tech_competency: `“<strong>ทักษะดิจิทัล</strong>” เป็นมากกว่าความสามารถทางเทคนิค เช่น การเขียนโค้ดและการใช้งานคอมพิวเตอร์ เป็นไปตาม <a href=\"https://standards.ieee.org/ieee/3527.1/7589/\" target=\"_blank\">IEEE digital intelligence (DQ) Global Standards (IEEE 3527.1™)</a> “ทักษะดิจิทัล” หมายถึงชุดความสามารถที่ครอบคลุมซึ่งจำเป็นต่อการเติบโตในยุคดิจิทัล`,
    home__based_on_un_sustainable_dev_goals: `DQ อิงตาม<a href=\"https://www.dqinstitute.org/collaborative-rd/\" target=\"_blank\">แพลตฟอร์ม R&D เพื่อการทำงานร่วมกัน</a>ที่รวบรวมเฟรมเวิร์ก การวิจัย และโปรแกรมต่างๆ Index พัฒนาชุดเมตริกที่เป็นมาตรฐานซึ่งติดตามว่าประเทศต่างๆ มีความก้าวหน้าอย่างไรในแง่ของทักษะด้านดิจิทัล 6 ประการต่อไปนี้ ซึ่งสอดคล้องกับเป้าหมายการพัฒนาที่ยั่งยืนของสหประชาชาติ`,
    home__global_tracker_for_digi_skills: `ติดตามทั่วโลก สำหรับทักษะดิจิทัล`,
    home__digi_skills_child_safety: `ทักษะดิจิทัลสำหรับ <span>ความปลอดภัยออนไลน์ของเด็ก</span>`,
    home__protect_all_children: `ปกป้องเด็กทุกคนจากความเสี่ยงและอันตรายทางไซเบอร์ และรับประกันทักษะการเป็นพลเมืองดิจิทัลสำหรับเด็กทุกคน`,
    home__digi_skills_learning: `ทักษะดิจิทัลสำหรับ<span>การเรียนรู้</span>`,
    home__ensure_quality_statement_1: `รับประกันการศึกษาทักษะดิจิทัลที่มีคุณภาพและครอบคลุม และส่งเสริมโอกาสตลอดชีวิตสำหรับทุกคน`,
    home__digiskills_innovation: `ทักษะดิจิทัลสำหรับ <span>งาน &amp; นวัตกรรม</span>`,
    home__ensure_digital_skilling: `ประกันทักษะดิจิทัล/ยกระดับฝีมือแรงงานทั้งหมด ส่งเสริมสถานที่ทำงานดิจิทัลที่ดีและมีประสิทธิผลในทุกระดับ และส่งเสริมวิทยาศาสตร์ เทคโนโลยี และนวัตกรรม`,
    home__digital_skills_for_female_empowerment: `ทักษะดิจิทัลสำหรับ <span>การเสริมพลังหญิง</span>`,
    home__achieve_digital_gender_equity: `บรรลุความเท่าเทียมทางเพศแบบดิจิทัลและส่งเสริมผู้หญิงและเด็กผู้หญิงทุกคนผ่านเทคโนโลยีที่เปิดใช้งาน`,
    home__digital_skills_for_well_being: `ทักษะดิจิทัลเพื่อ<span>ความเป็นอยู่ที่ดี</span>`,
    home__promote_wellbeing_in_digital_lives: `ส่งเสริมความเป็นอยู่ที่ดีในชีวิตดิจิทัล ลดความเหลื่อมล้ำ และส่งเสริมสังคมดิจิทัลที่ปลอดภัย ไว้วางใจได้ และสงบสุขสำหรับทุกคนในทุกระดับ`,
    home__digital_skills_for_online_participation: `ทักษะดิจิทัลสำหรับ <span>การมีส่วนร่วมทางออนไลน์</span>`,
    home__ensure_universal_access_by_all_people: `ตรวจสอบให้แน่ใจว่าทุกคนเข้าถึงได้อย่างทั่วถึงและครอบคลุม โดยเฉพาะอย่างยิ่งผู้คนที่อยู่ในสถานการณ์ที่เปราะบางรวมถึงผู้สูงอายุและบุคคลที่มีความต้องการพิเศษ อินเทอร์เน็ตและการมีส่วนร่วมในเศรษฐกิจดิจิทัล`,
    home__take_action: `เริ่มปฏิบัติ`,
    home__we_are_inviting_those_who_share_the_common_goal: `เราขอเชิญผู้ที่มีเป้าหมายร่วมกันในการปิดช่องว่างทักษะดิจิทัลให้เข้าร่วมโครงการ DQ Index เราตรวจสอบและติดตามความคืบหน้าทั่วโลกในการบรรลุเป้าหมายโดยการวัดผลกระทบโดยรวมของเราผ่านดัชนี DQ`,
    home__safer_internet_day_february_8_2022: `วันอินเทอร์เน็ตที่ปลอดภัยยิ่งขึ้น<br> (8 กุมภาพันธ์ 2022)`,
    home__digital_citizenship_test_dct: `การทดสอบการเป็นพลเมืองดิจิทัล (DCT)`,
    home__dct_was_made_available_to_the_public: `DCT เผยแพร่สู่สาธารณะเพื่อให้ทุกองค์กรและบุคคลสามารถใช้เพื่อทำความเข้าใจว่าพวกเขาพร้อมที่จะใช้เทคโนโลยีอย่างปลอดภัยเพียงใด`,
    home__download_the_test_kit: `ดาวน์โหลด<br>ชุดทดสอบ`,
    home__tbd: `จะแจ้งภายหลัง`,
    home__dq_index_launch: `เปิดตัวดัชนี DQ <br>&amp; ร่วมสร้างสรรค์`,
    home__the_dq_index_platform_will_be_launched: `แพลตฟอร์ม DQ Index จะเปิดตัวในภายหลังในปี 2565 ขณะที่เราเชิญชวนพันธมิตรให้เข้าร่วมโครงการวิจัยและพัฒนาร่วมกัน`,
    home__join_the_collaborative: `เข้าร่วม <br>ความร่วมมือด้านการวิจัยและพัฒนา`,
    home__dq_day_october_10_2022: `วัน DQ <br> (10 ตุลาคม 2565)`,
    home__2022_dq_conference: `การประชุม DQ ปี 2022`,
    home__dq_conference_provides_learning_experiences: `การประชุม DQ มอบประสบการณ์การเรียนรู้แก่ชุมชนทั่วโลกเกี่ยวกับทักษะดิจิทัล การประชุมจะประกอบด้วยการอภิปรายเกี่ยวกับดัชนี DQ กรณีศึกษา และอื่นๆ`,
    home__if_you_interested_in_becoming_a_sponsor: `หากคุณสนใจที่จะเป็นผู้สนับสนุน ผู้พูด หรือผู้เข้าร่วม โปรดลงทะเบียนด้านล่าง`,
    home__register_for_the_conference: `ลงทะเบียนสำหรับ <br>การประชุม`,
    home__privacy_policy: `นโยบายความเป็นส่วนตัว`,
    home__dq_lab_pte_ltd_all_rights_reserved: `DQ LAB Pte Ltd. สงวนลิขสิทธิ์`,
    home__language: `Language`,
    home__term_of_use: `ระยะเวลาของการใช้งาน`,
    home__email: `อีเมล`,
    home__general_enquiries: `สอบถามทั่วไป`,
    home__social_media: `สื่อสังคม`,
    assessment__whats_your_dq:`DQ ของคุณคืออะไร?`,
    assessment__find_out_what_type_of_digital_citizen_you_are: `ค้นหาว่า<br>คุณเป็นพลเมืองดิจิทัลประเภทใด`,
    assessment__take_the_free_test: `ทำแบบทดสอบฟรี`,
    assessment__5_types_of_digital_citizen: `5 ประเภทของพลเมืองดิจิทัล`,
    assessment__based_on_statement_misc_1: `จากฐานข้อมูลระดับโลกของ <a href=\"https://www.dqinstitute.org/\" target=\"_blank\">DQ Institute</a> เราได้ระบุพลเมืองดิจิทัล 5 ประเภทที่แสดงรูปแบบทักษะและการใช้เทคโนโลยีที่แตกต่างกัน และความเสี่ยงทางไซเบอร์`,
    assessment__carefree: `ที่ไร้กังวล`,
    assessment__feels_more_comfortable_online_than_offline: `ออนไลน์สบายใจกว่าออฟไลน์`,
    assessment__potentially_involved_with_various_cyber_risks: `อาจเกี่ยวข้องกับความเสี่ยงทางไซเบอร์ต่างๆ`,
    assessment__ordinary: `สามัญ`,
    assessment__average_technology_user_who_not_fully_aware: `ผู้ใช้เทคโนโลยีทั่วไปที่อาจไม่ตระหนักถึงความเสี่ยงและโอกาสอย่างเต็มที่`,
    assessment__tend_to_passively_follow_the_algorithm: `มักจะทำตามอัลกอริทึมอย่างเฉยเมย`,
    assessment__always_on: `เปิดอยู่เสมอ`,
    assessment__likely_communicate_with_others_a_lot_online: `มีแนวโน้มที่จะสื่อสารกับผู้อื่นมากทางออนไลน์`,
    assessment__may_not_consider_much_about_risks: `อาจไม่คำนึงถึงความเสี่ยงทางไซเบอร์ที่อาจเกิดขึ้นมากนัก`,
    assessment__cautious: `ระมัดระวัง`,
    assessment__being_aware_of_potential_cyber_risks: `ตระหนักถึงความเสี่ยงทางไซเบอร์ที่อาจเกิดขึ้นและไม่ได้สำรวจโลกดิจิทัลอย่างเต็มที่`,
    assessment__likely_having_low_risks_in_the_digi_world: `น่าจะมีความเสี่ยงต่ำในโลกดิจิทัล`,
    assessment__digilog: `ดิจิล็อก`,
    assessment__well_rounded_digital_citizen: `พลเมืองดิจิทัลรอบรู้ที่สร้างสมดุลให้กับชีวิตออนไลน์และออฟไลน์`,
    assessment__uses_technology_mindfully: `ใช้เทคโนโลยีอย่างมีสติ`,
    assessment__low: `ต่ำ`,
    assessment__medium: `ปานกลาง`,
    assessment__high: `สูง`,
    assessment__skills: `ทักษะ`,
    assessment__usage: `การใช้งาน`,
    assessment__risks: `ความเสี่ยง`,
    assessment__be_yourself_and_answer_honestly: `เป็นตัวของตัวเองและตอบอย่างตรงไปตรงมาเพื่อค้นหาประเภทและระดับพลเมืองดิจิทัลของคุณ`,
    assessment__take_the_test: `ทำแบบทดสอบ`,
    assessment__digital_citizenship_test_and_dq_index: `การทดสอบการเป็นพลเมืองดิจิทัลและดัชนี DQ`,
    assessment__the_level_of_digital_citizenship_is: `ระดับของ <span class=\"isection-toolip\">การเป็นพลเมืองดิจิทัล<div class=\"tooltip-content\">การเป็นพลเมืองดิจิทัลเป็นชุดทักษะที่จำเป็นสำหรับการใช้เทคโนโลยีอย่างปลอดภัย ในฐานะที่เป็นขั้นตอนแรกของ IEEE digital intelligence (DQ) Global Standards (IEEE 3527.1™) ​​การเป็นพลเมืองดิจิทัลถือเป็นข้อกำหนดเบื้องต้นสำหรับทักษะดิจิทัลอื่นๆ เช่น การเข้ารหัส ความปลอดภัยในโลกไซเบอร์ และทักษะทางเทคนิคอื่นๆ เพื่อความพร้อมในการทำงาน</div ></span> ในประเทศหนึ่งเป็นสัญญาณที่ดีว่าคะแนนดัชนี DQ ของพวกเขาจะสูงหรือไม่`,
    assessment__based_on_years_of_research_desc: `จากการวิจัยเป็นเวลาหลายปี สถาบัน DQ ได้พัฒนาชุดคำถามที่ประเมินระดับการเป็นพลเมืองยุคดิจิทัลของแต่ละบุคคลได้อย่างมีประสิทธิภาพ ซึ่งเรียกว่าแบบทดสอบการเป็นพลเมืองยุคดิจิทัล (DCT)`,
    assessment__connected_with_the_dq_index_desc: `เมื่อเชื่อมต่อกับดัชนี DQ ผลลัพธ์จาก DCT จะได้รับการอัปเดตเป็นดัชนีทันทีเพื่อแสดงจุดยืนของแต่ละประเทศ ซึ่งช่วยให้ประเทศต่างๆ ใช้ทรัพยากรได้อย่างมีประสิทธิภาพและปรับปรุงระดับทักษะด้านดิจิทัลโดยรวม ซึ่งสร้างผลกระทบเชิงบวก`,
    assessment__digital_citizenship_is_a_skill_desc: `การเป็นพลเมืองดิจิทัลเป็นชุดทักษะที่จำเป็นสำหรับการใช้เทคโนโลยีอย่างปลอดภัย ในฐานะที่เป็นขั้นตอนแรกของ IEEE digital intelligence (DQ) Global Standards (IEEE 3527.1™) ​​การเป็นพลเมืองดิจิทัลถือเป็นข้อกำหนดเบื้องต้นสำหรับทักษะดิจิทัลอื่น ๆ เช่น การเข้ารหัส ความปลอดภัยในโลกไซเบอร์ และทักษะด้านเทคนิคเพื่อความพร้อมในการทำงานอื่น ๆ`,
    assessment__digital_citizenship_scores: `คะแนนความเป็นพลเมืองดิจิทัล`,
    assessment__global: `ทั่วโลก`,
    assessment__australia: `ออสเตรเลีย`,
    assessment__cambodia: `กัมพูชา`,
    assessment__china: `จีน`,
    assessment__colombia: `โคลอมเบีย`,
    assessment__dominican_republic: `สาธารณรัฐโดมินิกัน`,
    assessment__ecuador: `เอกวาดอร์`,
    assessment__el_salvador: `เอลซัลวาดอร์`,
    assessment__hong_kong: `ฮ่องกง`,
    assessment__india: `อินเดีย`,
    assessment__indonesia: `อินโดนีเซีย`,
    assessment__ireland: `ไอร์แลนด์`,
    assessment__japan: `ญี่ปุ่น`,
    assessment__korea_republic_of: `เกาหลี สาธารณรัฐ`,
    assessment__malaysia: `มาเลเซีย`,
    assessment__mexico: `เม็กซิโก`,
    assessment__new_zealand: `นิวซีแลนด์`,
    assessment__nigeria: `ไนจีเรีย`,
    assessment__peru: `เปรู`,
    assessment__philippines: `ฟิลิปปินส์`,
    assessment__qatar: `กาตาร์`,
    assessment__singapore: `สิงคโปร์`,
    assessment__south_africa: `แอฟริกาใต้`,
    assessment__spain: `สเปน`,
    assessment__sweden: `สวีเดน`,
    assessment__thailand: `ประเทศไทย`,
    assessment__turkey: `ไก่งวง`,
    assessment__united_kingdom: `ประเทศอังกฤษ`,
    assessment__united_states: `สหรัฐ`,
    assessment__viet_nam: `เวียดนาม`,
    assessment__n_a_text: `ไม่มีข้อมูล`,
    assessment__national_dq: `DQ แห่งชาติ`,
    assessment__global_dq: `DQ ระดับโลก`,
    assessment__percent_of_participants: `% ของผู้เข้าร่วม`,
    assessment__digital_citizenship_score: `คะแนนความเป็นพลเมืองดิจิทัล`,
    assessment__distribution_of_dcs: `การกระจาย DCS (คะแนนความเป็นพลเมืองดิจิทัล)`,
    assessment__about_dq_assessments: `เกี่ยวกับการประเมิน DQ`,
    assessment__forall: `สำหรับทุกอย่าง`,
    assessment__available_for_all_age_groups_and_types: `มีให้สำหรับทุกกลุ่มอายุและประเภท.`,
    assessment__any_system: `ระบบใดก็ได้`,
    assessment__whether_you_looking_to_integrate: `ไม่ว่าคุณจะต้องการรวมเข้ากับระบบที่มีอยู่หรือต้องการโซลูชันที่นอกกรอบ เราทำให้เกิดขึ้นได้`,
    assessment__dq_index: `ดัชนี DQ`,
    assessment__scores_are_immediately_updated_into: `คะแนนจะอัปเดตทันทีใน <a href=\"https://www.dqindex.org\">ดัชนี DQ</a> สำหรับการเปรียบเทียบระดับชาติและระดับโลก.`,
    assessment__dq_assessment_was_developed_as: `การประเมิน DQ ได้รับการพัฒนาเป็นแพลตฟอร์มบนเว็บ/มือถือที่ให้การประเมิน & เครื่องมือวิเคราะห์เพื่อสนับสนุนโปรแกรมการศึกษา/ฝึกอบรมทักษะดิจิทัลเพื่อวัดทักษะดิจิทัลสำหรับบุคคลและองค์กรตามเกณฑ์มาตรฐานระดับโลก มันจะเปิดใช้งาน:`,
    assessment__dq_assessment_will_enable:`มันจะเปิดใช้งาน:`,
    assessment__1_common_assessments_that_measure: `การประเมินทั่วไปที่วัดระดับความสามารถที่หลากหลายของปัญญาดิจิทัลของบุคคลและองค์กรโดยเทียบกับมาตรฐานสากลพร้อมเกณฑ์มาตรฐานระดับโลก`,
    assessment__2_in_depth_analysis_to_the_end_users: `การวิเคราะห์เชิงลึกสำหรับผู้ใช้ปลายทาง (เช่น บุคคลและองค์กร) เพื่อระบุจุดแข็งและจุดอ่อนของพวกเขาผ่านเกณฑ์มาตรฐานระดับโลก`,
    assessment__3_customized_digital_skill_development: `การพัฒนาทักษะด้านดิจิทัลแบบกำหนดเองสำหรับบุคคลและองค์กรเพื่อช่วยออกแบบการฝึกอบรมตลอดชีวิตที่กำหนดเอง`,
    assessment__dq_assessment_tools_are_developed_based_on: `เครื่องมือการประเมิน DQ ได้รับการพัฒนาขึ้นจากการรวบรวมเครื่องมือการประเมินต่างๆ จากแหล่งข้อมูลที่น่าเชื่อถือต่างๆ และโครงการวิจัยที่มีอยู่ของพันธมิตรเจ้าของเนื้อหา`,
    assessment__toolkits: `ชุดเครื่องมือ`,
    assessment__whether_it_be_your_school_company_or: `ไม่ว่าจะเป็นโรงเรียน บริษัท หรือประเทศของคุณ เรามีแบบประเมินที่หลากหลายซึ่งช่วยในการวัดทักษะการเป็นพลเมืองยุคดิจิทัลของคุณตามเกณฑ์มาตรฐานระดับโลก`,
    assessment__if_you_looking_to_create_a_customized: `หากคุณต้องการสร้างเครื่องมือประเมินแบบกำหนดเอง (ป้ายขาว) หรือบริการวิเคราะห์เชิงลึก หรือต้องการข้อมูลเพิ่มเติม โปรด<a href=\"mailto:contact@dqforall.com\">ติดต่อเรา</a>`,
    assessment__primary_schools: `โรงเรียนประถมศึกษา<br>`,
    assessment__schools_can_get_their_students_ages_8_12: `โรงเรียนสามารถให้นักเรียนอายุ 8-12 ปีทำแบบทดสอบการเป็นพลเมืองยุคดิจิทัลที่ครอบคลุมมากขึ้น เพื่อค้นหาระดับการเป็นพลเมืองยุคดิจิทัลของพวกเขา`,
    assessment__measures_dq_1_8: `วัด DQ 1-8`,
    assessment__analytics_dashboard_included_for_teachers: `รวมแดชบอร์ดการวิเคราะห์สำหรับครู`,
    assessment__digital_parenting: `การเลี้ยงดูแบบดิจิทัล`,
    assessment__cyber_risk_assessment: `การประเมินความเสี่ยงทางไซเบอร์`,
    assessment__learn_more: `เรียนรู้เพิ่มเติม`,
    assessment__secondary_schools: `มัธยมศึกษา<br>โรงเรียน`,
    assessment__schools_can_get_their_teenage_students_ages_13_18: `โรงเรียนสามารถให้นักเรียนวัยรุ่นอายุ 13-18 ปีทำแบบทดสอบการเป็นพลเมืองยุคดิจิทัลที่ครอบคลุมมากขึ้น เพื่อค้นหาระดับการเป็นพลเมืองยุคดิจิทัลของพวกเขา`,
    assessment__companies_corporates_span: `บริษัท<span class=\"kits-col-spacer\">องค์กร</span>`,
    assessment__companies_and_organizations_can_assess: `บริษัทและองค์กรต่างๆ สามารถประเมินทักษะการเป็นพลเมืองดิจิทัลของพนักงานและระบุด้านที่ต้องการการเติบโตได้`,
    assessment__dashboard_included_for_hr: `รวมแดชบอร์ดการวิเคราะห์สำหรับเจ้าหน้าที่ฝ่ายทรัพยากรบุคคล`,
    assessment__national_packages: `แพ็คเกจแห่งชาติ<br/>`,
    assessment__countries_and_govt_localize_assess: `ประเทศและรัฐบาลสามารถวัด DQ ของประเทศตนได้ด้วยการประเมินที่ปรับแต่งและแปลเป็นภาษาท้องถิ่น`,
    assessment__customize_assessment: `การประเมินที่กำหนดเอง`,
    assessment__localized_for_your_country: `แปลเป็นภาษาท้องถิ่น / แปลสำหรับประเทศของคุณ`,
    assessment__consult_strategies_plans: `ปรึกษา วางกลยุทธ์ และร่วมพัฒนาแผนการเปิดตัว`,
    assessment__balance_use_of_tech: `การใช้เทคโนโลยีอย่างสมดุล`,
    assessment__behavioural_risk_management: `การจัดการความเสี่ยงทางพฤติกรรมทางไซเบอร์`,
    assessment__digital_empathy: `การเอาใจใส่แบบดิจิทัล`,
    assessment__dc_identity: `ตัวตนของพลเมืองดิจิทัล`,
    assessment__ps_cyber_sec_management: `การจัดการความปลอดภัยทางไซเบอร์ส่วนบุคคล`,
    assessment__media_and_info_literacy: `การรู้เท่าทันสื่อและสารสนเทศ`,
    assessment__digi_footprint_management: `การจัดการรอยเท้าดิจิทัล`,
    assessment__privacy_management: `การจัดการความเป็นส่วนตัว`,
    assessment__read_press_release: `อ่าน <br>ข่าวประชาสัมพันธ์`,
}

const lc_es = {
    home__dq_index: `Índice DQ`,
    home__dq_assessment: `Evaluación DQ`,
    home__take_the_test: `Tomar el examen`,
    home__get_started:`Empezar`,
    home__global_project_to_measure: `Proyecto Global para Medir el Nivel de Habilidades Digitales`,
    home__realtime_tracker: `Rastreador en tiempo real para habilidades digitales`,
    home__disclaimer_1: `Descargo de responsabilidad: Lo anterior es solo para fines ilustrativos y no es indicativo del nivel de habilidades digitales de un país ni nada relacionado.`,
    home__read_press_release: `Lea el comunicado de prensa`,
    home__measure_digi_skills: `Mide el Nivel de Habilidades Digitales de tu País`,
    home__dqindex_intro_title: `El <a href=\"www.dqinstitute.org\">Instituto DQ</a> está iniciando un nuevo proyecto de colaboración global para desarrollar el Índice DQ, un rastreador global de habilidades digitales.`,
    home__disc_about_cookies: `Usamos cookies para brindar nuestros servicios en línea y para hacer que las interacciones con nuestros servicios en línea sean fáciles y significativas. Si continúa utilizando nuestro sitio, acepta nuestra política de cookies. Los detalles de las cookies que utilizamos y las instrucciones sobre cómo deshabilitarlas se establecen en nuestro `,
    home__cookies_piolicy_link: ` <a href=\"/cookies\" target=\"_blank\">Política de cookies</a>`,
    home__project_measure_digi_skill_level: `Proyecto Global para Medir el Nivel de Habilidades Digitales`,
    home__realtime_tracker_for_digi_skills: `Rastreador en tiempo real para habilidades digitales`,
    home__read_press_release_br: `Lea el comunicado de prensa de <br>`,
    home__disclaimer_1_br: `Descargo de responsabilidad: Lo anterior es solo para fines ilustrativos y no es indicativo del nivel de habilidades digitales de un país ni nada relacionado.`,
    home__measure_digi_skill_lvl_of_country: `Mide el Nivel de Habilidades Digitales de tu País`,
    home__dq_is_starting_new_collab_project: `El <a href=\"https://www.dqinstitute.org/\" target=\"_blank\">Instituto DQ</a> está iniciando un nuevo proyecto de colaboración global para desarrollar el Índice DQ, un rastreador global de habilidades digitales.`,
    home__covid19_pushed_world_message: `COVID-19 ha empujado al mundo hacia una digitalización rápida, donde las habilidades digitales ahora son imprescindibles para cada individuo.`,
    home__ieee_digiskill_beyond_tech_competency: `Las “<strong>habilidades digitales</strong>” van más allá de la competencia técnica, como la codificación y las operaciones informáticas. De acuerdo con los <a href=\"https://standards.ieee.org/ieee/3527.1/7589/\" target=\"_blank\">Estándares globales de inteligencia digital (DQ) IEEE (IEEE 3527.1™)</a>, Las “habilidades digitales” se definen como un conjunto completo de competencias necesarias para prosperar en la era digital.`,
    home__based_on_un_sustainable_dev_goals: `Basado en su <a href=\"https://www.dqinstitute.org/collaborative-rd/\" target=\"_blank\">plataforma colaborativa de investigación y desarrollo</a> que agrega diferentes marcos, investigaciones y programas, el DQ Index desarrolla un conjunto de métricas estandarizadas que rastrean el progreso de los países en términos de las siguientes seis habilidades digitales que están alineadas con los Objetivos de Desarrollo Sostenible de la ONU.`,
    home__global_tracker_for_digi_skills: `Rastreador global de habilidades digitales`,
    home__digi_skills_child_safety: `Habilidades digitales para <span>Seguridad infantil en línea</span>`,
    home__protect_all_children: `Proteger a todos los niños y niñas de los riesgos y daños cibernéticos y garantizar habilidades de ciudadanía digital para todos los niños y niñas`,
    home__digi_skills_learning: `Habilidades digitales para <span>aprendizaje</span>`,
    home__ensure_quality_statement_1: `Garantizar una educación integral y de calidad en habilidades digitales y promover oportunidades de por vida para todos y todas.`,
    home__digiskills_innovation: `Habilidades digitales para <span>Work &amp; Innovación</span>`,
    home__ensure_digital_skilling: `Asegurar la capacitación/mejora de las habilidades digitales de todas las fuerzas laborales, promover un lugar de trabajo digital decente y productivo, en todos los niveles, y fomentar la ciencia, la tecnología y la innovación.`,
    home__digital_skills_for_female_empowerment: `Habilidades digitales para <span>empoderamiento femenino</span>`,
    home__achieve_digital_gender_equity: `Lograr la equidad de género digital y empoderar a todas las mujeres y niñas a través de tecnología habilitadora`,
    home__digital_skills_for_well_being: `Habilidades digitales para <span>Bienestar</span>`,
    home__promote_wellbeing_in_digital_lives: `Promover el bienestar en la vida digital, reducir la desigualdad y promover sociedades digitalizadas seguras, confiables y pacíficas para todos y todas, en todos los niveles`,
    home__digital_skills_for_online_participation: `Habilidades digitales para <span>Participación en línea</span>`,
    home__ensure_universal_access_by_all_people: `Garantizar el acceso universal e inclusivo de todas las personas, en particular de las personas en situación de vulnerabilidad, incluidas las personas mayores y las personas con necesidades especiales, a Internet y a la participación en la economía digital.`,
    home__take_action: `Tomar acción`,
    home__we_are_inviting_those_who_share_the_common_goal: `Estamos invitando a aquellos que comparten el objetivo común de cerrar la brecha de habilidades digitales a unirse al proyecto DQ Index. Monitoreamos y rastreamos el progreso global para lograr el objetivo midiendo nuestro impacto colectivo a través del Índice DQ.`,
    home__safer_Internet_day_february_8_2022: `Día de Internet Segura<br> (8 de febrero de 2022)`,
    home__digital_citizenship_test_dct: `Prueba de Ciudadanía Digital (DCT)`,
    home__dct_was_made_available_to_the_public: `DCT se puso a disposición del público para que todas las organizaciones e individuos puedan usarlo para comprender qué tan listos están para usar la tecnología de manera segura.`,
    home__download_the_test_kit: `Descargue el kit de prueba de <br>`,
    home__tbd: `TBD`,
    home__dq_index_launch: `Lanzamiento del índice TBDDQ <br>&amp; Co-Creación`,
    home__the_dq_index_platform_will_be_launched: `La plataforma DQ Index se lanzará en una fecha posterior en 2022 mientras invitamos a los socios a unirse al proyecto colaborativo de I+D.`,
    home__join_the_collaborative: `Únete a  <br>Collaborative R&amp;D`,
    home__dq_day_october_10_2022: `Día DQ <br> (10 de octubre de 2022)`,
    home__2022_dq_conference: `Conferencia DQ 2022`,
    home__dq_conference_provides_learning_experiences: `DQ Conference brinda experiencias de aprendizaje a la comunidad global sobre habilidades digitales. La conferencia incluirá discusiones sobre el índice DQ, estudios de casos y más.`,
    home__if_you_interested_in_becoming_a_sponsor: `Si está interesado(a) en convertirse en patrocinador, orador o participante, regístrese a continuación.`,
    home__register_for_the_conference: `Regístrese para <br>la Conferencia`,
    home__privacy_policy: `política de privacidad`,
    home__dq_lab_pte_ltd_all_rights_reserved: `DQ LAB Pte Ltd. Todos los derechos reservados.`,
    home__language: `Idioma`,
    home__term_of_use: `Terminos de uso`,
    home__email: `Correo electrónico`,
    home__general_enquiries: `Consultas generales`,
    home__social_media: `Medios de comunicación social`,
    assessment__whats_your_dq:`¿Cuál es tu DQ`,
    assessment__find_out_what_type_of_digital_citizen_you_are: `Descubre qué tipo de ciudadano digital eres`,
    assessment__take_the_free_test: `Tome la prueba gratis`,
    assessment__5_types_of_digital_citizen: `Tipos de ciudadano(a) digital`,
    assessment__based_on_statement_misc_1: `Con base en el banco de datos global del <a href=\"https://www.dqinstitute.org/\" target=\"_blank\">DQ Institute</a>, hemos identificado 5 tipos diferentes de ciudadanos digitales que muestran diferentes patrones de habilidades, uso de tecnología y la exposición al riesgo cibernético.`,
    assessment__carefree: `DESPREOCUPADO(A)`,
    assessment__feels_more_comfortable_online_than_offline: `Se siente más cómodo(a) en línea que fuera de línea`,
    assessment__potentially_involved_with_various_cyber_risks: `Potencialmente involucrado(a) con varios riesgos cibernéticos`,
    assessment__ordinary: `ORDINARIO/COMÚN`,
    assessment__average_technology_user_who_not_fully_aware: `Usuario(a) promedio de tecnología que puede no ser completamente consciente de los riesgos ni las oportunidades.`,
    assessment__tend_to_passively_follow_the_algorithm: `Tienden a seguir pasivamente el algoritmo.`,
    assessment__always_on: `SIEMPRE EN LINEA`,
    assessment__likely_communicate_with_others_a_lot_online: `Es probable que se comunique mucho con otros(as) en línea`,
    assessment__may_not_consider_much_about_risks: `Puede que no tenga mucho en cuenta los posibles riesgos cibernéticos`,
    assessment__cautious: `CAUTELOSO(A) `,
    assessment__being_aware_of_potential_cyber_risks: `Es consciente de los riesgos cibernéticos potenciales y no explorar completamente el mundo digital`,
    assessment__likely_having_low_risks_in_the_digi_world: `Es probable que tenga riesgos bajos en el mundo digital`,
    assessment__digilog: `DIGILOG`,
    assessment__well_rounded_digital_citizen: `Ciudadano(a) digital integral que equilibra la vida en línea y fuera de línea`,
    assessment__uses_technology_mindfully: `Usa la tecnología conscientemente`,
    assessment__low: `Bajo`,
    assessment__medium: `Medio`,
    assessment__high: `Alto`,
    assessment__skills: `Habilidades`,
    assessment__usage: `Uso`,
    assessment__risks: `Riesgos`,
    assessment__be_yourself_and_answer_honestly: `Sé tú mismo(a) y responde honestamente para conocer tu tipo y nivel de ciudadano(a) digital.`,
    assessment__take_the_test: `Tomar el examen`,
    assessment__digital_citizenship_test_and_dq_index: `Test de Ciudadanía Digital e Índice DQ`,
    assessment__the_level_of_digital_citizenship_is: `El nivel de <span class=\"isection-toolip\">ciudadanía digital<div class=\"tooltip-content\">La ciudadanía digital es un conjunto de habilidades necesarias para usar la tecnología de manera segura. Como primer paso de los Estándares globales de inteligencia digital (DQ) de IEEE (IEEE 3527.1™), la ciudadanía digital se considera un requisito previo para otras habilidades digitales, como la codificación, la seguridad cibernética y otras habilidades técnicas de preparación para el trabajo.</div ></span> en un país es una fuerte señal de si su puntuación en el índice DQ es alta o no.`,
    assessment__based_on_years_of_research_desc: `Basado en años de investigación, el Instituto DQ desarrolló un conjunto de preguntas que evalúa efectivamente el nivel de ciudadanía digital de las personas, que se denomina Prueba de Ciudadanía Digital (DCT).`,
    assessment__connected_with_the_dq_index_desc: `Conectado con el Índice DQ, los resultados del DCT se actualizan inmediatamente al índice para mostrar dónde se encuentra cada país. Esto permite a los países utilizar los recursos de manera efectiva y mejorar de manera inclusiva sus niveles de habilidades digitales, creando un impacto positivo.`,
    assessment__digital_citizenship_is_a_skill_desc: `La ciudadanía digital es un conjunto de habilidades necesarias para usar la tecnología de manera segura. Como primer paso de los Estándares globales de inteligencia digital (DQ) de IEEE (IEEE 3527.1™), la ciudadanía digital se considera un requisito previo para otras habilidades digitales, como la codificación, la seguridad cibernética y otras habilidades técnicas de preparación para el trabajo.`,
    assessment__digital_citizenship_scores: `Puntuaciones de ciudadanía digital`,
    assessment__global: `Global`,
    assessment__australia: `Australia`,
    assessment__cambodia: `Camboya`,
    assessment__china: `China`,
    assessment__colombia: `Colombia`,
    assessment__dominican_republic: `República Dominicana`,
    assessment__ecuador: `Ecuador`,
    assessment__el_salvador: `El Salvador`,
    assessment__hong_kong: `Hong Kong`,
    assessment__india: `India`,
    assessment__indonesia: `Indonesia`,
    assessment__ireland: `Irlanda`,
    assessment__japan: `Japón`,
    assessment__korea_republic_of: `República de Corea`,
    assessment__malaysia: `Malasia`,
    assessment__mexico: `México`,
    assessment__new_zealand: `Nueva Zelanda`,
    assessment__nigeria: `Nigeria`,
    assessment__peru: `Perú`,
    assessment__philippines: `Filipinas`,
    assessment__qatar: `Qatar`,
    assessment__singapore: `Singapur`,
    assessment__south_africa: `Sudáfrica`,
    assessment__spain: `España`,
    assessment__sweden: `Suecia`,
    assessment__thailand: `Tailandia`,
    assessment__turkey: `Turquía`,
    assessment__united_kingdom: `Reino Unido`,
    assessment__united_states: `Estados Unidos`,
    assessment__viet_nam: `Vietnam`,
    assessment__n_a_text: `N/A`,
    assessment__national_dq: `DQ Nacional`,
    assessment__global_dq: `DQ Global`,
    assessment__percent_of_participants: `% de participantes`,
    assessment__digital_citizenship_score: `Puntuación de ciudadanía digital`,
    assessment__distribution_of_dcs: `Distribución de DCS (puntaje de ciudadanía digital)`,
    assessment__about_dq_assessments: `Acerca de las evaluaciones DQ`,
    assessment__forall: `para todos`,
    assessment__available_for_all_age_groups_and_types: `Disponible para todos los grupos de edad y tipos.`,
    assessment__any_system: `Cualquier sistema`,
    assessment__whether_you_looking_to_integrate: `Ya sea que esté buscando integrarse en su sistema existente o desee una solución lista para usar, podemos hacer que suceda.`,
    assessment__dq_index: `Índice DQ`,
    assessment__scores_are_immediately_updated_into: `Las puntuaciones se actualizan inmediatamente en el <a href=\"https://www.dqindex.org\">Índice DQ</a> para realizar comparaciones nacionales y mundiales.`,
    assessment__dq_assessment_was_developed_as: `DQ Assessment se desarrolló como una plataforma basada en Web/móvil que proporciona evaluación & herramientas de análisis para respaldar los programas de educación/capacitación de habilidades digitales para medir las habilidades digitales de individuos y organizaciones en función de puntos de referencia globales.`,
    assessment__dq_assessment_will_enable:`Permitirá:`,
    assessment__1_common_assessments_that_measure: `Evaluaciones comunes que miden los niveles de competencias múltiples de inteligencia digital de individuos y organizaciones contra estándares globales con puntos de referencia globales`,
    assessment__2_in_depth_analysis_to_the_end_users: `Análisis en profundidad de los usuarios finales (por ejemplo, individuos y organizaciones) para identificar sus fortalezas y debilidades a través de puntos de referencia globales`,
    assessment__3_customized_digital_skill_development: `Desarrollo personalizado de habilidades digitales para individuos y organizaciones para ayudar a diseñar una formación personalizada a lo largo de toda la vida.`,
    assessment__dq_assessment_tools_are_developed_based_on: `Las herramientas de evaluación de DQ se desarrollan en base a la recopilación de varios instrumentos de evaluación de varias fuentes creíbles y proyectos de investigación existentes de socios propietarios de contenido.`,
    assessment__toolkits: `Cajas de herramientas`,
    assessment__whether_it_be_your_school_company_or: `Ya sea su escuela, empresa o país, tenemos una variedad de evaluaciones que ayudan a medir sus habilidades de ciudadanía digital en función de un punto de referencia global.`,
    assessment__if_you_looking_to_create_a_customized: `Si desea crear una herramienta de evaluación personalizada (etiqueta blanca) o servicios analíticos en profundidad, o si necesita más información, <a href=\"mailto:contact@dqforall.com\">contáctenos</a>.`,
    assessment__primary_schools: `ESCUELAS<br>PRIMARIAS`,
    assessment__schools_can_get_their_students_ages_8_12: `Las escuelas pueden hacer que sus estudiantes de 8 a 12 años realicen una prueba de ciudadanía digital más completa para conocer sus niveles de ciudadanía digital.`,
    assessment__measures_dq_1_8: `Medidas DQ 1-8`,
    assessment__analytics_dashboard_included_for_teachers: `Tablero de análisis incluido para maestros`,
    assessment__digital_parenting: `Paternidad digital`,
    assessment__cyber_risk_assessment: `Evaluación de riesgos cibernéticos`,
    assessment__learn_more: `Aprende más`,
    assessment__secondary_schools: `ESCUELAS<br>SECUNDARIAS`,
    assessment__schools_can_get_their_teenage_students_ages_13_18: `Las escuelas pueden hacer que sus estudiantes adolescentes de 13 a 18 años realicen una prueba de ciudadanía digital más completa para conocer sus niveles de ciudadanía digital.`,
    assessment__companies_corporates_span: `EMPRESAS`,
    assessment__companies_and_organizations_can_assess: `Las empresas y organizaciones pueden evaluar las habilidades de ciudadanía digital de sus empleados e identificar áreas de crecimiento necesario.`,
    assessment__dashboard_included_for_hr: `Tablero de análisis incluido para el personal de recursos humanos`,
    assessment__national_packages: `PAQUETES NACIONALES<br/>`,
    assessment__countries_and_govt_localize_assess: `Los países y gobiernos pueden medir el DQ de su país con evaluaciones personalizadas y localizadas.`,
    assessment__customize_assessment: `Evaluaciones personalizadas`,
    assessment__localized_for_your_country: `Localizado/traducido para su país`,
    assessment__consult_strategies_plans: `Consultar, elaborar estrategias y co-desarrollar planes de implementación`,
    assessment__balance_use_of_tech: `Uso equilibrado de la tecnología`,
    assessment__behavioural_risk_management: `Gestión de riesgos cibernéticos conductuales`,
    assessment__digital_empathy: `Empatía digital`,
    assessment__dc_identity: `Identidad Ciudadana Digital`,
    assessment__ps_cyber_sec_management: `Gestión de la seguridad cibernética personal`,
    assessment__media_and_info_literacy: `Alfabetización mediática e informacional`,
    assessment__digi_footprint_management: `Gestión de Huella Digital`,
    assessment__privacy_management: `Gestión de privacidad`,
    assessment__read_press_release: `Lea el comunicado de prensa de <br>`,
}


const lc_vi = {
    home__dq_index: `Chỉ số DQ`,
    home__dq_assessment: `Đánh giá DQ`,
    home__take_the_test: `Làm bài kiểm tra`,
    home__get_started:`Bắt đầu`,
    home__global_project_to_measure: `Dự án Toàn cầu nhằm Đo lường Trình độ Kỹ năng Kỹ thuật số`,
    home__realtime_tracker: `Trình theo dõi thời gian thực cho các kỹ năng kỹ thuật số`,
    home__disclaimer_1: `Tuyên bố từ chối trách nhiệm: Phần trên chỉ nhằm mục đích minh họa và không thể hiện trình độ kỹ năng kỹ thuật số của một quốc gia hoặc bất kỳ điều gì liên quan.`,
    home__read_press_release: `Đọc thông cáo báo chí`,
    home__measure_digi_skills: `Đo lường trình độ kỹ năng số của quốc gia bạn`,
    home__dqindex_intro_title: `<a href="www.dqinst acad.org">Viện DQ</a> đang bắt đầu một dự án hợp tác toàn cầu mới để phát triển Chỉ số DQ, một công cụ theo dõi toàn cầu về kỹ năng kỹ thuật số.`,
    home__disc_about_cookies: ` Chúng tôi sử dụng cookie để cung cấp các dịch vụ trực tuyến của mình và giúp việc tương tác với các dịch vụ trực tuyến của chúng tôi trở nên dễ dàng và có ý nghĩa. Bằng cách tiếp tục sử dụng trang web của chúng tôi, bạn đồng ý với chính sách cookie của chúng tôi. Chi tiết về các cookie chúng tôi sử dụng và hướng dẫn cách tắt chúng được nêu trong `,
    home__cookies_piolicy_link: ` <a href="/cookies" target="_blank">Chính sách cookie</a>`,
    home__project_measure_digi_skill_level: `Dự án Toàn cầu nhằm Đo lường Trình độ Kỹ năng Kỹ thuật số`,
    home__realtime_tracker_for_digi_skills: `Trình theo dõi thời gian thực cho các kỹ năng kỹ thuật số`,
    home__read_press_release_br: `Đọc <br>Thông cáo báo chí`,
    home__disclaimer_1_br: `Tuyên bố miễn trừ trách nhiệm: Thông tin trên chỉ nhằm mục đích minh họa và không <br>biểu thị trình độ kỹ năng kỹ thuật số của một quốc gia hoặc bất kỳ điều gì liên quan.`,
    home__measure_digi_skill_lvl_of_country: `Đo lường trình độ kỹ năng số của quốc gia bạn`,
    home__dq_is_starting_new_collab_project: `<a href="https://www.dqinstitute.org/" target="_blank">Viện DQ</a> đang bắt đầu một dự án hợp tác toàn cầu mới để phát triển Chỉ số DQ, một công cụ theo dõi toàn cầu về kỹ năng kỹ thuật số.`,
    home__covid19_pushed_world_message: `COVID-19 đã đẩy thế giới vào thời kỳ số hóa nhanh chóng, nơi các kỹ năng kỹ thuật số hiện là điều bắt buộc đối với mỗi cá nhân.`,
    home__ieee_digiskill_beyond_tech_competency: `“<strong>Kỹ năng kỹ thuật số</strong>” vượt xa năng lực kỹ thuật như viết mã và vận hành máy tính. Theo <a href="https://standards.ieee.org/ieee/3527.1/7589/" target="_blank">Tiêu chuẩn toàn cầu về trí tuệ kỹ thuật số (DQ) của IEEE (IEEE 3527.1™)</a>, “Kỹ năng kỹ thuật số” được định nghĩa là tập hợp các năng lực toàn diện cần thiết để phát triển trong thời đại kỹ thuật số.`,
    home__based_on_un_sustainable_dev_goals: `Dựa trên <a href="https://www.dqinstitute.org/collaborative-rd/" target="_blank">nền tảng R&amp;D hợp tác</a> tổng hợp các khuôn khổ, nghiên cứu và chương trình khác nhau, Chỉ số DQ phát triển một bộ số liệu được tiêu chuẩn hóa để theo dõi mức độ tiến bộ của các quốc gia về sáu kỹ năng kỹ thuật số sau đây phù hợp với Mục tiêu Phát triển Bền vững của Liên Hợp Quốc.`,
    home__global_tracker_for_digi_skills: `Công cụ theo dõi toàn cầu về kỹ năng kỹ thuật số`,
    home__digi_skills_child_safety: `Kỹ năng kỹ thuật số cho <span>An toàn trực tuyến cho trẻ em</span>`,
    home__protect_all_children: `Bảo vệ tất cả trẻ em khỏi những rủi ro và tác hại trên mạng, đồng thời đảm bảo kỹ năng công dân kỹ thuật số cho tất cả trẻ em`,
    home__digi_skills_learning: `Kỹ năng kỹ thuật số cho <span>Học tập</span>`,
    home__ensure_quality_statement_1: `Đảm bảo giáo dục kỹ năng số toàn diện và có chất lượng, đồng thời thúc đẩy cơ hội lâu dài cho tất cả mọi người`,
    home__digiskills_innovation: `Kỹ năng kỹ thuật số cho <span>Công việc &amp; Đổi mới</span>`,
    home__ensure_digital_skilling: `Đảm bảo đào tạo/nâng cao kỹ năng kỹ thuật số cho tất cả lực lượng lao động, thúc đẩy nơi làm việc kỹ thuật số tử tế và hiệu quả ở mọi cấp độ, đồng thời thúc đẩy khoa học, công nghệ và đổi mới`,
    home__digital_skills_for_female_empowerment: `Kỹ năng kỹ thuật số để <span>Trao quyền cho phụ nữ</span>`,
    home__achieve_digital_gender_equity: `Đạt được bình đẳng giới kỹ thuật số và trao quyền cho tất cả phụ nữ và trẻ em gái thông qua công nghệ hỗ trợ`,
    home__digital_skills_for_well_being: `Kỹ năng kỹ thuật số cho <span>Sức khỏe</span>`,
    home__promote_wellbeing_in_digital_lives: `Thúc đẩy phúc lợi trong cuộc sống số, giảm bất bình đẳng và thúc đẩy xã hội số hóa an toàn, đáng tin cậy và hòa bình cho tất cả mọi người, ở mọi cấp độ`,
    home__digital_skills_for_online_participation: `Kỹ năng kỹ thuật số để <span>Tham gia trực tuyến</span>`,
    home__ensure_universal_access_by_all_people: `Đảm bảo khả năng tiếp cận phổ cập và toàn diện cho tất cả mọi người, đặc biệt là những người trong hoàn cảnh dễ bị tổn thương bao gồm người cao tuổi và những người có nhu cầu đặc biệt, với Internet và tham gia vào nền kinh tế kỹ thuật số`,
    home__take_action: `Hãy hành động`,
    home__we_are_inviting_those_who_share_the_common_goal: `Chúng tôi đang mời những người có chung mục tiêu là thu hẹp khoảng cách kỹ năng kỹ thuật số tham gia dự án Chỉ số DQ. Chúng tôi giám sát và theo dõi tiến độ toàn cầu trong việc đạt được mục tiêu bằng cách đo lường tác động chung của chúng tôi thông qua Chỉ số DQ.`,
    home__safer_internet_day_february_8_2022: `Ngày Internet An toàn hơn<br> (ngày 8 tháng 2 năm 2022)`,
    home__digital_citizenship_test_dct: `Bài kiểm tra quyền công dân kỹ thuật số (DCT)`,
    home__dct_was_made_available_to_the_public: `DCT được cung cấp cho công chúng để tất cả các tổ chức và cá nhân có thể sử dụng nó để hiểu mức độ sẵn sàng sử dụng công nghệ một cách an toàn.`,
    home__download_the_test_kit: `Tải xuống <br>Bộ thử nghiệm`,
    home__tbd: `TBD`,
    home__dq_index_launch: `Ra mắt chỉ số DQ <br>&amp; Đồng sáng tạo`,
    home__the_dq_index_platform_will_be_launched: `Nền tảng Chỉ số DQ sẽ được ra mắt vào cuối năm 2022 trong khi chúng tôi mời các đối tác tham gia dự án hợp tác R&D.`,
    home__join_the_collaborative: `Tham gia <br>hợp tác R&amp;D`,
    home__dq_day_october_10_2022: `Ngày DQ <br> (10 tháng 10 năm 2022)`,
    home__2022_dq_conference: `Hội nghị DQ 2022`,
    home__dq_conference_provides_learning_experiences: `Hội nghị DQ cung cấp kinh nghiệm học tập cho cộng đồng toàn cầu về kỹ năng số. Hội nghị sẽ bao gồm các cuộc thảo luận về Chỉ số DQ, các nghiên cứu điển hình và hơn thế nữa.`,
    home__if_you_interested_in_becoming_a_sponsor: `Nếu bạn muốn trở thành nhà tài trợ, diễn giả hoặc người tham gia, vui lòng đăng ký bên dưới.`,
    home__register_for_the_conference: `Đăng ký <br>Hội thảo`,
    home__privacy_policy: `Chính sách bảo mật`,
    home__dq_lab_pte_ltd_all_rights_reserved: `Công ty tư nhân TNHH DQ LAB. Mọi quyền được bảo lưu.`,
    home__language: `Ngôn ngữ`,
    home__term_of_use: `Điều khoản sử dụng`,
    home__email: `E-mail`,
    home__general_enquiries: `Thắc mắc chung`,
    home__social_media: `Truyền thông xã hội`,
    assessment__whats_your_dq:`DQ của bạn là gì?`,
    assessment__find_out_what_type_of_digital_citizen_you_are: `Tìm hiểu <br>bạn là loại công dân kỹ thuật số nào`,
    assessment__take_the_free_test: `Làm bài kiểm tra miễn phí`,
    assessment__5_types_of_digital_citizen: `5 Loại công dân kỹ thuật số`,
    assessment__based_on_statement_misc_1: `Dựa trên ngân hàng dữ liệu toàn cầu của <a href="https://www.dqinstitute.org/" target="_blank">Viện DQ</a>, chúng tôi đã xác định được 5 loại công dân kỹ thuật số khác nhau thể hiện các kiểu kỹ năng, cách sử dụng công nghệ khác nhau và rủi ro mạng.`,
    assessment__carefree: `VÔ TƯ`,
    assessment__feels_more_comfortable_online_than_offline: `Cảm thấy trực tuyến thoải mái hơn ngoại tuyến`,
    assessment__potentially_involved_with_various_cyber_risks: `Có khả năng liên quan đến nhiều rủi ro mạng khác nhau`,
    assessment__ordinary: `BÌNH THƯỜNG`,
    assessment__average_technology_user_who_not_fully_aware: `Người dùng công nghệ trung bình có thể không nhận thức đầy đủ về rủi ro cũng như cơ hội`,
    assessment__tend_to_passively_follow_the_algorithm: `Có xu hướng làm theo thuật toán một cách thụ động`,
    assessment__always_on: `LUÔN SẴN SÀNG`,
    assessment__likely_communicate_with_others_a_lot_online: `Có khả năng giao tiếp trực tuyến nhiều với người khác`,
    assessment__may_not_consider_much_about_risks: `Có thể không cân nhắc nhiều về các rủi ro mạng tiềm ẩn`,
    assessment__cautious: `CẨN TRỌNG`,
    assessment__being_aware_of_potential_cyber_risks: `Nhận thức được các rủi ro mạng tiềm ẩn và chưa khám phá đầy đủ thế giới kỹ thuật số`,
    assessment__likely_having_low_risks_in_the_digi_world: `Có khả năng gặp rủi ro thấp trong thế giới kỹ thuật số`,
    assessment__digilog: `DIGILOG`,
    assessment__well_rounded_digital_citizen: `Công dân kỹ thuật số toàn diện, biết cân bằng cuộc sống trực tuyến và ngoại tuyến`,
    assessment__uses_technology_mindfully: `Sử dụng công nghệ một cách có ý thức`,
    assessment__low: `Thấp`,
    assessment__medium: `Trung bình`,
    assessment__high: `Cao`,
    assessment__skills: `Kỹ năng`,
    assessment__usage: `Cách sử dụng`,
    assessment__risks: `Rủi ro`,
    assessment__be_yourself_and_answer_honestly: `Hãy là chính mình và trả lời trung thực để tìm ra loại và cấp độ công dân kỹ thuật số của bạn.`,
    assessment__take_the_test: `Làm bài kiểm tra`,
    assessment__digital_citizenship_test_and_dq_index: `Kiểm tra quyền công dân kỹ thuật số và chỉ số DQ`,
    assessment__the_level_of_digital_citizenship_is: `Cấp độ <span class="isection-toolip">công dân kỹ thuật số<div class="tooltip-content">Quyền công dân kỹ thuật số là bộ kỹ năng cần thiết để sử dụng công nghệ một cách an toàn. Là bước đầu tiên của Tiêu chuẩn toàn cầu về trí tuệ kỹ thuật số (DQ) của IEEE (IEEE 3527.1™), quyền công dân kỹ thuật số được coi là điều kiện tiên quyết cho các kỹ năng kỹ thuật số khác, chẳng hạn như mã hóa, an ninh mạng và các kỹ năng kỹ thuật sẵn sàng cho công việc khác.</div ></span> ở một quốc gia là dấu hiệu rõ ràng cho thấy điểm chỉ số DQ của họ có cao hay không.`,
    assessment__based_on_years_of_research_desc: `Dựa trên nhiều năm nghiên cứu, Viện DQ đã phát triển một bộ câu hỏi đánh giá hiệu quả cấp độ công dân kỹ thuật số của các cá nhân, được gọi là Bài kiểm tra công dân kỹ thuật số (DCT).`,
    assessment__connected_with_the_dq_index_desc: `Được kết nối với Chỉ số DQ, kết quả từ DCT sẽ được cập nhật ngay lập tức vào chỉ mục để hiển thị vị trí của mỗi quốc gia. Điều này cho phép các quốc gia sử dụng hiệu quả các nguồn lực và cải thiện toàn diện trình độ kỹ năng kỹ thuật số của mình, tạo ra tác động tích cực.`,
    assessment__digital_citizenship_is_a_skill_desc: `Quyền công dân kỹ thuật số là một bộ kỹ năng cần thiết để sử dụng công nghệ một cách an toàn. Là bước đầu tiên của Tiêu chuẩn toàn cầu về trí tuệ kỹ thuật số (DQ) của IEEE (IEEE 3527.1™), quyền công dân kỹ thuật số được coi là điều kiện tiên quyết cho các kỹ năng kỹ thuật số khác, chẳng hạn như mã hóa, an ninh mạng và các kỹ năng kỹ thuật sẵn sàng cho công việc khác.`,
    assessment__digital_citizenship_scores: `Điểm công dân kỹ thuật số`,
    assessment__global: `Toàn cầu`,
    assessment__australia: `Châu Úc`,
    assessment__cambodia: `Campuchia`,
    assessment__china: `Trung Quốc`,
    assessment__colombia: `Colombia`,
    assessment__dominican_republic: `Cộng hòa Dominica`,
    assessment__ecuador: `Ecuador`,
    assessment__el_salvador: `El Salvador`,
    assessment__hong_kong: `Hồng Kông`,
    assessment__india: `Ấn Độ`,
    assessment__indonesia: `Indonesia`,
    assessment__ireland: `Ireland`,
    assessment__japan: `Nhật Bản`,
    assessment__korea_republic_of: `Hàn Quốc, Cộng hòa`,
    assessment__malaysia: `Malaysia`,
    assessment__mexico: `México`,
    assessment__new_zealand: `New Zealand`,
    assessment__nigeria: `Nigeria`,
    assessment__peru: `Peru`,
    assessment__philippines: `Philippin`,
    assessment__qatar: `Qatar`,
    assessment__singapore: `Singapore`,
    assessment__south_africa: `Nam Phi`,
    assessment__spain: `Tây ban nha`,
    assessment__sweden: `Thụy Điển`,
    assessment__thailand: `nước Thái Lan`,
    assessment__turkey: `Thổ Nhĩ Kỳ`,
    assessment__united_kingdom: `Vương quốc Anh`,
    assessment__united_states: `Hoa Kỳ`,
    assessment__viet_nam: `Việt Nam`,
    assessment__n_a_text: `không áp dụng`,
    assessment__national_dq: `DQ quốc gia`,
    assessment__global_dq: `DQ toàn cầu`,
    assessment__percent_of_participants: `% người tham gia`,
    assessment__digital_citizenship_score: `Điểm công dân kỹ thuật số`,
    assessment__distribution_of_dcs: `Phân phối DCS (Điểm công dân kỹ thuật số)`,
    assessment__about_dq_assessments: `Giới thiệu về đánh giá DQ`,
    assessment__forall: `Cho tất cả`,
    assessment__available_for_all_age_groups_and_types: `Có sẵn cho mọi lứa tuổi và chủng loại`,
    assessment__any_system: `Bất kỳ hệ thống nào`,
    assessment__whether_you_looking_to_integrate: `Cho dù bạn đang tìm cách tích hợp vào hệ thống hiện tại của mình hay muốn có một giải pháp vượt trội, chúng tôi đều có thể biến điều đó thành hiện thực.`,
    assessment__dq_index: `Chỉ số DQ`,
    assessment__scores_are_immediately_updated_into: `Điểm số được cập nhật ngay lập tức vào <a href="https://www.dqindex.org">Chỉ số DQ</a> để so sánh trong nước và toàn cầu`,
    assessment__dq_assessment_was_developed_as: `Đánh giá DQ được phát triển dưới dạng nền tảng dựa trên web/di động, cung cấp các công cụ đánh giá và phân tích để hỗ trợ các chương trình giáo dục/đào tạo kỹ năng số nhằm đo lường kỹ năng kỹ thuật số cho các cá nhân và tổ chức dựa trên tiêu chuẩn toàn cầu.`,
    assessment__dq_assessment_will_enable:`Nó sẽ kích hoạt:`,
    assessment__1_common_assessments_that_measure: `Các đánh giá chung đo lường mức độ đa năng lực trí tuệ kỹ thuật số của các cá nhân và tổ chức theo tiêu chuẩn toàn cầu với các tiêu chuẩn toàn cầu`,
    assessment__2_in_depth_analysis_to_the_end_users: `Phân tích chuyên sâu tới người dùng cuối (ví dụ: cá nhân và tổ chức) để xác định điểm mạnh và điểm yếu của họ thông qua tiêu chuẩn toàn cầu`,
    assessment__3_customized_digital_skill_development: `Phát triển kỹ năng kỹ thuật số tùy chỉnh cho các cá nhân và tổ chức để giúp thiết kế chương trình đào tạo suốt đời tùy chỉnh`,
    assessment__dq_assessment_tools_are_developed_based_on: `Các công cụ đánh giá DQ được phát triển dựa trên việc thu thập các công cụ đánh giá khác nhau từ nhiều nguồn đáng tin cậy khác nhau và các dự án nghiên cứu hiện có của các đối tác chủ sở hữu nội dung.`,
    assessment__toolkits: `Bộ công cụ`,
    assessment__whether_it_be_your_school_company_or: `Cho dù đó là trường học, công ty hay quốc gia của bạn, chúng tôi đều có nhiều đánh giá khác nhau giúp đo lường kỹ năng công dân kỹ thuật số của bạn dựa trên tiêu chuẩn toàn cầu.`,
    assessment__if_you_looking_to_create_a_customized: `Nếu bạn đang muốn tạo một công cụ đánh giá tùy chỉnh (được gắn nhãn trắng) hoặc các dịch vụ phân tích chuyên sâu hoặc cần thêm thông tin, vui lòng <a href="mailto:partnership@dqinstitute.org">liên hệ với chúng tôi</a>.`,
    assessment__primary_schools: `TRƯỜNG TIỂU HỌC<br>`,
    assessment__schools_can_get_their_students_ages_8_12: `Các trường học có thể yêu cầu học sinh từ 8-12 tuổi làm bài kiểm tra công dân kỹ thuật số toàn diện hơn để tìm hiểu cấp độ công dân kỹ thuật số của các em.`,
    assessment__measures_dq_1_8: `Đo lường DQ 1-8`,
    assessment__analytics_dashboard_included_for_teachers: `Bao gồm Bảng điều khiển phân tích dành cho giáo viên`,
    assessment__digital_parenting: `Nuôi dạy con kỹ thuật số`,
    assessment__cyber_risk_assessment: `Đánh giá rủi ro mạng`,
    assessment__learn_more: `Tìm hiểu thêm`,
    assessment__secondary_schools: `TRƯỜNG TRUNG HỌC<br>`,
    assessment__schools_can_get_their_teenage_students_ages_13_18: `Các trường học có thể yêu cầu học sinh tuổi teen từ 13-18 tuổi làm bài kiểm tra công dân kỹ thuật số toàn diện hơn để tìm hiểu cấp độ công dân kỹ thuật số của các em.`,
    assessment__companies_corporates_span: `CÁC CÔNG TY`,
    assessment__companies_and_organizations_can_assess: `Các công ty và tổ chức có thể đánh giá kỹ năng công dân kỹ thuật số của nhân viên và xác định các lĩnh vực cần phát triển.`,
    assessment__dashboard_included_for_hr: `Bảng điều khiển phân tích được bao gồm cho nhân viên nhân sự`,
    assessment__national_packages: `GÓI QUỐC GIA`,
    assessment__countries_and_govt_localize_assess: `Các quốc gia và chính phủ có thể đo lường DQ của quốc gia mình bằng các đánh giá tùy chỉnh và cục bộ.`,
    assessment__customize_assessment: `Đánh giá tùy chỉnh`,
    assessment__localized_for_your_country: `Bản địa hóa/dịch cho quốc gia của bạn`,
    assessment__consult_strategies_plans: `Tư vấn, lập chiến lược và hợp tác phát triển các kế hoạch triển khai`,
    assessment__balance_use_of_tech: `Sử dụng công nghệ cân bằng`,
    assessment__behavioural_risk_management: `Quản lý rủi ro mạng hành vi`,
    assessment__digital_empathy: `Đồng cảm kỹ thuật số`,
    assessment__dc_identity: `Định dạng công dân kỹ thuật số`,
    assessment__ps_cyber_sec_management: `Quản lý an ninh mạng cá nhân`,
    assessment__media_and_info_literacy: `Kiến thức thông tin và truyền thông`,
    assessment__digi_footprint_management: `Quản lý dấu chân kỹ thuật số`,
    assessment__privacy_management: `Quản lý quyền riêng tư`,
    assessment__read_press_release: `Đọc <br>Thông cáo báo chí`,
}

export const LC_VALUES_WEBSITE = {
    'en': lc_en,
    'ko': lc_ko,
    'ja': lc_ja,
    'th': lc_th,
    'es': lc_es,
    'vi': lc_vi
}

const validate_keys_syncing = () => {
    const en_keys_count = Object.keys(lc_en).length;
    const ko_keys_count = Object.keys(lc_ko).length;
    const ja_keys_count = Object.keys(lc_ja).length;
    const th_keys_count = Object.keys(lc_th).length;
    const es_keys_count = Object.keys(lc_es).length;
    const vi_keys_count = Object.keys(lc_vi).length;
    if (en_keys_count === ko_keys_count && en_keys_count === ja_keys_count && en_keys_count === th_keys_count && en_keys_count === es_keys_count && en_keys_count === vi_keys_count) {
        console.log('lc_values::website:: keys are in sync');
    } else {
        console.log('lc_values::website:: FATAL ISSUE : keys not in sync');
    }
}

validate_keys_syncing();