import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { AuthContext } from "./context/auth";
import { GlobalStateContext } from "./context/global_state";
import {LOCAL_STORAGE_KEYS, DQ_THEMES, DQ_ENABLED_THEMES} from "./utils/constants";
import Toast from "./components/UI/Toast/Toast.ui";

import createTheme from "./theme/index";
import Routes from './Routes';
import {ThemeProvider} from "styled-components";

const browserHistory = createBrowserHistory();
const App = () => {

	/*
	* ***********
	* Auth Context Data
	* ***********
	*/
	let existingToken;
	let existingUser;
	let existingSettings;
	if (typeof window !== "undefined") {
		existingToken = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
		existingUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_USER));
		try {
			existingSettings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_DQ_SETTINGS));
		} catch (e) {
			console.log('FAILED to load the settings from lc-storage, err: ', e);
		}
	}

	const [authToken, setAuthToken] = useState(existingToken);
	const [authUser, setAuthUser] = useState(existingUser);

	const setToken = (data) => {
		localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, JSON.stringify(data));
		setAuthToken(data);
	}

	const setUser = (data) => {
		localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_USER, JSON.stringify(data));
		setAuthUser(data);
	}

	/*
	* ***********
	* Global Context Data
	* ***********
	*/

	const initializeProfile = () => {
		const org_code = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE);
		if(org_code) {
			const available_profiles = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AVAILABLE_PROFILES));
			if(available_profiles) {
				return available_profiles.find(profile => profile.org_code === org_code);
			}
		}
		return undefined;
	}

	const saveProfile = (profile) => {
		if(profile) {
			const org_code = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE);
			const available_profiles = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AVAILABLE_PROFILES));
			if(org_code && available_profiles) {
				const index = available_profiles.findIndex(profile => profile.org_code === org_code);
				if(index !== -1) {
					available_profiles.splice(index, 1, profile);
					localStorage.setItem(LOCAL_STORAGE_KEYS.AVAILABLE_PROFILES, JSON.stringify(available_profiles));
				}
			}
		}
	}

	const [toastShow, _setToastShow] = useState(false);
	const [toastMessage, _setToastMessage] = useState('');
	const [profile, _setProfile] = useState({'badges': {}, 'org_code': ''});

	const setToastShow = (flag) => {
		_setToastShow(flag);
	}
	const setToastMessage = (message) => {
		_setToastMessage(message);
	}
	const setProfile = (profile) => {
		_setProfile(profile);
		//saveProfile(profile);
	}


	if (!(existingSettings && existingSettings.theme && DQ_ENABLED_THEMES.includes(existingSettings.theme))) {
		existingSettings = {
			theme: DQ_THEMES.DEFAULT
		}
	}
	const [settings, _setSettings] = useState(existingSettings);

	const setSettings = (st_obj) => {
		localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_DQ_SETTINGS, JSON.stringify(st_obj));
		_setSettings(st_obj);
	}
	/*
	* ***********
	* Main App Body
	* ***********
	*/
	return (
		<ThemeProvider theme={createTheme(settings.theme)}>
		<div id="app">
			<AuthContext.Provider value={{
				authToken,
				setAuthToken: setToken,
				authUser,
				setAuthUser: setUser
			}}>
				<GlobalStateContext.Provider value={{
					toastShow,
					setToastShow,
					toastMessage,
					setToastMessage,
					profile,
					setProfile,
					settings,
					setSettings,
				}}>
					<Router history={browserHistory}>
						<Routes />
					</Router>
					<Toast />
				</GlobalStateContext.Provider>

			</AuthContext.Provider>

		</div>
		</ThemeProvider>
	);
}

export default App;
