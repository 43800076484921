import React, {useEffect, useState} from 'react';
import ListItemProgress from './ListItemProgress.comp';
import {useGlobalState} from "../../../context/global_state";
import styled from "styled-components";
import {DQ_THEMES} from "../../../utils/constants";


const ListItem = (props) => {

    const { badge, selectItem, profile } = props;

    const { setToastShow, setToastMessage } = useGlobalState();

    useEffect( ()=>{

    //     const curr_badge_state = profile.badges[badge.badge_code];
    //     if (curr_badge_state) {
    //         if (curr_badge_state.in_progress) {
    //             setScoreBoxText(`${curr_badge_state.progress.toFixed(1)}%`)
    //         } else if (curr_badge_state.finished) {
    //             setScoreBoxText(`${curr_badge_state.score}`)
    //         }
    //     }
    }, [] );

    const renderURL = (url) => {
        if (url.substring(0,8) === 'https://') {
            return url
        }
        if (url.substring(0,7) === 'http://') {
            return url
        }
        return `${process.env.REACT_APP_MYDQ_API}${url}`
    }

    const microBadgeClickAction = (badge_id, badge_object) => {
        selectItem(badge_id, badge_object);
    }

    let is_locked = false;
    if (badge.pre_test_mod_code) {
        is_locked = !(profile.badges[badge.pre_test_mod_code]
            && profile.badges[badge.pre_test_mod_code]['scores']
            && profile.badges[badge.pre_test_mod_code]['scores'].length > 0
        );
    } else {
        is_locked = false;
    }

    const mainStyle_open = {
        backgroundColor: badge.badge_background,
        borderColor: `${badge.theme_color}`,
        borderTop: `1px solid ${badge.theme_color}`,
        color: badge.primary_color,
        cursor:'pointer',
    };

    const mainStyle_locked = {
        backgroundColor: '#575e6a',
        borderColor: `${badge.theme_color}`,
        borderTop: `1px solid ${badge.theme_color}`,
        color: badge.primary_color,
        cursor:'default',
    };
    const HeadingContainer = styled.div`
        font-family: ${(props) => {
            switch (props.theme.name) {
                case DQ_THEMES.DEFAULT:
                    return `"${props.theme.fonts[2]}", "${props.theme.fonts[0]}", "${props.theme.fonts[1]}"` ;
                case DQ_THEMES.NUS_THEME:
                    return `"${props.theme.fonts[3]}"`;
                default:
                    return '';
            }
        }};
        font-weight: ${(props) => {
            switch (props.theme.name) {
                case DQ_THEMES.DEFAULT:
                    return 700;
                case DQ_THEMES.NUS_THEME:
                    return 700;
                default:
                    return 600;
            }
        }};
        font-size: 1.5em;
    `
    return(
        <div
            className={'home-list-item-main'}
            style={is_locked ? mainStyle_locked : mainStyle_open}
            onClick={()=> {
                if (is_locked) {
                    setToastShow(true);
                    setToastMessage(`Assessment is not available, to unlock first finish ${badge.pre_test_mod_name}`);
                } else {
                    microBadgeClickAction(badge.id, badge);
                }
            }} >
            {/*<span className={style.title}>{item.title}</span>*/}

            <div className={'home-list-item-container'}>
                <div className={'home-list-item-title-section'}>
                    <img className={'home-list-item-img'} src={
                        is_locked ? 'https://storage.googleapis.com/mydq-live-bucket/Content/Images/pngegg.png'
                            : renderURL(badge.icon_url)
                    } />
                    <HeadingContainer className={'home-list-item-title'}>
                        <span>{badge.duration}</span>
                        <p>{badge.name}</p>
                    </HeadingContainer>
                </div>
                {
                    is_locked ? undefined : <ListItemProgress
                        badge={badge}
                        profile={profile}
                    />
                }
            </div>
        </div>
    )
}

export default ListItem;