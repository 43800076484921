import React from "react";
import Spinner from "../../assets/Spinner-1s-272px.svg";

const NUSResultsLoader = (props) => {

    const { loading } = props;

    return (
        <>
            {
                loading &&
                <div className={'nus-loading-container'}>
                    <div className="nus-backdrop-container"></div>
                    <div className={`loading loader-nus`}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#EDEDED",
                            fontFamily: "Varela",
                            padding: "45px 30px",
                            borderRadius: "50px",
                            color: "#676767",
                            minHeight: "300px",
                            width: '275px',
                            zIndex: "99"
                        }}
                    >

                        <img height={190} src={Spinner} style={{ marginTop: '-5vh' }}/>
                        <div className="loading-label">
                            <span className="label-head" style={{display: "block", color: "#ED2E82"}}><b>Almost Done!</b></span>
                            Please wait for your Digital Wellbeing Score.
                        </div>

                    </div>
                </div>
            }
        </>
    );
};

export default NUSResultsLoader;