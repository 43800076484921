import React, {useEffect, useState, useRef} from 'react';
import { withRouter } from "react-router";
import Helmet from "react-helmet";
import ListItem from './components/ListItem.comp';
import ListItemDetail from './components/ListItemDetail.comp';
import TopSection from './components/TopSection.comp';
import Footer from "../../components/Footer/plugable.footer";
import {useGlobalState} from "../../context/global_state";
import {appStartService, getProfileService} from "../../services/home.service";
import {
	DEFAULT_LOCALE,
	DQ_ASSESS_APP_MODE,
	FREE_TESTS,
	LOCAL_STORAGE_KEYS,
	QUIZ_TIME_MODES
} from "../../utils/constants";
import {getMapFromObjectListByKey, hasDQPAccess} from "../../utils/utils";
import { Animate, AnimateGroup } from "react-simple-animate";
import {useAuth} from "../../context/auth";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";
import {
	globalNationalAveragesScoresService
} from "../../services/stats.service";



const Home = (props) => {

	const {history, location} = props;

	const { authUser } = useAuth();

	const [loading, setLoading] = useState(false);
	const [badgeSelected, setBadgeSelected] = useState(-1);
	const [badgesUIC, setBadgesUIC] = useState([]);
	const [areasUIC, setAreasUIC] = useState(undefined);
	const [areasUICMap, setAreasUICMap] = useState({});
	const [totalScore, setTotalScore] = useState(0);
	const [globalAverage, setGlobalAverage] = useState(0);
	const [nationalAverage, setNationalAverage] = useState(0);

	const [loadItemsAnim, setLoadItemsAnim] = useState(false);

	const { setToastShow, setToastMessage, profile, setProfile } = useGlobalState();

	const pageLc = location.pathname.split('/')[1];
	const is_first_boot = useRef(false);

	const getItemsLoadWaitTime = () => {
		if (is_first_boot.current) {
			return 1500;
		} else {
			return 0;
		}
	}

	const getListItemAnimDuration = () => {
		if (is_first_boot.current) {
			return 0.25;
		} else {
			return 0;
		}
	}

	useEffect( () => {
		if (loadItemsAnim) {
			if (is_first_boot.current) {
				// turn it off after and approx time, totalling to all the animations
				// current implementation, turning off in 7 seconds
				setTimeout( () => {
					localStorage.removeItem(LOCAL_STORAGE_KEYS.FIRST_TIME_BOOT);
					is_first_boot.current = false;
				}, 5000 )
			}
		}
	}, [loadItemsAnim] )

	useEffect(  () => {

		is_first_boot.current = localStorage.getItem(LOCAL_STORAGE_KEYS.FIRST_TIME_BOOT)
		fetchData().then(r => console.log('data loaded'));

		setTimeout( ()=>{
			setLoadItemsAnim(true)
		}, getItemsLoadWaitTime() )

		const params = new URLSearchParams(location.search);
		const reload_profile = params.get('reload_profile');
		if (reload_profile && reload_profile === '1') {
			reloadProfile().then(r=>console.log('profile reloaded at page boot time'))
		}
	}, [])

	useEffect(() => {
		fetchNationalAndGlobalAverages().then(r=>console.log('successfully updated module global and nations avg'));
	}, [badgeSelected]);

	useEffect( ()=>{

		console.log('current loaded profile ==> ', profile);

		// update the profile org in localStorage :: for app_start (landing-page) logic
		if (profile && profile.org_code) {
			localStorage.setItem(LOCAL_STORAGE_KEYS.ORG_CODE_OF_DB_PROFILE, profile.org_code);

			// NUS required this, also this is now a generic case: that if user came via connect api, then start test
			const def_mod_code = localStorage.getItem(LOCAL_STORAGE_KEYS.CONN_DEFAULT_MODULE_CODE);
			if (def_mod_code) {
				return history.push(`/take/quiz/${QUIZ_TIME_MODES.START_MODE}/${def_mod_code}`);
			}

			// move user back to demographic if it's not updated yet
			if (!profile.is_demographic_updated) {
				return history.push('/demographic');
			}

			// move user back to select platform if it doesn't have access

			if(!hasDQPAccess(profile)) {
				return history.push('/select_platform')
			}

			// fetch data again, so that free test can be added along with other profile related data.
			fetchDataWithoutProfileUpdate().then(r => console.log('data re-loaded'));
		}

		let finished_tests = 0;
		let net_score = 0;
		const _badges = (profile && profile.badges) ? profile.badges : {}
		for (let [key, _badge] of Object.entries(_badges)) {
			if (_badge.finished) {
				const sc = parseFloat(_badge.obtained_score);
				if (sc > 0) {
					finished_tests ++;
					net_score += sc;
				}
			}
		}
		// if (finished_tests > 0) {
		// 	const score_avg = net_score / finished_tests
		// 	setTotalScore(score_avg);
		// }
		if (profile && profile.dq_avg_score) {
			setTotalScore(profile.dq_avg_score)
		}
		if (profile && profile.last_finished_badge) {
			if (badgesUIC && badgesUIC.length > 0) {
				for (let i=0; i<badgesUIC.length; i++) {
					if (profile.last_finished_badge === badgesUIC[i].badge_code) {
						if (!authUser.is_guest_user) {
							setBadgeSelected(badgesUIC[i].id);
						}
					}
				}
				setProfile({
					...profile,
					last_finished_badge: undefined
				})
			}
		}

		if (profile) {
			if (profile.badges) {
				if (Object.values(profile.badges).length > 0) {
					for (let badge of Object.values(profile.badges)) {
						if (badge.progress === 100) {
							history.push(`/take/quiz/${QUIZ_TIME_MODES.RESUME_MODE}/${badge.resume_ref}`);
						}
					}
				} else {
					// land user into free assessment only if it's not an org-user
					if (profile.is_demographic_updated && process.env.REACT_APP_FREE_TEST_ACTIVE === '1') {
						if (!profile.is_org_provide) {
							history.push(`/take/quiz/${QUIZ_TIME_MODES.START_MODE}/${FREE_TESTS.SimpleDQTest.badge(pageLc).badge_code}`);
						}
					}

				}
			}
		}
	}, [profile] )

	const fetchNationalAndGlobalAverages = async () => {
		if (badgesUIC && badgesUIC.length > 0) {
			let badge;
			for (let i=0; i<badgesUIC.length; i++) {
				if (badgeSelected === badgesUIC[i].id) {
					badge = badgesUIC[i];
				}
			}
			if(profile.country && badge) {
				let averages_data = getAveragesFromLocalStorage(`${badge.org_code}-${badge.badge_code}`);

				if (!averages_data) {
					averages_data = await getNationalAndGlobalAverages(badge.org_code, badge.badge_code, profile.country, badge.default_scoring);
				}

				setGlobalAverage(averages_data.global_average);
				setNationalAverage(averages_data.national_average);
			}
		}
	}

	const fetchData = async () => {
		setLoading(true);

		let api_data;
		try {
			const res = await appStartService();
			api_data = res.data;
			localStorage.setItem(LOCAL_STORAGE_KEYS.HOME_APP_START_API_RESPONSE, JSON.stringify(res.data));
			localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_ASSESS_MODE, JSON.stringify(res.data.assess_mode));

			setProfile({
				...profile,
				...api_data.profile
			});
		} catch (e) {
			setToastShow(true);
			setToastMessage(e.message);
		}

		if (api_data) {
			let area_uic_data = [];

			if (api_data.assess_mode === DQ_ASSESS_APP_MODE.MODULES) {
				// adding a default area, to keep things functional.
				area_uic_data.push(FREE_TESTS.SimpleDQTest.area);
			} else {
				// badges based app-start data received.
				if (authUser.is_guest_user) {
					area_uic_data = []
				} else {
					area_uic_data = api_data.ui_config.areas_uic;
				}

				// FREE TEST AREA ADDITION
				if (process.env.REACT_APP_FREE_TEST_ACTIVE === '1' &&
					(authUser.is_guest_user || profile.org_code.toUpperCase().trim() === 'DQ')) {
					let pre_exist = false;
					const area_code_tobe_add = FREE_TESTS.SimpleDQTest.area.area_code;
					for (let item of area_uic_data) {
						if (item.area_code === area_code_tobe_add) {
							pre_exist = true;
						}
					}
					if (!pre_exist) {
						area_uic_data.push(FREE_TESTS.SimpleDQTest.area);
					}
				}
			}



			const _areas_uic_data_map = getMapFromObjectListByKey(area_uic_data, 'area_code');
			setAreasUIC(area_uic_data);
			if (Object.keys(_areas_uic_data_map).length > 0) {
				setAreasUICMap(_areas_uic_data_map);
			}


			let _badges_uic_data = [];

			if (api_data.assess_mode === DQ_ASSESS_APP_MODE.MODULES) {
				// adjust the modules structure inside badges-view
				let modules = api_data.modules_uic;
				if (modules && modules.length > 0) {
					// set _badges_uic_data
					for (let module of modules ) {
						const item = {
							id: module.id,
							area_code: "DQDC",
							badge_background: module.badge_background,
							badge_code: module.module_code,
							desc: module.module_description,
							duration: module.duration,
							icon_url: module.icon_url,
							is_active: true,
							name: module.module_name,
							org_code: module.org_code,
							primary_color: module.primary_color,
							theme_color: module.theme_color,
							pre_test_mod_code: module.pre_test_mod_code,
							pre_test_mod_name: module.pre_test_mod_name,
							default_scoring: module.default_scoring
						}
						_badges_uic_data.push(item);
					}
				}

			} else {
				const _badges_meta = getMapFromObjectListByKey(api_data.meta_data.badges_meta, 'code');
				// FREE TEST BADGE ADDITION
				if (process.env.REACT_APP_FREE_TEST_ACTIVE === '1' &&
					(authUser.is_guest_user || profile.org_code.toUpperCase().trim() === 'DQ')) {
					_badges_uic_data.push(FREE_TESTS.SimpleDQTest.badge(pageLc));
				}
				for (let a_uic of area_uic_data) {
					for (let uic of api_data.ui_config.badges_uic) {
						if (uic.area_code.trim() === a_uic.area_code.trim()) {
							let item = {
								...uic
							}
							item.name = uic.badge_code;
							if (_badges_meta[item.badge_code]) {
								item.name = _badges_meta[item.badge_code].app_badge_name;
								item.desc = _badges_meta[item.badge_code].app_badge_desc;
							}
							// integrate theme info to the item as well
							item.theme_color = a_uic.theme_color;
							item.badge_background = a_uic.badge_background;
							item.primary_color = a_uic.primary_color;

							_badges_uic_data.push(item);
						}
					}
				}
			}


			if (_badges_uic_data.length > 0) {
				setBadgesUIC(_badges_uic_data);
				const list_items_data = getMapFromObjectListByKey(_badges_uic_data, 'badge_code')
				localStorage.setItem(LOCAL_STORAGE_KEYS.HOME_LIST_ITEMS_DATA, JSON.stringify(list_items_data));
			}

			// auto-open last finish badge
			if (profile && profile.last_finished_badge) {
				for (let i=0; i<_badges_uic_data.length; i++) {
					if (profile.last_finished_badge === _badges_uic_data[i].badge_code) {
						if (!authUser.is_guest_user) {
							setBadgeSelected(_badges_uic_data[i].id);
						}
					}
				}
				setProfile({
					...profile,
					last_finished_badge: undefined
				})
			}
		}

		setLoading(false);
	}

	const fetchDataWithoutProfileUpdate = async () => {
		setLoading(true);

		const cachedAppStart = localStorage.getItem(LOCAL_STORAGE_KEYS.HOME_APP_START_API_RESPONSE);
		const api_data = JSON.parse(cachedAppStart);


		if (api_data) {
			let area_uic_data = [];

			if (api_data.assess_mode === DQ_ASSESS_APP_MODE.MODULES) {
				// adding a default area, to keep things functional.
				area_uic_data.push(FREE_TESTS.SimpleDQTest.area);
			} else {
				if (authUser.is_guest_user) {
					area_uic_data = []
				} else {
					area_uic_data = api_data.ui_config.areas_uic;
				}

				// FREE TEST AREA ADDITION
				if (process.env.REACT_APP_FREE_TEST_ACTIVE === '1' &&
					(authUser.is_guest_user || profile.org_code.toUpperCase().trim() === 'DQ')) {
					let pre_exist = false;
					const area_code_tobe_add = FREE_TESTS.SimpleDQTest.area.area_code;
					for (let item of area_uic_data) {
						if (item.area_code === area_code_tobe_add) {
							pre_exist = true;
						}
					}
					if (!pre_exist) {
						area_uic_data.push(FREE_TESTS.SimpleDQTest.area);
					}
				}
			}

			const _areas_uic_data_map = getMapFromObjectListByKey(area_uic_data, 'area_code');
			setAreasUIC(area_uic_data);
			if (Object.keys(_areas_uic_data_map).length > 0) {
				setAreasUICMap(_areas_uic_data_map);
			}

			let _badges_uic_data = [];

			if (api_data.assess_mode === DQ_ASSESS_APP_MODE.MODULES) {
				// adjust the modules structure inside badges-view
				let modules = api_data.modules_uic;
				if (modules && modules.length > 0) {
					// set _badges_uic_data
					for (let module of modules ) {
						const item = {
							id: module.id,
							area_code: "DQDC",
							badge_background: module.badge_background,
							badge_code: module.module_code,
							desc: module.module_description,
							duration: module.duration,
							icon_url: module.icon_url,
							is_active: true,
							name: module.module_name,
							org_code: module.org_code,
							primary_color: module.primary_color,
							theme_color: module.theme_color,
							pre_test_mod_code: module.pre_test_mod_code,
							pre_test_mod_name: module.pre_test_mod_name,
							default_scoring: module.default_scoring
						}
						_badges_uic_data.push(item);
					}
				}

			} else {
				const _badges_meta = getMapFromObjectListByKey(api_data.meta_data.badges_meta, 'code');

				// FREE TEST BADGE ADDITION
				if (process.env.REACT_APP_FREE_TEST_ACTIVE === '1' &&
					(authUser.is_guest_user || profile.org_code.toUpperCase().trim() === 'DQ')) {
					_badges_uic_data.push(FREE_TESTS.SimpleDQTest.badge(pageLc));
				}

				for (let a_uic of area_uic_data) {
					for (let uic of api_data.ui_config.badges_uic) {
						if (uic.area_code.trim() === a_uic.area_code.trim()) {
							let item = {
								...uic
							}
							item.name = uic.badge_code;
							if (_badges_meta[item.badge_code]) {
								item.name = _badges_meta[item.badge_code].app_badge_name;
								item.desc = _badges_meta[item.badge_code].app_badge_desc;
							}
							// integrate theme info to the item as well
							item.theme_color = a_uic.theme_color;
							item.badge_background = a_uic.badge_background;
							item.primary_color = a_uic.primary_color;
							_badges_uic_data.push(item);
						}
					}
				}
			}

			if (_badges_uic_data.length > 0) {
				setBadgesUIC(_badges_uic_data);
			}
		}

		setLoading(false);
	}

	const reloadProfile = async () => {
		try {
			const res = await getProfileService();
			setProfile({
				...profile,
				...res.data.profile
			});
		} catch (e) {
			setToastShow(true);
			setToastMessage(e.message);
		}
	}

	const getNationalAndGlobalAverages = async (org_code, progL5, country, scoring) => {
		setLoading(true);

		try {
			const result = await globalNationalAveragesScoresService(org_code, progL5, country, scoring);

			if(result.data) {
				console.log('averages: ', result);
				const averagesData = {
					global_average: result.data.global_average,
					national_average: result.data.national_average
				};
				localStorage.setItem(`${org_code}-${progL5}`, JSON.stringify(averagesData));

				return averagesData;
			}
		} catch (e) {
			setToastShow(true);
			setToastMessage(e.message);
			setLoading(false);
		}
		setLoading(false);
	}

	const getAveragesFromLocalStorage = (averagesKey) => {
		const averagesDataString = localStorage.getItem(averagesKey);
		if (averagesDataString) {
			const averagesData = JSON.parse(averagesDataString);
			console.log('Retrieved averages:', averagesData);
			return averagesData;
		} else {
			console.log('No averages data found for key: ', averagesKey);
			return null;
		}
	}

	const itemClickAction = async (id, badge) => {
		if (id === badgeSelected) {
			setBadgeSelected(-1)
		} else {
			setBadgeSelected(id);
		}


		const curr_badge_state = profile.badges[badge.badge_code];
		if (curr_badge_state) {
			if (curr_badge_state.in_progress) {
				history.push(`/take/quiz/${QUIZ_TIME_MODES.RESUME_MODE}/${curr_badge_state.resume_ref}`);
			}
		} else {
			history.push(`/take/quiz/${QUIZ_TIME_MODES.START_MODE}/${badge.badge_code}`);
		}
	}

	return (
		<div className={'home-container'}>
			<Helmet htmlAttributes={{lang: pageLc}} />
			<TopSection
				score={totalScore}
				all_close={badgeSelected === -1}
				history={history}
				is_first_boot={localStorage.getItem(LOCAL_STORAGE_KEYS.FIRST_TIME_BOOT)}
			/>
			<div className={'home-badges-section-full'}>
			{

				badgesUIC && loadItemsAnim && (
					badgesUIC.length > 0 ?
					<AnimateGroup play={loadItemsAnim}>
						{
							badgesUIC.map((badge, index) => {
								return <div
									key={`home-anim0-${index}`}
								>
									<Animate
										duration={getListItemAnimDuration()}
										start={{ opacity: 0 }}
										end={{ opacity: 1 }}
										sequenceIndex={index}
									>
										<ListItem
											badge={badge}
											profile={profile}
											selectItem={itemClickAction}
										/>
									</Animate>

									<ListItemDetail
										history={history}
										badge={badge}
										selected={badgeSelected}
										profile={profile}
										global_average={globalAverage}
										national_average={nationalAverage}
									/>
								</div>
							})
						}
					</AnimateGroup> : 
					<h3 className={'home-info'}
						>{

						loading ? GET_APP_LOCALIZE_TEXT(pageLc, 'uo__loading')
							: GET_APP_LOCALIZE_TEXT(pageLc, 'uo__no_assessment_available_chk_later')

						}</h3>
				)
			}
			</div>
			{/* <Footer /> */}
		</div>
	);
}

export default withRouter(Home);
