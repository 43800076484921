import raz_assess_start from "../../../assets/raz/RazPoses_0001_lets_start.png";
import raz_assess_section from "../../../assets/raz/RazPoses_0000_sections.png";
import raz_assess_game from "../../../assets/raz/RazPoses_0002_lets_play.png";
import raz_quiz_time from "../../../assets/raz/RazPoses_0006_quiz_time.png";
import NUSWheel from "../../../assets/images/NUS_Wheel.png"
import React, {useEffect} from "react";
import BorderButton , {btnSize} from "../../../components/UI/BorderButton/BorderButton.ui";
import {getActiveThemeColor} from "../../../utils/utils";
import {useGlobalState} from "../../../context/global_state";
import Footer from "../../../components/Footer/plugable.footer";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import {DQ_THEMES, LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import styled from "styled-components";

const GuidePoster = (props) => {

    const { locale, nextAction, title, description, raz_type } = props;

    const { profile } = useGlobalState();

    useEffect(() => {
        const skip_the_poster = localStorage.getItem(LOCAL_STORAGE_KEYS.SKIP_INTRO_POSTER_OF_QUIZ);
        if (skip_the_poster === 'true') {
            if (raz_type === 'raz_assess_start') {
                setTimeout(
                    () => {
                        localStorage.removeItem(LOCAL_STORAGE_KEYS.SKIP_INTRO_POSTER_OF_QUIZ);
                    }, 50
                );
                setTimeout(
                    () => {
                        nextAction();
                    }, 100
                );
            }
        }
    }, []);

    const getRazSrcByType = (r_type) => {
        switch (r_type) {
            case 'raz_assess_start':
                return raz_assess_start;
            case 'raz_assess_section':
                return raz_assess_section;
            case 'raz_assess_game':
                return raz_assess_game;
            case 'raz_quiz_time':
                return raz_quiz_time;
            default:
                return raz_assess_start;
        }
    }
    // const Theme = createTheme(settings.NUS_Theme);
    const Logo = styled.img`
        content: url(${props => {
            switch (props.theme.name) {
                case DQ_THEMES.DEFAULT:
                    return getRazSrcByType(raz_type, 'DQTheme');
                case DQ_THEMES.NUS_THEME:
                    return NUSWheel; 
                default:
                    return '';
            }
        }});
    `;

    const HeadingContainer = styled.div`
        font-family: ${(props) => {
            switch (props.theme.name) {
                case DQ_THEMES.DEFAULT:
                    return ` "${props.theme.fonts[2]}", "${props.theme.fonts[0]}", "${props.theme.fonts[1]}"` ;
                case DQ_THEMES.NUS_THEME:
                    return `"${props.theme.fonts[3]}"`;
                default:
                    return '';
            }
        }};
        font-weight: ${(props) => {
            switch (props.theme.name) {
                case DQ_THEMES.DEFAULT:
                    return 500;
                case DQ_THEMES.NUS_THEME:
                    return 900;
                default:
                    return 600;
            }
        }};
    `

    const BodyContainer = styled.div`
        font-family: ${(props) => {
            switch (props.theme.name) {
                case DQ_THEMES.DEFAULT:
                    return `"${props.theme.fonts[0]}", "${props.theme.fonts[1]}"` ;
                case DQ_THEMES.NUS_THEME:
                    return `"${props.theme.fonts[4]}"`;
                default:
                    return '';
            }
        }};
        font-weight: 600;
    `

    return (
        <>
            <div className={'qt-guide-container'}>
                <HeadingContainer
                    style={{color: getActiveThemeColor(profile)}}
                    className={'qt-guide-heading'} >
                    <span>{title}</span>
                </HeadingContainer>
                <div className={'qt-guide-img-container'}>
                    <Logo className={'qt-guide-img'} ></Logo>
                    {/*<img className={'qt-guide-img'} src={getRazSrcByType(raz_type)} />*/}
                </div>
                <BodyContainer className={'qt-guide-body'}>
                    <span>{description && description.replace('This score', 'This test')}</span>
                    <div
                        className={'qt-cont-btn-container'}>
                        <BorderButton
                            size={btnSize.md}
                            title={GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__next')}
                            action={nextAction}
                            themeColor={getActiveThemeColor(profile)}
                        />
                    </div>
                </BodyContainer>

            </div>

        </>
    )
};

export default GuidePoster;