import React, {useEffect, useState} from 'react';
import Button, {btnSize} from "../../components/UI/Button/Button.ui";

import { withRouter } from "react-router";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const NotFound = (props) => {

    const [loading, setLoading] = useState(true);

    const { history } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    useEffect( () => {
        setTimeout( () => {
            setLoading(false);
        }, 1200);
    }, [])

    return (
        <>
            {
                loading ? <LoaderWithBackDrop loading={loading}/> : <div className={'not-found-main-container'}>
                    <div className={'not-found-heading'}>404</div>
                    <div className={'not-found-heading'}>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__page_not_found')}</div>
                    <div className={'not-found-btn-container'}>
                        <Button
                            action={() => history.push(`/${pageLc}`)}
                            title={GET_APP_LOCALIZE_TEXT(pageLc, 'uo__goto_home')}
                            size={btnSize.lg}
                        />
                    </div>

                </div>
            }
        </>
    );
}

export default withRouter(NotFound);
