import React, {useEffect, useState} from 'react';
import '../../../assets/css/ui.comp.css';

export const btnSize = {
    lg: 1,
    md: 2,
    sm: 3
}

const Button = (props) => {

    const { size, action, title, themeColor } = props;

    const [btnClasses, setBtnClasses] = useState(`${'simple-btn'} ${'simple-btn-md'}`);

    const getThemeColor = () => {
        return themeColor ? themeColor : '#3399FF'
    }

    useEffect( () => {

        if (size) {
            switch (size) {
                case 1:
                    setBtnClasses(`${'simple-btn'} ${'simple-btn-lg'}`);
                    break;
                case 2:
                    setBtnClasses(`${'simple-btn'} ${'simple-btn-md'}`);
                    break;
                case 3:
                    setBtnClasses(`${'simple-btn'} ${'simple-btn-sm'}`);
                    break;
                default:
                    // do nothing
                    break;
            }
        }

    }, [] )

    return (
        <button
            className={btnClasses}
            style={{
                backgroundColor: getThemeColor(),
                cursor: "pointer"
            }}
            onClick={action}
        >
            {title}
        </button>
    );

};

export default Button;