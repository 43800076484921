import React, {useEffect} from 'react';
import Button, {btnSize} from "../../../components/UI/Button/Button.ui";
import {loginUser, logoutUser} from "../../../services/auth.service";
import {useAuth} from "../../../context/auth";
import {useGlobalState} from "../../../context/global_state";
import { withRouter } from "react-router";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {loadNewGuestUser} from "../auth_common.lib";

import dqIcon from '../../../assets/icons/dq-icons/dq-logo.png';
import {DQ_THEMES, LOCAL_STORAGE_KEYS, LOCALES_ENABLED} from "../../../utils/constants";
import {sha256} from "js-sha256";
import {useLocation} from "react-router-dom";

const Logout = (props) => {

    const { history } = props;

    const location = useLocation();

    const { setAuthToken, setAuthUser } = useAuth();
    const { setToastShow, setToastMessage, setProfile, settings, setSettings } = useGlobalState();

    useEffect( () => {
        LogoutAction().then(d => console.log('user logged out!', d));
    }, [] )

    const LogoutAction = async () => {

        let redirect_path = '/home';
        // const app_key = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_APP_KEY);
        // if (app_key) {
        //     redirect_path = redirect_path + '?app_key=' + app_key
        // }

        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            setProfile(null);
            setSettings({
                ...settings,
                theme: DQ_THEMES.DEFAULT
            });
            localStorage.clear();

            const locale = location.pathname.split('/')[1];
            if (LOCALES_ENABLED.includes(locale)) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, locale);
            }
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
        }

        // redirect to landing page.
        history.push(redirect_path, true);
    }


    return (
        <div className={'logout-container'}>

            <p>logout...</p>

        </div>
    );

}

export default withRouter(Logout);