import React, {useState, useEffect} from 'react';
import {initPassReset, loginUser, loginUserViaToken, logoutUser} from "../../../services/auth.service";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import { withRouter } from "react-router";
import Helmet from "react-helmet";
import {useLocation} from "react-router-dom";
import {DQ_THEMES, LOCAL_STORAGE_KEYS, LOCALES_ENABLED} from "../../../utils/constants";
import {useAuth} from "../../../context/auth";
import {useGlobalState} from "../../../context/global_state";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import dqIcon from "../../../assets/icons/dq-icons/dq-logo.png";
import ReactHtmlParser from "react-html-parser";

const InitPasswordReset = (props) => {

    const { history, location } = props;

    const page_location = useLocation();
    const pageLc = page_location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [formUsername, setFormUsername] = useState('');
    const [message, setMessage] = useState(undefined);

    const { setAuthToken, setAuthUser, authToken, authUser } = useAuth();
    const { setToastShow, setToastMessage, setProfile, settings, setSettings } = useGlobalState();

    useEffect( () => {
        if (authToken && authUser) {
            LogoutActionWithoutRedirect().then(r=>console.log('logged out of current account automatically'));
        }
    }, [] );

    const LogoutActionWithoutRedirect = async () => {

        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            setProfile(null);
            setSettings({
                ...settings,
                theme: DQ_THEMES.DEFAULT
            });
            localStorage.clear();

            const locale = location.pathname.split('/')[1];
            if (LOCALES_ENABLED.includes(locale)) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, locale);
            }
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
        }
    }
    const onChangeUsername = (e) => {
        setFormUsername(e.target.value);
    }

    const sendLinkAction = async () => {
        //TODO Prevent user from spamming send link
        try {
            setLoading(true);
            const result = await initPassReset(formUsername);
            if(result.data.request_initiated){
                setMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__send_link_success'));
            }
            else {
                setToastShow(true);
                setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__send_link_failed'));
            }
            setLoading(false);

        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
        }
    }

    return (
        <div className={'pass-reset-container'}>
            <LoaderWithBackDrop loading={loading} />
            {
                message ?
                    <div className={'pass-reset-result'}>{message}</div>
                :
                    <>
                        <div className='pass-reset-title'>
                            <p>
                                {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__reset_pass_guide'))}
                            </p>
                        </div>
                        <div className={'pass-reset-content'}>
                            <form onSubmit={e => e.preventDefault()}>
                                <div className={'pass-reset-input'}>
                                    <label>{`${GET_APP_LOCALIZE_TEXT(pageLc, 'uo__username')}`}</label>
                                    <input
                                        type='text'
                                        name={'username'}
                                        id={'username'}
                                        value={formUsername}
                                        onChange={onChangeUsername}
                                    />
                                </div>
                                <div className={'pass-reset-submit'}>
                                    <button onClick={sendLinkAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__send_link')}</button>
                                </div>
                            </form>
                        </div>
                    </>

            }
        </div>
    );
}

export default withRouter(InitPasswordReset);