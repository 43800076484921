import React, {useEffect, useState} from 'react';
import DQLogoImg from '../../../assets/icons/dq-icons/dq-logo-orange.png';
import {DQ_THEMES, LOCAL_STORAGE_KEYS, LOCALES_ENABLED} from "../../../utils/constants";
import {useAuth} from "../../../context/auth";
import {logoutUser} from "../../../services/auth.service";
import {useGlobalState} from "../../../context/global_state";

import { withRouter } from "react-router";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import {hasDQWAccess} from "../../../utils/utils";
import {fetchDQWRedirectLink} from "../../../services/app_start.service";

const SideBar = (props) => {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {show, closeBar, history} = props;
    const { setAuthToken, setAuthUser, authUser } = useAuth();
    const { setToastShow, setToastMessage, profile, setProfile } = useGlobalState();

    const { settings, setSettings } = useGlobalState();


    let drawerClasses = `${'sidebar'} ${'sidebar_close'}`
    if (show) {
        drawerClasses = `${'sidebar'} ${'sidebar_open'}`
    }

    const [registerRequire, setRegisterRequire] = useState(true);

    useEffect( () => {
        const user_age = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_USER_AGE);

        if (user_age && user_age < 16) {
            setRegisterRequire(false);
        }
    }, [] )

    const available_profiles = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AVAILABLE_PROFILES));

    const clickAction = (path) => {
        history.push(path);
        closeBar();
    }

    const DQWRedirectAction = async () => {
        try {
            const result = await fetchDQWRedirectLink(`${process.env.REACT_APP_DQ_ASSESS_BASE_URL}/logout`);
            const {redirect_link} = result.data;
            window.open(redirect_link, "_self");
        }
        catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
        }
    }

    const LogoutAction = async () => {

        let redirect_path = '/login';
        const app_key = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_APP_KEY);
        if (app_key) {
            redirect_path = redirect_path + '?app_key=' + app_key
        }

        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            setProfile(null);
            setSettings({
                ...settings,
                theme: DQ_THEMES.DEFAULT
            });
            localStorage.clear();

            if (LOCALES_ENABLED.includes(pageLc)) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, pageLc);
            }
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
        }

        // redirect to landing page.
        history.push(redirect_path, true);
        closeBar();
    }

    const getHelloString = () => {
        if (authUser.is_guest_user) {
            return GET_APP_LOCALIZE_TEXT(pageLc, 'menu__guest_text')
        }
        return authUser.username
    }

    const handleToggle = () => {
        setSettings(prevSettings => {
            const newTheme = prevSettings.theme === DQ_THEMES.DEFAULT ? DQ_THEMES.NUS_THEME : DQ_THEMES.DEFAULT;
            console.log(`Active theme: ${newTheme === DQ_THEMES.NUS_THEME ? DQ_THEMES.NUS_THEME : DQ_THEMES.DEFAULT}`);
            return {
                ...prevSettings,
                theme: newTheme
            };
        });
    };

    return (
        <div className={drawerClasses}>
            <p className={'usr'}>{GET_APP_LOCALIZE_TEXT(pageLc, 'menu__hello_text')}, {getHelloString()}!</p>
            <hr />
            <ul>
                <li onClick={() => {
                    clickAction('/home')
                }}>{GET_APP_LOCALIZE_TEXT(pageLc, 'menu__home')}
                </li>
                {
                    available_profiles && available_profiles.length > 1 &&
                    <li onClick={() => {
                        clickAction('/switch_profile')
                    }}>{GET_APP_LOCALIZE_TEXT(pageLc, 'menu__switch_profile')}
                    </li>
                }
                {
                    profile && hasDQWAccess(profile) &&
                    <li onClick={DQWRedirectAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'menu__goto_dqw')}
                    </li>
                }
                {/*{*/}
                {/*    process.env.REACT_APP_DEVSID_BUILD !== '1' && <li onClick={() => {*/}
                {/*        clickAction('/stats')*/}
                {/*    }}>View Stats*/}
                {/*    </li>*/}
                {/*}*/}

                {
                    authUser.is_guest_user && registerRequire && <li onClick={() => {
                        clickAction('/persist_guest')
                    }}>{GET_APP_LOCALIZE_TEXT(pageLc, 'menu__register')}
                    </li>
                }
                {/* <li onClick={() => {
                    clickAction('/settings')
                }}>{GET_APP_LOCALIZE_TEXT(pageLc, 'menu__settings')}
                </li> */}
                <li onClick={LogoutAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'menu__logout')}
                </li>

                {/*<li>*/}
                {/*    <div className={`switch ${settings.theme === DQ_THEMES.NUS_THEME ? 'checked' : ''}`}*/}
                {/*         style={{float: 'left'}}>*/}
                {/*        <input type="checkbox" id="themeToggle" checked={settings.theme === DQ_THEMES.NUS_THEME}*/}
                {/*               onChange={handleToggle}/>*/}
                {/*        <label className="slider round" htmlFor="themeToggle"></label>*/}
                {/*    </div>*/}
                {/*</li>*/}
            </ul>
        </div>
    );
};

export default withRouter(SideBar);