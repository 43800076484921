import React, {useEffect, useState} from 'react';
import Helmet from "react-helmet";
// import Button, {btnSize} from "../../components/UI/Button/Button.ui";
import BorderButton , {btnSize} from "../../components/UI/BorderButton/BorderButton.ui";

import { withRouter } from "react-router";
import {useLocation, useParams} from "react-router-dom";
import {finishAssessService} from "../../services/home.service";
import {useGlobalState} from "../../context/global_state";

import rubricImg from '../../assets/images/score-rubric.png';
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import Footer from "../../components/Footer/plugable.footer";

import {LOCAL_STORAGE_KEYS, DIGITAL_CITIZEN_TYPES, LOCALES_ENABLED, DQ_THEMES, NUS_TYPE} from "../../utils/constants";
import {getActiveThemeColor, getMapFromObjectListByKey, getPercentileStr, getBadgeResultStr, getBadgeResultColorClass} from "../../utils/utils";
import {logoutUser} from "../../services/auth.service";
import {useAuth} from "../../context/auth";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import NUSAnalytics from "./NUSAnalytics.comp";

//default_scoring_value is undefined then it is true to show the standard and alternative RUBRIC otherwise
let is_standard_rubric = true;

const QuizResult = (props) => {

    const { identifier } = useParams();
    const { history } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);

    const [score, setScore] = useState(-1);
    const [percentile, setPercentile] = useState('');
    const [resultText, setResultText] = useState('');
    const [resultColorClass, setResultColorClass] = useState('');

    const [subScreen, setSubScreen] = useState(1);
    
    const [badgeCode, setBadgeCode] = useState('');
    const [dcType, setDcType] = useState(false);
    const [totalScore, setTotalScore] = useState(-1);
    const [title, setTitle] = useState('');
    const [level6Scores, setLevel6Scores] = useState([]);

    const { setAuthToken, setAuthUser } = useAuth();
    const { setToastShow, setToastMessage, setProfile, profile, setSettings, settings } = useGlobalState();

    const btnAction = async () => {
        // if user came via connection-params, then it needs to be logout now, and go-to the url provided in connect-params;
        const conn_redirect_url = localStorage.getItem(LOCAL_STORAGE_KEYS.CONN_REDIRECT_URL)
        if (conn_redirect_url && conn_redirect_url !== '') {
            await logoutAction();
            window.location.replace(conn_redirect_url);
        }

        if (dcType && subScreen === 1) {
            setSubScreen(2);
        } else {

            let redirect_path = '/home';
            let host_redirect = false;
            let land_on_index = false;


            // if (profile.is_org_provide) {
            //     redirect_path = '/home';
            // }

            const user_age = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_USER_AGE);

            // Temp fix to redirect DQ users and users below age 16 to index page
            // TODO: This needs to be changed when more assessments are added for DQ
            //if (user_age && user_age < 16  && !profile.is_org_provide) {
            if (user_age && user_age < 16  || !profile.is_org_provide) {
                redirect_path = '/';
                land_on_index = true;
            }

            const collab_value_raw = localStorage.getItem(LOCAL_STORAGE_KEYS.COLLAB_STRING);
            if (collab_value_raw) {
                const collab_value = atob(collab_value_raw);
                const collab_obj = JSON.parse(collab_value);
                if (collab_obj.url && collab_obj.url !== '') {
                    redirect_path = collab_obj.url
                    host_redirect = true;
                }
            }

            if (host_redirect) {
                await logoutAction();
                window.location.replace(redirect_path);
            } else {
                if (land_on_index) {
                    await logoutAction();
                }
                history.push(redirect_path, true);
            }
        }

    }

    const logoutAction = async () => {
        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            setProfile(null);
            setSettings({
                ...settings,
                theme: DQ_THEMES.DEFAULT
            });
            localStorage.clear();

            if (LOCALES_ENABLED.includes(pageLc)) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, pageLc);
            }
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
        }
    }

    useEffect( () => {

        if (profile && profile.org_code !== "" && !profile.is_demographic_updated) {
            localStorage.setItem(LOCAL_STORAGE_KEYS.POST_DEMOGRAPHIC_REDIRECT_LINK, window.location.pathname);
            return history.push('/demographic');
        }

        finishAssess().then(d=>console.log('fetched assess score'));

    }, []);

    useEffect( () => {
        if (badgeCode !== '') {
            const list_items_map = localStorage.getItem(LOCAL_STORAGE_KEYS.HOME_LIST_ITEMS_DATA);
            if (list_items_map && list_items_map[badgeCode]) {
                const theme_color = list_items_map[badgeCode];
                if (theme_color) {
                    setProfile({
                        ...profile,
                        active_theme_color: theme_color
                    })
                }
            }
        }
    }, [badgeCode] )

    const finishAssess = async () => {
        try {
            setLoading(true);

            const res = await finishAssessService(identifier);
            setProfile({
                ...profile,
                ...res.data.profile,
            });

            // if default_scoring_value = raw (70-100) Rubric else (85-115) Standard Rubric
                if(res.data.default_scoring_value === "raw"){
                is_standard_rubric=false;
            }
            // console.log("Default Score Value & Rubric Flag for Rubric:: ",
            //     res.data.default_scoring_value," :: ",is_standard_rubric, typeof (res.data.default_scoring_value));

            if (res.data.error_from_flask) {
                console.log('flask::-error-logs:::-- message-> ', res.data.flask_error_message)
                console.log('flask::-error-logs:::-- input-data-> ', res.data.flask_function_input)
            }

            let score_to_set = res.data.score
            if (!score_to_set) {
                score_to_set = 0.01;
            }
            setScore(score_to_set);

            setPercentile(getPercentileStr(res.data.score, res.data.percentile, pageLc));
            setResultText(getBadgeResultStr(res.data.score, false, is_standard_rubric, pageLc));
            setResultColorClass(getBadgeResultColorClass(res.data.score, is_standard_rubric));
            setBadgeCode(res.data.badge_code)
            setDcType(res.data.dc_type);
            setTotalScore(res.data.total_score);
            setTitle(res.data.title);
            setLevel6Scores(res.data.level_6_scores);
            
            setLoading(false);

        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
            history.push('/home?reload_profile=1')
        }
    }

    const ScoreSpinner = styled.div`
        background: url(${props => props.theme.scoreSpinner}) no-repeat center center;
        background-size: contain ;
        font-family: ${(props) => {
            switch (props.theme.name) {
                case DQ_THEMES.DEFAULT:
                    return `"${props.theme.fonts[2]}", "${props.theme.fonts[0]}", "${props.theme.fonts[1]}"` ;
                case DQ_THEMES.NUS_THEME:
                    return `"${props.theme.fonts[3]}"`;
                default:
                    return '';
            }
        }};

    `;
    const HeadingContainer = styled.div`
        font-family: ${(props) => {
            switch (props.theme.name) {
                case DQ_THEMES.DEFAULT:
                    return `"${props.theme.fonts[2]}", "${props.theme.fonts[0]}", "${props.theme.fonts[1]}"` ;
                case DQ_THEMES.NUS_THEME:
                    return `"${props.theme.fonts[3]}"`;
                default:
                    return '';
            }
        }};
        font-weight: bold;
       
    `

    const BodyContainer = styled.div`
        font-family: ${(props) => {
            switch (props.theme.name) {
                case DQ_THEMES.DEFAULT:
                    return `"${props.theme.fonts[0]}", "${props.theme.fonts[1]}"` ;
                case DQ_THEMES.NUS_THEME:
                    return `"${props.theme.fonts[4]}"`;
                default:
                    return '';
            }
        }};
        font-weight: 400;
    `

    return (
        <div className={'quiz-result-container'}>
            <Helmet htmlAttributes={{lang: pageLc}} />

            { settings.theme === DQ_THEMES.NUS_THEME ? 
                    <NUSAnalytics 
                                loading={loading}
                                score={score} 
                                percentile={percentile} 
                                totalScore={totalScore} 
                                title={title} 
                                level6Scores={level6Scores} 
                                badgeCode={badgeCode} 
                                logoutAction={logoutAction} />
                : 
            <>
            <LoaderWithBackDrop loading={loading} />
            {
                subScreen === 1 && <div className={'quiz-result-content'}>
                    {
                            score && percentile && resultText && resultColorClass &&
                                <>
                                    <HeadingContainer><h1 className={resultColorClass}>{resultText}</h1></HeadingContainer>
                                <BodyContainer>
                                    <div className={'quiz-score-container'}>
                                        <div className={'quiz-score-content'}>
                                            <ScoreSpinner className={'score-spinner'} >
                                                {score && (
                                                    <>
                                                        <span>{parseFloat(score).toFixed(2).split('.')[0]}</span>
                                                        <span className={'score-label-pt'}>.{parseFloat(score).toFixed(2).split('.')[1]}</span>
                                                    </>
                                                )}
                                            </ScoreSpinner>
                                            <div className={'score-desc'}>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__your_score_span')}<span className={resultColorClass}>{percentile}</span>.</div>
                                        </div>
                                        <div className={'score-rubric'}>
                                            {/* <img className={'rubric-desktop'} src={`/images/${pageLc}/mydq-rubric-desktop.png`} alt="" />
                                            <img className={'rubric-mobile'} src={`/images/${pageLc}/mydq-rubric-mobile.png`} alt="" /> */}
                                            { !is_standard_rubric &&
                                                <div id="raw" className='rubric-container raw-rubric' >
                                                    <div className='rubric-content'>
                                                    <div className="Rubric-row" >
                                                        <div className="rubric-scores rubric-excellent" >
                                                            <span>100</span>
                                                        </div>
                                                        <div className='rubric-row rubric-excellent'>
                                                            <span>{ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__high'))}</span>
                                                            <div className='rubric-row-content'>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__high_score_description')}</div>
                                                        </div>
                                                    </div>

                                                    <div className="Rubric-row">
                                                        <div className="rubric-scores rubric-above-avg">
                                                            <span>70</span>
                                                        </div>
                                                        <div className='rubric-row rubric-above-avg'>
                                                            <span>{ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__medium'))}</span>
                                                            <div className='rubric-row-content'>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__medium_score_description')}</div>
                                                        </div>
                                                    </div>

                                                    <div className="Rubric-row">
                                                        <div className="rubric-scores rubric-req-att">
                                                            <span>30</span>
                                                        </div>
                                                        <div className='rubric-row rubric-req-att'>
                                                            <span>{ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__low'))}</span>
                                                            <div className='rubric-row-content'>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__low_score_description')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }

                                            { is_standard_rubric &&
                                            <div id="standard" className='rubric-container' >
                                                <div className='rubric-content'>
                                                    <div className="Rubric-row" >
                                                        <div className="rubric-scores rubric-excellent" >
                                                            <span>115</span>
                                                        </div>
                                                        <div className='rubric-row rubric-excellent'>
                                                            <span>{ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__excellent_br'))}</span>
                                                            <div className='rubric-row-content'>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__you_are_ready_to_use_tech_rubirc')}</div>
                                                        </div>
                                                    </div>

                                                    <div className="Rubric-row">
                                                        <div className="rubric-scores rubric-above-avg">
                                                            <span>100</span>
                                                        </div>
                                                        <div className='rubric-row rubric-above-avg'>
                                                            <span>{ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__above_avg_br'))}</span>
                                                            <div className='rubric-row-content'>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__your_score_is_above_average_rubric')}</div>
                                                        </div>
                                                    </div>
                                                    <div className="Rubric-row ">
                                                        <div className="rubric-scores rubric-req-att">
                                                            <span>85</span>
                                                        </div>
                                                        <div className='rubric-row rubric-below-avg'>
                                                            <span>{ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__bellow_avg_br'))}</span>
                                                            <div className='rubric-row-content'>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__your_score_is_less_than_average_rubirc')}</div>
                                                        </div>
                                                    </div>
                                                    <div className="Rubric-row">
                                                        <div className="rubric-scores rubric-req-att">
                                                            <span></span>
                                                        </div>
                                                        <div className='rubric-row rubric-req-att'>
                                                            <span>{ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__require_attention_br'))}</span>
                                                            <div className='rubric-row-content'>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__you_may_potentially_be_exposed_rubirc')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {
                                                badgeCode === "FreeDCT" ?
                                                    <div className={'dq-disclaimer dq-disc-desktop'}>
                                                        {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__dct_discalimer_html_text'))}
                                                    </div>
                                                : null

                                            }
                                        </div>
                                    </div>
                                    <div className={'dq-result-button-container'}>
                                        {/*<div className={'dq-more-info-link'}>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__click_message_dct_type')}</div>*/}
                                        <BorderButton
                                            size={btnSize.sm}
                                            title={GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__next')}
                                            action={btnAction}
                                            themeColor={getActiveThemeColor(profile)}
                                        />
                                    </div>
                                    {
                                        badgeCode === "FreeDCT" ?
                                            <div className={'dq-disclaimer dq-disc-mobile'}>
                                                {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__dct_discalimer_html_text'))}
                                            </div>
                                            : null
                                    }
                                </BodyContainer>
                                </>
                        }         
                </div>
                }
            </>
            }
            
            <div>
            {
                subScreen === 2 && (() => {
                    switch (settings.theme) {
                        case DQ_THEMES.NUS_THEME:
                            return (
                                <NUSAnalytics score={score}/>
                            );
                        default:
                            return (
                   <div style={{marginTop: '2%'}} className={'qt-guide-container'}>
                    <div  style={{color: getActiveThemeColor(profile)}} className={'qt-guide-heading'} >
                        <span>{DIGITAL_CITIZEN_TYPES(pageLc)[dcType].title}</span>
                    </div>
                    <div className={'qt-guide-img-container dc-type-container'}>
                        <div className={`dq-dc-type ${dcType}`} />
                    </div>

                                {settings.theme === DQ_THEMES.NUS_THEME ? (
                                    <div className={'qt-guide-body dc-type-desc'}>
                                        <ul>
                                            <li>Tech Explorer description</li>
                                        </ul>
                                    </div>): (
                    <div className={'qt-guide-body dc-type-desc'}>
                        <ul>
                            { DIGITAL_CITIZEN_TYPES(pageLc)[dcType].desc_list.map( (desc, index) => <li>{desc}</li>) }
                        </ul>
                    </div>
                                )}

                    <div className={'dq-result-button-container'}>
                        <div className={'dq-more-info-link'}>
                            {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__learn_more_html_text'))}
                        </div>
                        <BorderButton
                            size={btnSize.sm}
                            title={GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__done')}
                            action={btnAction}
                            themeColor={getActiveThemeColor(profile)}
                        />
                    </div>
                    <div className={'dq-disclaimer dq-disc-mobile'}>
                        {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__dct_discalimer_html_text'))}
                    </div>
                </div>
                        );
                    }
                })()
            }
            <Footer />
         </div>
        </div>
    );
};

export default withRouter(QuizResult);
