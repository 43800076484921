import React, { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import StripeCheckout from "./StripeCheckout.comp";
import {
    buyBadgeStripeService,
    buyModuleStripeService,
    buyPackageStripeService,
    getStripeConfigService,
    updateStripeIntentService
} from "../../../services/payment.service";
import {PRODUCT_TYPE} from "../../../utils/constants";
import {useGlobalState} from "../../../context/global_state";
import {getProfileService} from "../../../services/home.service";
import {useHistory, useLocation} from "react-router-dom";

function StripePayment(props) {

    const {product, onCreatePayment, setLoading} = props;

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [paymentIntent, setPaymentIntent] = useState("");

    const {setToastMessage, setToastShow, profile, setProfile} = useGlobalState();

    const location = useLocation();
    const history = useHistory();

    async function loadConfig() {
        const result = await getStripeConfigService();
        if(result.data) {
            const {publishableKey} = result.data;
            setStripePromise(loadStripe(publishableKey));
        }
        return true;
    }

    function getIntentService(product_type) {
        switch(product_type) {
            case PRODUCT_TYPE.PACKAGE:
                return buyPackageStripeService;
            case PRODUCT_TYPE.MODULE:
                return buyModuleStripeService;
            case PRODUCT_TYPE.BADGE:
                return buyBadgeStripeService;
        }
    }

    async function createIntent() {
        const intentService = getIntentService(product.type);
        const result = await intentService(profile._id, product.code);
        if(result.data) {
            const {paymentID, paymentIntent, clientSecret} = result.data;
            onCreatePayment(paymentID);
            setClientSecret(clientSecret);
            setPaymentIntent(paymentIntent);
        }
        return true;
    }

    const reloadProfile = async () => {
        let updated_profile = profile;
        try {
            const res = await getProfileService();
            updated_profile = {
                ...profile,
                ...res.data.profile
            }
            setProfile(updated_profile);
        } catch (e) {
            console.error(e)
            setToastMessage('Unable to reload profile');
            setToastShow();
        }
    }

    function toastOnPaymentStatus(status) {
        switch (status) {
            case "succeeded":
                setToastMessage("Payment successful")
                break;
            case "processing":
                setToastMessage("Payment is in processing")
                break;
            case "requires_payment_method":
                setToastMessage("Payment was not successful, try again")
                break;
            default:
                setToastMessage("Something went wrong")
                break;
        }
        setToastShow(true);
    }

    function removeUrlParameter(paramKey) {
        const url = window.location.href
        const r = new URL(url)
        r.searchParams.delete(paramKey)
        const newUrl = r.href
        window.history.pushState({ path: newUrl }, '', newUrl)
    }

    async function updateStripePayment(paymentIntent) {
        const result = await updateStripeIntentService(paymentIntent);
        const {updated_profile} = result.data;
        if(updated_profile) {
            setProfile(updated_profile)
        }
        return true;
    }

    function readRedirectStatus() {
        const redirectStatus = new URLSearchParams(location.search).get("redirect_status");
        if(redirectStatus) {
            toastOnPaymentStatus(redirectStatus);
            return true;
        }
        return false;
    }

    function readRedirectTo() {
        const redirect_to = new URLSearchParams(location.search).get("redirect_to");
        if(redirect_to) {
            history.push(redirect_to);
            return true;
        }
        return false;
    }

    function readPaymentIntent() {
        readRedirectStatus();
        const paymentIntent = new URLSearchParams(location.search).get("payment_intent");
        if(paymentIntent) {
            updateStripePayment(paymentIntent)
                .then(() => console.log(`stripe payment updated`))
                .catch((e) => console.error(`stripe payment update failed, ${e}`))
                .finally(() => readRedirectTo())
            removeUrlParameter('payment_intent');
            removeUrlParameter('payment_intent_client_secret')
            return true;
        }
        // readRedirectTo()
        return false;
    }

    useEffect(() => {
        if(profile && profile._id) {
            if(!product) {
                readPaymentIntent();
            } else {
                loadConfig().then(() => console.log(`stripe config loaded`)).catch((e) => console.error(`stripe config failed, ${e}`));
                createIntent().then(() => console.log(`payment intent created`)).catch((e) => console.error(`payment intent creation failed, ${e}`));
            }
        }
        else {
            reloadProfile().then(_ => console.log('profile reloaded successfully'));
        }
    }, [profile]);

    return (
        <>
            {clientSecret && paymentIntent && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <StripeCheckout paymentIntent={paymentIntent} {...props}/>
                </Elements>
            )}
        </>
    );
}

export default StripePayment;