import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components";
import NUSResultsLoader from "../../components/LoaderWithBackDrop/NUSResultsLoader.comp";
import {DIGITAL_WELLBEING_DOMAINS, DIGITAL_WELLBEING_PROFILES, NUS_REDIRECT_URL, QUIZ_TIME_MODES} from "../../utils/constants";
import {getMapFromObjectListByKey} from "../../utils/utils";


const NUSAnalytics = (props) => {

    const { loading, score, percentile, totalScore, title, level6Scores, badgeCode, logoutAction } = props;

    const { history } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];
    const [openPopup, setOpenPopup] = useState('');
    const ScoreSpinner = styled.div`
        background: url(${props => props.theme.scoreSpinner}) no-repeat center center;
        background-size: contain;
    `;

    const btnAction = async () => {
        await logoutAction();
        window.location.replace(NUS_REDIRECT_URL);
    }

    const retakeBtnAction = () => {
        window.location = `/take/quiz/${QUIZ_TIME_MODES.START_MODE}/${badgeCode}`;
    }

    const togglePopup = (popupName) => {
        setOpenPopup(openPopup === popupName ? '' : popupName);
    };

    return (
        <>
            <NUSResultsLoader loading={loading} />
            <section className={'digital-wellbeing-container'}>
                <div className={'digital-wellbeing-score'} >
                    <div className={'digital-wellbeing-score-header'}>
                        <div className="header-column">
                            <h2>Your Digital <br/> Wellbeing Score</h2>
                            <ScoreSpinner className={'digital-wellbeing-score-spinner'}>
                                {score >= 0 && totalScore >= 0 && (
                                    <>
                                        <span>{parseFloat(score).toFixed(2).split('.')[0]}</span>
                                        <span
                                            className={'score-label-pt'}>/{totalScore}</span>
                                    </>
                                )}
                            </ScoreSpinner>
                        </div>
                        <div className="divider"></div>
                        
                    </div>
                    <div className={'digital-wellbeing-type'}>
                        <span className="dw-score-label">Click on the icons below to find out more about each Digital Wellbeing domain</span>
                        {
                            Object.values(DIGITAL_WELLBEING_DOMAINS(pageLc)).map((domain, index) => 
                            
                            <div key={index} className={`digital-type type-${index+1}`}>
                                <div className="circle"></div>
                                <h3 onClick={() => togglePopup(domain.title)}>{domain.title}</h3>
                                <div className={`range type${index+1}`}>
                                    {
                                        Object.keys(getMapFromObjectListByKey(level6Scores, "code")).includes(domain.title) ?
                                        level6Scores.map((dm, idx) => dm.code === domain.title ?
                                                <div className="percentage" style={{width: `${dm.score/dm.total*100}%`}}>{parseInt(dm.score/dm.total*100)}%</div>
                                            : null
                                        ) : <div className="type-default">N/A</div>
                                    }
                                    <div className="range-bg"></div>
                                </div>
                                {openPopup === domain.title && (
                                    <div className="popup">
                                        <span className="popup-close" onClick={() => togglePopup(domain.title)}>X</span>
                                        <p>{domain.desc}</p>
                                        <button className={'popup-button'} onClick={() => togglePopup(domain.title)}>
                                            <a href={`${NUS_REDIRECT_URL}/tag/${domain.title.toLowerCase().split(' ').join('-')}`} target="_blank">Explore Resources</a>
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    
                    </div>
                    {  level6Scores.length < Object.keys(DIGITAL_WELLBEING_DOMAINS(pageLc)).length && 
                        <div className={'digital-wellbeing-score-desc'}>
                            <p>
                                Please note that this is a partial Digital Wellbeing Score, as you only selected {level6Scores.length === 1 ? '1 domain' : `${level6Scores.length} domains`}.<br/>
                                To receive a more accurate and comprehensive score, you may take the full Digital Wellbeing Test.
                            </p>
                        </div>
                    }
                </div>
                <div className={'digital-wellbeing-type-desc'}>
                    {
                        Object.values(DIGITAL_WELLBEING_PROFILES(pageLc)).map((pf, index) => pf.title === title ? 
                        <div  className={`digital-wellbeing-type-content P${index+1}`} >
                            <h2>{title}</h2>
                            <div className={`wellbeing-type P${index+1}`} />
                            <p>{pf.desc}</p>
                        </div>
                        : null
                    )}
                    {totalScore && title && (
                        <>
                            <buton className={'digital-wellbeing-button'} onClick={btnAction}>Finish</buton>
                            <buton className={'digital-wellbeing-retake-button'} onClick={retakeBtnAction}><i className={"fa fa-repeat retake-icon"}></i>Retake</buton>
                        </>
                    )}
                </div>

            </section>
        </>
    )
};

export default NUSAnalytics;