import React, {useEffect, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
import Button, {btnSize} from "../../components/UI/Button/Button.ui";
import { withRouter } from "react-router";
import Helmet from "react-helmet";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {DEFAULT_LOCALE, DQ_ENABLED_THEMES, DQ_THEMES, LOCAL_STORAGE_KEYS, LOCALES_ENABLED} from "../../utils/constants";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";
import {useLocation} from "react-router-dom";
import crypto from "crypto";
import uuid from "uuid";
import {loginUserViaConnectToken, logoutUser} from "../../services/auth.service";
import {useAuth} from "../../context/auth";
import {useGlobalState} from "../../context/global_state";


const AssessmentWelcomePage = (props) => {

    const { history } = props;
    const location = useLocation();

    const { setAuthToken, setAuthUser, authToken, authUser } = useAuth();
    const { setToastShow, setToastMessage, setProfile, settings, setSettings} = useGlobalState();

    const [loading, setLoading] = useState(false);

    const pageLc = location.pathname.split('/')[1];

    useEffect( () => {
        continueAction();
    }, [])


    const continueAction = async () => {
        if (LOCALES_ENABLED.includes(pageLc)) {
            setLoading(true);
            const AppKey = '9f003068-3d6e-4bb9-9aa9-3d2d430c9d7e';
            const signWith = 'a8bc4646-1578-43b2-a217-747afd412f32';

            const jsonObject = {
                "app_key": AppKey,
                "module": "NUS-CTIC-DWIF",
                "utc_timestamp": Date.now(),
                "user_id": uuid.v4(),
            };

            const jsonString = JSON.stringify(jsonObject);
            const connect_token = btoa(jsonString);
            const signature = crypto.createHmac("sha256", signWith).update(jsonString).digest("hex");

            // make the service call, to login Via Connect API
            try {
                // logout old user first
                await LogoutActionWithoutRedirect();

                let profile;
                const result = await loginUserViaConnectToken(connect_token, signature);

                if (result.data && result.data.user && result.data.org_code) {
                    // set the data of login for new user
                    await setAuthToken(result.data.auth_token);
                    await setAuthUser(result.data.user);
                    // set default profile
                    localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE, result.data.org_code);
                    profile = result.data.profile
                    await setProfile(profile);
                    if (result.data.mydq_app_theme && DQ_ENABLED_THEMES.includes(result.data.mydq_app_theme)) {
                        await setSettings({
                            ...settings,
                            theme: result.data.mydq_app_theme
                        });
                    }

                    // set module_code & redirect_url in local-storage.
                    localStorage.setItem(LOCAL_STORAGE_KEYS.CONN_DEFAULT_MODULE_CODE, result.data.module_code);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.CONN_REDIRECT_URL, result.data.redirect_url);

                    history.push(`/${pageLc}/home`);
                }

                setLoading(false);
            } catch (e) {
                setToastShow(true);
                setToastMessage(e.message);
                setLoading(false);
            }
        } else {
            setToastShow(true);
            setToastMessage('invalid locale passed in url!');
        }
    }

    const LogoutActionWithoutRedirect = async (make) => {
        console.log('action-called: LogoutActionWithoutRedirect.....');
        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            setProfile(null);
            setSettings({
                ...settings,
                theme: DQ_THEMES.DEFAULT
            });
            localStorage.clear();

            const locale = location.pathname.split('/')[1];
            if (LOCALES_ENABLED.includes(locale)) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, locale);
            }
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
        }
    }


    return (
        <div
            style={{ height: '60vh' }}
            className={'dq-audience-container'}>
            <Helmet title="Digital Wellbeing Test" />
            <LoaderWithBackDrop
                loading={loading}
            />


            <p className={'dq-audience-title'}>{ ReactHtmlParser(`Welcome to the <br>Digital Wellbeing Test!`) }</p>

            <br/>
            <div className={'dq-options-submit'}
            >
                <button onClick={continueAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__start_test_text')}</button>
            </div>

            <br/>



        </div>
    );
};


export default withRouter(AssessmentWelcomePage);
