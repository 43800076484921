import React, {useEffect, useState} from 'react';
import {useGlobalState} from "../../../context/global_state";

import { withRouter } from "react-router";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import dqIcon from "../../../assets/icons/dq-icons/dq-logo.png";
import lockIcon from '../../../assets/images/lock.png';
import {useLocation} from "react-router-dom";
import {hasDQPAccess, hasDQWAccess} from "../../../utils/utils";
import {fetchDQWRedirectLink} from "../../../services/app_start.service";
import {getProfileService} from "../../../services/home.service";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import {DEFAULT_ORG_CODE, PRODUCT_TYPE} from "../../../utils/constants";

const DQW = 'menu__goto_dqw';
const DQP = 'dq_platform__dq_assessment';

const SelectPlatform = (props) => {

    const { history } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);

    const { setToastShow, setToastMessage, profile, setProfile } = useGlobalState();

    const [ platforms, setPlatforms ] = useState([]);

    useEffect(() => {
        //runs once, on load
        if(!profile || !profile.org_code) {
            reloadProfile().then(() => console.log('profile reloaded successfully'));
        } else {
            checkPlatformAvailability(profile);
        }
    }, [profile])

    const multiplePlatformsAvailable = () => {
        return platforms.length >= 2;
    }

    const reloadProfile = async () => {
        setLoading(true);
        let updated_profile = profile;
        try {
            const res = await getProfileService();
            updated_profile = {
                ...profile,
                ...res.data.profile
            }
            setProfile(updated_profile);
        } catch (e) {
            showToast(e.message);
        }
        setLoading(false);
    }

    const showToast = (message) => {
        setToastShow(true);
        setToastMessage(message);
    }

    const checkPlatformAvailability = (profile) => {
        const available_platforms = [];
        if(hasDQWAccess(profile)) available_platforms.push(DQW);
        if(hasDQPAccess(profile)) available_platforms.push(DQP);
        if(available_platforms.length === 0) {
            showToast(GET_APP_LOCALIZE_TEXT(pageLc, 'dq_profile__inactive'))
            redirectToLogout();
        }
        else if(available_platforms.length === 1 && profile.org_code !== DEFAULT_ORG_CODE) {
            const platform = available_platforms[0];
            onSelectPlatform(platform).then();
        }
        else {
            setPlatforms(available_platforms);
        }
    }

    const redirectToDQWorld = async () => {
        setLoading(true);
        try {
            const result = await fetchDQWRedirectLink(`${process.env.REACT_APP_DQ_ASSESS_BASE_URL}/logout`);
            const {redirect_link} = result.data;
            window.open(redirect_link, "_self");
        }
        catch (e) {
            showToast(e.message);
            setLoading(false);
            if(!hasDQPAccess(profile)) redirectToLogout();
        }
    }

    const redirectToDQPro = () => {
        history.push('/home');
    }

    const redirectToLogout = () => {
        history.push('/logout');
    }

    const onSelectPlatform = async (platform) => {
        if (platform === DQW) {
            redirectToDQWorld().then();
        } else if (platform === DQP) {
            redirectToDQPro();
        }
    }

    const onClickPurchase = (platform) => {
        setLoading(true)
        if(platform === DQW) {
            history.push({
                pathname: '/products',
                state: {
                    autoSelect: "dqw",
                    productType: PRODUCT_TYPE.PACKAGE,
                    referrer: '/select_platform'
                }
            })
        }
        setLoading(false);
    }

    return (
        <div className={'register-container platforms-page'}>

            <div className="dq-logo-image"><img src={dqIcon} alt='DQ'/></div>
            <div className={'dq-audience-login'}>
                <p>
                    {GET_APP_LOCALIZE_TEXT(pageLc, 'dq_platform__select')}
                </p>
            </div>
            <div className={'dq-platform-container'}>
                {
                    [DQW, DQP].map((platform) =>
                        <div>
                            <button className={'dq-platform-select'} disabled={!platforms.includes(platform)} onClick={() => onSelectPlatform(platform)}>
                                {GET_APP_LOCALIZE_TEXT(pageLc, platform)}
                                {
                                    !platforms.includes(platform) &&
                                    <div>
                                        <img className={'dq-platform-lock-img'} src={lockIcon} />
                                    </div>
                                }
                            </button>
                            { //must not be a part of parent button, or it will get disabled along with it
                                process.env.REACT_APP_ENABLE_ONLINE_PAYMENTS === "1" && !platforms.includes(platform) && platform === DQW &&
                                <button className={'dq-platform-buy'} onClick={() => onClickPurchase(platform)}>
                                    Buy Premium
                                </button>
                            }
                        </div>
                    )
                }
            </div>
        <LoaderWithBackDrop loading={loading}/>
        </div>
    );

}

export default withRouter(SelectPlatform);
