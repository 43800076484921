import React, { useState } from 'react';
import { withRouter } from "react-router";

import SideBar from './SideBar/SideBar.comp'
import BackDrop from './BackDrop/BackDrop.comp'
import {useGlobalState} from "../../context/global_state";
import {getActiveThemeColor} from "../../utils/utils";
import {NUS_Variant} from "../../theme/variants";
import styled from "styled-components";
import {DQ_THEMES} from "../../utils/constants";
import {logoutUser} from "../../services/auth.service";
import {useAuth} from "../../context/auth";
import {NUS_REDIRECT_URL} from "../../utils/constants";

const Header = (props) => {

	const { history } = props;

	const { setAuthToken, setAuthUser } = useAuth();
	const {  setToastShow, setToastMessage, setProfile, profile, settings, setSettings } = useGlobalState();

	const [drawerOpen, setDrawerOpen] = useState(false);
	
	const openDrawer = () => {
		setDrawerOpen(true);
		console.log('clicked --> openDrawer')
	}

	const closeDrawer = () => {
		setDrawerOpen(false);
	}

	const logoutAction = async () => {
        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            setProfile(null);
            setSettings({
                ...settings,
                theme: DQ_THEMES.DEFAULT
            });
            localStorage.clear();
			window.location.replace(NUS_REDIRECT_URL);

        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
        }
    }

	const getHamburgerIconColor = () => {
		const curr_path = props.location.pathname;
		const path_parts = curr_path.split('/')
		
		if (path_parts.length > 3 && path_parts[3] === 'quiz') {
			return getActiveThemeColor(profile);
		}
		return '#4a90e2';
	}

	const HeaderImg = styled.img`
		content: url(${props => props.theme.headerImg});
	`;

	return (
		<section>

			<SideBar show={drawerOpen} closeBar={closeDrawer} />

			{drawerOpen && <BackDrop closeDrawer={closeDrawer} />}

			<header className={'header'}>

				{settings.theme === DQ_THEMES.NUS_THEME ? (
					<img src={NUS_Variant.logo} alt="Living Well Digitally"  style={{objectFit: 'fill', cursor:'pointer', width:'122px', height:'40px'}} />
				) : (
				<div className={'toggle'} onClick={openDrawer}>
					<span style={{background: getHamburgerIconColor()}} />
					<span style={{background: getHamburgerIconColor()}} />
					<span style={{background: getHamburgerIconColor()}} />
				</div>
				)}

				{settings.theme === DQ_THEMES.NUS_THEME ? (
					<HeaderImg onClick={logoutAction} style={{objectFit: 'contain', cursor:'pointer'}}/>
				) : 
					props.location.pathname === '/home' ? undefined :
						<HeaderImg onClick={() => history.push('/home')} style={{objectFit: 'contain', cursor:'pointer'}}/>
				}


			</header>
		</section>

	)

}

export default withRouter(Header);