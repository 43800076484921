import React, { useEffect, useState } from 'react';
import Helmet from "react-helmet";
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from "react-router";
import DQIndexFooter from './components/Footer.comp';
import dqIndexAssessBanner from "../../assets/images/dqindex/dqassessment-banner.png";

import dqforAl from "../../assets/images/dqindex/icons/DQ-forAll.png";
import anySys from "../../assets/images/dqindex/icons/DQ-anySystem.png";
import dqIndexAssess from "../../assets/images/dqindex/icons/DQ-Index.png";
import Video3 from "../../assets/images/Video3.mp4";
import dqIndexIpadImg from "../../assets/images/dqindex/dqIndexIpad.png";
import dqIndexMobileImg from "../../assets/images/dqindex/dqIndexMobile.png";
import RaazCarefree from '../../assets/images/raaz_img1.png';
import RaazOrdinary from '../../assets/images/raaz_img2.png';
import RaazAlwaysOn from '../../assets/images/raaz_img3.png';
import RaazCautious from '../../assets/images/raaz_img4.png';
import RaazDigilog from '../../assets/images/raaz_img5.png';
import DQGraphic from '../../assets/images/graphicImage.png';

import primarySchools from "../../assets/images/dqindex/icons/PrimarySchool.png";
import secondarySchools from "../../assets/images/dqindex/icons/SecondarySchool.png";
import companies from "../../assets/images/dqindex/icons/Companies.png";
import globePack from "../../assets/images/dqindex/icons/Globe.png";
import DC_ScoreChart from "../../components/UI/Charts/DC_ScoreChart.ui";

import { DQW_DCT_SCORES_DIST_FUNC } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import { GET_WEBSITE_LOCALIZE_TEXT } from "../../utils/dq_lc_service/lc_service";
import Header from "./components/Header.comp";

import AOS from 'aos';
import 'aos/dist/aos.css';

const DQIndexAssessment = (props) => {
    const { history } = props;
    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [countryData, setCountryData] = useState(DQW_DCT_SCORES_DIST_FUNC(pageLc)[0]);
    const [aosEnabled, setAosEnabled] = useState(false);

    const dropdownAction = (e) => {
        setCountryData(DQW_DCT_SCORES_DIST_FUNC(pageLc)[parseInt(e.target.value)]);
    }


    useEffect(() => {
        if (aosEnabled) {
            AOS.init({
                duration: 2000,
            });
        }
    }, [aosEnabled]);

    const handleScroll = () => {
        if (!aosEnabled) {
            setAosEnabled(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="index-page-container">
            <Helmet htmlAttributes={{ lang: pageLc }} title="DQ Assessment" />
            <Header />

            <section className='index-banner index-banner2'>
                <img className='ibanner-img' src={dqIndexAssessBanner} alt='DQ' />
                <div className='ibanner-content'>
                    <div className='ibanner-heading-assess'>
                        <h1 className='ihead-title'>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__whats_your_dq'))}</h1>
                        <a className="ibanner-button " href="/login" target='_blank' >{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__take_the_test')}</a>
                    </div>

                    <div className="ibanner-video">
                        <video autoPlay loop muted playsInline>
                            <source src={Video3} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </section>

            <section className='index-section-container full-length-container container-grey-shade '>
                <div className='index-section-content'>
                    {/* <div className='isection-content'> */}
                    <h1 className='isection-title'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__about_dq_assessments')} </h1>
                    <div className='isection-assessments'>
                        <div data-aos-offset="100" data-aos="fade-up" className='isection-assessments-type1'>
                            <img src={dqforAl} alt='forAll'></img>
                            <span data-aos="fade-right">{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__forall')}</span>
                            {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__available_for_all_age_groups_and_types')}
                        </div>
                        <div data-aos-offset="100" data-aos="fade-up" data-aos-delay="300" className='isection-assessments-type2'>
                            <img src={anySys} alt='Any System'></img>
                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__any_system')}</span>
                            {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__whether_you_looking_to_integrate')}
                        </div>
                        <div data-aos-offset="100" data-aos="fade-up" data-aos-delay="300" className='isection-assessments-type3 '>
                            <img src={dqIndexAssess} alt='DQ Index' style={{ height: "38%", marginTop: '10px' }}></img>
                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__dq_index')}</span>
                            {ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__scores_are_immediately_updated_into'))}
                        </div>
                    </div>
                </div>
            </section>
            <section className="index-section-container container-beige-shade full-length-container">
                <div className="iassess-section">
                    <p data-aos="fade-up" data-aos-offset="100">{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__dq_assessment_was_developed_as')}</p>
                    <img data-aos="fade-up" data-aos-delay="300" data-aos-offset="100" src={dqIndexMobileImg} alt='DQ Index'></img>
                </div>
                <div className="iassess-section">
                    <div data-aos="fade-up" data-aos-delay="900" data-aos-offset="100" className='iassess-details-image'>
                        <img src={dqIndexIpadImg} alt='DQ Index'></img>
                        <div className="iaction-btn ibtn-assess" >
                            <a target='_blank' href="/login" style={{ marginLeft: '19%' }}>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__get_started')}</a>
                        </div>

                    </div>

                    <div data-aos="fade-up" data-aos-delay="600" data-aos-offset="100" className='iassess-details-content'>
                        <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__dq_assessment_will_enable')}</p>
                        <ol>
                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__1_common_assessments_that_measure')}</li>
                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__2_in_depth_analysis_to_the_end_users')}</li>
                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__3_customized_digital_skill_development')}</li>
                        </ol>
                        <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__dq_assessment_tools_are_developed_based_on')}</p>
                    </div>
                </div>
            </section>


            <section className='index-section-container container-bg-shade full-length-container' >
                <div className='index-section-content isection-assess-sp'>
                    <h1 className='isection-title'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__5_types_of_digital_citizen')}</h1>
                    <div className='isection-content'>
                        <p>
                            {ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__based_on_statement_misc_1'))}
                        </p>
                        <div className='dc-types-container'>
                            <div className='dc-types-bars-title'>
                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__skills')}</span>
                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__usage')}</span>
                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__risks')}</span>
                            </div>
                            <div className="mobile-slider-arrows">
                                <button className="next-arrow">
                                <i className="fa fa-arrow-right"></i>
                                </button>
                            </div>
                            <div className='dc-types'>
                                <div data-aos="fade-left" data-aos-offset="100" className='dc-type dt-carefree'>
                                    <img className='dc-type-icon' src={RaazCautious} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__carefree')}></img>
                                    <h3>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__carefree')}</h3>
                                    <div className='dc-type-skills'>
                                        <ul>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__feels_more_comfortable_online_than_offline')}</li>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__potentially_involved_with_various_cyber_risks')}</li>
                                        </ul>
                                    </div>
                                    <div className="container">
                                        <div className='dc-type-bars'>
                                            <div className='dc-type-bars-range'>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__low')}</span>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__medium')}</span>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__high')}</span>
                                            </div>
                                            <div className="bar-graph">
                                                <div className="lines">
                                                    <div className="line1" style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 46px' }}></div>
                                                    <div className="line2" style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 46px' }}></div>
                                                    <div className="line3" style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 23px' }}></div>
                                                </div>
                                                <div className="bars">
                                                    <div className="bar aos-init" data-aos="moveBar1" data-aos-once="true" data-aos-delay="0" style={{ width: '20%', marginBottom: '20px', zIndex:'3', top: '30%',transformOrigin: 'left center'  }}></div>
                                                    <div className="bar aos-init" data-aos="moveBar2" data-aos-once="true" data-aos-delay="1000" style={{ width: '41%', marginBottom: '20px',zIndex:'4', top: '77%',transformOrigin: 'left center'   }}></div>
                                                    <div className="bar aos-init" data-aos="moveBar3" data-aos-once="true" data-aos-delay="2000" style={{ width: '81%',zIndex:'5', top: '122%',transformOrigin: 'left center'   }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-aos="fade-left" data-aos-delay="300" data-aos-offset="100" className='dc-type dt-ordinary' >
                                    <img className='dc-type-icon' src={RaazOrdinary} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__ordinary')}></img>
                                    <h3>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__ordinary')}</h3>
                                    <div className='dc-type-skills'>
                                        <ul>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__average_technology_user_who_not_fully_aware')}</li>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__tend_to_passively_follow_the_algorithm')}</li>
                                        </ul>
                                    </div>
                                    <div className="container">
                                        <div className='dc-type-bars'>
                                            <div className='dc-type-bars-range'>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__low')}</span>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__medium')}</span>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__high')}</span>
                                            </div>
                                            <div className="bar-graph">
                                                <div className="lines">
                                                    <div className="line1" style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 46px' }}></div>
                                                    <div className="line2" style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 46px' }}></div>
                                                    <div className="line3" style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 23px' }}></div>
                                                </div>
                                                <div className="bars">
                                                    <div className="bar aos-init" data-aos="moveBar1" data-aos-once="true" data-aos-delay="0" style={{ width: '25%', marginBottom: '20px', zIndex: '3', top: '30%', transformOrigin: 'left center' }}></div>
                                                    <div className="bar aos-init" data-aos="moveBar2" data-aos-once="true" data-aos-delay="1000" style={{ width: '41%', marginBottom: '20px', zIndex: '4', top: '77%', transformOrigin: 'left center' }}></div>
                                                    <div className="bar aos-init" data-aos="moveBar3" data-aos-once="true" data-aos-delay="2000" style={{ width: '41%', zIndex: '5', top: '122%', transformOrigin: 'left center' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-aos="fade-left" data-aos-delay="600" data-aos-offset="100" className='dc-type dt-always-on'>
                                    <img className='dc-type-icon' src={RaazCarefree} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__always_on')}></img>
                                    <h3>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__always_on')}</h3>
                                    <div className='dc-type-skills'>
                                        <ul>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__likely_communicate_with_others_a_lot_online')}</li>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__may_not_consider_much_about_risks')}</li>
                                        </ul>
                                    </div>
                                    <div className="container">
                                        <div className='dc-type-bars'>
                                            <div className='dc-type-bars-range'>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__low')}</span>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__medium')}</span>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__high')}</span>
                                            </div>
                                            <div className="bar-graph">
                                                <div className="lines">
                                                    <div className="line1" style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 46px' }}></div>
                                                    <div className="line2" style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 46px' }}></div>
                                                    <div className="line3" style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 23px' }}></div>
                                                </div>
                                                <div className="bars">
                                                    <div className="bar aos-init" data-aos="moveBar1" data-aos-once="true" data-aos-delay="0" style={{ width: '41%', marginBottom: '20px', zIndex: '3', top: '30%', transformOrigin: 'left center' }}></div>
                                                    <div className="bar aos-init" data-aos="moveBar2" data-aos-once="true" data-aos-delay="1000" style={{ width: '81%', marginBottom: '20px', zIndex: '4', top: '77%', transformOrigin: 'left center' }}></div>
                                                    <div className="bar aos-init" data-aos="moveBar3" data-aos-once="true" data-aos-delay="2000" style={{ width: '63%', zIndex: '5', top: '122%', transformOrigin: 'left center' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-aos="fade-left" data-aos-delay="900" data-aos-offset="100" className='dc-type dt-cautious' >
                                    <img className='dc-type-icon' src={RaazDigilog} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__cautious')}></img>
                                    <h3>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__cautious')}</h3>
                                    <div className='dc-type-skills'>
                                        <ul>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__being_aware_of_potential_cyber_risks')}</li>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__likely_having_low_risks_in_the_digi_world')}</li>
                                        </ul>
                                    </div>
                                    <div className="container">
                                        <div className='dc-type-bars'>
                                            <div className='dc-type-bars-range' style={{ position: 'relative' }}>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__low')}</span>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__medium')}</span>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__high')}</span>
                                            </div>
                                            <div className="bar-graph" style={{ position: 'relative' }}>
                                                <div className="lines">
                                                    <div className="line1" style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 46px' }}></div>
                                                    <div className="line2" style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 46px' }}></div>
                                                    <div className='line3' style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 23px' }}></div>
                                                </div>
                                                <div className="bars">
                                                    <div className="bar aos-init" data-aos="moveBar1" data-aos-once="true" data-aos-delay="0" style={{ width: '41%', marginBottom: '20px', zIndex: '3', top: '30%', transformOrigin: 'left center' }}></div>
                                                    <div className="bar aos-init" data-aos="moveBar2" data-aos-once="true" data-aos-delay="1000" style={{ width: '26%', marginBottom: '20px', zIndex: '4', top: '77%', transformOrigin: 'left center' }}></div>
                                                    <div className="bar aos-init" data-aos="moveBar3" data-aos-once="true" data-aos-delay="2000" style={{ width: '26%', zIndex: '5', top: '122%', transformOrigin: 'left center' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-aos="fade-left" data-aos-delay="1200" data-aos-offset="100" className='dc-type dt-digilog'>
                                    <img className='dc-type-icon' src={RaazAlwaysOn} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digilog')}></img>
                                    <h3>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digilog')}</h3>
                                    <div className='dc-type-skills'>
                                        <ul>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__well_rounded_digital_citizen')}</li>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__uses_technology_mindfully')}</li>
                                        </ul>
                                    </div>
                                    <div className="container">
                                        <div className='dc-type-bars'>
                                            <div className='dc-type-bars-range'>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__low')}</span>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__medium')}</span>
                                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__high')}</span>
                                            </div>
                                            <div class="bar-graph">
                                                <div className="lines">
                                                    <div className='line1' style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 46px' }}></div>
                                                    <div className='line2' style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 46px' }}></div>
                                                    <div className='line3' style={{ height: '13.7vh', marginTop: '20px', borderLeft: '3px solid #ffff', padding: '0 23px' }}></div>
                                                </div>
                                                <div className="bars">
                                                    <div className="bar aos-init" data-aos="moveBar1" data-aos-once="true" data-aos-delay="0" style={{ width: '81%', marginBottom: '20px', zIndex: '3', top: '30%', transformOrigin: 'left center' }}></div>
                                                    <div className="bar aos-init" data-aos="moveBar2" data-aos-once="true" data-aos-delay="1000" style={{ width: '41%', marginBottom: '20px', zIndex: '4', top: '77%', transformOrigin: 'left center' }}></div>
                                                    <div className="bar aos-init" data-aos="moveBar3" data-aos-once="true" data-aos-delay="2000" style={{ width: '22%', zIndex: '5', top: '122%', transformOrigin: 'left center' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dots">
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                        </div>
                        <p className='ilabel-blue'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__be_yourself_and_answer_honestly')}</p>
                        <div className='iaction-btn ibtn-assess' style={{ justifyContent: 'center !important' }}>
                            <a href="/login" target='_blank'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__take_the_test')}</a></div>
                    </div>
                </div>
            </section>

            <section className='index-section-container container-gn-shade full-length-container'>
                <div className='index-section-content isection-assess-sp'>
                    <h1 className='isection-title'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__toolkits')}</h1>
                    <div className='isection-content'>
                        <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__whether_it_be_your_school_company_or')}</p>
                        <p>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__if_you_looking_to_create_a_customized'))}</p>
                    </div>
                    <div className='index-actions'>
                        <div className='iactions-content iaction-kits-content'>
                            <div data-aos="fade-up" data-aos-offset="100" className='iaction-col iaction-kits-col'>
                                <img src={primarySchools} alt='Primary Schools'></img>
                                <h4>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__primary_schools'))}</h4>
                                <p>
                                    {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__schools_can_get_their_students_ages_8_12')}
                                </p>
                                <ul>
                                    <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__measures_dq_1_8')}</li>
                                    <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__analytics_dashboard_included_for_teachers')}</li>
                                    <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digital_parenting')}</li>
                                    <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__cyber_risk_assessment')}</li>
                                </ul>

                                <div className='iaction-btn'><a href="mailto:contact@dqforall.com" target='_blank'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__learn_more')}</a></div>
                            </div>
                            <div data-aos="fade-up" data-aos-delay="300" data-aos-offset="100" className='iaction-col iaction-kits-col'>
                                <img src={secondarySchools} alt='Secondary Schools'></img>
                                <h4>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__secondary_schools'))}</h4>
                                <p>
                                    {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__schools_can_get_their_teenage_students_ages_13_18')}
                                </p>
                                <ul>
                                    <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__measures_dq_1_8')}</li>
                                    <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__analytics_dashboard_included_for_teachers')}</li>
                                    <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digital_parenting')}</li>
                                    <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__cyber_risk_assessment')}</li>
                                </ul>

                                <div className='iaction-btn'><a href="mailto:contact@dqforall.com" target='_blank'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__learn_more')}</a></div>
                            </div>
                            <div data-aos="fade-up" data-aos-delay="600" data-aos-offset="100" className='iaction-col iaction-kits-col'>
                                <img src={companies} alt='Companies'></img>
                                <h4>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__companies_corporates_span'))}</h4>
                                <p>
                                    {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__companies_and_organizations_can_assess')}
                                </p>
                                <ul>
                                    <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__measures_dq_1_8')}</li>
                                    <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__dashboard_included_for_hr')}</li>
                                    <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__cyber_risk_assessment')}</li>
                                </ul>

                                <div className='iaction-btn'><a href="mailto:contact@dqforall.com" target='_blank'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__learn_more')}</a></div>
                            </div>
                            <div data-aos="fade-up" data-aos-delay="900" data-aos-offset="100" className='iaction-col iaction-kits-col'>
                                <img src={globePack} alt='Global'></img>
                                <h4>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__national_packages'))}</h4>
                                <p>
                                    {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__countries_and_govt_localize_assess')}
                                </p>
                                <ul>
                                    <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__customize_assessment')}</li>
                                    <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__localized_for_your_country')}</li>
                                    <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__consult_strategies_plans')}</li>
                                </ul>

                                <div className='iaction-btn'><a href="mailto:contact@dqforall.com" target='_blank'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__learn_more')}</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='index-section-container full-length-container container-gr-shade'>
                <div className='index-section-content content'>
                    <div className="isection-image">
                        <img src={DQGraphic} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__carefree')}></img>
                    </div>
                    <div className="isection-content" style={{ color: '#082949', }}>
                        <h1 className='isection-title' style={{ textAlign: 'left' }}>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digital_citizenship_test_and_dq_index')}</h1>
                        <p>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__the_level_of_digital_citizenship_is'))}</p>
                        <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__based_on_years_of_research_desc')}</p>
                        <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__connected_with_the_dq_index_desc')}</p>
                        <div className='dc-tooltip' style={{ display: 'none' }}>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digital_citizenship_is_a_skill_desc'))}</div>
                    </div>
                </div>
                <div className="index-section-content" style={{ backgroundColor: '#E8E6E6', width: '80%', borderRadius: '30px', height: 'fitContent', }}>
                    <div className='dc-scores-container' >
                        <h1 className='isection-title'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digital_citizenship_scores')}</h1>
                        <div className='dc-country-ddl-container'>
                            <select className='dc-country-ddl' onChange={dropdownAction}>
                                {DQW_DCT_SCORES_DIST_FUNC(pageLc).map((dcScore, index) => <option value={index}>{dcScore.demo_country}</option>)}
                            </select>
                        </div>
                        <div className="dc-scores">
                            <div className='dc-score-content'>
                                <div>
                                    {countryData ? countryData.AVGScore.toString().split('.')[0] : GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__n_a_text')}
                                </div>
                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__national_dq')}</span>
                            </div>
                            <div class="vertical-line" style={{ height: '10vh', margin: '5px auto', marginTop: '20px' }}></div>
                            <div className='dc-score-content'>
                                <div>100</div>
                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__global_dq')}</span>
                            </div>
                        </div>
                        <div className='iaction-btn ibtn-assess' style={{ justifyContent: 'center !important' }}>
                            <a href='/login' target='_blank'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__take_the_test')}</a></div>
                    </div>
                    <div class="vertical-line"></div>
                    <div className='dq-dc-chart'>
                        {/* BAR CHART HERE */}
                        <DC_ScoreChart
                            all_scores={countryData}
                            locale={pageLc}
                        ></DC_ScoreChart>
                    </div>

                </div>
            </section>

            <DQIndexFooter />
        </div>
    );
}

export default withRouter(DQIndexAssessment);