import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../utils/invokeApi";

export const appStartService = () => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/home/app_start',
        method : 'GET',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        }
    };

    return invokeApi(requestObj);
};

export const getProfileService = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : `/my_dq/home/profile`,
        method : 'GET',
        headers : {
            Authorization : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj);
}

export const fetchAssessmentsService = (dq_no) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : `/my_dq/assessment/fetch/${dq_no}`,
        method : 'GET',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        }
    };

    return invokeApi(requestObj);
};

export const getAssessHistoryService = (identifier) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : `/my_dq/assessment/get/history/${identifier}`,
        method : 'GET',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        }
    };

    return invokeApi(requestObj);
};

export const updateAssessHistoryService = (id, answers, progress_percentage, current_step, current_question
                                           , step_hide_count, hide_alias_keys, show_alias_keys
                                           , post_selection_actions_trace, step_skip_count) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : `/my_dq/assessment/update/history/${id}`,
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        answers,
        progress_percentage,
        current_step,
        current_question,
        step_hide_count,
        hide_alias_keys,
        show_alias_keys,
        post_selection_actions_trace,
        step_skip_count
    };

    return invokeApi(requestObj);
}

export const finishAssessService = (identifier) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : `/my_dq/assessment/finish/${identifier}`,
        method : 'GET',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        }
    };

    return invokeApi(requestObj);
};