import React, {useState, useEffect} from 'react';
import ReactHtmlParser from 'react-html-parser';
import './footer.comp.scss';
import {
    LOCAL_STORAGE_KEYS,
    LOCALE_TO_LANGUAGE_MAP,
    LOCALES_DISABLED_LIVE,
    LOCALES_ENABLED
} from "../../../utils/constants";
import {useHistory, useLocation} from "react-router-dom";
import {GET_WEBSITE_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import {isEnvDev} from "../../../utils/utils";

const DQIndexFooter = (props) => {

    const history = useHistory();
    const location = useLocation();

    const pageLc = location.pathname.split('/')[1];

    const [cookieClass, setCookieClass] = useState('');

    const disabledLocales = isEnvDev() ? [] : LOCALES_DISABLED_LIVE;

    useEffect( ()=> {
        const cookieAgreed = localStorage.getItem(LOCAL_STORAGE_KEYS.COOKIE_POPUP);
        if (!cookieAgreed) {
            setCookieClass('icookies-show');
        }
    }, [] );

    const closeCookiesPopup = () => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.COOKIE_POPUP, 1);
        setCookieClass('');
    }

    const getLanguageFromLc = (lc_code) => {
        if (LOCALE_TO_LANGUAGE_MAP[lc_code]) {
            return LOCALE_TO_LANGUAGE_MAP[lc_code]
        }
        return lc_code
    }

    const changeLocale = (e) => {
        const path = location.pathname.split('/')[2];
        const loc_code = e.target.getAttribute("lc_code");

        if (LOCALES_ENABLED.includes(loc_code)) {
            localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, loc_code);
        }

        history.push(`/${loc_code}/${path}`);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }

    return(
        <div className='dqindex-footer'>
            <div className='ifooter-content'>
                <div className='ifooter-col ifooter-copyright'>
                    {'©'}
                    {new Date().getFullYear()}
                    {' ' + GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__dq_lab_pte_ltd_all_rights_reserved')}
                    <div>
                        <a href='/privacy_policy' target={'_blank'}>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__privacy_policy')}</a>
                        <span> | </span>
                        <a href='/terms_of_use' target={'_blank'}>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__term_of_use')}</a>
                    </div>
                    <div className='ifooter-gdpr'>
                        Information notice pursuant to Art. 13 GDPR: <a href="https://storage.googleapis.com/dqlab-public/docs/DQ%20LAB%20-%20Information%20notice%20for%20business%20partners.pdf" target={'_blank'}>Information concerning the processing of your personal data</a>
                    </div>
                    <div className='language'>
                        <i className="fa fa-globe"></i> {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__language')}: <span>{getLanguageFromLc(pageLc)}</span>
                        <div className={`locales`}>
                            {
                                LOCALES_ENABLED
                                    .filter((lc) => !disabledLocales.includes(lc))
                                    .map((lc) => <span lc_code={lc} onClick={changeLocale}>{getLanguageFromLc(lc)}</span>)
                            }
                        </div>
                    </div>
                </div>
                <div className='ifooter-col ifooter-email'>
                    <div className='ifooter-title'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__email')}</div>
                    <div className='ifooter-subtitle'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__general_enquiries')}</div>
                    <a href="mailto:contact@dqforall.com">contact@dqforall.com</a>
                </div>
                <div className='ifooter-col ifooter-social-media'>
                    <div className='ifooter-title'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__social_media')}</div>
                    <a href="https://twitter.com/DQforAll" target={'_blank'}><i class="fa fa-twitter"></i></a>
                    <a href="https://www.linkedin.com/company/dqinstitute/" target={'_blank'}><i class="fa fa-linkedin"></i></a>
                    <a href="https://www.facebook.com/DQInstitute.org/?ref=aymt_homepage_panel" target={'_blank'}><i class="fa fa-facebook"></i></a>
                    <a href="https://www.youtube.com/channel/UCEs9Av_hrYf7PzY3pXrY_VQ" target={'_blank'}><i class="fa fa-youtube-play"></i></a>
                </div>
            </div>
            
            <div className={`dqindex-cookies ${cookieClass}`}>
                <div className='icookies-content'>
                    <div className='icookies-close' onClick={closeCookiesPopup}><i class="fa fa-close"></i></div>
                    {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__disc_about_cookies')}
                    {ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__cookies_piolicy_link'))}.
                </div>
            </div>
             
        </div>
    )
};

export default DQIndexFooter;