
const lc_en = {
    menu__hello_text: `Hello`,
    menu__guest_text: `Guest`,
    menu__home: `Home`,
    menu__register: `Register`,
    menu__settings: `Settings`,
    menu__switch_profile: `Switch Profile`,
    menu__goto_dqw: `Go to DQ World`,
    menu__logout: `Logout`,
    uo__welcome_dct_text: `Welcome to the <br>Digital Citizenship Test!`,
    uo__start_test_text: `Start Test`,
    post_q_landing__congrats: `Congratulations!`,
    post_q_landing__finished_test_message_text: `You have finished the test.<br>In order to get your score, you'll need to sign up for an account.`,
    post_q_landing__finished_test_short_message: `You have finished the test.`,
    post_q_landing__if_under_18_text: `If you are under the age of 18, you’ll need your parent’s or guardian’s email and consent to sign up.`,
    post_q_landing__signup: `Sign Up`,
    post_q_landing__no_thanks: `No, thanks`,
    persist_guest__fname: `First Name`,
    persist_guest__lname: `Last Name`,
    persist_guest__email_address: `Email Address`,
    persist_guest__require_parent_email_text: `(Parent/Guardian email address required if you’re under 18 years of age.)`,
    persist_guest__password: `Password`,
    persist_guest__confirm_password: `Confirm Password`,
    persist_guest__have_access_code: `Have an Access Code? Click to Enter!`,
    persist_guest__access_code_span: `Access Code <span style="font-weight: bold;">(click here to hide)</span>`,
    persist_guest__accept_terms_of_use: `I have read and accept the <br><a href="/terms_of_use" target="_blank"> TERMS of USE </a> and<a href="/privacy_policy" target="_blank"> PRIVACY POLICY</a>.`,
    persist_guest__register: `Register`,
    persist_guest__already_have_account: `Already have an account?`,
    persist_guest__login_here: `Login here`,
    persist_guest__login: `Log in`,
    persist_guest__signup: `Sign Up`,
    persist_guest__not_have_an_account: `Don't have an account?`,
    persist_guest__register_here: `Register here`,
    persist_guest__forgot_password: `Forgot Password?`,
    persist_guest__login_to_dashboard: `Login to DQ Dashboard`,
    persist_guest__login_with_google: `Login with Google`,
    persist_guest__register_with_google: `Register with Google`,
    persist_guest__reset_pass_guide: `Please type in the registered username.<br/>We will then send a reset password link via email associated with the account.`,
    persist_guest__send_link: `Send Link`,
    persist_guest__send_link_success: `Reset Link Sent Successfully\nCheck your inbox!`,
    persist_guest__send_link_failed: `Failed to send reset link`,
    persist_guest__reset_password: `Reset Password`,
    persist_guest__reset_pass_success: `You have changed your password. Please login with your username or email with the new password.`,
    persist_guest__reset_pass_failed: `Failed to send reset password`,
    persist_guest__reset_pass_link_invalid: `Invalid Reset Password Link`,
    persist_guest__recover_password_here: `Recover Password Here`,
    persist_guest__go_to_login: `Go to Login`,
    uo__register_as_individual: `Register as an INDIVIDUAL`,
    uo__register_as_company: `Register as a COMPANY`,
    uo__register_as_school: `Register as a SCHOOL`,
    uo__not_found: `Not Found`,
    persist_guest__privacy_policy: `Privacy Policy`,
    persist_guest__terms_of_use: `Terms of Use`,
    persist_guest__wrong_pass_user_not_found: `wrong username/email entered: user not found`,
    persist_guest__plz_fill_req_fields: `Please fill all the required fields`,
    uo__plz_accept_terms_of_use: `Please Accept the Terms of Use`,
    uo__pass_mismatch_plz_retype: `Password Mismatch! Please re-type`,
    uo__err_loading_guest: `Error Loading Guest User!`,
    uo__plz_set_ur_new_pass: `Please set your new password.`,
    uo__no_assessment_available_chk_later: `NO Assessments available at the moment, Please check again later`,
    uo__dq_under_maintainance_txt: `The DQ Assessment is currently under maintenance.`,
    uo__not_available_try_later: `Not Available at the moment, Try back later!`,
    uo__submit: `Submit`,
    uo__view_score: `View Score`,
    uo__admin_login: `Admin Login`,
    uo__plz_wait: `Please wait...`,
    uo__loading: `Loading…`,
    uo__loaded: `Loaded`,
    result_quiz__require_attention: `Requires Attention!`,
    result_quiz__bellow_avg: `Below Average!`,
    result_quiz__good_job: `Good Job!`,
    result_quiz__excellent: `Excellent!`,
    result_quiz__require_attention_br: `Requires<br>Attention`,
    result_quiz__above_avg_br: `Above<br>Average`,
    result_quiz__bellow_avg_br: `Below<br>Average`,
    result_quiz__excellent_br: `Excellent`,
    result_quiz__high: `High`,
    result_quiz__medium: `Medium`,
    result_quiz__low: `Low`,
    result_quiz__high_score_description: `Excellent work! You’re well-versed in navigating the digital world. Keep up the good work and think about sharing your insights.`,
    result_quiz__medium_score_description: `You’re on the right track with a basic understanding of digital conduct. Expanding your knowledge could further enhance your digital experiences.`,
    result_quiz__low_score_description: `There’s a chance to grow in your digital journey. Exploring educational materials can help improve your understanding and impact online.`,
    result_quiz__mydq_score: `My DQ Score`,
    result_quiz__mydq_badges: `My DQ Badges`,
    result_quiz__you_are_ready_to_use_tech_rubirc: `You are ready to use technology. You understand how to use technology safely. Continue building your digital skills to thrive.`,
    result_quiz__your_score_is_above_average_rubric: `Your score is above average compared to the global average. We recommend that you keep building a comprehensive set of digital citizenship skills.`,
    result_quiz__your_score_is_less_than_average_rubirc: `Your score is less than average compared to the global average. We recommend that you enhance your digital citizenship skills if you are actively using technology.`,
    result_quiz__you_may_potentially_be_exposed_rubirc: `You may potentially be exposed to one or more cyber-risks and unhealthy habits related to your digital usage. We recommend that you check your cyber-risk exposure level.`,
    result_quiz__your_score_span: `Your score is in the `,
    result_quiz__dct_discalimer_html_text: `*Disclaimer: This Digital Citizenship Test was developed to raise awareness about the importance of digital citizenship and may not be accurate in identifying your cyber-risk exposure and digital skill level. If you are interested in getting a full assessment, you can learn more <a href="/" target="_blank">here</a>.`,
    result_quiz__click_message_dct_type: `Click ‘Next’ to find out what Digital Citizen Type you are`,
    result_quiz__next: `Next`,
    result_quiz__carefree_citizen: `Carefree Citizen!`,
    result_quiz__feel_comfort_online_text: `Feels more comfortable online than offline`,
    result_quiz__invlove_with_risks_text: `Potentially involved with various cyber-risks`,
    result_quiz__ordinary_citizen: `Ordinary Citizen!`,
    result_quiz__avg_tech_user_message_text: `Average technology user who may not be fully aware of the risks nor opportunities`,
    result_quiz__tend_to_follow_algo: `Tend to passively follow the algorithm`,
    result_quiz__always_on_citizen: `Always-On Citizen!`,
    result_quiz__like_comm_with_others: `Likely communicate with others a lot online`,
    result_quiz__maynot_consider_risks: `May not consider much about potential cyber-risks`,
    result_quiz__catious_citizen: `Cautious Citizen!`,
    result_quiz__aware_of_risks_not_fully_explore: `Being aware of potential cyber-risks and not fully exploring the digital world`,
    result_quiz__likely_having_risks_in_dw: `Likely having low risks in the digital world`,
    result_quiz__digilog_citizen: `DigiLog Citizen!`,
    result_quiz__balanced_digital_citizen: `Well-rounded digital citizen who balances online and offline lives`,
    result_quiz__use_tech_mindfully: `Uses technology mindfully`,
    result_quiz__learn_more_html_text: `Learn more at <a href="/" target="_blank">DQ Assessment</a>.`,
    result_quiz__done: `Done`,
    dq_home__min_duration: `MIN`,
    dq_home__dc_test: `Digital Citizenship Test`,
    dq_home__free_assess: `Free Assessment`,
    dq_home__bellow_avg: `Below Avg`,
    dq_home__avg: `Average`,
    dq_home__excellent: `Excellent`,
    dq_home__top_percent: `top`,
    dq_home__bottom_percent: `bottom`,
    dq_home__dc_score_helps_what_message: `The Digital Citizenship Score helps you to understand your digital skill level for using technology safely and responsibly. It also helps you to identify your Digital Citizen type.`,
    dq_home__share: `Share`,
    dq_home__retake: `Retake`,
    dq_home__myscore_comparison: `My Score Comparison`,
    dq_home__myscore: `My Score`,
    dq_home__national_avg: `National Avg.`,
    dq_home__global_avg: `Global Avg.`,
    dq_home__retake_test: `Retake Test`,
    dq_demographic__gender: `Gender`,
    dq_demographic__male: `Male`,
    dq_demographic__female: `Female`,
    dq_demographic__non_binary: `Non-binary`,
    dq_demographic__prefer_not_to_answer: `Prefer not to answer`,
    dq_demographic__other: `Other`,
    dq_demographic__other_specify: `Please specify`,
    dq_demographic__year_of_birth: `Year of Birth`,
    dq_demographic__country: `Country`,
    dq_country__afghanistan: `Afghanistan`,
    dq_country__aland_islands: `Åland Islands`,
    dq_country__albania: `Albania`,
    dq_country__algeria: `Algeria`,
    dq_country__american_samoa: `American Samoa`,
    dq_country__andorra: `Andorra`,
    dq_country__angola: `Angola`,
    dq_country__anguilla: `Anguilla`,
    dq_country__antarctica: `Antarctica`,
    dq_country__antigua_and_barbuda: `Antigua and Barbuda`,
    dq_country__argentina: `Argentina`,
    dq_country__armenia: `Armenia`,
    dq_country__aruba: `Aruba`,
    dq_country__australia: `Australia`,
    dq_country__austria: `Austria`,
    dq_country__azerbaijan: `Azerbaijan`,
    dq_country__bahamas: `Bahamas`,
    dq_country__bahrain: `Bahrain`,
    dq_country__bangladesh: `Bangladesh`,
    dq_country__barbados: `Barbados`,
    dq_country__belarus: `Belarus`,
    dq_country__belgium: `Belgium`,
    dq_country__belize: `Belize`,
    dq_country__benin: `Benin`,
    dq_country__bermuda: `Bermuda`,
    dq_country__bhutan: `Bhutan`,
    dq_country__bolivia: `Bolivia`,
    dq_country__bosnia_and_herzegovina: `Bosnia and Herzegovina`,
    dq_country__botswana: `Botswana`,
    dq_country__bouvet_island: `Bouvet Island`,
    dq_country__brazil: `Brazil`,
    dq_country__british_indian_ocean_territory: `British Indian Ocean Territory`,
    dq_country__brunei_darussalam: `Brunei Darussalam`,
    dq_country__bulgaria: `Bulgaria`,
    dq_country__burkina_faso: `Burkina Faso`,
    dq_country__burundi: `Burundi`,
    dq_country__cambodia: `Cambodia`,
    dq_country__cameroon: `Cameroon`,
    dq_country__canada: `Canada`,
    dq_country__cape_verde: `Cape Verde`,
    dq_country__cayman_islands: `Cayman Islands`,
    dq_country__central_african_republic: `Central African Republic`,
    dq_country__chad: `Chad`,
    dq_country__chile: `Chile`,
    dq_country__china: `China`,
    dq_country__christmas_island: `Christmas Island`,
    dq_country__cocos_keeling_islands: `Cocos (Keeling) Islands`,
    dq_country__colombia: `Colombia`,
    dq_country__comoros: `Comoros`,
    dq_country__congo: `Congo`,
    dq_country__congo_the_democratic_republic_of_the: `Congo, The Democratic Republic of the`,
    dq_country__cook_islands: `Cook Islands`,
    dq_country__costa_rica: `Costa Rica`,
    dq_country__cote_divoire: `Cote D'Ivoire`,
    dq_country__croatia: `Croatia`,
    dq_country__cuba: `Cuba`,
    dq_country__cyprus: `Cyprus`,
    dq_country__czech_republic: `Czech Republic`,
    dq_country__denmark: `Denmark`,
    dq_country__djibouti: `Djibouti`,
    dq_country__dominica: `Dominica`,
    dq_country__dominican_republic: `Dominican Republic`,
    dq_country__ecuador: `Ecuador`,
    dq_country__egypt: `Egypt`,
    dq_country__el_salvador: `El Salvador`,
    dq_country__equatorial_guinea: `Equatorial Guinea`,
    dq_country__eritrea: `Eritrea`,
    dq_country__estonia: `Estonia`,
    dq_country__ethiopia: `Ethiopia`,
    dq_country__falkland_islands_malvinas: `Falkland Islands (Malvinas)`,
    dq_country__faroe_islands: `Faroe Islands`,
    dq_country__fiji: `Fiji`,
    dq_country__finland: `Finland`,
    dq_country__france: `France`,
    dq_country__french_guiana: `French Guiana`,
    dq_country__french_polynesia: `French Polynesia`,
    dq_country__french_southern_territories: `French Southern Territories`,
    dq_country__gabon: `Gabon`,
    dq_country__gambia: `Gambia`,
    dq_country__georgia: `Georgia`,
    dq_country__germany: `Germany`,
    dq_country__ghana: `Ghana`,
    dq_country__gibraltar: `Gibraltar`,
    dq_country__greece: `Greece`,
    dq_country__greenland: `Greenland`,
    dq_country__grenada: `Grenada`,
    dq_country__guadeloupe: `Guadeloupe`,
    dq_country__guam: `Guam`,
    dq_country__guatemala: `Guatemala`,
    dq_country__guernsey: `Guernsey`,
    dq_country__guinea: `Guinea`,
    dq_country__guinea_bissau: `Guinea-Bissau`,
    dq_country__guyana: `Guyana`,
    dq_country__haiti: `Haiti`,
    dq_country__heard_island_and_mcdonald_islands: `Heard Island and Mcdonald Islands`,
    dq_country__holy_see_vatican_city_state: `Holy See (Vatican City State)`,
    dq_country__honduras: `Honduras`,
    dq_country__hong_kong: `Hong Kong`,
    dq_country__hungary: `Hungary`,
    dq_country__iceland: `Iceland`,
    dq_country__india: `India`,
    dq_country__indonesia: `Indonesia`,
    dq_country__iran_islamic_republic_of: `Iran, Islamic Republic Of`,
    dq_country__iraq: `Iraq`,
    dq_country__ireland: `Ireland`,
    dq_country__isle_of_man: `Isle of Man`,
    dq_country__israel: `Israel`,
    dq_country__italy: `Italy`,
    dq_country__jamaica: `Jamaica`,
    dq_country__japan: `Japan`,
    dq_country__jersey: `Jersey`,
    dq_country__jordan: `Jordan`,
    dq_country__kazakhstan: `Kazakhstan`,
    dq_country__kenya: `Kenya`,
    dq_country__kiribati: `Kiribati`,
    dq_country__korea_democratic_peoples_republic_of: `Korea, Democratic People's Republic of`,
    dq_country__korea_republic_of: `Korea, Republic of`,
    dq_country__kuwait: `Kuwait`,
    dq_country__kyrgyzstan: `Kyrgyzstan`,
    dq_country__lao_peoples_democratic_republic: `Lao People's Democratic Republic`,
    dq_country__latvia: `Latvia`,
    dq_country__lebanon: `Lebanon`,
    dq_country__lesotho: `Lesotho`,
    dq_country__liberia: `Liberia`,
    dq_country__libyan_arab_jamahiriya: `Libyan Arab Jamahiriya`,
    dq_country__liechtenstein: `Liechtenstein`,
    dq_country__lithuania: `Lithuania`,
    dq_country__luxembourg: `Luxembourg`,
    dq_country__macao: `Macao`,
    dq_country__macedonia_the_former_yugoslav_republic_of: `Macedonia, The Former Yugoslav Republic of`,
    dq_country__madagascar: `Madagascar`,
    dq_country__malawi: `Malawi`,
    dq_country__malaysia: `Malaysia`,
    dq_country__maldives: `Maldives`,
    dq_country__mali: `Mali`,
    dq_country__malta: `Malta`,
    dq_country__marshall_islands: `Marshall Islands`,
    dq_country__martinique: `Martinique`,
    dq_country__mauritania: `Mauritania`,
    dq_country__mauritius: `Mauritius`,
    dq_country__mayotte: `Mayotte`,
    dq_country__mexico: `Mexico`,
    dq_country__micronesia_federated_states_of: `Micronesia, Federated States of`,
    dq_country__moldova_republic_of: `Moldova, Republic of`,
    dq_country__monaco: `Monaco`,
    dq_country__mongolia: `Mongolia`,
    dq_country__montserrat: `Montserrat`,
    dq_country__morocco: `Morocco`,
    dq_country__mozambique: `Mozambique`,
    dq_country__myanmar: `Myanmar`,
    dq_country__namibia: `Namibia`,
    dq_country__nauru: `Nauru`,
    dq_country__nepal: `Nepal`,
    dq_country__netherlands: `Netherlands`,
    dq_country__netherlands_antilles: `Netherlands Antilles`,
    dq_country__new_caledonia: `New Caledonia`,
    dq_country__new_zealand: `New Zealand`,
    dq_country__nicaragua: `Nicaragua`,
    dq_country__niger: `Niger`,
    dq_country__nigeria: `Nigeria`,
    dq_country__niue: `Niue`,
    dq_country__norfolk_island: `Norfolk Island`,
    dq_country__northern_mariana_islands: `Northern Mariana Islands`,
    dq_country__norway: `Norway`,
    dq_country__oman: `Oman`,
    dq_country__pakistan: `Pakistan`,
    dq_country__palau: `Palau`,
    dq_country__palestinian_territory_occupied: `Palestinian Territory, Occupied`,
    dq_country__panama: `Panama`,
    dq_country__papua_new_guinea: `Papua New Guinea`,
    dq_country__paraguay: `Paraguay`,
    dq_country__peru: `Peru`,
    dq_country__philippines: `Philippines`,
    dq_country__pitcairn: `Pitcairn`,
    dq_country__poland: `Poland`,
    dq_country__portugal: `Portugal`,
    dq_country__puerto_rico: `Puerto Rico`,
    dq_country__qatar: `Qatar`,
    dq_country__reunion: `Reunion`,
    dq_country__romania: `Romania`,
    dq_country__russian_federation: `Russian Federation`,
    dq_country__rwanda: `RWANDA`,
    dq_country__saint_helena: `Saint Helena`,
    dq_country__saint_kitts_and_nevis: `Saint Kitts and Nevis`,
    dq_country__saint_lucia: `Saint Lucia`,
    dq_country__saint_pierre_and_miquelon: `Saint Pierre and Miquelon`,
    dq_country__saint_vincent_and_the_grenadines: `Saint Vincent and the Grenadines`,
    dq_country__samoa: `Samoa`,
    dq_country__san_marino: `San Marino`,
    dq_country__sao_tome_and_principe: `Sao Tome and Principe`,
    dq_country__saudi_arabia: `Saudi Arabia`,
    dq_country__senegal: `Senegal`,
    dq_country__serbia_and_montenegro: `Serbia and Montenegro`,
    dq_country__seychelles: `Seychelles`,
    dq_country__sierra_leone: `Sierra Leone`,
    dq_country__singapore: `Singapore`,
    dq_country__slovakia: `Slovakia`,
    dq_country__slovenia: `Slovenia`,
    dq_country__solomon_islands: `Solomon Islands`,
    dq_country__somalia: `Somalia`,
    dq_country__south_africa: `South Africa`,
    dq_country__south_georgia_and_the_south_sandwich_islands: `South Georgia and the South Sandwich Islands`,
    dq_country__spain: `Spain`,
    dq_country__sri_lanka: `Sri Lanka`,
    dq_country__sudan: `Sudan`,
    dq_country__suriname: `Suriname`,
    dq_country__svalbard_and_jan_mayen: `Svalbard and Jan Mayen`,
    dq_country__swaziland: `Swaziland`,
    dq_country__sweden: `Sweden`,
    dq_country__switzerland: `Switzerland`,
    dq_country__syrian_arab_republic: `Syrian Arab Republic`,
    dq_country__taiwan_province_of_china: `Taiwan, Province of China`,
    dq_country__tajikistan: `Tajikistan`,
    dq_country__tanzania_united_republic_of: `Tanzania, United Republic of`,
    dq_country__thailand: `Thailand`,
    dq_country__timor_leste: `Timor-Leste`,
    dq_country__togo: `Togo`,
    dq_country__tokelau: `Tokelau`,
    dq_country__tonga: `Tonga`,
    dq_country__trinidad_and_tobago: `Trinidad and Tobago`,
    dq_country__tunisia: `Tunisia`,
    dq_country__turkey: `Turkey`,
    dq_country__turkmenistan: `Turkmenistan`,
    dq_country__turks_and_caicos_islands: `Turks and Caicos Islands`,
    dq_country__tuvalu: `Tuvalu`,
    dq_country__uganda: `Uganda`,
    dq_country__ukraine: `Ukraine`,
    dq_country__united_arab_emirates: `United Arab Emirates`,
    dq_country__united_kingdom: `United Kingdom`,
    dq_country__united_states: `United States`,
    dq_country__united_states_minor_outlying_islands: `United States Minor Outlying Islands`,
    dq_country__uruguay: `Uruguay`,
    dq_country__uzbekistan: `Uzbekistan`,
    dq_country__vanuatu: `Vanuatu`,
    dq_country__venezuela: `Venezuela`,
    dq_country__viet_nam: `Viet Nam`,
    dq_country__virgin_islands_british: `Virgin Islands, British`,
    dq_country__virgin_islands_us: `Virgin Islands, U.S.`,
    dq_country__wallis_and_futuna: `Wallis and Futuna`,
    dq_country__western_sahara: `Western Sahara`,
    dq_country__yemen: `Yemen`,
    dq_country__zambia: `Zambia`,
    dq_country__zimbabwe: `Zimbabwe`,
    dq_demographic__postal_code: `Postal Code`,
    dq_demographic__next: `Next`,
    dq_demographic__get_score: `Get Score`,
    dq_demographic__plz_fill_req_fields: `Please fill all the required fields`,
    uo__lets_start: `Let's Start`,
    uo__dct_test_intro_message_text: `This test will help you check your level of digital citizenship, to understand whether you are ready to use digital devices and media safely and responsibly.`,
    quiz_result_see_your_score: `See Your Score!`,
    post_quiz_resume_test: `Resume Test`,
    uo__username: `Username`,
    uo__failed_to_proceed: `Failed to Proceed!`,
    uo__save_failed_try_again: `Save Failed! Try Again...`,
    uo__continue_as_guest: `Continue as a Guest?`,
    uo__guest_login: `Guest login`,
    dq_profile__inactive: `Your account is not active, please contact your organization`,
    dq_profile__multiple: `Your account is associated with multiple organizations, please select one profile`,
    dq_profile__not_exists: `This profile does not exist`,
    dq_platform__select: `Please select a platform`,
    dq_platform__dq_assessment: `DQ Assessment`,
    dq_platform__dq_world: `DQ World`,
    uo__page_not_found: `Not found`,
    uo__goto_home: `Go to Home Page`,
    uo__wrong_username_enter: `Wrong username entered!`,
    uo__wrong_pass_enter: `Wrong password entered!`,
}


const lc_ko = {
    menu__hello_text: `안녕하세요`,
    menu__guest_text: `손님`,
    menu__home: `홈 화면`,
    menu__register: `등록하기`,
    menu__settings: `설정`,
    menu__switch_profile: `프로필 전환`,
    menu__goto_dqw: `DQ World 바로가기`,
    menu__logout: `로그아웃하기`,
    uo__welcome_dct_text: `디지털 시민의식 테스트에 오신 것을 환영합니다!`,
    uo__start_test_text: `무료 테스트 시작하기`,
    post_q_landing__congrats: `축하합니다!`,
    post_q_landing__finished_test_message_text: `테스트를 마쳤습니다.<br>점수를 확인하기 위해서 계정을 만들어주세요.`,
    post_q_landing__finished_test_short_message: `테스트를 마쳤습니다.`,
    post_q_landing__if_under_18_text: `18세 이하의 사용자는 부모님이나 보호자의 이메일 동의가 필요합니다.`,
    post_q_landing__signup: `가입하기`,
    post_q_landing__no_thanks: `괜찮습니다.`,
    persist_guest__fname: `이름`,
    persist_guest__lname: `성`,
    persist_guest__email_address: `이메일 주소`,
    persist_guest__require_parent_email_text: `(18세 이하는 부모님/보호자의 이메일 동의가 필요합니다)`,
    persist_guest__password: `비밀번호`,
    persist_guest__confirm_password: `비밀번호 확인`,
    persist_guest__have_access_code: `접속 코드가 있나요? 클릭하여 들어가세요.`,
    persist_guest__access_code_span: `접속 코드<span style="font-weight: bold;">(가리기)</span>`,
    persist_guest__accept_terms_of_use: `나는 <br><a href="/terms_of_use" target="_blank"> 이용약관</a>과 <a href="/privacy_policy" target="_blank"> 개인정보 정책</a>을 읽어보았고 이에 동의합니다.`,
    persist_guest__register: `등록하기`,
    persist_guest__already_have_account: `이미 계정이 있나요?`,
    persist_guest__login_here: `로그인하기`,
    persist_guest__login: `로그인`,
    persist_guest__signup:`가입하기`,
    persist_guest__not_have_an_account: `계정이 없다면`,
    persist_guest__register_here: `등록하기`,
    persist_guest__forgot_password: `비밀번호를 잊으 셨나요?`,
    persist_guest__login_to_dashboard: `Login to DQ Dashboard`,
    persist_guest__login_with_google: `Login with Google`,
    persist_guest__register_with_google: `Register with Google`,
    persist_guest__reset_pass_guide: `등록된 사용자 이름을 입력하십시오.<br/>그러면 계정과 연결된 이메일을 통해 비밀번호 재설정 링크를 보내드립니다.`,
    persist_guest__send_link: `링크 보내기`,
    persist_guest__send_link_success: `재설정 링크가 전송되었습니다\n받은 편지함을 확인!`,
    persist_guest__send_link_failed: `재설정 링크를 보내지 못했습니다`,
    persist_guest__reset_password: `암호를 재설정`,
    persist_guest__reset_pass_success: `비밀번호를 변경했습니다. 사용자 이름 또는 이메일로 새 비밀번호로 로그인하십시오.`,
    persist_guest__reset_pass_failed: `재설정 비밀번호를 보내지 못했습니다`,
    persist_guest__reset_pass_link_invalid: `Invalid Reset Password Link`,
    persist_guest__recover_password_here: `Recover Password Here`,
    persist_guest__go_to_login: `로그인으로 이동`,
    uo__register_as_individual: `개인으로 등록하기`,
    uo__register_as_company: `기업으로 등록하기`,
    uo__register_as_school: `학교로 등록하기`,
    uo__not_found: `찾을 수 없음`,
    persist_guest__privacy_policy: `개인정보 정책`,
    persist_guest__terms_of_use: `이용약관`,
    persist_guest__wrong_pass_user_not_found: `잘못된 아이디/이메일입니다. 사용자를 찾을 수 없습니다.`,
    persist_guest__plz_fill_req_fields: `필요한 정보를 기입해주세요.`,
    uo__plz_accept_terms_of_use: `이용약관에 동의해주세요.`,
    uo__pass_mismatch_plz_retype: `비밀번호 오류! 다시 입력해주세요.`,
    uo__err_loading_guest: `잘못된 접근입니다!`,
    uo__plz_set_ur_new_pass: `새로운 비밀번호를 설정해주세요.`,
    uo__no_assessment_available_chk_later: `현재 평가가 불가능합니다. 나중에 다시 시도해주세요.`,
    uo__dq_under_maintainance_txt: `DQ 평가가 현재 점검 중입니다.`,
    uo__not_available_try_later: `현재 이용이 불가능합니다. 나중에 다시 시도해주세요.`,
    uo__submit: `제출하기`,
    uo__view_score: `점수 확인하기`,
    uo__admin_login: `관리자 로그인`,
    uo__plz_wait: `기다려주세요.`,
    uo__loading: `로딩 중`,
    uo__loaded: `로딩 완료`,
    result_quiz__require_attention: `주의가 필요합니다!`,
    result_quiz__bellow_avg: `평균 이하입니다!`,
    result_quiz__good_job: `좋습니다!`,
    result_quiz__excellent: `훌륭합니다!`,
    result_quiz__require_attention_br: `주의가<br>필요합니다`,
    result_quiz__above_avg_br: `평균<br>이상입니다 `,
    result_quiz__bellow_avg_br: `평균<br>이하입니다`,
    result_quiz__excellent_br: `훌륭합니다`,
    result_quiz__high: `높은`,
    result_quiz__medium: `중간`,
    result_quiz__low: `낮은`,
    result_quiz__high_score_description: `잘했습니다! 당신은 디지털 세계를 탐색하는 데 능숙합니다. 계속해서 좋은 일을 하고 통찰력을 공유하는 것에 대해 생각해 보세요.`,
    result_quiz__medium_score_description: `귀하는 디지털 행동에 대한 기본적인 이해를 바탕으로 올바른 길을 가고 있습니다. 지식을 확장하면 디지털 경험이 더욱 향상될 수 있습니다.`,
    result_quiz__low_score_description: `디지털 여정에서 성장할 기회가 있습니다. 교육 자료를 탐색하면 온라인에 대한 이해도와 영향력을 높이는 데 도움이 될 수 있습니다.`,
    result_quiz__mydq_score: `나의 DQ 점수`,
    result_quiz__mydq_badges: `나의 DQ 배지`,
    result_quiz__you_are_ready_to_use_tech_rubirc: `귀右ㅏ는 기슬을 사용힐 준비가 되었습니 다． 귀하는 기술을 인전하게 사용하는 방법을 이해합니 다． 더 좋은 결과를 위해 계속해서 디지 털 기술을 쌓아보세요．`,
    result_quiz__your_score_is_above_average_rubric: `귀右『의 점수는 글로벌 평균보다 높습니다． 포괄적인 디지털 시민권 기술을 계속 쌓는 것이 좇습니다．`,
    result_quiz__your_score_is_less_than_average_rubirc: `귀하의 점수는 글로벌 평균보다 낮습니다． 기술을 적극적으로 사용하는 경우 디지털 시 민권 기술을 힝상하는 것이 좇습니다．`,
    result_quiz__you_may_potentially_be_exposed_rubirc: `귀하는 디지털 사용과 관련된 하나 이상의 사이버 위헝 및 건강에 해로운 습관에 잠재적으로 노출될 수 있습니다． 사이버 위험 노출 수준을 확인하는 것이 좇습니다．`,
    result_quiz__your_score_span: `당신의 점수는`,
    result_quiz__dct_discalimer_html_text: `*주의: 이 디지털 시민의식 테스트는 디지털 시민의 중요성에 대한 인식을 높이기 위해 개발되었으며, 사이버 위험도 및 디지털 역량 수준을 확인하는 데 정확하지 않을 수 있습니다. 전체 평가에 관심이 있는 경우 <a href="/" target="_blank">여기</a>에서 더 알아볼 수 있습니다.`,
    result_quiz__click_message_dct_type: `당신의 디지털 시민 타입을 알고 싶다면 '다음'을 클릭하세요.`,
    result_quiz__next: `다음`,
    result_quiz__carefree_citizen: `골수 디지털 네이게이터`,
    result_quiz__feel_comfort_online_text: `당신은 오프라인보다 온라인에서 생활하는 것이 더 편함.`,
    result_quiz__invlove_with_risks_text: `하지만 다양한 디지털 위험에 이미 노출되어 있을 수 있음.`,
    result_quiz__ordinary_citizen: `평범한 디지털 유저`,
    result_quiz__avg_tech_user_message_text: `디지털 세상의 위험도, 그렇다고 기회도 완전히 잘 알지는 못함.`,
    result_quiz__tend_to_follow_algo: `디지털에서 추천하는 알고리즘을 수동적으로 따르는 경향이 있음.`,
    result_quiz__always_on_citizen: `상시접속형 디지털 관종`,
    result_quiz__like_comm_with_others: `온라인으로 사람들과 소통하는 것에 많은 시간을 투자함.`,
    result_quiz__maynot_consider_risks: `디지털 위험 따위는 크게 안중에 없음.`,
    result_quiz__catious_citizen: `조심성 많은 디지털 경계형`,
    result_quiz__aware_of_risks_not_fully_explore: `디지털 위험을 잘 인지하고 있고, 디지털 이용에 대한 경계심을 가지고 있어 쉽게 위험에 빠지지는 않음.`,
    result_quiz__likely_having_risks_in_dw: `그렇지만 디지털을 제대로 이용하지도 않음.`,
    result_quiz__digilog_citizen: `균형 잡힌 디지로그형`,
    result_quiz__balanced_digital_citizen: `온라인과 오프라인의 삶을 균형 잡을 줄 아는 모범적인 디지털 시민.`,
    result_quiz__use_tech_mindfully: `기술을 제대로 이해하고, 신중하게 사용할 줄 알음.`,
    result_quiz__learn_more_html_text: `<a href="/" target="_blank">DQ 인덱스</a>에 대해 더 알아보세요.`,
    result_quiz__done: `완료`,
    dq_home__min_duration: `분`,
    dq_home__dc_test: `디지털 시민권 시험`, //verify
    dq_home__free_assess: `무료 평가`,
    dq_home__bellow_avg: `평균 이하`,
    dq_home__avg: `평균`,
    dq_home__excellent: `평균 이상`,
    dq_home__top_percent: `상위`,
    dq_home__bottom_percent: `하위`,
    dq_home__dc_score_helps_what_message: `디지털 시민의식 점수는 당신이 얼마나 디지털 기술을 안전하고 책임감 있게 사용할 수 있는 역량이 있는지에 대해 알려줍니다. 또한 당신의 디지털 시민 타입을 확인해보세요.`,
    dq_home__share: `공유하기`,
    dq_home__retake: `다시하기`,
    dq_home__myscore_comparison: `점수 비교하기`,
    dq_home__myscore: `나의 점수`,
    dq_home__national_avg: `국가 평균`,
    dq_home__global_avg: `세계 평균`,
    dq_home__retake_test: `테스트 다시 하기`,
    dq_demographic__gender: `성별`,
    dq_demographic__male: `남성`,
    dq_demographic__female: `여성`,
    dq_demographic__non_binary: `넌바이너리`,
    dq_demographic__prefer_not_to_answer: `대답하고 싶지 않음`,
    dq_demographic__other: `기타`,
    dq_demographic__other_specify: `명시 해주세요`,
    dq_demographic__year_of_birth: `출생연도`,
    dq_demographic__country: `국가`,
    dq_country__afghanistan: `아프가니스탄`,
    dq_country__aland_islands: `올란드 제도`,
    dq_country__albania: `알바니아`,
    dq_country__algeria: `알제리`,
    dq_country__american_samoa: `사모아`,
    dq_country__andorra: `안도라`,
    dq_country__angola: `앙골라`,
    dq_country__anguilla: `앵귈라`,
    dq_country__antarctica: `남극`,
    dq_country__antigua_and_barbuda: `앤티가바부다`,
    dq_country__argentina: `아르헨티나`,
    dq_country__armenia: `아르메니아`,
    dq_country__aruba: `아루바`,
    dq_country__australia: `호주`,
    dq_country__austria: `오스트리아`,
    dq_country__azerbaijan: `아제르바이잔`,
    dq_country__bahamas: `바하마`,
    dq_country__bahrain: `바레인`,
    dq_country__bangladesh: `방글라데시`,
    dq_country__barbados: `바베이도스`,
    dq_country__belarus: `벨라루스`,
    dq_country__belgium: `벨기에`,
    dq_country__belize: `벨리즈`,
    dq_country__benin: `베냉`,
    dq_country__bermuda: `버뮤다`,
    dq_country__bhutan: `부탄`,
    dq_country__bolivia: `볼리비아`,
    dq_country__bosnia_and_herzegovina: `보스니아 헤르체고비나`,
    dq_country__botswana: `보츠와나`,
    dq_country__bouvet_island: `부벳 섬`,
    dq_country__brazil: `브라질`,
    dq_country__british_indian_ocean_territory: `영국령 인도양 준주`,
    dq_country__brunei_darussalam: `브루나이`,
    dq_country__bulgaria: `불가리아`,
    dq_country__burkina_faso: `부르키나파소`,
    dq_country__burundi: `부룬디`,
    dq_country__cambodia: `캄보디아`,
    dq_country__cameroon: `카메룬`,
    dq_country__canada: `캐나다`,
    dq_country__cape_verde: `카보베르데`,
    dq_country__cayman_islands: `케이맨 제도`,
    dq_country__central_african_republic: `중앙 아프리카 공화국`,
    dq_country__chad: `차드`,
    dq_country__chile: `칠레`,
    dq_country__china: `중국`,
    dq_country__christmas_island: `크리스마스 섬`,
    dq_country__cocos_keeling_islands: `코코스 (킬링) 제도`,
    dq_country__colombia: `콜롬비아`,
    dq_country__comoros: `코모로`,
    dq_country__congo: `콩고`,
    dq_country__congo_the_democratic_republic_of_the: `콩고 민주공화국`,
    dq_country__cook_islands: `쿡 제도`,
    dq_country__costa_rica: `코스타리카`,
    dq_country__cote_divoire: `코트디부아르`,
    dq_country__croatia: `크로아티아`,
    dq_country__cuba: `쿠바`,
    dq_country__cyprus: `키프로스`,
    dq_country__czech_republic: `체코`,
    dq_country__denmark: `덴마크`,
    dq_country__djibouti: `지부티`,
    dq_country__dominica: `도미니카`,
    dq_country__dominican_republic: `도미니카 공화국`,
    dq_country__ecuador: `에콰도르`,
    dq_country__egypt: `이집트`,
    dq_country__el_salvador: `엘살바도르`,
    dq_country__equatorial_guinea: `적도 기니`,
    dq_country__eritrea: `에리트레아`,
    dq_country__estonia: `에스토니아`,
    dq_country__ethiopia: `에티오피아`,
    dq_country__falkland_islands_malvinas: `포클랜드 제도 (말비나스)`,
    dq_country__faroe_islands: `페로 제도`,
    dq_country__fiji: `피지`,
    dq_country__finland: `핀란드`,
    dq_country__france: `프랑스`,
    dq_country__french_guiana: `프랑스령 기아나`,
    dq_country__french_polynesia: `프랑스령 폴리네시아`,
    dq_country__french_southern_territories: `프랑스 남부 영토`,
    dq_country__gabon: `가봉`,
    dq_country__gambia: `감비아`,
    dq_country__georgia: `그루지야`,
    dq_country__germany: `독일`,
    dq_country__ghana: `가나`,
    dq_country__gibraltar: `지브롤터`,
    dq_country__greece: `그리스`,
    dq_country__greenland: `그린란드`,
    dq_country__grenada: `그라나다`,
    dq_country__guadeloupe: `과들루프`,
    dq_country__guam: `괌`,
    dq_country__guatemala: `과테말라`,
    dq_country__guernsey: `건지`,
    dq_country__guinea: `기니`,
    dq_country__guinea_bissau: `기니비사우`,
    dq_country__guyana: `가이아나`,
    dq_country__haiti: `아이티`,
    dq_country__heard_island_and_mcdonald_islands: `허드 아일랜드와 맥도날드 제도`,
    dq_country__holy_see_vatican_city_state: `바티칸 시국`,
    dq_country__honduras: `온두라스`,
    dq_country__hong_kong: `홍콩`,
    dq_country__hungary: `헝가리`,
    dq_country__iceland: `아이슬란드`,
    dq_country__india: `인도`,
    dq_country__indonesia: `인도네시아`,
    dq_country__iran_islamic_republic_of: `이란`,
    dq_country__iraq: `이라크`,
    dq_country__ireland: `아일랜드`,
    dq_country__isle_of_man: `맨 섬`,
    dq_country__israel: `이스라엘`,
    dq_country__italy: `이탈리아`,
    dq_country__jamaica: `자메이카`,
    dq_country__japan: `일본`,
    dq_country__jersey: `저지 섬`,
    dq_country__jordan: `요르단`,
    dq_country__kazakhstan: `카자흐스탄`,
    dq_country__kenya: `케냐`,
    dq_country__kiribati: `키리바시`,
    dq_country__korea_democratic_peoples_republic_of: `조선민주주의인민공화국`,
    dq_country__korea_republic_of: `대한민국`,
    dq_country__kuwait: `쿠웨이트`,
    dq_country__kyrgyzstan: `키르기스스탄`,
    dq_country__lao_peoples_democratic_republic: `라오스 인민민주공화국`,
    dq_country__latvia: `라트비아`,
    dq_country__lebanon: `레바논`,
    dq_country__lesotho: `레소토`,
    dq_country__liberia: `라이베리아`,
    dq_country__libyan_arab_jamahiriya: `리비아 아랍 자마히리야`,
    dq_country__liechtenstein: `리히텐슈타인`,
    dq_country__lithuania: `리투아니아`,
    dq_country__luxembourg: `룩셈부르크`,
    dq_country__macao: `마카오`,
    dq_country__macedonia_the_former_yugoslav_republic_of: `마케도니아`,
    dq_country__madagascar: `마다가스카르`,
    dq_country__malawi: `말라위`,
    dq_country__malaysia: `말레이시아`,
    dq_country__maldives: `몰디브`,
    dq_country__mali: `말리`,
    dq_country__malta: `몰타`,
    dq_country__marshall_islands: `마셜 제도`,
    dq_country__martinique: `마르티니크`,
    dq_country__mauritania: `모리타니`,
    dq_country__mauritius: `모리셔스`,
    dq_country__mayotte: `마요트`,
    dq_country__mexico: `멕시코`,
    dq_country__micronesia_federated_states_of: `미크로네시아, 연방연방국가`,
    dq_country__moldova_republic_of: `몰도바 공화국`,
    dq_country__monaco: `모나코`,
    dq_country__mongolia: `몽골`,
    dq_country__montserrat: `몬세라트`,
    dq_country__morocco: `모로코`,
    dq_country__mozambique: `모잠비크`,
    dq_country__myanmar: `미얀마`,
    dq_country__namibia: `나미비아`,
    dq_country__nauru: `나우루`,
    dq_country__nepal: `네팔어`,
    dq_country__netherlands: `네덜란드`,
    dq_country__netherlands_antilles: `네덜란드 안틸레스`,
    dq_country__new_caledonia: `누벨칼레도니`,
    dq_country__new_zealand: `뉴질랜드`,
    dq_country__nicaragua: `니카라과`,
    dq_country__niger: `니제르`,
    dq_country__nigeria: `나이지리아`,
    dq_country__niue: `니우에`,
    dq_country__norfolk_island: `노퍽 섬`,
    dq_country__northern_mariana_islands: `북마리아나 제도`,
    dq_country__norway: `노르웨이`,
    dq_country__oman: `오만`,
    dq_country__pakistan: `파키스탄`,
    dq_country__palau: `팔라우`,
    dq_country__palestinian_territory_occupied: `팔레스타인`,
    dq_country__panama: `파나마`,
    dq_country__papua_new_guinea: `파푸아뉴기니`,
    dq_country__paraguay: `파라과이`,
    dq_country__peru: `페루`,
    dq_country__philippines: `필리핀`,
    dq_country__pitcairn: `핏케언`,
    dq_country__poland: `폴란드`,
    dq_country__portugal: `포르투갈`,
    dq_country__puerto_rico: `푸에르토리코`,
    dq_country__qatar: `카타르`,
    dq_country__reunion: `레위니옹`,
    dq_country__romania: `루마니아`,
    dq_country__russian_federation: `러시아 연방`,
    dq_country__rwanda: `르완다`,
    dq_country__saint_helena: `세인트 헬레나`,
    dq_country__saint_kitts_and_nevis: `세인트 키츠 네비스`,
    dq_country__saint_lucia: `세인트루시아`,
    dq_country__saint_pierre_and_miquelon: `생 피에르와 미켈론`,
    dq_country__saint_vincent_and_the_grenadines: `세인트 빈센트와 그레나딘`,
    dq_country__samoa: `사모아`,
    dq_country__san_marino: `산마리노`,
    dq_country__sao_tome_and_principe: `상 토메와 프린시페`,
    dq_country__saudi_arabia: `사우디아라비아`,
    dq_country__senegal: `세네갈`,
    dq_country__serbia_and_montenegro: `세르비아 몬테네그로`,
    dq_country__seychelles: `세이셸`,
    dq_country__sierra_leone: `시에라리온`,
    dq_country__singapore: `싱가포르`,
    dq_country__slovakia: `슬로바키아`,
    dq_country__slovenia: `슬로베니아`,
    dq_country__solomon_islands: `솔로몬 제도`,
    dq_country__somalia: `소말리아`,
    dq_country__south_africa: `남아프리카 공화국`,
    dq_country__south_georgia_and_the_south_sandwich_islands: `사우스 조지아 및 사우스 샌드위치 제도`,
    dq_country__spain: `스페인`,
    dq_country__sri_lanka: `스리랑카`,
    dq_country__sudan: `수단`,
    dq_country__suriname: `수리남`,
    dq_country__svalbard_and_jan_mayen: `스발바르와 얀 마옌`,
    dq_country__swaziland: `스와질란드`,
    dq_country__sweden: `스웨덴`,
    dq_country__switzerland: `스위스`,
    dq_country__syrian_arab_republic: `시리아`,
    dq_country__taiwan_province_of_china: `대만`,
    dq_country__tajikistan: `타지키스탄`,
    dq_country__tanzania_united_republic_of: `탄자니아`,
    dq_country__thailand: `태국`,
    dq_country__timor_leste: `동티모르`,
    dq_country__togo: `토고`,
    dq_country__tokelau: `토켈라우`,
    dq_country__tonga: `통가`,
    dq_country__trinidad_and_tobago: `트리니다드 토바고`,
    dq_country__tunisia: `튀니지`,
    dq_country__turkey: `터키`,
    dq_country__turkmenistan: `투르크메니스탄`,
    dq_country__turks_and_caicos_islands: `터크스 케이커스 제도`,
    dq_country__tuvalu: `투발루`,
    dq_country__uganda: `우간다`,
    dq_country__ukraine: `우크라이나`,
    dq_country__united_arab_emirates: `아랍 에미리트 연합국`,
    dq_country__united_kingdom: `영국`,
    dq_country__united_states: `미국`,
    dq_country__united_states_minor_outlying_islands: `미국령 제도`,
    dq_country__uruguay: `우루과이`,
    dq_country__uzbekistan: `우즈베키스탄`,
    dq_country__vanuatu: `바누아투`,
    dq_country__venezuela: `베네수엘라`,
    dq_country__viet_nam: `베트남`,
    dq_country__virgin_islands_british: `버진 아일랜드, 영국`,
    dq_country__virgin_islands_us: `버진 아일랜드, 미국`,
    dq_country__wallis_and_futuna: `월리스와 푸투나`,
    dq_country__western_sahara: `서사하라`,
    dq_country__yemen: `예멘`,
    dq_country__zambia: `잠비아`,
    dq_country__zimbabwe: `짐바브웨`,
    dq_demographic__postal_code: `우편번호`,
    dq_demographic__next: `다음`,
    dq_demographic__get_score: `점수 얻기`,
    dq_demographic__plz_fill_req_fields: `필수 정보를 기입해주세요.`,
    uo__lets_start: `시작해봅시다.`,
    uo__dct_test_intro_message_text: `이 테스트는 여러분의 디지털 시민의식 수준을 확인하도록 돕고, 여러분이 디지털 기기와 미디어를 안전하고 책임감 있게 사용할 준비가 되어 있는지 알려줍니다.`,
    quiz_result_see_your_score: `여러분의 점수를 확인하세요!`,
    post_quiz_resume_test: `테스트 계속하기`,
    uo__username: `사용자 이름`,
    uo__failed_to_proceed: `진행하지 못했습니다!`,
    uo__save_failed_try_again: `저장 실패! 다시 시도하십시오...`,
    uo__continue_as_guest: `게스트로 계속하시겠습니까?`,
    uo__guest_login: `게스트 로그인`,
    dq_profile__inactive: `Your account is not active, please contact your organization`,
    dq_profile__multiple: `Your account is associated with multiple organizations, please select one profile`,
    dq_profile__not_exists: `This profile does not exist`,
    dq_platform__select: `Please select a platform`,
    dq_platform__dq_assessment: `DQ Assessment`,
    dq_platform__dq_world: `DQ World`,
    uo__page_not_found: `Not found`,
    uo__goto_home: `Go to Home Page`,
}

const lc_ja = {
    menu__hello_text: `こんにちは`,
    menu__guest_text: `ゲスト`,
    menu__home: `ホーム`,
    menu__register: `アカウント登録`,
    menu__settings: `設定`,
    menu__switch_profile: `プロフィールを切り替える`,
    menu__goto_dqw: `DQ Worldに移動する`,
    menu__logout: `ログアウト`,
    uo__welcome_dct_text: `デジタル・シティズンシップテストへ<br>ようこそ!`,
    uo__start_test_text: `テストを開始する`,
    post_q_landing__congrats: `おめでとうございます！`,
    post_q_landing__finished_test_message_text: `テストは終了しました。<br>スコアを取得するためには、アカウントを登録する必要があります。`,
    post_q_landing__finished_test_short_message: `テストは終了しました。`,
    post_q_landing__if_under_18_text: `18歳未満の方が登録する場合は保護者の方のメールアドレスと同意が必要です。`,
    post_q_landing__signup: `サインアップする`,
    post_q_landing__no_thanks: `いいえ`,
    persist_guest__fname: `名前（なまえ）`,
    persist_guest__lname: `名字（みょうじ）`,
    persist_guest__email_address: `メールアドレス`,
    persist_guest__require_parent_email_text: `(18さいみまんの方は、お家の方のメールアドレスが必要です。)`,
    persist_guest__password: `パスワード`,
    persist_guest__confirm_password: `パスワードをかくにんする`,
    persist_guest__have_access_code: `アクセスコードをお持ちですか？持っている場合、クリックして入力してください。`,
    persist_guest__access_code_span: `アクセスコード <span style="font-weight: bold;">(非表示にするにはここをクリックしてください)</span>`,
    persist_guest__accept_terms_of_use: `<a href="/terms_of_use" target="_blank">利用規約</a>と<a href="/privacy_policy" target="_blank">プライバシー ポリシー</a><br>を読み、同意しました。`,
    persist_guest__register: `アカウント登録`,
    persist_guest__already_have_account: `すでにアカウントをお持ちですか？`,
    persist_guest__login_here: `ここからログインしてください`,
    persist_guest__login: `ログインする`,
    persist_guest__signup:`サインアップ`,
    persist_guest__not_have_an_account: `アカウントをお持ちでない場合`,
    persist_guest__register_here: `ここからアカウント登録をしてください`,
    persist_guest__forgot_password: `パスワードを忘れた`,
    persist_guest__login_to_dashboard: `ダッシュボードにログイン`,
    persist_guest__login_with_google: `Googleでログイン`,
    persist_guest__register_with_google: `Register with Google`,
    persist_guest__reset_pass_guide: `登録済みのユーザー名を入力してください。<br/>アカウントに登録されているメールアドレスにパスワードのリセットリンクを送信します。`,
    persist_guest__send_link: `リンクを送る`,
    persist_guest__send_link_success: `パスワードのリセットリンクを送信しました。\nメールボックスを確認してください。`,
    persist_guest__send_link_failed: `パスワードのリセットリンクを送れませんでした。`,
    persist_guest__reset_password: `パスワードをリセットする`,
    persist_guest__reset_pass_success: `パスワードを変更しました。新しいパスワードでもう一度ログインをお試しください。`,
    persist_guest__reset_pass_failed: `リセットするパスワードを送信できませんでした。`,
    persist_guest__reset_pass_link_invalid: `無効なパスワードのリセットリンクです。`,
    persist_guest__recover_password_here: `パスワードを確認する`,
    persist_guest__go_to_login: `ログインする`,
    uo__register_as_individual: `アカウント登録する`,
    uo__register_as_company: `企業として登録する`,
    uo__register_as_school: `学校として登録する`,
    uo__not_found: `見つかりません。`,
    persist_guest__privacy_policy: `プライバシーポリシー`,
    persist_guest__terms_of_use: `利用規約`,
    persist_guest__wrong_pass_user_not_found: `入力されたユーザー名またはメールアドレスが間違っています。: ユーザーが見つかりません。`,
    persist_guest__plz_fill_req_fields: `すべての必須項目を入力してください。`,
    uo__plz_accept_terms_of_use: `利用規約に同意してください。`,
    uo__pass_mismatch_plz_retype: `パスワードがまちがっています。もう一度試してください。`,
    uo__err_loading_guest: `ゲストユーザーの読み込みに失敗しました。`,
    uo__plz_set_ur_new_pass: `新しいパスワードを設定してください。`,
    uo__no_assessment_available_chk_later: `現在、アセスメントをご利用いただけません。`,
    uo__dq_under_maintainance_txt: `DQアセスメントは現在メンテナンス中です。`,
    uo__not_available_try_later: `現在ご利用いただけません。後でもう一度お試しください。`,
    uo__submit: `送信`,
    uo__view_score: `スコアを見る`,
    uo__admin_login: `管理者のログインはこちら`,
    uo__plz_wait: `お待ちください...`,
    uo__loading: `読み込み中...`,
    uo__loaded: `読み込み完了`,
    result_quiz__require_attention: `要注意`,
    result_quiz__bellow_avg: `平均以下`,
    result_quiz__good_job: `とても良い`,
    result_quiz__excellent: `素晴らしい`,
    result_quiz__require_attention_br: `注意が必要`,
    result_quiz__above_avg_br: `平均以上`,
    result_quiz__bellow_avg_br: `平均以下`,
    result_quiz__excellent_br: `素晴らしい`,
    result_quiz__high: `高い`,
    result_quiz__medium: `中くらい`,
    result_quiz__low: `低い`,
    result_quiz__high_score_description: `素晴らしい出来です！あなたはデジタル世界をナビゲートすることに精通しています。これからも頑張って、自分の洞察を共有することを考えてください。`,
    result_quiz__medium_score_description: `あなたはデジタル行為の基本を理解し、正しい方向に進んでいます。知識を広げることで、デジタル体験をさらに強化できる可能性があります。`,
    result_quiz__low_score_description: `デジタル化への取り組みには成長のチャンスがあります。教材を調べると、オンラインでの理解と効果が向上します。`,
    result_quiz__mydq_score: `DQスコア`,
    result_quiz__mydq_badges: `マイDQバッジ`,
    result_quiz__you_are_ready_to_use_tech_rubirc: `あなたは、テクノロジーを安全に使用する方法を理解し、デジタル端末を使う準備ができています。この調子でデジタルスキルを高めましょう。`,
    result_quiz__your_score_is_above_average_rubric: `あなたの点数は、世界平均と比較すると平均以上です。この調子でデジタルシティズンシップのすべてのスキルを身につけていきましょう。`,
    result_quiz__your_score_is_less_than_average_rubirc: `あなたの点数は、世界平均と比較して平均以下です。テクノロジーをよく使っている場合は、デジタルシティズンシップスキルをさらに高めることが必要です。`,
    result_quiz__you_may_potentially_be_exposed_rubirc: `あなたは、デジタル端末を使う時、1つ以上のインターネット上の危険や不健康な習慣にさらされている可能性があります。どのくらい危険にあっているのか確認することをおすすめします。`,
    result_quiz__your_score_span: `あなたのスコアは`,
    result_quiz__dct_discalimer_html_text: `*このデジタル・シティズンシップテストは、デジタル・シティズンシップの重要性について認識を高めるために開発されたものであり、サイバーリスクの危険やデジタルスキルのレベルを正確に測定できない場合があります。より精度の高いテストの受験を希望する場合は、<a href="/" target="_blank">こちら</a>から詳細をご覧ください。`,
    result_quiz__click_message_dct_type: `「次へ」をクリックして、あなたのデジタル市民のタイプを確認します。`,
    result_quiz__next: `次へ`,
    result_quiz__carefree_citizen: `不注意な市民`,
    result_quiz__feel_comfort_online_text: `オンラインの世界の方が現実世界より心地よく感じています。`,
    result_quiz__invlove_with_risks_text: `さまざまなサイバーリスクにまきこまれる可能性があります。`,
    result_quiz__ordinary_citizen: `ふつうの市民`,
    result_quiz__avg_tech_user_message_text: `オンラインの世界がもつリスクやチャンスを十分に理解していない普通の市民です。`,
    result_quiz__tend_to_follow_algo: `アルゴリズムがしめす情報をそのまま受け取る傾向があります。`,
    result_quiz__always_on_citizen: `アクティブな市民`,
    result_quiz__like_comm_with_others: `他の人とオンライン上でひんぱんにコミュニケーションをとっている可能性が高いです。`,
    result_quiz__maynot_consider_risks: `オンライン上で行動する時、せん在的なサイバーリスクについてあまり考えていない可能性があります。`,
    result_quiz__catious_citizen: `慎重な市民`,
    result_quiz__aware_of_risks_not_fully_explore: `せん在的なサイバーリスクを認識し、デジタル世界をあまり探索していない状態です。`,
    result_quiz__likely_having_risks_in_dw: `サイバーリスクにあう可能性は低いでしょう。`,
    result_quiz__digilog_citizen: `デジログ市民`,
    result_quiz__balanced_digital_citizen: `デジタル世界と現実世界の生活のバランスがとれているデジタル市民です。`,
    result_quiz__use_tech_mindfully: `テクノロジーを注意深く使用することができています。`,
    result_quiz__learn_more_html_text: `<a href="/" target="_blank">DQインデックス</a>で詳細をご覧ください。`,
    result_quiz__done: `閉じる`,
    dq_home__min_duration: `分`,
    dq_home__dc_test: `デジタル・シティズンシップテスト`,
    dq_home__free_assess: `無料のテスト`,
    dq_home__bellow_avg: `平均以下`,
    dq_home__avg: `平均`,
    dq_home__excellent: `とても良い`,
    dq_home__top_percent: `上位`,
    dq_home__bottom_percent: `下位`,
    dq_home__dc_score_helps_what_message: `デジタル・シティズンシップスコアは、テクノロジを安全かつ責任を持って使用するためのデジタルスキルレベルを理解するのに役立ちます。また、スコアに基づいてデジタル市民のタイプを診断することができます。`,
    dq_home__share: `シェアする`,
    dq_home__retake: `もう一度テストを受ける`,
    dq_home__myscore_comparison: `スコアの比較`,
    dq_home__myscore: `あなたのスコア`,
    dq_home__national_avg: `全国平均`,
    dq_home__global_avg: `世界平均`,
    dq_home__retake_test: `もう一度テストを受ける`,
    dq_demographic__gender: `性別`,
    dq_demographic__male: `男性`,
    dq_demographic__female: `女性`,
    dq_demographic__non_binary: `非バイナリ`,
    dq_demographic__prefer_not_to_answer: `答えたくありません`,
    dq_demographic__other: `その他`,
    dq_demographic__other_specify: `ご指定ください`,
    dq_demographic__year_of_birth: `生まれた年`,
    dq_demographic__country: `国`,
    dq_country__afghanistan: `アフガニスタン`,
    dq_country__aland_islands: `オーランド諸島`,
    dq_country__albania: `アルバニア`,
    dq_country__algeria: `アルジェリア`,
    dq_country__american_samoa: `アメリカ領サモア`,
    dq_country__andorra: `アンドラ`,
    dq_country__angola: `アンゴラ`,
    dq_country__anguilla: `アンギラ`,
    dq_country__antarctica: `南極大陸`,
    dq_country__antigua_and_barbuda: `アンティグア・バーブーダ`,
    dq_country__argentina: `アルゼンチン`,
    dq_country__armenia: `アルメニア`,
    dq_country__aruba: `アルバ`,
    dq_country__australia: `オーストラリア`,
    dq_country__austria: `オーストリア`,
    dq_country__azerbaijan: `アゼルバイジャン`,
    dq_country__bahamas: `バハマ`,
    dq_country__bahrain: `バーレーン`,
    dq_country__bangladesh: `バングラデシュ`,
    dq_country__barbados: `バルバドス`,
    dq_country__belarus: `ベラルーシ`,
    dq_country__belgium: `ベルギー`,
    dq_country__belize: `ベリーズ`,
    dq_country__benin: `ベナン`,
    dq_country__bermuda: `バミューダ`,
    dq_country__bhutan: `ブータン`,
    dq_country__bolivia: `ボリビア`,
    dq_country__bosnia_and_herzegovina: `ボスニア・ヘルツェゴビナ`,
    dq_country__botswana: `ボツワナ`,
    dq_country__bouvet_island: `ブーベ島`,
    dq_country__brazil: `ブラジル`,
    dq_country__british_indian_ocean_territory: `イギリス領インド洋地域`,
    dq_country__brunei_darussalam: `ブルネイ ダルサラーム`,
    dq_country__bulgaria: `ブルガリア`,
    dq_country__burkina_faso: `ブルキナファソ`,
    dq_country__burundi: `ブルンジ`,
    dq_country__cambodia: `カンボジア`,
    dq_country__cameroon: `カメルーン`,
    dq_country__canada: `カナダ`,
    dq_country__cape_verde: `カーボベルデ`,
    dq_country__cayman_islands: `ケイマン諸島`,
    dq_country__central_african_republic: `中央アフリカ共和国`,
    dq_country__chad: `チャド`,
    dq_country__chile: `チリ`,
    dq_country__china: `中国`,
    dq_country__christmas_island: `クリスマス島`,
    dq_country__cocos_keeling_islands: `ココス (キーリング) 諸島`,
    dq_country__colombia: `コロンビア`,
    dq_country__comoros: `コモロ`,
    dq_country__congo: `コンゴ`,
    dq_country__congo_the_democratic_republic_of_the: `コンゴ民主共和国`,
    dq_country__cook_islands: `クック諸島`,
    dq_country__costa_rica: `コスタリカ`,
    dq_country__cote_divoire: `コートジボワール`,
    dq_country__croatia: `クロアチア`,
    dq_country__cuba: `キューバ`,
    dq_country__cyprus: `キプロス`,
    dq_country__czech_republic: `チェコ共和国`,
    dq_country__denmark: `デンマーク`,
    dq_country__djibouti: `ジブチ`,
    dq_country__dominica: `ドミニカ`,
    dq_country__dominican_republic: `ドミニカ共和国`,
    dq_country__ecuador: `エクアドル`,
    dq_country__egypt: `エジプト`,
    dq_country__el_salvador: `エルサルバドル`,
    dq_country__equatorial_guinea: `赤道ギニア`,
    dq_country__eritrea: `エリトリア`,
    dq_country__estonia: `エストニア`,
    dq_country__ethiopia: `エチオピア`,
    dq_country__falkland_islands_malvinas: `フォークランド諸島 (マルビナス)`,
    dq_country__faroe_islands: `フェロー諸島`,
    dq_country__fiji: `フィジー`,
    dq_country__finland: `フィンランド`,
    dq_country__france: `フランス`,
    dq_country__french_guiana: `フランス領ギアナ`,
    dq_country__french_polynesia: `フランス領ポリネシア`,
    dq_country__french_southern_territories: `フランス領南方領土`,
    dq_country__gabon: `ガボン`,
    dq_country__gambia: `ガンビア`,
    dq_country__georgia: `グルジア`,
    dq_country__germany: `ドイツ`,
    dq_country__ghana: `ガーナ`,
    dq_country__gibraltar: `ジブラルタル`,
    dq_country__greece: `ギリシャ`,
    dq_country__greenland: `グリーンランド`,
    dq_country__grenada: `グレナダ`,
    dq_country__guadeloupe: `グアドループ`,
    dq_country__guam: `グアム`,
    dq_country__guatemala: `グアテマラ`,
    dq_country__guernsey: `ガーンジー`,
    dq_country__guinea: `ギニア`,
    dq_country__guinea_bissau: `ギニアビサウ`,
    dq_country__guyana: `ガイアナ`,
    dq_country__haiti: `ハイチ`,
    dq_country__heard_island_and_mcdonald_islands: `ハード島とマクドナルド諸島`,
    dq_country__holy_see_vatican_city_state: `バチカン市国`,
    dq_country__honduras: `ホンジュラス`,
    dq_country__hong_kong: `香港`,
    dq_country__hungary: `ハンガリー`,
    dq_country__iceland: `アイスランド`,
    dq_country__india: `インド`,
    dq_country__indonesia: `インドネシア`,
    dq_country__iran_islamic_republic_of: `イラン`,
    dq_country__iraq: `イラク`,
    dq_country__ireland: `アイルランド`,
    dq_country__isle_of_man: `マン島`,
    dq_country__israel: `イスラエル`,
    dq_country__italy: `イタリア`,
    dq_country__jamaica: `ジャマイカ`,
    dq_country__japan: `日本`,
    dq_country__jersey: `ジャージー`,
    dq_country__jordan: `ヨルダン`,
    dq_country__kazakhstan: `カザフスタン`,
    dq_country__kenya: `ケニア`,
    dq_country__kiribati: `キリバス`,
    dq_country__korea_democratic_peoples_republic_of: `朝鮮民主主義人民共和国`,
    dq_country__korea_republic_of: `韓国`,
    dq_country__kuwait: `クウェート`,
    dq_country__kyrgyzstan: `キルギスタン`,
    dq_country__lao_peoples_democratic_republic: `ラオス人民民主共和国`,
    dq_country__latvia: `ラトビア`,
    dq_country__lebanon: `レバノン`,
    dq_country__lesotho: `レソト`,
    dq_country__liberia: `リベリア`,
    dq_country__libyan_arab_jamahiriya: `リビア アラブ ジャマヒリヤ`,
    dq_country__liechtenstein: `リヒテンシュタイン`,
    dq_country__lithuania: `リトアニア`,
    dq_country__luxembourg: `ルクセンブルク`,
    dq_country__macao: `マカオ`,
    dq_country__macedonia_the_former_yugoslav_republic_of: `マケドニア、旧ユーゴスラビア共和国`,
    dq_country__madagascar: `マダガスカル`,
    dq_country__malawi: `マラウイ`,
    dq_country__malaysia: `マレーシア`,
    dq_country__maldives: `モルディブ`,
    dq_country__mali: `マリ`,
    dq_country__malta: `マルタ`,
    dq_country__marshall_islands: `マーシャル諸島`,
    dq_country__martinique: `マルティニーク`,
    dq_country__mauritania: `モーリタニア`,
    dq_country__mauritius: `モーリシャス`,
    dq_country__mayotte: `マヨット`,
    dq_country__mexico: `メキシコ`,
    dq_country__micronesia_federated_states_of: `ミクロネシア連邦`,
    dq_country__moldova_republic_of: `モルドバ共和国`,
    dq_country__monaco: `モナコ`,
    dq_country__mongolia: `モンゴル`,
    dq_country__montserrat: `モントセラト`,
    dq_country__morocco: `モロッコ`,
    dq_country__mozambique: `モザンビーク`,
    dq_country__myanmar: `ミャンマー`,
    dq_country__namibia: `ナミビア`,
    dq_country__nauru: `ナウル`,
    dq_country__nepal: `ネパール`,
    dq_country__netherlands: `オランダ`,
    dq_country__netherlands_antilles: `オランダ領アンティル`,
    dq_country__new_caledonia: `ニューカレドニア`,
    dq_country__new_zealand: `ニュージーランド`,
    dq_country__nicaragua: `ニカラグア`,
    dq_country__niger: `ニジェール`,
    dq_country__nigeria: `ナイジェリア`,
    dq_country__niue: `ニウエ`,
    dq_country__norfolk_island: `ノーフォーク島`,
    dq_country__northern_mariana_islands: `北マリアナ諸島`,
    dq_country__norway: `ノルウェー`,
    dq_country__oman: `オマーン`,
    dq_country__pakistan: `パキスタン`,
    dq_country__palau: `パラオ`,
    dq_country__palestinian_territory_occupied: `占領下のパレスチナ領土`,
    dq_country__panama: `パナマ`,
    dq_country__papua_new_guinea: `パプアニューギニア`,
    dq_country__paraguay: `パラグアイ`,
    dq_country__peru: `ペルー`,
    dq_country__philippines: `フィリピン`,
    dq_country__pitcairn: `ピトケアン`,
    dq_country__poland: `ポーランド`,
    dq_country__portugal: `ポルトガル`,
    dq_country__puerto_rico: `プエルトリコ`,
    dq_country__qatar: `カタール`,
    dq_country__reunion: `仏領レユニオン島`,
    dq_country__romania: `ルーマニア`,
    dq_country__russian_federation: `ロシア連邦`,
    dq_country__rwanda: `ルワンダ`,
    dq_country__saint_helena: `セントヘレナ`,
    dq_country__saint_kitts_and_nevis: `セントクリストファー・ネイビス`,
    dq_country__saint_lucia: `セントルシア`,
    dq_country__saint_pierre_and_miquelon: `サンピエール島とミクロン島`,
    dq_country__saint_vincent_and_the_grenadines: `セントビンセントおよびグレナディーン諸島`,
    dq_country__samoa: `サモア`,
    dq_country__san_marino: `サンマリノ`,
    dq_country__sao_tome_and_principe: `サントメ・プリンシペ`,
    dq_country__saudi_arabia: `サウジアラビア`,
    dq_country__senegal: `セネガル`,
    dq_country__serbia_and_montenegro: `セルビアとモンテネグロ`,
    dq_country__seychelles: `セーシェル`,
    dq_country__sierra_leone: `シエラレオネ`,
    dq_country__singapore: `シンガポール`,
    dq_country__slovakia: `スロバキア`,
    dq_country__slovenia: `スロベニア`,
    dq_country__solomon_islands: `ソロモン諸島`,
    dq_country__somalia: `ソマリア`,
    dq_country__south_africa: `南アフリカ`,
    dq_country__south_georgia_and_the_south_sandwich_islands: `サウスジョージア・サウスサンドウィッチ諸島`,
    dq_country__spain: `スペイン`,
    dq_country__sri_lanka: `スリランカ`,
    dq_country__sudan: `スーダン`,
    dq_country__suriname: `スリナム`,
    dq_country__svalbard_and_jan_mayen: `スヴァールバル諸島とヤンマイエン`,
    dq_country__swaziland: `スワジランド`,
    dq_country__sweden: `スウェーデン`,
    dq_country__switzerland: `スイス`,
    dq_country__syrian_arab_republic: `シリア・アラブ共和国`,
    dq_country__taiwan_province_of_china: `台湾`,
    dq_country__tajikistan: `タジキスタン`,
    dq_country__tanzania_united_republic_of: `タンザニア連合共和国`,
    dq_country__thailand: `タイ`,
    dq_country__timor_leste: `東ティモール`,
    dq_country__togo: `トーゴ`,
    dq_country__tokelau: `トケラウ`,
    dq_country__tonga: `トンガ`,
    dq_country__trinidad_and_tobago: `トリニダード・トバゴ`,
    dq_country__tunisia: `チュニジア`,
    dq_country__turkey: `トルコ`,
    dq_country__turkmenistan: `トルクメニスタン`,
    dq_country__turks_and_caicos_islands: `タークス・カイコス諸島`,
    dq_country__tuvalu: `ツバル`,
    dq_country__uganda: `ウガンダ`,
    dq_country__ukraine: `ウクライナ`,
    dq_country__united_arab_emirates: `アラブ首長国連邦`,
    dq_country__united_kingdom: `イギリス`,
    dq_country__united_states: `アメリカ`,
    dq_country__united_states_minor_outlying_islands: `合衆国領有小離島`,
    dq_country__uruguay: `ウルグアイ`,
    dq_country__uzbekistan: `ウズベキスタン`,
    dq_country__vanuatu: `バヌアツ`,
    dq_country__venezuela: `ベネズエラ`,
    dq_country__viet_nam: `ベトナム`,
    dq_country__virgin_islands_british: `バージン諸島、イギリス`,
    dq_country__virgin_islands_us: `バージン諸島、アメリカ`,
    dq_country__wallis_and_futuna: `ウォリス・フツナ`,
    dq_country__western_sahara: `西サハラ`,
    dq_country__yemen: `イエメン`,
    dq_country__zambia: `ザンビア`,
    dq_country__zimbabwe: `ジンバブエ`,
    dq_demographic__postal_code: `郵便番号`,
    dq_demographic__next: `次へ`,
    dq_demographic__get_score: `スコアを取得`,
    dq_demographic__plz_fill_req_fields: `すべての必須フィールドに入力してください。`,
    uo__lets_start: `はじめましょう`,
    uo__dct_test_intro_message_text: `このテストは、デジタル・シティズンシップのレベルをチェックし、あなたがデジタルデバイスとメディアを安全かつ責任を持って使用する準備ができているかどうかを理解するのに役立ちます。`,
    quiz_result_see_your_score: `結果を見てみましょう！`,
    post_quiz_resume_test: `テストを再開する`,
    uo__username: `ユーザー名`,
    uo__failed_to_proceed: `続行できませんでした。`,
    uo__save_failed_try_again: `保存できませんでした。もう一度お試しください。`,
    uo__continue_as_guest: `ゲストとして続けますか？`,
    uo__guest_login: `ゲストとしてログイン`,
    dq_profile__inactive: `アカウントが無効化されています。運営会社に問い合わせてください。`,
    dq_profile__multiple: `アカウントが複数の組織に紐づいています。1つのプロフィールを選んでください。`,
    dq_profile__not_exists: `プロフィールが見つかりません。`,
    dq_platform__select: `移動するサイトを選んでください。`,
    dq_platform__dq_assessment: `DQ Assessment`,
    dq_platform__dq_world: `DQ World`,
    uo__page_not_found: `ページが見つかりません。`,
    uo__goto_home: `ホームに戻る`,
}

const lc_th = {
    menu__hello_text: `สวัสดี`,
    menu__guest_text: `ผู้เยี่ยมชม`,
    menu__home: `หน้าหลัก`,
    menu__register: `ลงทะเบียน`,
    menu__settings: `การตั้งค่า`,
    menu__logout: `ออกจากระบบ`,
    uo__welcome_dct_text: `ยินดีต้อนรับสู่ <br>แบบทดสอบการเป็นพลเมืองยุคดิจิทัล!`,
    uo__start_test_text: `เริ่มแบบทดสอบ`,
    post_q_landing__congrats: `ขอแสดงความยินดี`,
    post_q_landing__finished_test_message_text: `คุณทำแบบทดสอบเสร็จแล้ว<br>เพื่อให้ได้คะแนน คุณจะต้องลงชื่อสมัครใช้บัญชี`,
    post_q_landing__finished_test_short_message: `คุณทำแบบทดสอบเสร็จแล้ว`,
    post_q_landing__if_under_18_text: `หากคุณอายุต่ำกว่า 18 ปี คุณต้องใช้อีเมลของพ่อแม่หรือผู้ปกครองและคำยินยอมในการสมัคร`,
    post_q_landing__signup: `ลงชื่อ`,
    post_q_landing__no_thanks: `ไม่, ขอบคุณ`,
    persist_guest__fname: `ชื่อ`,
    persist_guest__lname: `นามสกุล`,
    persist_guest__email_address: `อีเมล`,
    persist_guest__require_parent_email_text: `ต้องระบุที่อยู่อีเมลของพ่อแม่/ผู้ปกครองหากคุณอายุต่ำกว่า 18 ปี`,
    persist_guest__password: `รหัสผ่าน`,
    persist_guest__confirm_password: `ยืนยันรหัสผ่าน`,
    persist_guest__have_access_code: `มีรหัสการเข้าถึง? คลิกเพื่อเข้าสู่!`,
    persist_guest__access_code_span: `รหัสการเข้าถึง <span style=\"font-weight: bold;\">(คลิกที่นี่เพื่อซ่อน)</span>`,
    persist_guest__accept_terms_of_use: `ฉันได้อ่านและยอมรับ <br><a href=\"/terms_of_use\" target=\"_blank\"> ข้อกำหนดการใช้งาน </a> และ<a href=\"/privacy_policy\" target=\"_blank\"> นโยบายความเป็นส่วนตัว</a >.`,
    persist_guest__register: `ลงทะเบียน`,
    persist_guest__already_have_account: `มีบัญชีอยู่แล้ว?`,
    persist_guest__login_here: `เข้าระบบที่นี่`,
    persist_guest__login: `เข้าระบบ`,
    persist_guest__signup:`ลงชื่อ`,
    persist_guest__not_have_an_account: `ไม่มีบัญชี?`,
    persist_guest__register_here: `ลงทะเบียนที่นี่`,
    uo__register_as_individual: `ลงทะเบียนเป็นรายบุคคล`,
    uo__register_as_company: `ลงทะเบียนเป็นบริษัท`,
    uo__register_as_school: `ลงทะเบียนเป็นโรงเรียน`,
    uo__not_found: `ไม่พบ`,
    persist_guest__privacy_policy: `นโยบายความเป็นส่วนตัว`,
    persist_guest__terms_of_use: `ข้อกำหนดการใช้งาน`,
    persist_guest__wrong_pass_user_not_found: `ป้อนชื่อผู้ใช้/อีเมลผิด: ไม่พบผู้ใช้`,
    persist_guest__plz_fill_req_fields: `กรุณากรอกข้อมูลในช่องที่ต้องกรอกทั้งหมด`,
    uo__plz_accept_terms_of_use: `โปรดยอมรับเงื่อนไขการใช้งาน`,
    uo__pass_mismatch_plz_retype: `รหัสผ่านไม่ตรงกัน! กรุณาพิมพ์ใหม่`,
    uo__err_loading_guest: `เกิดข้อผิดพลาดในการโหลดผู้ใช้ทั่วไป!`,
    uo__plz_set_ur_new_pass: `กรุณาตั้งรหัสผ่านใหม่ของคุณ`,
    uo__no_assessment_available_chk_later: `ไม่มีการประเมินในขณะนี้ โปรดตรวจสอบอีกครั้งในภายหลัง`,
    uo__dq_under_maintainance_txt: `การประเมิน DQ อยู่ระหว่างการบำรุงรักษา`,
    uo__not_available_try_later: `ไม่พร้อมใช้งานในขณะนี้ โปรดลองอีกครั้งในภายหลัง!`,
    uo__submit: `ส่ง`,
    uo__view_score: `ดูคะแนน`,
    uo__admin_login: `เข้าสู่ระบบผู้ดูแลระบบ`,
    uo__plz_wait: `โปรดรอ`,
    uo__loading: `กำลังโหลด`,
    uo__loaded: `โหลดแล้ว`,
    result_quiz__require_attention: `ต้องการความสนใจ`,
    result_quiz__bellow_avg: `ต่ำกว่าค่าเฉลี่ย`,
    result_quiz__good_job: `ดีมาก`,
    result_quiz__excellent: `ยอดเยี่ยม`,
    result_quiz__require_attention_br: `ต้องการ<br>ความสนใจ`,
    result_quiz__above_avg_br: `สูงกว่า<br>ค่าเฉลี่ย`,
    result_quiz__bellow_avg_br: `ต่ำกว่า<br>ค่าเฉลี่ย`,
    result_quiz__excellent_br: `ยอดเยี่ยม`,
    result_quiz__high: `สูง`,
    result_quiz__medium: `ปานกลาง`,
    result_quiz__low: `ต่ำ`,
    result_quiz__high_score_description: `ผลงานเยี่ยมมาก! คุณมีความรอบรู้ในการนำทางในโลกดิจิทัล ติดตามการทำงานที่ดีและคิดถึงการแบ่งปันข้อมูลเชิงลึกของคุณ`,
    result_quiz__medium_score_description: `คุณมาถูกทางแล้วด้วยความเข้าใจพื้นฐานเกี่ยวกับหลักปฏิบัติทางดิจิทัล การขยายความรู้ของคุณอาจช่วยยกระดับประสบการณ์ดิจิทัลของคุณให้ดียิ่งขึ้น`,
    result_quiz__low_score_description: `มีโอกาสที่จะเติบโตบนเส้นทางดิจิทัลของคุณ การสำรวจสื่อการเรียนรู้สามารถช่วยปรับปรุงความเข้าใจและสร้างผลกระทบทางออนไลน์ได้`,
    result_quiz__mydq_score: `คะแนน DQ ของฉัน`,
    result_quiz__mydq_badges: `ป้าย DQ ของฉัน`,
    result_quiz__your_score_span: `คะแนนของคุณอยู่ใน`,
    result_quiz__dct_discalimer_html_text: `*ข้อจำกัดความรับผิดชอบ: แบบทดสอบการเป็นพลเมืองดิจิทัลนี้พัฒนาขึ้นเพื่อสร้างความตระหนักรู้เกี่ยวกับความสำคัญของการเป็นพลเมืองดิจิทัล และอาจไม่ถูกต้องในการระบุความเสี่ยงทางไซเบอร์และระดับทักษะด้านดิจิทัลของคุณ หากคุณสนใจรับการประเมินฉบับเต็ม คุณสามารถเรียนรู้เพิ่มเติมได้<a href="/" target="_blank">ที่นี่</a>`,
    result_quiz__click_message_dct_type: `คลิก 'ถัดไป' เพื่อดูว่าคุณเป็นพลเมืองดิจิทัลประเภทใด`,
    result_quiz__next: `ต่อไป`,
    result_quiz__carefree_citizen: `พลเมืองไร้กังวล`,
    result_quiz__feel_comfort_online_text: `ออนไลน์สบายใจกว่าออฟไลน์`,
    result_quiz__invlove_with_risks_text: `อาจเกี่ยวข้องกับความเสี่ยงทางไซเบอร์ต่างๆ`,
    result_quiz__ordinary_citizen: `ประชาชนธรรมดา`,
    result_quiz__avg_tech_user_message_text: `ผู้ใช้เทคโนโลยีทั่วไปที่อาจไม่ตระหนักถึงความเสี่ยงและโอกาสอย่างเต็มที่`,
    result_quiz__tend_to_follow_algo: `มักจะทำตามอัลกอริทึมอย่างเฉยเมย`,
    result_quiz__always_on_citizen: `พลเมืองที่พร้อมเสมอ!`,
    result_quiz__like_comm_with_others: `มีแนวโน้มที่จะสื่อสารกับผู้อื่นมากทางออนไลน์`,
    result_quiz__maynot_consider_risks: `อาจไม่คำนึงถึงความเสี่ยงทางไซเบอร์ที่อาจเกิดขึ้นมากนัก`,
    result_quiz__catious_citizen: `พลเมืองดีระวัง!`,
    result_quiz__aware_of_risks_not_fully_explore: `ตระหนักถึงความเสี่ยงทางไซเบอร์ที่อาจเกิดขึ้นและไม่ได้สำรวจโลกดิจิทัลอย่างเต็มที่`,
    result_quiz__likely_having_risks_in_dw: `น่าจะมีความเสี่ยงต่ำในโลกดิจิทัล`,
    result_quiz__digilog_citizen: `พลเมือง Digilog!`,
    result_quiz__balanced_digital_citizen: `พลเมืองดิจิทัลรอบรู้ที่สร้างสมดุลให้กับชีวิตออนไลน์และออฟไลน์`,
    result_quiz__use_tech_mindfully: `ใช้เทคโนโลยีอย่างมีสติ`,
    result_quiz__learn_more_html_text: `เรียนรู้เพิ่มเติมที่  <a href="/" target="_blank">DQ Assessment</a>.`,
    result_quiz__done: `เสร็จแล้ว`,
    dq_home__min_duration: `นาที`,
    dq_home__dc_test: `การทดสอบการเป็นพลเมืองดิจิทัล`,
    dq_home__bellow_avg: `ต่ำกว่าค่าเฉลี่ย`,
    dq_home__avg: `เฉลี่ย`,
    dq_home__excellent: `ยอดเยี่ยม`,
    dq_home__top_percent: `สูงสุด`,
    dq_home__bottom_percent: `ด้านล่าง`,
    dq_home__dc_score_helps_what_message: `คะแนนความเป็นพลเมืองดิจิทัลช่วยให้คุณเข้าใจระดับทักษะดิจิทัลสำหรับการใช้เทคโนโลยีอย่างปลอดภัยและมีความรับผิดชอบ นอกจากนี้ยังช่วยให้คุณระบุประเภทพลเมืองดิจิทัลของคุณ`,
    dq_home__share: `แบ่งปัน`,
    dq_home__retake: `เอาใหม่`,
    dq_home__myscore_comparison: `การเปรียบเทียบคะแนนของฉัน`,
    dq_home__myscore: `คะแนนของของฉัน`,
    dq_home__national_avg: `ค่าเฉลี่ยของประเทศ`,
    dq_home__global_avg: `ค่าเฉลี่ยทั่วโลก`,
    dq_home__retake_test: `ทำแบบทดสอบใหม่`,
    dq_demographic__gender: `เพศ`,
    dq_demographic__male: `เพศชาย`,
    dq_demographic__female: `เพศหญิง`,
    dq_demographic__non_binary: `ไม่ใช่ไบนารี`,
    dq_demographic__prefer_not_to_answer: `ไม่อยากตอบ`,
    dq_demographic__other: `อื่น ๆ`,
    dq_demographic__other_specify: `โปรดระบุ`,
    dq_demographic__year_of_birth: `ปีเกิด`,
    dq_demographic__country: `ประเทศ`,
    dq_country__afghanistan: `อัฟกานิสถาน`,
    dq_country__aland_islands: `หมู่เกาะโอลันด์`,
    dq_country__albania: `แอลเบเนีย`,
    dq_country__algeria: `แอลจีเรีย`,
    dq_country__american_samoa: `อเมริกันซามัว`,
    dq_country__andorra: `อันดอร์เอ`,
    dq_country__angola: `แองโกลา`,
    dq_country__anguilla: `แองกวิลลา`,
    dq_country__antarctica: `แอนตาร์กติกา`,
    dq_country__antigua_and_barbuda: `แอนติกาและบาร์บูดา`,
    dq_country__argentina: `อาร์เจนตินา`,
    dq_country__armenia: `อาร์เมเนีย`,
    dq_country__aruba: `อารูบา`,
    dq_country__australia: `ออสเตรเลีย`,
    dq_country__austria: `ออสเตรีย`,
    dq_country__azerbaijan: `อาเซอร์ไบจาน`,
    dq_country__bahamas: `บาฮามาส`,
    dq_country__bahrain: `บาห์เรน`,
    dq_country__bangladesh: `บังกลาเทศ`,
    dq_country__barbados: `บาร์เบโดส`,
    dq_country__belarus: `เบลารุส`,
    dq_country__belgium: `เบลเยี่ยม`,
    dq_country__belize: `เบลีซ`,
    dq_country__benin: `เบนิน`,
    dq_country__bermuda: `เบอร์มิวดา`,
    dq_country__bhutan: `ภูฏาน`,
    dq_country__bolivia: `โบลิเวีย`,
    dq_country__bosnia_and_herzegovina: `บอสเนียและเฮอร์เซโก`,
    dq_country__botswana: `บอตสวานา`,
    dq_country__bouvet_island: `เกาะบูเวต์`,
    dq_country__brazil: `บราซิล`,
    dq_country__british_indian_ocean_territory: `บริติชอินเดียนโอเชียนเทร์ริทอรี`,
    dq_country__brunei_darussalam: `บรูไนดารุสซาลาม`,
    dq_country__bulgaria: `บัลแกเรีย`,
    dq_country__burkina_faso: `บูร์กินาฟาโซ`,
    dq_country__burundi: `บุรุนดี`,
    dq_country__cambodia: `กัมพูชา`,
    dq_country__cameroon: `แคเมอรูน`,
    dq_country__canada: `แคนาดา`,
    dq_country__cape_verde: `เคปเวิร์ด`,
    dq_country__cayman_islands: `หมู่เกาะเคย์เเมน`,
    dq_country__central_african_republic: `สาธารณรัฐแอฟริกากลาง`,
    dq_country__chad: `ชาด`,
    dq_country__chile: `ชิลี`,
    dq_country__china: `จีน`,
    dq_country__christmas_island: `เกาะคริสต์มาส`,
    dq_country__cocos_keeling_islands: `หมู่เกาะโคโคส (คีลิง)`,
    dq_country__colombia: `โคลอมเบีย`,
    dq_country__comoros: `คอโมโรส`,
    dq_country__congo: `คองโก`,
    dq_country__congo_the_democratic_republic_of_the: `คองโก สาธารณรัฐประชาธิปไตย`,
    dq_country__cook_islands: `หมู่เกาะคุก`,
    dq_country__costa_rica: `คอสตาริกา`,
    dq_country__cote_divoire: `โกตดิวัวร์`,
    dq_country__croatia: `โครเอเชีย`,
    dq_country__cuba: `คิวบา`,
    dq_country__cyprus: `ไซปรัส`,
    dq_country__czech_republic: `สาธารณรัฐเช็ก`,
    dq_country__denmark: `เดนมาร์ก`,
    dq_country__djibouti: `จิบูตี`,
    dq_country__dominica: `โดมินิกา`,
    dq_country__dominican_republic: `สาธารณรัฐโดมินิกัน`,
    dq_country__ecuador: `เอกวาดอร์`,
    dq_country__egypt: `อียิปต์`,
    dq_country__el_salvador: `เอลซัลวาดอร์`,
    dq_country__equatorial_guinea: `อิเควทอเรียลกินี`,
    dq_country__eritrea: `เอริเทรีย`,
    dq_country__estonia: `เอสโตเนีย`,
    dq_country__ethiopia: `เอธิโอเปีย`,
    dq_country__falkland_islands_malvinas: `หมู่เกาะฟอล์กแลนด์ (มัลวินาส)`,
    dq_country__faroe_islands: `หมู่เกาะแฟโร`,
    dq_country__fiji: `ฟิจิ`,
    dq_country__finland: `ฟินแลนด์`,
    dq_country__france: `ฝรั่งเศส`,
    dq_country__french_guiana: `เฟรนช์เกีย`,
    dq_country__french_polynesia: `เฟรนช์โปลินีเซีย`,
    dq_country__french_southern_territories: `เฟรนช์เซาเทิร์นเทร์ริทอรีส์`,
    dq_country__gabon: `กาบอง`,
    dq_country__gambia: `แกมเบีย`,
    dq_country__georgia: `จอร์เจีย`,
    dq_country__germany: `เยอรมนี`,
    dq_country__ghana: `กานา`,
    dq_country__gibraltar: `ยิบรอลตาร์`,
    dq_country__greece: `กรีซ`,
    dq_country__greenland: `กรีนแลนด์`,
    dq_country__grenada: `เกรนาดา`,
    dq_country__guadeloupe: `กวาเดอลูป`,
    dq_country__guam: `กวม`,
    dq_country__guatemala: `กัวเตมาลา`,
    dq_country__guernsey: `เกิร์นซีย์`,
    dq_country__guinea: `กินี`,
    dq_country__guinea_bissau: `กินีบิสเซา`,
    dq_country__guyana: `กายอานา`,
    dq_country__haiti: `เฮติ`,
    dq_country__heard_island_and_mcdonald_islands: `เกาะเฮิร์ดและหมู่เกาะแมกดอนัลด์`,
    dq_country__holy_see_vatican_city_state: `Holy See (นครรัฐวาติกัน)`,
    dq_country__honduras: `ฮอนดูรัส`,
    dq_country__hong_kong: `ฮ่องกง`,
    dq_country__hungary: `ฮังการี`,
    dq_country__iceland: `ไอซ์แลนด์`,
    dq_country__india: `อินเดีย`,
    dq_country__indonesia: `อินโดนีเซีย`,
    dq_country__iran_islamic_republic_of: `อิหร่าน สาธารณรัฐอิสลาม`,
    dq_country__iraq: `อิรัก`,
    dq_country__ireland: `ไอร์แลนด์`,
    dq_country__isle_of_man: `เกาะแมน`,
    dq_country__israel: `อิสราเอล`,
    dq_country__italy: `อิตาลี`,
    dq_country__jamaica: `จาเมกา`,
    dq_country__japan: `ญี่ปุ่น`,
    dq_country__jersey: `เจอร์ซีย์`,
    dq_country__jordan: `จอร์แดน`,
    dq_country__kazakhstan: `คาซัคสถาน`,
    dq_country__kenya: `เคนยา`,
    dq_country__kiribati: `คิริบาส`,
    dq_country__korea_democratic_peoples_republic_of: `เกาหลี สาธารณรัฐประชาธิปไตยประชาชนเกาหลี`,
    dq_country__korea_republic_of: `เกาหลี, สาธารณรัฐเกาหลี`,
    dq_country__kuwait: `คูเวต`,
    dq_country__kyrgyzstan: `คีร์กีซสถาน`,
    dq_country__lao_peoples_democratic_republic: `สาธารณรัฐประชาธิปไตยประชาชนลาว`,
    dq_country__latvia: `ลัตเวีย`,
    dq_country__lebanon: `เลบานอน`,
    dq_country__lesotho: `เลโซโท`,
    dq_country__liberia: `ประเทศไลบีเรีย`,
    dq_country__libyan_arab_jamahiriya: `สาธารณรัฐอาหรับลิเบีย`,
    dq_country__liechtenstein: `ลิกเตนสไตน์`,
    dq_country__lithuania: `ลิทัวเนีย`,
    dq_country__luxembourg: `ลักเซมเบิร์ก`,
    dq_country__macao: `มาเก๊า`,
    dq_country__macedonia_the_former_yugoslav_republic_of: `มาซิโดเนีย อดีตสาธารณรัฐยูโกสลาเวีย`,
    dq_country__madagascar: `มาดากัสการ์`,
    dq_country__malawi: `มาลาวี`,
    dq_country__malaysia: `มาเลเซีย`,
    dq_country__maldives: `มัลดีฟส์`,
    dq_country__mali: `มาลี`,
    dq_country__malta: `มอลตา`,
    dq_country__marshall_islands: `หมู่เกาะมาร์แชลล์`,
    dq_country__martinique: `มาร์ตินีก`,
    dq_country__mauritania: `มอริเตเนีย`,
    dq_country__mauritius: `มอริเชียส`,
    dq_country__mayotte: `มายอต`,
    dq_country__mexico: `เม็กซิโก`,
    dq_country__micronesia_federated_states_of: `ไมโครนีเซีย สหพันธรัฐ`,
    dq_country__moldova_republic_of: `มอลโดวา สาธารณรัฐ`,
    dq_country__monaco: `โมนาโก`,
    dq_country__mongolia: `มองโกเลีย`,
    dq_country__montserrat: `มอนต์เซอร์รัต`,
    dq_country__morocco: `โมร็อกโก`,
    dq_country__mozambique: `โมซัมบิก`,
    dq_country__myanmar: `พม่า`,
    dq_country__namibia: `นามิเบีย`,
    dq_country__nauru: `นาอูรู`,
    dq_country__nepal: `เนปาล`,
    dq_country__netherlands: `เนเธอร์แลนด์`,
    dq_country__netherlands_antilles: `หมู่เกาะอินเดียตะวันตกของเนเธอร์แลนด์`,
    dq_country__new_caledonia: `นิวแคลิโดเนีย`,
    dq_country__new_zealand: `นิวซีแลนด์`,
    dq_country__nicaragua: `นิการากัว`,
    dq_country__niger: `ไนเจอร์`,
    dq_country__nigeria: `ไนจีเรีย`,
    dq_country__niue: `นีอูเอ`,
    dq_country__norfolk_island: `เกาะนอร์ฟอล์ก`,
    dq_country__northern_mariana_islands: `หมู่เกาะนอร์เทิร์นมาเรียนา`,
    dq_country__norway: `นอร์เวย์`,
    dq_country__oman: `โอมาน`,
    dq_country__pakistan: `ปากีสถาน`,
    dq_country__palau: `ปาเลา`,
    dq_country__palestinian_territory_occupied: `ดินแดนปาเลสไตน์, ยึดครอง`,
    dq_country__panama: `ปานามา`,
    dq_country__papua_new_guinea: `ปาปัวนิวกินี`,
    dq_country__paraguay: `ประเทศปารากวัย`,
    dq_country__peru: `เปรู`,
    dq_country__philippines: `ฟิลิปปินส์`,
    dq_country__pitcairn: `พิตแคร์น`,
    dq_country__poland: `โปแลนด์`,
    dq_country__portugal: `โปรตุเกส`,
    dq_country__puerto_rico: `เปอร์โตริโก้`,
    dq_country__qatar: `กาตาร์`,
    dq_country__reunion: `เรอูนียง`,
    dq_country__romania: `โรมาเนีย`,
    dq_country__russian_federation: `สหพันธรัฐรัสเซีย`,
    dq_country__rwanda: `รวันดา`,
    dq_country__saint_helena: `เซนต์เฮเลน่า`,
    dq_country__saint_kitts_and_nevis: `เซนต์คิตส์และเนวิส`,
    dq_country__saint_lucia: `เซนต์ลูเซีย`,
    dq_country__saint_pierre_and_miquelon: `เซนต์ปีเตอร์และมีเกอลง`,
    dq_country__saint_vincent_and_the_grenadines: `เซนต์วินเซนต์และเกรนาดีนส์`,
    dq_country__samoa: `ซามัว`,
    dq_country__san_marino: `ซานมาริโน`,
    dq_country__sao_tome_and_principe: `เซาตูเมและหลักการ`,
    dq_country__saudi_arabia: `ซาอุดิอาราเบีย`,
    dq_country__senegal: `เซเนกัล`,
    dq_country__serbia_and_montenegro: `เซอร์เบียและมอนเตเนโกร`,
    dq_country__seychelles: `เซเชลส์`,
    dq_country__sierra_leone: `เซียร์ราลีโอน`,
    dq_country__singapore: `สิงคโปร์`,
    dq_country__slovakia: `สโลวาเกีย`,
    dq_country__slovenia: `สโลวีเนีย`,
    dq_country__solomon_islands: `หมู่เกาะโซโลมอน`,
    dq_country__somalia: `โซมาเลีย`,
    dq_country__south_africa: `แอฟริกาใต้`,
    dq_country__south_georgia_and_the_south_sandwich_islands: `เกาะเซาท์จอร์เจียและหมู่เกาะเซาท์แซนด์วิช`,
    dq_country__spain: `สเปน`,
    dq_country__sri_lanka: `ศรีลังกา`,
    dq_country__sudan: `ซูดาน`,
    dq_country__suriname: `ซูรินาเม`,
    dq_country__svalbard_and_jan_mayen: `สวาลบาร์ดและยานไมเอน`,
    dq_country__swaziland: `สวาซิแลนด์`,
    dq_country__sweden: `สวีเดน`,
    dq_country__switzerland: `สวิตเซอร์แลนด์`,
    dq_country__syrian_arab_republic: `สาธารณรัฐอาหรับซีเรีย`,
    dq_country__taiwan_province_of_china: `ไต้หวัน มณฑลของจีน`,
    dq_country__tajikistan: `ทาจิกิสถาน`,
    dq_country__tanzania_united_republic_of: `แทนซาเนีย สหสาธารณรัฐ`,
    dq_country__thailand: `ประเทศไทย`,
    dq_country__timor_leste: `ติมอร์-เลสเต`,
    dq_country__togo: `ไป`,
    dq_country__tokelau: `โตเกเลา`,
    dq_country__tonga: `ตองกา`,
    dq_country__trinidad_and_tobago: `ตรินิแดดและโตเบโก`,
    dq_country__tunisia: `ตูนิเซีย`,
    dq_country__turkey: `ไก่งวง`,
    dq_country__turkmenistan: `เติร์กเมนิสถาน`,
    dq_country__turks_and_caicos_islands: `หมู่เกาะเติกส์และหมู่เกาะเคคอส`,
    dq_country__tuvalu: `ตูวาลู`,
    dq_country__uganda: `ยูกันดา`,
    dq_country__ukraine: `ยูเครน`,
    dq_country__united_arab_emirates: `สหรัฐอาหรับเอมิเรตส์`,
    dq_country__united_kingdom: `ประเทศอังกฤษ`,
    dq_country__united_states: `สหรัฐ`,
    dq_country__united_states_minor_outlying_islands: `เกาะเล็กรอบนอกของสหรัฐอเมริกา`,
    dq_country__uruguay: `อุรุกวัย`,
    dq_country__uzbekistan: `อุซเบกิสถาน`,
    dq_country__vanuatu: `วานูอาตู`,
    dq_country__venezuela: `เวเนซุเอลา`,
    dq_country__viet_nam: `เวียดนาม`,
    dq_country__virgin_islands_british: `หมู่เกาะเวอร์จิน ประเทศอังกฤษ`,
    dq_country__virgin_islands_us: `หมู่เกาะเวอร์จิน สหรัฐอเมริกา`,
    dq_country__wallis_and_futuna: `วาลลิสและฟุตูนา`,
    dq_country__western_sahara: `ซาฮาร่าตะวันตก`,
    dq_country__yemen: `เยเมน`,
    dq_country__zambia: `แซมเบีย`,
    dq_country__zimbabwe: `ซิมบับเว`,
    dq_demographic__postal_code: `รหัสไปรษณีย์`,
    dq_demographic__next: `ต่อไป`,
    dq_demographic__get_score: `รับคะแนน`,
    dq_demographic__plz_fill_req_fields: `กรุณากรอกข้อมูลในช่องที่ต้องกรอกทั้งหมด`,
    quiz_poster__lets_start: `เริ่มกันเลย`,
    uo__dct_test_intro_message_text: `แบบทดสอบนี้จะช่วยตรวจสอบระดับการเป็นพลเมืองดิจิทัลของคุณ เพื่อทำความเข้าใจว่าคุณพร้อมจะใช้อุปกรณ์และสื่อดิจิทัลอย่างปลอดภัยและมีความรับผิดชอบหรือไม่`,
    quiz_result_see_your_score: `ดูคะแนนของคุณ!`,
    post_quiz_resume_test: `ทำการทดสอบต่อ`,
    uo__username: `ชื่อผู้ใช้`,
    uo__failed_to_proceed: `ไม่สามารถดำเนินการต่อได้!`,
    uo__save_failed_try_again: `บันทึกล้มเหลว! ลองอีกครั้ง...`,
    uo__continue_as_guest: `Continue as a Guest?`,
    uo__guest_login: `Guest login`,
    dq_profile__inactive: `Your account is not active, please contact your organization`,
    dq_profile__multiple: `Your account is associated with multiple organizations, please select one profile`,
    dq_profile__not_exists: `This profile does not exist`,
    dq_platform__select: `Please select a platform`,
    dq_platform__dq_assessment: `DQ Assessment`,
    dq_platform__dq_world: `DQ World`,
    uo__page_not_found: `Not found`,
    uo__goto_home: `Go to Home Page`,
    uo__wrong_username_enter: `ใส่ชื่อผู้ใช้ผิด!`,
    uo__wrong_pass_enter: `ใส่รหัสผ่านผิด!`,
}

const lc_es = {
    menu__hello_text: `Hola`,
    menu__guest_text: `Invitado`,
    menu__home: `Página de inicio`,
    menu__register: `Registro`,
    menu__settings: `Ajustes`,
    menu__switch_profile: `Cambiar de perfil`,
    menu__switch_platform: `Cambiar de plataforma`,
    menu__goto_dqw: `Ir a Mundo DQ`,
    menu__logout: `Cerrar sesión`,
    uo__welcome_dct_text: `¡Bienvenido a la <br>Prueba de Ciudadanía Digital!`,
    uo__start_test_text: `Empezar la prueba`,
    post_q_landing__congrats: `¡Felicidades!`,
    post_q_landing__finished_test_message_text: `Para obtener tu puntaje, deberás registrarse para obtener una cuenta.`,
    post_q_landing__finished_test_short_message: `Has terminado la prueba.`,
    post_q_landing__if_under_18_text: `Si eres menor de 18 años, necesitarás el correo electrónico y el consentimiento de tus padres o tutores para registrarte.`,
    post_q_landing__signup: `Inscribirse`,
    post_q_landing__no_thanks: `No, gracias`,
    persist_guest__fname: `Nombre`,
    persist_guest__lname: `Apellido`,
    persist_guest__email_address: `Correo electrónico`,
    persist_guest__require_parent_email_text: `(Se requiere la dirección de correo electrónico del padre/tutor si eres menor de 18 años).`,
    persist_guest__password: `Contraseña`,
    persist_guest__confirm_password: `Confirmar contraseña`,
    persist_guest__have_access_code: `¿Tienes un código de acceso? ¡Haz clic para entrar!`,
    persist_guest__access_code_span: `Código de acceso <span style=\"font-weight: bold;\">(haz clic aquí para ocultar)</span>`,
    persist_guest__accept_terms_of_use: `He leído y acepto las <br><a href=\"/terms_of_use\" target=\"_blank\"> CONDICIONES DE USO </a> y la <a href=\"/privacy_policy\" target=\"_blank\"> POLÍTICA DE PRIVACIDAD</a >.`,
    persist_guest__register: `Registro`,
    persist_guest__already_have_account: `¿Ya tienes una cuenta?`,
    persist_guest__login_here: `Entra aquí`,
    persist_guest__login: `Iniciar sesión`,
    persist_guest__signup:`Inscribirse`,
    persist_guest__not_have_an_account: `¿No tienes una cuenta?`,
    persist_guest__register_here: `Regístrate aquí`,
    persist_guest__forgot_password: `¿Has olvidado tu contraseña?`,
    persist_guest__login_to_dashboard: `Iniciar sesión en el panel de DQ`,
    persist_guest__login_with_google: `Iniciar sesión con Google`,
    persist_guest__reset_pass_guide: `Escribe el nombre de usuario registrado.<br/>Te enviaremos un enlace para restablecer la contraseña al correo electrónico que registraste.`,
    persist_guest__send_link: `Enviar enlace`,
    persist_guest__send_link_success: `Enlace restablecido enviado con éxito\n¡Revisa tu bandeja de entrada!`,
    persist_guest__send_link_failed: `No se pudo enviar el enlace de reinicio`,
    persist_guest__reset_password: `Restablecer la contraseña`,
    persist_guest__reset_pass_success: `Has cambiado tu contraseña. Inicia sesión con tu nombre de usuario o correo electrónico con la nueva contraseña.`,
    persist_guest__reset_pass_failed: `No se pudo enviar la contraseña restablecida`,
    persist_guest__reset_pass_link_invalid: `Enlace de restablecimiento de contraseña no válido`,
    persist_guest__recover_password_here: `Recuperar Contraseña Aquí`,
    persist_guest__go_to_login: `Ir a Iniciar sesión`,
    uo__register_as_individual: `Regístrate como INDIVIDUAL`,
    uo__register_as_company: `Regístrate como EMPRESA`,
    uo__register_as_school: `Regístrate como ESCUELA`,
    uo__not_found: `No encontrado`,
    persist_guest__privacy_policy: `Política de privacidad`,
    persist_guest__terms_of_use: `Condiciones de uso`,
    persist_guest__wrong_pass_user_not_found: `nombre de usuario/correo electrónico incorrecto ingresado: usuario no encontrado`,
    persist_guest__plz_fill_req_fields: `Por favor llene todos los campos requeridos`,
    uo__plz_accept_terms_of_use: `Acepte los Términos de uso`,
    uo__pass_mismatch_plz_retype: `¡La contraseña no coincide! Por favor vuelva a escribirla`,
    uo__err_loading_guest: `Error al cargar usuario invitado`,
    uo__plz_set_ur_new_pass: `Establezca su nueva contraseña.`,
    uo__no_assessment_available_chk_later: `NO hay evaluaciones disponibles en este momento, vuelva a consultar más tarde`,
    uo__dq_under_maintainance_txt: `La evaluación DQ se encuentra actualmente en mantenimiento.`,
    uo__not_available_try_later: `No disponible en este momento, ¡Vuelva a intentarlo más tarde!`,
    uo__submit: `Entregar`,
    uo__view_score: `Ver puntuación`,
    uo__admin_login: `Inicio de sesión de administrador`,
    uo__plz_wait: `Espere por favor...`,
    uo__loading: `Cargando…`,
    uo__loaded: `Cargado`,
    result_quiz__require_attention: `Requiere Atención!`,
    result_quiz__bellow_avg: `¡Por debajo del promedio!`,
    result_quiz__good_job: `¡Buen trabajo!`,
    result_quiz__excellent: `¡Excelente!`,
    result_quiz__require_attention_br: `Requiere<br>atención`,
    result_quiz__above_avg_br: `Por encima<br>del promedio`,
    result_quiz__bellow_avg_br: `Por debajo<br>del promedio`,
    result_quiz__excellent_br: `¡Excelente!`,
    result_quiz__high: `Alto`,
    result_quiz__medium: `Medio`,
    result_quiz__low: `Bajo`,
    result_quiz__high_score_description: `¡Excelente trabajo! Estás bien versado en navegar por el mundo digital. Continúe con el buen trabajo y piense en compartir sus ideas.`,
    result_quiz__medium_score_description: `Estás en el camino correcto con una comprensión básica de la conducta digital. Ampliar sus conocimientos podría mejorar aún más sus experiencias digitales.`,
    result_quiz__low_score_description: `Existe la posibilidad de crecer en su viaje digital. Explorar materiales educativos puede ayudarle a mejorar su comprensión y su impacto en línea.`,
    result_quiz__mydq_score: `Mi puntaje DQ`,
    result_quiz__mydq_badges: `Mis insignias DQ`,
    result_quiz__you_are_ready_to_use_tech_rubirc: `Estás listo para usar la tecnología. Entiendes cómo usar la tecnología de forma segura. Continua desarrollando tus habilidades digitales para prosperar.`,
    result_quiz__your_score_is_above_average_rubric: `Tu puntuación está por encima de la media en comparación con la media mundial. Te recomendamos que sigas desarrollando un conjunto integral de habilidades de ciudadanía digital.`,
    result_quiz__your_score_is_less_than_average_rubirc: `Tu puntuación es inferior a la media en comparación con la media mundial. Te recomendamos que mejores tus habilidades de ciudadanía digital si está utilizando activamente la tecnología.`,
    result_quiz__you_may_potentially_be_exposed_rubirc: `Es posible que estés expuesto(a) a uno o más riesgos cibernéticos y hábitos poco saludables relacionados con tu uso digital. Te recomendamos que verifiques tu nivel de exposición al riesgo cibernético.`,
    result_quiz__your_score_span: `Tu puntuación está en el rango`,
    result_quiz__dct_discalimer_html_text: `*Descargo de responsabilidad: esta prueba de ciudadanía digital se desarrolló para crear conciencia sobre la importancia de la ciudadanía digital y puede no ser precisa para identificar su exposición al riesgo cibernético y su nivel de habilidad digital. Si estás interesado(a) en obtener una evaluación completa, puedes obtener más información <a href="/" target="_blank">aquí</a>.`,
    result_quiz__click_message_dct_type: `Haz clic en 'Siguiente' para averiguar qué tipo de ciudadano digital eres`,
    result_quiz__next: `Siguiente`,
    result_quiz__carefree_citizen: `Ciudadano despreocupado`,
    result_quiz__feel_comfort_online_text: `Te sientes más cómodo(a) en línea que fuera de línea`,
    result_quiz__invlove_with_risks_text: `Potencialmente involucrado(a) con varios riesgos cibernéticos`,
    result_quiz__ordinary_citizen: `Ciudadano(a) ordinario(a)`,
    result_quiz__avg_tech_user_message_text: `Usuario(a) promedio de tecnología que puede no ser completamente consciente de los riesgos ni las oportunidades.`,
    result_quiz__tend_to_follow_algo: `Tiendes a seguir pasivamente el algoritmo.`,
    result_quiz__always_on_citizen: `¡Ciudadano(a) siempre activo(a)!`,
    result_quiz__like_comm_with_others: `Es probable que te comunique mucho con otros(as) en línea`,
    result_quiz__maynot_consider_risks: `Puede que no tengas mucho en cuenta los posibles riesgos cibernéticos`,
    result_quiz__catious_citizen: `Ciudadano(a) cauteloso(a)`,
    result_quiz__aware_of_risks_not_fully_explore: `Estas consciente de los riesgos cibernéticos potenciales y no exploras completamente el mundo digital`,
    result_quiz__likely_having_risks_in_dw: `Es probable que tengas riesgos bajos en el mundo digital`,
    result_quiz__digilog_citizen: `¡Ciudadano(a) de DigiLog!`,
    result_quiz__balanced_digital_citizen: `Ciudadano(a) digital integral que equilibra la vida en línea y fuera de línea`,
    result_quiz__use_tech_mindfully: `Usas la tecnología conscientemente`,
    result_quiz__learn_more_html_text: `Obtén más información en <a href="/" target="_blank">DQ Assessment</a>.`,
    result_quiz__done: `Hecho`,
    dq_home__min_duration: `MIN`,
    dq_home__dc_test: `Prueba de ciudadanía MINDigital`,
    dq_home__free_assess: `Evaluación gratuita`,
    dq_home__bellow_avg: `Por debajo del promedio`,
    dq_home__avg: `Promedio`,
    dq_home__excellent: `Excelente`,
    dq_home__top_percent: `Alto`,
    dq_home__bottom_percent: `Bajo`,
    dq_home__dc_score_helps_what_message: `El puntaje de ciudadanía digital te ayuda a comprender tu nivel de habilidad digital para usar la tecnología de manera segura y responsable. También te ayuda a identificar qué tipo de Ciudadano(a) Digital eres.`,
    dq_home__share: `Compartir`,
    dq_home__retake: `Volver a responder`,
    dq_home__myscore_comparison: `Comparación de mi puntaje`,
    dq_home__myscore: `Mi puntaje`,
    dq_home__national_avg: `Promedio nacional`,
    dq_home__global_avg: `Promedio mundial`,
    dq_home__retake_test: `Volver a responer el examen`,
    dq_demographic__gender: `Género`,
    dq_demographic__male: `Masculino`,
    dq_demographic__female: `Femenino`,
    dq_demographic__non_binary: `No binario`,
    dq_demographic__prefer_not_to_answer: `Prefiero no responder`,
    dq_demographic__other: `Otro`,
    dq_demographic__other_specify: `Por favor especifique`,
    dq_demographic__year_of_birth: `Año de nacimiento`,
    dq_demographic__country: `País`,
    dq_country__afghanistan: `Afganistán`,
    dq_country__aland_islands: `Islas Aland`,
    dq_country__albania: `Albania`,
    dq_country__algeria: `Argelia`,
    dq_country__american_samoa: `Samoa Americana`,
    dq_country__andorra: `Andorra`,
    dq_country__angola: `Angola`,
    dq_country__anguilla: `Anguilla`,
    dq_country__antarctica: `Antártida `,
    dq_country__antigua_and_barbuda: `Antigua y Barbuda`,
    dq_country__argentina: `Argentina`,
    dq_country__armenia: `Armenia`,
    dq_country__aruba: `Aruba`,
    dq_country__australia: `Australia`,
    dq_country__austria: `Austria`,
    dq_country__azerbaijan: `Azerbaiyán `,
    dq_country__bahamas: `Bahamas`,
    dq_country__bahrain: `Bahrein`,
    dq_country__bangladesh: `Bangladesh`,
    dq_country__barbados: `Barbados`,
    dq_country__belarus: `Bielorrusia `,
    dq_country__belgium: `Bélgica`,
    dq_country__belize: `Belice`,
    dq_country__benin: `Benín`,
    dq_country__bermuda: `Bermudas`,
    dq_country__bhutan: `Bután`,
    dq_country__bolivia: `Bolivia`,
    dq_country__bosnia_and_herzegovina: `Bosnia-Herzegovina`,
    dq_country__botswana: `Botsuana`,
    dq_country__bouvet_island: `Isla Bouvet`,
    dq_country__brazil: `Brasil`,
    dq_country__british_indian_ocean_territory: `Territorio Británico del Océano Índico`,
    dq_country__brunei_darussalam: `Brunei Darussalam`,
    dq_country__bulgaria: `Bulgaria`,
    dq_country__burkina_faso: `Burkina Faso`,
    dq_country__burundi: `Burundi`,
    dq_country__cambodia: `Camboya`,
    dq_country__cameroon: `Camerún`,
    dq_country__canada: `Canadá`,
    dq_country__cape_verde: `Capo Verde`,
    dq_country__cayman_islands: `Islas Caimán`,
    dq_country__central_african_republic: `República Centroafricana        `,
    dq_country__chad: `Chad`,
    dq_country__chile: `Chile`,
    dq_country__china: `China`,
    dq_country__christmas_island: `Isla de Navidad`,
    dq_country__cocos_keeling_islands: `Islas Cocos`,
    dq_country__colombia: `Colombia`,
    dq_country__comoros: `Comores `,
    dq_country__congo: `Congo`,
    dq_country__congo_the_democratic_republic_of_the: `República Democrática del Congo`,
    dq_country__cook_islands: `Islas Cook`,
    dq_country__costa_rica: `Costa Rica`,
    dq_country__cote_divoire: `Costa de Marfil`,
    dq_country__croatia: `Croacia`,
    dq_country__cuba: `Cuba`,
    dq_country__cyprus: `Chipre`,
    dq_country__czech_republic: `República Checa`,
    dq_country__denmark: `Dinamarca`,
    dq_country__djibouti: `Djibouti, Yibuti`,
    dq_country__dominica: `Dominica`,
    dq_country__dominican_republic: `República Dominicana`,
    dq_country__ecuador: `Ecuador`,
    dq_country__egypt: `Egipto`,
    dq_country__el_salvador: `El Salvador`,
    dq_country__equatorial_guinea: `Guinea Ecuatorial`,
    dq_country__eritrea: `Eritrea`,
    dq_country__estonia: `Estonia`,
    dq_country__ethiopia: `Etiopía`,
    dq_country__falkland_islands_malvinas: `Islas Malvinas`,
    dq_country__faroe_islands: `Islas Feroe        `,
    dq_country__fiji: `Fiyi`,
    dq_country__finland: `Finlandia`,
    dq_country__france: `Francia`,
    dq_country__french_guiana: `Guayana Francesa`,
    dq_country__french_polynesia: `Polinesia Francesa        `,
    dq_country__french_southern_territories: `Tierras Australes y Antárticas Francesas`,
    dq_country__gabon: `Gabón `,
    dq_country__gambia: `Gambia`,
    dq_country__georgia: `Georgia`,
    dq_country__germany: `Alemania`,
    dq_country__ghana: `Ghana`,
    dq_country__gibraltar: `Gibraltar`,
    dq_country__greece: `Grecia`,
    dq_country__greenland: `Groenlandia `,
    dq_country__grenada: `Granada`,
    dq_country__guadeloupe: `Guadalupe`,
    dq_country__guam: `Guam`,
    dq_country__guatemala: `Guatemala`,
    dq_country__guernsey: `Guernesey`,
    dq_country__guinea: `República Guinea`,
    dq_country__guinea_bissau: `Guinea Bissau`,
    dq_country__guyana: `Guyana`,
    dq_country__haiti: `Haiti`,
    dq_country__heard_island_and_mcdonald_islands: `Territorio de las Islas Heard y McDonald`,
    dq_country__holy_see_vatican_city_state: `Santa Sede (Estado de la Ciudad del Vaticano)`,
    dq_country__honduras: `Honduras`,
    dq_country__hong_kong: `Hong Kong`,
    dq_country__hungary: `Hungría`,
    dq_country__iceland: `Islandia`,
    dq_country__india: `India`,
    dq_country__indonesia: `Indonesia`,
    dq_country__iran_islamic_republic_of: `Irán`,
    dq_country__iraq: `Iraq`,
    dq_country__ireland: `Irlanda`,
    dq_country__isle_of_man: `Isla de Man`,
    dq_country__israel: `Israel`,
    dq_country__italy: `Italia`,
    dq_country__jamaica: `Jamaica`,
    dq_country__japan: `Japón`,
    dq_country__jersey: `Jersey`,
    dq_country__jordan: `Jordania`,
    dq_country__kazakhstan: `Kazajstán `,
    dq_country__kenya: `Kenia`,
    dq_country__kiribati: `Kiribati`,
    dq_country__korea_democratic_peoples_republic_of: `Corea del Norte`,
    dq_country__korea_republic_of: `Corea del Sur        `,
    dq_country__kuwait: `Kuwait`,
    dq_country__kyrgyzstan: `Kirguistán `,
    dq_country__lao_peoples_democratic_republic: `Laos; oficialmente: República Democrática Popular Lao`,
    dq_country__latvia: `Letonia `,
    dq_country__lebanon: `Líbano`,
    dq_country__lesotho: `Lesotho`,
    dq_country__liberia: `Liberia`,
    dq_country__libyan_arab_jamahiriya: `Libia`,
    dq_country__liechtenstein: `Liechtenstein`,
    dq_country__lithuania: `Lituania`,
    dq_country__luxembourg: `Luxemburgo`,
    dq_country__macao: `Macao`,
    dq_country__macedonia_the_former_yugoslav_republic_of: `Macedonia, la ex República Yugoslava de Macedonia`,
    dq_country__madagascar: `Madagascar`,
    dq_country__malawi: `Malaui`,
    dq_country__malaysia: `Malasia`,
    dq_country__maldives: `Maldivas`,
    dq_country__mali: `Malí`,
    dq_country__malta: `Malta`,
    dq_country__marshall_islands: `Islas Marshall`,
    dq_country__martinique: `Martinica `,
    dq_country__mauritania: `Mauritania`,
    dq_country__mauritius: `Mauricio `,
    dq_country__mayotte: `Mayotte`,
    dq_country__mexico: `México`,
    dq_country__micronesia_federated_states_of: `Micronesia, Estados Federados de`,
    dq_country__moldova_republic_of: `Moldavia `,
    dq_country__monaco: `Mónaco`,
    dq_country__mongolia: `Mongolia`,
    dq_country__montserrat: `Montserrat `,
    dq_country__morocco: `Marruecos`,
    dq_country__mozambique: `Mozambique`,
    dq_country__myanmar: `Myanmar`,
    dq_country__namibia: `Namibia`,
    dq_country__nauru: `Nauru`,
    dq_country__nepal: `Nepal`,
    dq_country__netherlands: `Países Bajos`,
    dq_country__netherlands_antilles: `Antillas Holandesas`,
    dq_country__new_caledonia: `Nueva Caledonia`,
    dq_country__new_zealand: `Nueva Zelanda`,
    dq_country__nicaragua: `Nicaragua`,
    dq_country__niger: `Niger`,
    dq_country__nigeria: `Nigeria`,
    dq_country__niue: `Niue`,
    dq_country__norfolk_island: `Isla Norfolk`,
    dq_country__northern_mariana_islands: `Islas Marianas del Norte`,
    dq_country__norway: `Noruega`,
    dq_country__oman: `Omán`,
    dq_country__pakistan: `Pakistán`,
    dq_country__palau: `Palau`,
    dq_country__palestinian_territory_occupied: `Territorios Palestinos`,
    dq_country__panama: `Panamá`,
    dq_country__papua_new_guinea: `Papúa-Nueva Guinea`,
    dq_country__paraguay: `Paraguay`,
    dq_country__peru: `Perú`,
    dq_country__philippines: `Filipinas`,
    dq_country__pitcairn: `Pitcairn`,
    dq_country__poland: `Polonia`,
    dq_country__portugal: `Portugal`,
    dq_country__puerto_rico: `Puerto Rico`,
    dq_country__qatar: `Qatar`,
    dq_country__reunion: `Reunión`,
    dq_country__romania: `Rumanía`,
    dq_country__russian_federation: `Federación Rusa`,
    dq_country__rwanda: `Ruanda`,
    dq_country__saint_helena: `Santa Elena`,
    dq_country__saint_kitts_and_nevis: `San Cristobal y Nevis`,
    dq_country__saint_lucia: `Santa Lucía`,
    dq_country__saint_pierre_and_miquelon: `San Pedro y Miquelón`,
    dq_country__saint_vincent_and_the_grenadines: `San Vincente y Granadinas`,
    dq_country__samoa: `Samoa`,
    dq_country__san_marino: `San Marino`,
    dq_country__sao_tome_and_principe: `Santo Tomé y Príncipe        `,
    dq_country__saudi_arabia: `Arabia Saudita`,
    dq_country__senegal: `Senegal`,
    dq_country__serbia_and_montenegro: `Serbia y Montenegro`,
    dq_country__seychelles: `Seychelles`,
    dq_country__sierra_leone: `Sierra Leona`,
    dq_country__singapore: `Singapur`,
    dq_country__slovakia: `Eslovaquia`,
    dq_country__slovenia: `Eslovenia`,
    dq_country__solomon_islands: `Islas Salomón`,
    dq_country__somalia: `Somalia`,
    dq_country__south_africa: `Sudáfrica`,
    dq_country__south_georgia_and_the_south_sandwich_islands: `Islas Georgias del Sur y Sandwich del Sur`,
    dq_country__spain: `España`,
    dq_country__sri_lanka: `Sri Lanka`,
    dq_country__sudan: `Sudán`,
    dq_country__suriname: `Surinam`,
    dq_country__svalbard_and_jan_mayen: `Svalbard y Jan Mayen`,
    dq_country__swaziland: `Suazilandia`,
    dq_country__sweden: `Suecia`,
    dq_country__switzerland: `Suiza`,
    dq_country__syrian_arab_republic: `República Árabe Siria`,
    dq_country__taiwan_province_of_china: `Taiwán, Provincia de China`,
    dq_country__tajikistan: `Tayikistán`,
    dq_country__tanzania_united_republic_of: `Tanzanía, República Unida de`,
    dq_country__thailand: `Tailandia`,
    dq_country__timor_leste: `Timor Oriental`,
    dq_country__togo: `Togo`,
    dq_country__tokelau: `Tokelau`,
    dq_country__tonga: `Tonga`,
    dq_country__trinidad_and_tobago: `Trinidad y Tobago`,
    dq_country__tunisia: `Túnez`,
    dq_country__turkey: `Turquía`,
    dq_country__turkmenistan: `Turkmenistán`,
    dq_country__turks_and_caicos_islands: `Islas Turcas y Caicos`,
    dq_country__tuvalu: `Tuvalu`,
    dq_country__uganda: `Uganda`,
    dq_country__ukraine: `Ucrania`,
    dq_country__united_arab_emirates: `Emiratos Árabes Unidos`,
    dq_country__united_kingdom: `Reino Unido`,
    dq_country__united_states: `Estados Unidos`,
    dq_country__united_states_minor_outlying_islands: `Islas Ultramarinas Menores de los Estados Unidos`,
    dq_country__uruguay: `Uruguay`,
    dq_country__uzbekistan: `Uzbekistán`,
    dq_country__vanuatu: `Vanuatu`,
    dq_country__venezuela: `Venezuela`,
    dq_country__viet_nam: `Vietnam`,
    dq_country__virgin_islands_british: `Islas Vírgenes Británicas`,
    dq_country__virgin_islands_us: `Islas Vírgenes, EE. UU.`,
    dq_country__wallis_and_futuna: `Wallis y Futuna`,
    dq_country__western_sahara: `Sahara Occidental`,
    dq_country__yemen: `Yemen`,
    dq_country__zambia: `Zambia`,
    dq_country__zimbabwe: `Zimbabue`,
    dq_demographic__postal_code: `Código Postal`,
    dq_demographic__next: `Siguiente`,
    dq_demographic__get_score: `Obtener puntuación`,
    dq_demographic__plz_fill_req_fields: `Por favor llena todos los campos requeridos`,
    uo__lets_start: `Empecemos`,
    uo__dct_test_intro_message_text: `Esta prueba te ayudará a verificar tu nivel de ciudadanía digital, para saber si estás listo(a) para usar dispositivos y medios digitales de manera segura y responsable.`,
    quiz_result_see_your_score: `¡Ve tu puntaje!`,
    post_quiz_resume_test: `Reanudar prueba`,
    uo__username: `Nombre de usuario`,
    uo__failed_to_proceed: `¡Error al continuar!`,
    uo__save_failed_try_again: `¡Error al guardar! Intenta otra vez…`,
    uo__continue_as_guest: `¿Continuar como invitado?`,
    uo__guest_login: `Acceso de usuario invitado`,
    dq_profile__inactive: `Tu cuenta no está activa, comunícate con tu organización`,
    dq_profile__multiple: `Tu cuenta está asociada con varias organizaciones, selecciona un perfil`,
    dq_profile__not_exists: `Este perfil no existe`,
    dq_platform__select: `Por favor selecciona una plataforma`,
    dq_platform__dq_assessment: `Evaluación DQ`,
    dq_platform__dq_world: `Mundo DQ`,
    uo__page_not_found: `No encontrado`,
    uo__goto_home: `Ir a la página de inicio`,
}

const lc_vi = {
    menu__hello_text: `Xin chào`,
    menu__guest_text: `Khách mời`,
    menu__home: `Trang chủ`,
    menu__register: `Đăng ký`,
    menu__settings: `Cài đặt`,
    menu__switch_profile: `Chuyển hồ sơ`,
    menu__goto_dqw: `Đến với DQ World`,
    menu__logout: `Đăng xuất`,
    uo__welcome_dct_text: `Chào mừng bạn đến với <br>Bài kiểm tra công dân kỹ thuật số!`,
    uo__start_test_text: `Bắt đầu kiểm tra`,
    post_q_landing__congrats: `Chúc mừng!`,
    post_q_landing__finished_test_message_text: `Bạn đã hoàn thành bài kiểm tra.<br>Để nhận được điểm, bạn cần đăng ký tài khoản.`,
    post_q_landing__finished_test_short_message: `Bạn đã hoàn thành bài kiểm tra.`,
    post_q_landing__if_under_18_text: `Nếu bạn dưới 18 tuổi, bạn cần có email của cha mẹ hoặc người giám hộ và sự đồng ý để đăng ký.`,
    post_q_landing__signup: `Đăng ký`,
    post_q_landing__no_thanks: `Không, cám ơn`,
    persist_guest__fname: `Tên`,
    persist_guest__lname: `Họ`,
    persist_guest__email_address: `Địa chỉ email`,
    persist_guest__require_parent_email_text: `(Cần có địa chỉ email của Phụ huynh/Người giám hộ nếu bạn dưới 18 tuổi.)`,
    persist_guest__password: `Mật khẩu`,
    persist_guest__confirm_password: `Xác nhận mật khẩu`,
    persist_guest__have_access_code: `Có mã truy cập? Nhấn vào đây để nhập!`,
    persist_guest__access_code_span: `Mã truy cập <span style="font-weight: bold;">(bấm vào đây để ẩn)</span>`,
    persist_guest__accept_terms_of_use: `Tôi đã đọc và chấp nhận <br><a href="/terms_of_use" target="_blank"> ĐIỀU KHOẢN SỬ DỤNG </a> và<a href="/privacy_policy" target="_blank"> CHÍNH SÁCH RIÊNG TƯ</a >.`,
    persist_guest__register: `Đăng ký`,
    persist_guest__already_have_account: `Bạn đã có tài khoản?`,
    persist_guest__login_here: `Đăng nhập tại đây`,
    persist_guest__login: `Đăng nhập`,
    persist_guest__signup: `Đăng ký`,
    persist_guest__not_have_an_account: `Bạn chưa có tài khoản?`,
    persist_guest__register_here: `Đăng ký ở đây`,
    persist_guest__forgot_password: `Quên mật khẩu?`,
    persist_guest__login_to_dashboard: `Đăng nhập vào Bảng điều khiển DQ`,
    persist_guest__login_with_google: `Đăng nhập bằng Google`,
    persist_guest__register_with_google: `Đăng ký với Google`,
    persist_guest__reset_pass_guide: `Vui lòng nhập tên người dùng đã đăng ký.<br/>Sau đó, chúng tôi sẽ gửi liên kết đặt lại mật khẩu qua email được liên kết với tài khoản.`,
    persist_guest__send_link: `Gửi liên kết`,
    persist_guest__send_link_success: `Đặt lại liên kết đã gửi thành công Kiểm tra hộp thư đến của bạn!`,
    persist_guest__send_link_failed: `Không gửi được liên kết đặt lại`,
    persist_guest__reset_password: `Đặt lại mật khẩu`,
    persist_guest__reset_pass_success: `Bạn đã thay đổi mật khẩu của bạn. Vui lòng đăng nhập bằng tên người dùng hoặc email với mật khẩu mới.`,
    persist_guest__reset_pass_failed: `Không gửi được mật khẩu đặt lại`,
    persist_guest__reset_pass_link_invalid: `Liên kết đặt lại mật khẩu không hợp lệ`,
    persist_guest__recover_password_here: `Khôi phục mật khẩu tại đây`,
    persist_guest__go_to_login: `Đi tới Đăng nhập`,
    uo__register_as_individual: `Đăng ký với tư cách CÁ NHÂN`,
    uo__register_as_company: `Đăng ký làm CÔNG TY`,
    uo__register_as_school: `Đăng ký làm TRƯỜNG`,
    uo__not_found: `Không tìm thấy`,
    persist_guest__privacy_policy: `Không tìm thấy`,
    persist_guest__terms_of_use: `Điều khoản sử dụng`,
    persist_guest__wrong_pass_user_not_found: `tên người dùng/email đã nhập sai: không tìm thấy người dùng`,
    persist_guest__plz_fill_req_fields: `Vui lòng điền vào tất cả các trường bắt buộc`,
    uo__plz_accept_terms_of_use: `Vui lòng chấp nhận Điều khoản sử dụng`,
    uo__pass_mismatch_plz_retype: `Mật khẩu không khớp! Vui lòng gõ lại`,
    uo__err_loading_guest: `Lỗi khi tải người dùng khách!`,
    uo__plz_set_ur_new_pass: `Vui lòng đặt mật khẩu mới của bạn.`,
    uo__no_assessment_available_chk_later: `Hiện tại KHÔNG có Đánh giá nào, Vui lòng kiểm tra lại sau`,
    uo__dq_under_maintainance_txt: `Đánh giá DQ hiện đang được bảo trì.`,
    uo__not_available_try_later: `Hiện không có sẵn, vui lòng thử lại sau!`,
    uo__submit: `Nộp`,
    uo__view_score: `Xem điểm`,
    uo__admin_login: `Đăng nhập quản trị viên`,
    uo__plz_wait: `Vui lòng chờ...`,
    uo__loading: `Đang tải…`,
    uo__loaded: `Đã tải`,
    result_quiz__require_attention: `Yêu cầu chú ý!`,
    result_quiz__bellow_avg: `Dưới mức trung bình!`,
    result_quiz__good_job: `Làm tốt lắm!`,
    result_quiz__excellent: `Xuất sắc!`,
    result_quiz__require_attention_br: `Yêu cầu<br>Chú ý`,
    result_quiz__above_avg_br: `Trên<br>Trung bình`,
    result_quiz__bellow_avg_br: `Dưới<br>Trung bình`,
    result_quiz__excellent_br: `Xuất sắc`,
    result_quiz__high: `Cao`,
    result_quiz__medium: `Trung bình`,
    result_quiz__low: `Thấp`,
    result_quiz__high_score_description: `Công việc tuyệt vời! Bạn rất thành thạo trong việc điều hướng thế giới kỹ thuật số. Hãy tiếp tục làm tốt công việc của mình và suy nghĩ về việc chia sẻ những hiểu biết sâu sắc của bạn.`,
    result_quiz__medium_score_description: `Bạn đang đi đúng hướng với sự hiểu biết cơ bản về hành vi kỹ thuật số. Mở rộng kiến ​​thức của bạn có thể nâng cao hơn nữa trải nghiệm kỹ thuật số của bạn.`,
    result_quiz__low_score_description: `Có cơ hội phát triển trong hành trình kỹ thuật số của bạn. Khám phá các tài liệu giáo dục có thể giúp nâng cao hiểu biết và tác động trực tuyến của bạn.`,
    result_quiz__mydq_score: `Điểm DQ của tôi`,
    result_quiz__mydq_badges: `Huy hiệu DQ của tôi`,
    result_quiz__you_are_ready_to_use_tech_rubirc: `Bạn đã sẵn sàng sử dụng công nghệ. Bạn hiểu cách sử dụng công nghệ một cách an toàn. Tiếp tục xây dựng các kỹ năng kỹ thuật số của bạn để phát triển mạnh mẽ.`,
    result_quiz__your_score_is_above_average_rubric: `Điểm của bạn cao hơn mức trung bình so với mức trung bình toàn cầu. Chúng tôi khuyên bạn nên tiếp tục xây dựng một bộ kỹ năng công dân kỹ thuật số toàn diện.`,
    result_quiz__your_score_is_less_than_average_rubirc: `Điểm của bạn thấp hơn mức trung bình so với mức trung bình toàn cầu. Chúng tôi khuyên bạn nên nâng cao kỹ năng công dân kỹ thuật số của mình nếu bạn đang tích cực sử dụng công nghệ.`,
    result_quiz__you_may_potentially_be_exposed_rubirc: `Bạn có thể gặp phải một hoặc nhiều rủi ro mạng và thói quen không lành mạnh liên quan đến việc sử dụng kỹ thuật số của bạn. Chúng tôi khuyên bạn nên kiểm tra mức độ tiếp xúc với rủi ro mạng của mình.`,
    result_quiz__your_score_span: `Điểm của bạn ở mức `,
    result_quiz__dct_discalimer_html_text: `*Tuyên bố miễn trừ trách nhiệm: Bài kiểm tra quyền công dân kỹ thuật số này được phát triển để nâng cao nhận thức về tầm quan trọng của quyền công dân kỹ thuật số và có thể không chính xác trong việc xác định mức độ rủi ro mạng và trình độ kỹ năng kỹ thuật số của bạn. Nếu muốn nhận được đánh giá đầy đủ, bạn có thể tìm hiểu thêm <a href="/" target="_blank">tại đây</a>.`,
    result_quiz__click_message_dct_type: `Nhấp vào 'Tiếp theo' để tìm hiểu xem bạn thuộc Loại Công dân Kỹ thuật số nào`,
    result_quiz__next: `Kế tiếp`,
    result_quiz__carefree_citizen: `Công dân vô tư!`,
    result_quiz__feel_comfort_online_text: `Cảm thấy trực tuyến thoải mái hơn ngoại tuyến`,
    result_quiz__invlove_with_risks_text: `Có khả năng liên quan đến nhiều rủi ro mạng khác nhau`,
    result_quiz__ordinary_citizen: `Công dân bình thường!`,
    result_quiz__avg_tech_user_message_text: `Người dùng công nghệ trung bình có thể không nhận thức đầy đủ về rủi ro cũng như cơ hội`,
    result_quiz__tend_to_follow_algo: `Có xu hướng làm theo thuật toán một cách thụ động`,
    result_quiz__always_on_citizen: `Công dân luôn sẵn sàng!`,
    result_quiz__like_comm_with_others: `Có khả năng giao tiếp trực tuyến nhiều với người khác`,
    result_quiz__maynot_consider_risks: `Có thể không cân nhắc nhiều về các rủi ro mạng tiềm ẩn`,
    result_quiz__catious_citizen: `Công dân cẩn trọng!`,
    result_quiz__aware_of_risks_not_fully_explore: `Nhận thức được các rủi ro mạng tiềm ẩn và chưa khám phá đầy đủ thế giới kỹ thuật số`,
    result_quiz__likely_having_risks_in_dw: `Có khả năng gặp rủi ro thấp trong thế giới kỹ thuật số`,
    result_quiz__digilog_citizen: `Công dân DigiLog!`,
    result_quiz__balanced_digital_citizen: `Công dân kỹ thuật số toàn diện, biết cân bằng cuộc sống trực tuyến và ngoại tuyến`,
    result_quiz__use_tech_mindfully: `Sử dụng công nghệ một cách có ý thức`,
    result_quiz__learn_more_html_text: `Tìm hiểu thêm tại <a href="/" target="_blank">Chỉ số DQ</a>.`,
    result_quiz__done: `Xong`,
    dq_home__min_duration: `PHÚT`,
    dq_home__dc_test: `Kiểm tra quyền công dân kỹ thuật số`,
    dq_home__free_assess: `Đánh giá miễn phí`,
    dq_home__bellow_avg: `Dưới mức trung bình`,
    dq_home__avg: `Trung bình`,
    dq_home__excellent: `Xuất sắc`,
    dq_home__top_percent: `đứng đầu`,
    dq_home__bottom_percent: `đáy`,
    dq_home__dc_score_helps_what_message: `Điểm Công dân Kỹ thuật số giúp bạn hiểu được trình độ kỹ năng kỹ thuật số của mình để sử dụng công nghệ một cách an toàn và có trách nhiệm. Nó cũng giúp bạn xác định loại Công dân kỹ thuật số của mình.`,
    dq_home__share: `Chia sẻ`,
    dq_home__retake: `Làm lại`,
    dq_home__myscore_comparison: `So sánh điểm của tôi`,
    dq_home__myscore: `Điểm số của tôi`,
    dq_home__national_avg: `Trung bình quốc gia`,
    dq_home__global_avg: `Trung bình toàn cầu`,
    dq_home__retake_test: `Làm lại bài kiểm tra`,
    dq_demographic__gender: `Giới tính`,
    dq_demographic__male: `Nam giới`,
    dq_demographic__female: `Nữ giới`,
    dq_demographic__non_binary: `Phi nhị phân`,
    dq_demographic__prefer_not_to_answer: `Không thích trả lời`,
    dq_demographic__other: `Khác`,
    dq_demographic__other_specify: `Vui lòng chỉ rõ`,
    dq_demographic__year_of_birth: `Năm sinh`,
    dq_demographic__country: `Quốc gia`,
    dq_country__afghanistan: `Afghanistan`,
    dq_country__aland_islands: `Quần đảo Aland`,
    dq_country__albania: `Albania`,
    dq_country__algeria: `Algérie`,
    dq_country__american_samoa: `American Samoa`,
    dq_country__andorra: `Andorra`,
    dq_country__angola: `Ăng-gô-la`,
    dq_country__anguilla: `Anguilla`,
    dq_country__antarctica: `Nam Cực`,
    dq_country__antigua_and_barbuda: `Antigua và Barbuda`,
    dq_country__argentina: `Argentina`,
    dq_country__armenia: `Armenia`,
    dq_country__aruba: `Aruba`,
    dq_country__australia: `Úc`,
    dq_country__austria: `Áo`,
    dq_country__azerbaijan: `Azerbaijan`,
    dq_country__bahamas: `Bahamas`,
    dq_country__bahrain: `Bahrain`,
    dq_country__bangladesh: `Bangladesh`,
    dq_country__barbados: `Barbados`,
    dq_country__belarus: `Bêlarut`,
    dq_country__belgium: `Bỉ`,
    dq_country__belize: `Belize`,
    dq_country__benin: `Bénin`,
    dq_country__bermuda: `Bermuda`,
    dq_country__bhutan: `Bhutan`,
    dq_country__bolivia: `Bôlivia`,
    dq_country__bosnia_and_herzegovina: `Bosnia và Herzegovina`,
    dq_country__botswana: `Botswana`,
    dq_country__bouvet_island: `Đảo Bouvet`,
    dq_country__brazil: `Brazil`,
    dq_country__british_indian_ocean_territory: `Lãnh thổ Ấn Độ Dương thuộc Anh`,
    dq_country__brunei_darussalam: `Vương quốc Bru-nây`,
    dq_country__bulgaria: `Bulgaria`,
    dq_country__burkina_faso: `Burkina Faso`,
    dq_country__burundi: `Burundi`,
    dq_country__cambodia: `Campuchia`,
    dq_country__cameroon: `Ca-mơ-run`,
    dq_country__canada: `Canada`,
    dq_country__cape_verde: `Cabo Verde`,
    dq_country__cayman_islands: `Quần đảo Cayman`,
    dq_country__central_african_republic: `Cộng hòa Trung Phi`,
    dq_country__chad: `Tchad`,
    dq_country__chile: `Chilê`,
    dq_country__china: `Trung Quốc`,
    dq_country__christmas_island: `Đảo Giáng Sinh`,
    dq_country__cocos_keeling_islands: `Quần đảo Cocos (Keeling)`,
    dq_country__colombia: `Colombia`,
    dq_country__comoros: `Comoros`,
    dq_country__congo: `Congo`,
    dq_country__congo_the_democratic_republic_of_the: `Cộng hòa Dân chủ Congo`,
    dq_country__cook_islands: `Quần đảo Cook`,
    dq_country__costa_rica: `Costa Rica`,
    dq_country__cote_divoire: `Bờ Biển Ngà`,
    dq_country__croatia: `Croatia`,
    dq_country__cuba: `Cuba`,
    dq_country__cyprus: `Síp`,
    dq_country__czech_republic: `Cộng hòa Séc`,
    dq_country__denmark: `Đan Mạch`,
    dq_country__djibouti: `Djibouti`,
    dq_country__dominica: `Dominica`,
    dq_country__dominican_republic: `Cộng hòa Dominica`,
    dq_country__ecuador: `Ecuador`,
    dq_country__egypt: `Ai Cập`,
    dq_country__el_salvador: `El Salvador`,
    dq_country__equatorial_guinea: `Equatorial Guinea`,
    dq_country__eritrea: `Eritrea`,
    dq_country__estonia: `Estonia`,
    dq_country__ethiopia: `Ethiopia`,
    dq_country__falkland_islands_malvinas: `Quần đảo Falkland (Malvinas)`,
    dq_country__faroe_islands: `Quần đảo Faroe`,
    dq_country__fiji: `Fiji`,
    dq_country__finland: `Phần Lan`,
    dq_country__france: `Pháp`,
    dq_country__french_guiana: `Guiana thuộc Pháp`,
    dq_country__french_polynesia: `Polynesia thuộc Pháp`,
    dq_country__french_southern_territories: `Lãnh thổ phía Nam của Pháp`,
    dq_country__gabon: `Gabon`,
    dq_country__gambia: `Gambia`,
    dq_country__georgia: `Gruzia`,
    dq_country__germany: `nước Đức`,
    dq_country__ghana: `Ghana`,
    dq_country__gibraltar: `Gibraltar`,
    dq_country__greece: `Hy Lạp`,
    dq_country__greenland: `Greenland`,
    dq_country__grenada: `Grenada`,
    dq_country__guadeloupe: `Guadeloupe`,
    dq_country__guam: `đảo Guam`,
    dq_country__guatemala: `Guatemala`,
    dq_country__guernsey: `Guernsey`,
    dq_country__guinea: `Ghi-nê`,
    dq_country__guinea_bissau: `Guiné-Bissau`,
    dq_country__guyana: `Guyana`,
    dq_country__haiti: `Haiti`,
    dq_country__heard_island_and_mcdonald_islands: `Đảo Heard và Quần đảo McDonald`,
    dq_country__holy_see_vatican_city_state: `Tòa Thánh (Thành phố Vatican)`,
    dq_country__honduras: `Honduras`,
    dq_country__hong_kong: `Hồng Kông`,
    dq_country__hungary: `Hungary`,
    dq_country__iceland: `Nước Iceland`,
    dq_country__india: `Ấn Độ`,
    dq_country__indonesia: `Indonesia`,
    dq_country__iran_islamic_republic_of: `Iran (Cộng hòa Hồi giáo`,
    dq_country__iraq: `Irắc`,
    dq_country__ireland: `Ireland`,
    dq_country__isle_of_man: `Đảo Man`,
    dq_country__israel: `Người israel`,
    dq_country__italy: `Nước Ý`,
    dq_country__jamaica: `Jamaica`,
    dq_country__japan: `Nhật Bản`,
    dq_country__jersey: `áo đấu`,
    dq_country__jordan: `Jordan`,
    dq_country__kazakhstan: `Kazakhstan`,
    dq_country__kenya: `Kenya`,
    dq_country__kiribati: `Kiribati`,
    dq_country__korea_democratic_peoples_republic_of: `Hàn Quốc, Cộng hòa Dân chủ Nhân dân`,
    dq_country__korea_republic_of: `Hàn Quốc, Cộng hòa`,
    dq_country__kuwait: `Cô-oét`,
    dq_country__kyrgyzstan: `Kyrgyzstan`,
    dq_country__lao_peoples_democratic_republic: `Cộng hòa Dân chủ nhân dân Lào`,
    dq_country__latvia: `Latvia`,
    dq_country__lebanon: `Liban`,
    dq_country__lesotho: `Lesotho`,
    dq_country__liberia: `Liberia`,
    dq_country__libyan_arab_jamahiriya: `Libyan Arab Jamahiriya`,
    dq_country__liechtenstein: `Liechtenstein`,
    dq_country__lithuania: `Litva`,
    dq_country__luxembourg: `Luxembourg`,
    dq_country__macao: `Macao`,
    dq_country__macedonia_the_former_yugoslav_republic_of: `Macedonia, Cộng hòa Nam Tư cũ`,
    dq_country__madagascar: `Madagascar`,
    dq_country__malawi: `Malawi`,
    dq_country__malaysia: `Malaysia`,
    dq_country__maldives: `Maldives`,
    dq_country__mali: `Mali`,
    dq_country__malta: `Malta`,
    dq_country__marshall_islands: `đảo Marshall`,
    dq_country__martinique: `Martinique`,
    dq_country__mauritania: `Mauritanie`,
    dq_country__mauritius: `Mô-ri-xơ`,
    dq_country__mayotte: `Mayotte`,
    dq_country__mexico: `México`,
    dq_country__micronesia_federated_states_of: `Micronesia, Liên bang`,
    dq_country__moldova_republic_of: `Moldova, Cộng hòa`,
    dq_country__monaco: `Monaco`,
    dq_country__mongolia: `Mông Cổ`,
    dq_country__montserrat: `Montserrat`,
    dq_country__morocco: `Ma-rốc`,
    dq_country__mozambique: `Mozambique`,
    dq_country__myanmar: `Myanmar`,
    dq_country__namibia: `Namibia`,
    dq_country__nauru: `Nauru`,
    dq_country__nepal: `Nepal`,
    dq_country__netherlands: `Hà Lan`,
    dq_country__netherlands_antilles: `Antille thuộc Hà Lan`,
    dq_country__new_caledonia: `Tân Caledonia`,
    dq_country__new_zealand: `New Zealand`,
    dq_country__nicaragua: `Nicaragua`,
    dq_country__niger: `Niger`,
    dq_country__nigeria: `Nigeria`,
    dq_country__niue: `Niue`,
    dq_country__norfolk_island: `Đảo Norfolk`,
    dq_country__northern_mariana_islands: `Quần đảo Bắc Mariana`,
    dq_country__norway: `Na Uy`,
    dq_country__oman: `Ô-man`,
    dq_country__pakistan: `Pakistan`,
    dq_country__palau: `Palau`,
    dq_country__palestinian_territory_occupied: `Lãnh thổ Palestine, bị chiếm đóng`,
    dq_country__panama: `Panama`,
    dq_country__papua_new_guinea: `Papua New Guinea`,
    dq_country__paraguay: `Paraguay`,
    dq_country__peru: `Peru`,
    dq_country__philippines: `Philippin`,
    dq_country__pitcairn: `Pitcairn`,
    dq_country__poland: `Ba Lan`,
    dq_country__portugal: `Bồ Đào Nha`,
    dq_country__puerto_rico: `Puerto Rico`,
    dq_country__qatar: `Qatar`,
    dq_country__reunion: `Đoàn tụ`,
    dq_country__romania: `Rumani`,
    dq_country__russian_federation: `Liên Bang Nga`,
    dq_country__rwanda: `RWANDA`,
    dq_country__saint_helena: `Thánh Helena`,
    dq_country__saint_kitts_and_nevis: `Saint Kitts và Nevis`,
    dq_country__saint_lucia: `Thánh Lucia`,
    dq_country__saint_pierre_and_miquelon: `Thánh Pierre và Miquelon`,
    dq_country__saint_vincent_and_the_grenadines: `Saint Vincent và Grenadines`,
    dq_country__samoa: `Samoa`,
    dq_country__san_marino: `San Marino`,
    dq_country__sao_tome_and_principe: `Sao Tome và Principe`,
    dq_country__saudi_arabia: `Ả Rập Saudi`,
    dq_country__senegal: `Sénégal`,
    dq_country__serbia_and_montenegro: `Serbia và Montenegro`,
    dq_country__seychelles: `Seychelles`,
    dq_country__sierra_leone: `Sierra Leone`,
    dq_country__singapore: `Singapore`,
    dq_country__slovakia: `Slovakia`,
    dq_country__slovenia: `Slovenia`,
    dq_country__solomon_islands: `Quần đảo Solomon`,
    dq_country__somalia: `Somali`,
    dq_country__south_africa: `Nam Phi`,
    dq_country__south_georgia_and_the_south_sandwich_islands: `Nam Georgia và Quần đảo Nam Sandwich`,
    dq_country__spain: `Tây ban nha`,
    dq_country__sri_lanka: `Sri Lanka`,
    dq_country__sudan: `Sudan`,
    dq_country__suriname: `Suriname`,
    dq_country__svalbard_and_jan_mayen: `Svalbard và Jan Mayen`,
    dq_country__swaziland: `Swaziland`,
    dq_country__sweden: `Thụy Điển`,
    dq_country__switzerland: `Thụy sĩ`,
    dq_country__syrian_arab_republic: `Cộng Hòa Arab Syrian`,
    dq_country__taiwan_province_of_china: `Đài Loan, tỉnh của Trung Quốc`,
    dq_country__tajikistan: `Tajikistan`,
    dq_country__tanzania_united_republic_of: `Tanzania, Cộng hòa thống nhất`,
    dq_country__thailand: `Thái Lan`,
    dq_country__timor_leste: `Timor-Leste`,
    dq_country__togo: `Togo`,
    dq_country__tokelau: `Tokelau`,
    dq_country__tonga: `Tonga`,
    dq_country__trinidad_and_tobago: `Trinidad và Tobago`,
    dq_country__tunisia: `Tunisia`,
    dq_country__turkey: `Thổ Nhĩ Kỳ`,
    dq_country__turkmenistan: `Turkmenistan`,
    dq_country__turks_and_caicos_islands: `Quần đảo Turks và Caicos`,
    dq_country__tuvalu: `Tuvalu`,
    dq_country__uganda: `Uganda`,
    dq_country__ukraine: `Ukraina`,
    dq_country__united_arab_emirates: `các Tiểu Vương Quốc Ả Rập Thống Nhất`,
    dq_country__united_kingdom: `Vương quốc Anh`,
    dq_country__united_states: `Hoa Kỳ`,
    dq_country__united_states_minor_outlying_islands: `Quần đảo xa xôi nhỏ của Hoa Kỳ`,
    dq_country__uruguay: `Uruguay`,
    dq_country__uzbekistan: `Uzbekistan`,
    dq_country__vanuatu: `Vanuatu`,
    dq_country__venezuela: `Venezuela`,
    dq_country__viet_nam: `Việt Nam`,
    dq_country__virgin_islands_british: `Quần đảo Virgin, Anh`,
    dq_country__virgin_islands_us: `Quần đảo Virgin, Mỹ`,
    dq_country__wallis_and_futuna: `Wallis và Futuna`,
    dq_country__western_sahara: `Phía tây Sahara`,
    dq_country__yemen: `Yêmen`,
    dq_country__zambia: `Zambia`,
    dq_country__zimbabwe: `Zimbabwe`,
    dq_demographic__postal_code: `Mã bưu điện`,
    dq_demographic__next: `Kế tiếp`,
    dq_demographic__get_score: `Nhận điểm`,
    dq_demographic__plz_fill_req_fields: `Vui lòng điền vào tất cả các trường bắt buộc`,
    uo__lets_start: `Hãy bắt đầu`,
    uo__dct_test_intro_message_text: `Bài kiểm tra này sẽ giúp bạn kiểm tra mức độ công dân kỹ thuật số của mình, để biết liệu bạn đã sẵn sàng sử dụng các thiết bị và phương tiện kỹ thuật số một cách an toàn và có trách nhiệm hay chưa.`,
    quiz_result_see_your_score: `Xem điểm của bạn!`,
    post_quiz_resume_test: `Tiếp tục kiểm tra`,
    uo__username: `Tên tài khoản`,
    uo__failed_to_proceed: `Xử lý thất bại!`,
    uo__save_failed_try_again: `Lưu không thành công! Thử lại...`,
    uo__continue_as_guest: `Tiếp tục với tư cách là Khách?`,
    uo__guest_login: `Đăng nhập của khách`,
    dq_profile__inactive: `Tài khoản của bạn không hoạt động, vui lòng liên hệ với tổ chức của bạn`,
    dq_profile__multiple: `Tài khoản của bạn được liên kết với nhiều tổ chức, vui lòng chọn một hồ sơ`,
    dq_profile__not_exists: `Hồ sơ này không tồn tại`,
    dq_platform__select: `Vui lòng chọn một nền tảng`,
    dq_platform__dq_assessment: `Đánh giá DQ`,
    dq_platform__dq_world: `DQ World`,
    uo__page_not_found: `Không tìm thấy`,
    uo__goto_home: `Đi tới Trang chủ`,
    uo__wrong_username_enter: `Tên người dùng đã nhập sai!`,
    uo__wrong_pass_enter: `Nhập sai mật khẩu!`,
}

export const LC_VALUES_APP = {
    'en': lc_en,
    'ko': lc_ko,
    'ja': lc_ja,
    'th': lc_th,
    'es': lc_es,
    'vi': lc_vi
}

const validate_keys_syncing = () => {
    const en_keys_count = Object.keys(lc_en).length;
    const ko_keys_count = Object.keys(lc_ko).length;
    const ja_keys_count = Object.keys(lc_ja).length;
    const th_keys_count = Object.keys(lc_th).length;
    const es_keys_count = Object.keys(lc_es).length;
    const vi_keys_count = Object.keys(lc_vi).length;
    if (en_keys_count === ko_keys_count && en_keys_count === ja_keys_count && en_keys_count === th_keys_count && en_keys_count === es_keys_count && en_keys_count === vi_keys_count) {
        console.log('lc_values::app:: keys are in sync');
    } else {
        console.log('lc_values::app:: FATAL ISSUE : keys not in sync');
    }
}

validate_keys_syncing();

